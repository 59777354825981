<template>
    <div class="padding">
        <div class="row col-md-12">
            <h3>
                <span> Add Service </span>
            </h3>
        </div>
        <v-form class="row" ref="form" v-model.trim="valid">
            <div class="col-lg-4 col-md-4 col-12">
                <label for="" class="text-gray">Service Category</label>
                <v-text-field solo class="mt-2" :rules="requiredRules" v-model.trim="category"></v-text-field>
            </div>
            <div class="col-lg-4 col-md-4 col-12">
                <label for="" class="text-gray">Service type</label>
                <v-text-field solo class="mt-2" :rules="requiredRules" v-model.trim="type"></v-text-field>
            </div>
            <div class="col-lg-4 col-md-4 col-12">
                <label for="" class="text-gray">Service Name</label>
                <v-text-field solo class="mt-2" :rules="requiredRules" v-model.trim="name"></v-text-field>
            </div>
        </v-form>
        <div class="row">
            <div class="col-lg-12  text-end">
                <v-btn class="blue-buttons" @click="addService">Submit</v-btn>
            </div>

        </div>
      <successModal  ref="success_modal" v-on:confirm="closeSuccessModal"></successModal>
    </div>
</template>
<script>
import api from '../../../../services/api'
import successModal from '../../../TablesTemplate/SuccessDialog.vue'
    export default{
        name:'AddService',
        components:{successModal},
        data(){
            return{
                category:'',
                type:'',
                name:'',
                valid:false,
                requiredRules: [
                v => !!v || 'This Field is required',
                ],
            }
        },
        computed:{
            route(){
                return this.$route.path
            },
            getClient(){
                return this.$store.getters.getClientGetter
            }
        },
        methods:{
            closeSuccessModal(){
              this.$router.push('/member/services');
            },
            addService(){
            this.$refs.form.validate();
            if(this.valid){
                    let data = {
                        "category": this.category,
                        "type": this.type,
                        "name": this.name
                    }
                    let user = JSON.parse(localStorage.getItem('user'));
                    let member = user.member 
                    api().post(`/members/${member}/requestService`,data).then((res)=>{
                        if(res){
                             this.$refs.success_modal.openPopUp('Service added successfully')
                        }
                    })
                }
            }
        }
    }
</script>