<template>
  <v-row class="add-client-form no-padding">
    <successModal
      ref="success_modal"
      v-on:confirm="closeSuccessModal"
    ></successModal>
    <div class="col-md-12 no-padding">
      <div class="row orange-underline-wizard margin-l-11">
        <div class="col-md-12 mt-2 mb-2">
          <h3 class="form-title font ml-2">Member Detail</h3>
        </div>
      </div>

      <v-form v-model.trim="valid" ref="form" class="row col-md-12">
        <div class="col-md-6">
          <span class="text-underline font-weight-bold">
            Member Information</span
          >

          <div class="col-md-12 no-padding mt-3">
            <label class="font" for="">First Name</label>
            <v-text-field
              required
              :rules="requiredRules"
              v-model.trim="member.firstName"
              solo
            ></v-text-field>
          </div>
          <div class="col-md-12 no-padding">
            <label class="font text-gray" for="">Middle Name</label>
            <v-text-field
              required
              :rules="requiredRules"
              v-model.trim="member.middleName"
              solo
            ></v-text-field>
          </div>
          <div class="col-md-12 no-padding">
            <label class="font text-gray" for="">Last Name</label>
            <v-text-field
              required
              v-model.trim="member.lastName"
              solo
            ></v-text-field>
          </div>
          <div class="col-md-12 no-padding">
            <label class="font text-gray" for="">Phone</label>
            <v-text-field
              :rules="requiredRules"
              required
              v-model.trim="member.phone"
              solo
            ></v-text-field>
          </div>
          <div class="col-md-12 no-padding">
            <label class="font text-gray" for="">Email</label>
            <v-text-field
              required
              :rules="requiredRules"
              v-model.trim="member.email"
              solo
            ></v-text-field>
          </div>
          <div class="col-md-12 no-padding">
            <label class="font text-gray" for="">Home Zip Code</label>
            <v-text-field
              required
              :rules="zipRules"
              v-model.trim="member.zipCode"
              solo
            ></v-text-field>
          </div>
          <div class="row">
            <div class="col-md-8 font text-gray">
              <label for="">Member Relationship Status</label>
              <div class="d-flex mt-2">
                In Active
                <v-switch
                  v-model.trim="member.status"
                  solo
                  class="no-padding ml-2 mr-2 vendors-switch"
                ></v-switch>
                Active
              </div>
            </div>
            <div class="col-md-4 mt-5 text-end">
              <a class="text-underline" @click="removeMember">Remove Member</a>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <span class="text-underline font-weight-bold"
            >Employer and Plan Information</span
          >
          <div class="col-md-12 mt-3 no-padding">
            <label class="font text-gray" for="">Company Name </label>
            <v-text-field
              required
              v-model.trim="member.companyName"
              solo
              :rules="requiredRules"
            ></v-text-field>
          </div>
          <div class="col-md-12 no-padding">
            <label class="font text-gray" for="">Payer ID</label>
            <v-text-field
              required
              v-model.trim="member.payerId"
              solo
              :rules="requiredRules"
            ></v-text-field>
          </div>
          <div class="col-md-12 no-padding">
            <label class="font text-gray" for="">Group ID</label>

            <v-text-field
              required
              v-model.trim="member.groupId"
              solo
              :rules="requiredRules"
            ></v-text-field>
          </div>
          <div class="col-md-12 no-padding">
            <label class="font text-gray" for="">Plan ID</label>
            <v-text-field
              :rules="requiredRules"
              required
              v-model.trim="member.planId"
              solo
            ></v-text-field>
          </div>
          <div class="col-md-12 no-padding">
            <label class="font text-gray" for="">Member ID</label>
            <v-text-field
              required
              :rules="requiredRules"
              v-model.trim="member.memberId"
              solo
            ></v-text-field>
          </div>
          <div class="col-md-12 pt-0 pb-2 row">
            <label for="" class="col-md-4 col-lg-4 col-xl-3 pt-0 pb-2">
              Next Visit Date:
            </label>
            <span class="font-weight-bold col-md-4 col-lg-4 col-xl-3 pt-0 pb-2">
              <span v-if="member && member.nextVisit">{{
                member.nextVisit
              }}</span>
            </span>
          </div>
          <div class="col-md-12 row pt-0 pb-2">
            <label for="" class="col-md-4 col-lg-4 col-xl-3 pt-0 pb-2">
              Eligibility Status:</label
            >

            <span class="font-weight-bold col-md-5 col-lg-8 col-xl-5 pt-0 pb-2"
              >Eligibility Confirmed on 06/01/2022</span
            >
          </div>
          <div class="col-md-12 row pt-0 pb-2">
            <label for="" class="col-md-4 col-lg-4 col-xl-3 pt-0 pb-2">
              Deductible Balance:</label
            >
            <span class="font-weight-bold col-md-4 col-lg-4 col-xl-3 pt-0 pb-2"
              ><v-text-field
                solo
                hide-details=""
                v-model="member.deductible"
              ></v-text-field
            ></span>
          </div>
          <div class="col-md-12 row pt-0 pb-2">
            <label class="col-md-4 col-lg-4 col-xl-3 pt-0 pb-2" for=""
              >Subscription:</label
            >
            <span
              class="font-weight-bold col-md-4 col-lg-4 col-xl-3 pt-0 pb-2"
              >{{ member.subscription.status }}</span
            >
          </div>
          <div class="col-md-12">
            <a class="text-underline">Encounter Check In</a>
          </div>
        </div>
      </v-form>
      <div class="col-md-12 no-padding">
        <div class="gray-underline-wizard row margin-l-11">
          <div class="text-end justify-end col-md-12 pt-5 pb-5 mt-6 mb-6">
            <span
              class="mr-10 text-underline text-gray pointer"
              @click="$router.go(-1)"
              >Cancel</span
            >
            <span class="mr-10 text-underline text-gray pointer" @click="update"
              >Save
            </span>
          </div>
        </div>
      </div>
    </div>
    <removeDialog
      ref="dialog_delete"
      v-on:confirm="confirmRemove"
    ></removeDialog>
  </v-row>
</template>
<script>
import api from "../../../../services/api";
import successModal from "../../../TablesTemplate/SuccessDialog.vue";
import { mapGetters } from "vuex";
import RemoveDialog from "../../../TablesTemplate/RemoveDialog.vue";

export default {
  components: { successModal, RemoveDialog },
  data() {
    return {
      member: {
        firstName: "",
        middleName: "",
        lastName: " ",
        phone: "",
        client: "",
        email: "",
        status: "",
        zipCode: "",
        companyName: "",
        payerId: "",
        groupId: "",
        planId: "",
        memberId: "",
        eligibilityStatus: "",
        lastVisit: "",
        nextVisit: "",
        deductible: "",
        subscription: "",
      },
      clientId: "",
      phoneRules: [
        (v) =>
          /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(v) ||
          "Phone number must be in a valid format (XXX XXX XXXX)",
      ],
      states: [],
      discountRate: "",
      valid: false,
      zipRules: [
        (v) => (v && v.length == 5) || " Zip Code should be five digits long",
      ],
      requiredRules: [(v) => !!v || "This Field is required"],
      emailRules: [
        (v) => !!v || "This Field is required",
        (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || "E-mail must be valid",
      ],
      taxIdRules: [
        (v) => !!v || "This Field is required",
        (v) =>
          !!/[0-9]{2}\-[0-9]{7}/.test(v) ||
          "Tax ID should contain 9 digits, Please enter your Tax ID in XX-XXXXXXX format",
      ],
    };
  },
  computed: {
    singleMember() {
      return this.$store.getters.getSingleMember;
    },
    ...mapGetters(["getSingleItemDPC"]),
  },
  created() {
    // this.getStates();
    this.getInfo();
    this.getClients();

    this.member = this.getSingleItemDPC;
  },
  methods: {
    getClients() {
      api()
        .get(`/vendors/clients`)
        .then((res) => {
          if (res && res.data) {
            this.clientId = res.data[0].id;
          }
        });
    },
    confirmRemove() {
      api()
        .delete(`/vendors/members/${this.getSingleItemDPC._id}`)
        .then((res) => {
          if (res) {
            this.$parent.getData();
            this.$router.push("/vendors/members");
          }
        });
    },
    removeMember() {
      this.$refs.dialog_delete.openPopUp(
        "Are you sure that you want to delete this Member"
      );
    },
    generatePdf() {
      window.open("https://api.apaly.net/terms");
    },
    showSlash(event) {
      if (this.taxId.length == 2 && event.keyCode != 8) {
        this.taxId += "-";
      }
    },
    activateFileInput() {
      this.$refs.fileInput.$refs.input.click();
    },
    getStates() {
      api()
        .get("/api/states")
        .then((res) => {
          res.data.forEach((el) => {
            if (el.active) {
              this.states.push(el);
            }
          });
        });
    },
    update() {
      this.$refs.form.validate();

      if (this.valid) {
        let data = {
          firstName: this.member.firstName,
          middleName: this.member.middleName,
          lastName: this.member.lastName,
          phone: this.member.phone,
          email: this.member.email,
          zipCode: this.member.zipCode,
          companyName: this.member.companyName,
          payerId: this.member.payerId,
          groupId: this.member.groupId,
          planId: this.member.planId,
          memberId: this.member.memberId,
          deductible: this.member.deductible,
          nextVisit: this.member.nextVisit,
          lastVisit: this.member.lastVisit,
          clientId: this.clientId,
        };

        api()
          .put("/vendors/members/" + this.getSingleItemDPC._id, data)
          .catch((err) => {
            if (err) {
              this.$refs.error_modal.openPopUp(err.response.data.message);
            }
          })
          .then((res) => {
            if (res) {
              this.$parent.getData();
              this.$refs.success_modal.openPopUp("Member updated successfully");
            }
          });
      }
    },
    getInfo() {
      this.item = this.getSingleItemDPC;
      // api()
      //   .get(`/dpc/eligibility`)
      //   .then((res) => {
      //     if (res.data.deductible) {
      //       this.member.deductible = res.data.deductible;
      //     }
      //   });
      //   .then((res) => {
      //     if (res) {
      //       this.companyName = res.data.provider.providerName
      //         ? res.data.provider.providerName
      //         : res.data.provider.companyName
      //         ? res.data.provider.companyName
      //         : "";
      //       this.displayName = res.data.provider.displayName
      //         ? res.data.provider.displayName
      //         : "";
      //       this.taxId = res.data.provider.taxId ? res.data.provider.taxId : "";
      //       this.address1 = res.data.provider.address1
      //         ? res.data.provider.address1
      //         : "";
      //       this.address2 = res.data.provider.address2
      //         ? res.data.provider.address2
      //         : "";
      //       this.website = res.data.provider.website
      //         ? res.data.provider.website
      //         : "";
      //       this.city = res.data.provider.city ? res.data.provider.city : "";
      //       this.state = res.data.provider.state ? res.data.provider.state : "";
      //       this.zip = res.data.provider.zipCode
      //         ? res.data.provider.zipCode
      //         : "";
      //       this.firstName = res.data.provider.primaryContact.firstName
      //         ? res.data.provider.primaryContact.firstName
      //         : "";
      //       this.lastName = res.data.provider.primaryContact.lastName
      //         ? res.data.provider.primaryContact.lastName
      //         : "";
      //       this.title = res.data.provider.primaryContact.title
      //         ? res.data.provider.primaryContact.title
      //         : "";
      //       this.phone = res.data.provider.primaryContact.phone
      //         ? res.data.provider.primaryContact.phone
      //         : "";
      //       this.email = res.data.provider.primaryContact.email
      //         ? res.data.provider.primaryContact.email
      //         : "";
      //       this.dataComponents.image.name = res.data.provider.logo
      //         ? res.data.provider.logo
      //         : "";
      //       this.dataComponents.profileImage.name = res.data.provider
      //         .primaryContact.profile
      //         ? res.data.provider.primaryContact.profile
      //         : "";
      //     }
      //   });
    },
    closeSuccessModal() {
      this.$refs.success_modal.closePopUp();
      this.$router.push("/vendors/members/");
    },
  },
};
</script>