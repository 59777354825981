<template>
  <div>
    <Header :title="'Member Detail'"></Header>
    <div class="col-md-12 mb-2">
      <div class="col-md-12">
        <div class="row gray-border-form form-row-height">
          <div class="col-md-6 col-lg-6">
            <div class="row">
              <div class="col-md-12 pb-0 d-flex">
                <span class="col-md-7 d-flex">
                <v-select
                underlined
                ></v-select>
                <p class="text-gray ml-3 mt-3">Member</p>
              </span>
              <span class="col-md-5 text-end mt-3"> 
                <a class="light-blue-text text-underline">Add Family Member</a>
              </span>
              </div>
              <div class="col-md-12 pb-0">
                <label for="">Relationship to Subscriber</label>
                <v-select solo v-model="member.subscriberRelation" :items="typeItems"></v-select>
              </div>
              <div class="col-md-12 pt-0 pb-0">
                <label for="">Member First Name</label>
                <v-text-field solo v-model="member.firstName"></v-text-field>
              </div>
              <div class="col-md-12 pt-0 pb-0">
                <label for="">Member Last Name</label>
                <v-text-field solo v-model="member.lastName"></v-text-field>
              </div>
              <div class="col-md-12 pt-0 pb-0">
                <label for="">Email</label>
                <v-text-field solo v-model="member.email"></v-text-field>
              </div>
              <div class="col-md-12 pt-0 pb-0">
                <label for="">Sex</label>
                <div class="d-flex">
                    <v-btn :class="member.gender == 'm' ? 'my-1 button-blue gray-border-buttons' : 'my-1 gray-border-buttons'"
                           style="width: 48%; margin: 0 5px" @click="member.gender = 'm'">
                    MALE
                    </v-btn>
                    <v-btn :class="member.gender == 'f' ? 'my-1 button-blue gray-border-buttons' : 'my-1 gray-border-buttons'"
                           style="width: 48%; margin: 0 5px" @click="member.gender = 'f'">
                      FEMALE
                    </v-btn>
                </div>
              </div>
              <div class="col-md-12  pb-0 mt-4 mb-2">
                <label for="">Date of Birth</label>
                <v-menu
                    ref="menu"
                    v-model.trim="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model.trim="member.dob"
                        solo
                        hide-details
                        append-icon="mdi-calendar"
                        persistent-hint
                        placeholder="YYYY-MM-DD"
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model.trim="member.dob" no-title @input="menu = false">
                    <v-spacer></v-spacer>
                  </v-date-picker>
                </v-menu>
              </div>
              <div class="col-md-12 pt-0 pb-0">
                <label for="">Paln ID</label>
                <v-text-field solo v-model="member.planId"></v-text-field>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-lg-6">
            <div class="row">
              <div class="col-md-12 pb-0 mt-8">
                <span class="d-flex"><p class="font-weight-bold font-18 mr-5 tpa-color">Member ID:</p>
                  <p class="font-weight-bold font-18 tpa-color">{{member.memberId}}</p></span>
               
              </div>
              <div class="col-md-12 pb-0">
                <label for="">Member ID</label>
                <v-text-field solo v-model="member.memberId" :rules="memberIdRules"></v-text-field>
              </div>
              <div class="col-md-12 pt-0 pb-0">
                <label for="">Address 1</label>
                <v-text-field solo v-model="member.address1"></v-text-field>
              </div>
              <div class="col-md-12 pt-0 pb-0">
                <label for="">Address 2</label>
                <v-text-field solo v-model="member.address2"></v-text-field>
              </div>
              <v-row class="no-padding">
                <div class="col-md-12 pt-0 pb-0">
                  <label class="font text-gray" for="">City</label>
                  <v-text-field required v-model.trim="member.city" solo :rules="requiredRules"></v-text-field>
                </div>
                <div class="col-md-6 pt-0 pb-0">
                  <label class="font text-gray" for="">State</label>
                  <v-select
                      :items="states"
                      item-text="name"
                      item-value="name"
                      required
                      v-model.trim="member.state"
                      solo
                      :rules="requiredRules"
                  ></v-select>
                </div>
                <div class="col-md-6 pt-0 pb-0 zip-field-height">
                  <label class="font text-gray" for="">Zip</label>
                  <v-text-field required type="number" v-model.trim="member.zipCode" solo :rules="zipRules"></v-text-field>
                </div>
              </v-row>
              <div class="col-md-12 mt-4 mb-0 pb-0">
                <span class="d-flex"><p class="font-weight-bold font-18 mr-5 tpa-color">Status:</p>
                  
                  <p class="font-weight-bold font-18 text-green">{{member.status.charAt(0).toUpperCase() + member.status.slice(1)}}</p></span>
               
              </div>
              <div class="col-md-12 pt-0 pb-0 d-flex">
                <span class="col-md-6 pl-0">
                <label for="" class="text-gray">Member Status</label>
                  <div class="d-flex mt-2 font-weight-bold text-gray">
                    Inactive 
                    <v-switch v-model="member.status" false-value="inactive" true-value="active" solo class="text-gray no-padding ml-2 mr-2 vendors-switch"></v-switch>
                    Active
                  </div>
                </span>
                  <!-- <span @click="removeItem" class="text-underline light-blue-text font-weight-bold mt-8 ml-auto col-md-6 pointer">
                    Remove Member
                  </span> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <div class="col-md-12 mb-4" align="end">
        <span class="light-blue-text text-underline pointer font-weight-bold mr-10 font-18" @click="goBack">
          Cancel
        </span>
        <span class=" light-blue-text text-underline pointer font-weight-bold mr-5 font-18 pointer" @click="update">Save / Update</span>
      </div>
    </div>
    <removeDialog ref="dialog_delete" v-on:confirm="confirmRemove"></removeDialog>
    <successModal ref="success_modal" v-on:confirm="closeSuccessModal"></successModal>
    <errorModal ref="error_modal" v-on:confirm="closeErrorModal"></errorModal>
  </div>
</template>
<script>
import Header from "../../../Layout/HeaderPage.vue";
import api from "../../../../../../services/api";
import successModal from "../../../../../TablesTemplate/SuccessDialog.vue";
import removeDialog from "../../../../../TablesTemplate/RemoveDialog.vue";
import errorModal from "../../../../../TablesTemplate/ErrorDialog.vue";

export default {
  components: { Header, successModal, removeDialog, errorModal },
  data() {
    return {
      menu: false,
      taxIdRules: [
        (v) => !!v || "This Field is required",
        (v) =>
          !!/[0-9]{2}\-[0-9]{7}/.test(v) ||
          "Tax ID should contain 9 digits, Please enter your Tax ID in XX-XXXXXXX format",
      ],
      zipRules: [
        (v) => (v && v.length == 5) || " Zip Code should be five digits long",
      ],
      states: [],
      phoneRules: [
        (v) =>
          /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(v) ||
          "Phone number must be in a valid format (XXX XXX XXXX)",
      ],
      requiredRules: [(v) => !!v || "This Field is required"],

      emailRules: [
        (v) => !!v || "This Field is required",
        (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || "E-mail must be valid",
      ],
      memberIdRules: [
        (v) => !!v || "This Field is required",
        (v) => (v && v.length == 9) || "Member ID should contain 9 digits",
      ],
      typeItems: ["Self", "Spouse", "Dependent"],
      member: {
        memberId: "",
        planId: "",
        subscriberRelation: "Self",
        gender: "",
        lastName: "",
        firstName: "",
        dob: "",
        city: "",
        email: "",
        state: "",
        zipCode: "",
        address1: "",
        address2: "",
        status: "",
      },
    };
  },
  created() {
    this.getStates();
    api().get(`/tpas/clients/${this.$route.params.clientId}/members/${this.$route.params.memberId}`)
        .then((res) => {
          if (res) {
            this.member = {
              memberId:res.data.memberId ,
              planId: res.data.planId,
              subscriberRelation:res.data.subscriberRelation ,
              gender:res.data.gender ,
              lastName:res.data.lastName ,
              firstName:res.data.firstName ,
              city:res.data.city ,
              email:res.data.email ,
              state:res.data.state ,
              zipCode:res.data.zipCode ,
              address1:res.data.address1 ,
              address2:res.data.address2 ,
              status:res.data.status ,
              dob:res.data.dob?.substring(0, 10) ?? "",
            }
          }
        });
  },
  methods: {
    goBack() {
      this.closeSuccessModal();
    },
    closeSuccessModal() {
      this.$refs.success_modal.closePopUp();
      this.$router.push(`/tpa/clients/${this.$route.params.clientId}/members` ); 
       },
    closeErrorModal() {
      this.$refs.error_modal.closePopUp();
    },
    update() {
      api().put(`/tpas/clients/${this.$route.params.clientId}/members/${this.$route.params.memberId}`, this.member)
        .then((res) => {
          if (res) {
            this.$refs.success_modal.openPopUp("Successfully updated");
          }
        });
    },
    confirmRemove() {
      api().delete(`/tpas/clients/${this.$route.params.clientId}/members/${this.$route.params.memberId}`)
        .then((res) => {
          if (res) {
            this.$router.push(`/tpa/clients/${this.$route.params.clientId}/members` ); 

          }
        });
    },
    removeItem() {
      this.$refs.dialog_delete.openPopUp(
        "Are you sure that you want to remove this members?"
      );
    },
    getStates() {
      api().get("/api/states")
        .then((res) => {
          res.data.forEach((el) => {
            if (el.active) {
              this.states.push(el);
            }
          });
        });
    },
  },
};
</script>
<style>
.text-green{
  color: #0AAD38;
}
.tpa-color{
  color: #334D6E;
}
.gray-border-form {
  border-right: 2px solid rgba(179, 177, 177, 0.5);
  border-left: 2px solid rgba(179, 177, 177, 0.5);
  border-bottom: 2px solid rgba(179, 177, 177, 0.5);
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
</style>