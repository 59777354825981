<template>
  <div class="col-md-6 mt-2">
            
    <p class="font-weight-bold">Actions</p>
    <div class="member-actions d-flex">
      <div @click="checkNow()" class="member-actions-card text-center">
          <img src="/svg/checkin.svg" />
        <div class="success-modal-blue-text mt-2 mb-2">Check In Now</div>
      </div>
      <div @click="setDate()" class="member-actions-card text-center">

        <img src="/svg/calendar-plus.svg" />
        <div class="success-modal-green-text mt-3 mb-2">
          Set Future Visit Date
        </div>
      </div>
      <div
        @click="singleUserVisit(memberData.memberInfo)"
        class="member-actions-card text-center"
      >

      <img src="/svg/report-visit.svg" />

        <div class="red-checkout-modal-text mt-2 mb-2">Report Visit</div>
      </div>
    </div>
    <v-dialog v-model="checkNowDialog" activator="parent" width="350">
      <v-card class="col-md-12">
        <div class="row text-center justify-center orange-underline-wizard">
          <div class="text-center justify-center col-md-10 font-weight-bold mt-3 mb-3">
            Check Member In Now
          </div>
          <div class="col-md-1 mt-3 mr-3">
            <span class="text-end"
              ><i
                class="fa fa-times text-gray pointer"
                style="font-size: 22px"
                @click="checkNowDialog = false"
              ></i>
            </span>
          </div>
        </div>

        <div class="row justify-center mt-10">
          <div style="width: 90%">
            <v-label class="text-gray">First Name</v-label>
            <v-text-field solo disabled v-model="memberData.memberInfo.firstName"></v-text-field>
          </div>
          <div style="width: 90%">
            <v-label class="text-gray">Last Name</v-label>
            <v-text-field solo disabled v-model="memberData.memberInfo.lastName"></v-text-field>
          </div>
          <div style="width: 90%" v-if="memberData.memberInfo.email">
            <v-label class="text-gray">Email</v-label>
            <v-text-field solo v-model="memberData.memberInfo.email"></v-text-field>
          </div>
          <div style="width: 90%" v-if="memberData.memberInfo.phone">
            <v-label class="text-gray">Phone</v-label>
            <v-text-field solo v-model="memberData.memberInfo.phone"></v-text-field>
          </div>
        </div>

        <div class="mt-5 justify-center row text-center">
          <div
            class="blue-success-modal mt-5 col-md-10 justify-center pointer mb-5"
            @click="checkMemberNow"
          >
          <img  src="/svg/checkin.svg"/>
          <div class="success-modal-blue-text mb-3 mt-2" style="font-size: 15px">
            Confirm / Enter Information and Check In
          </div>
          </div>
        </div>
      </v-card>
    </v-dialog>  
    <v-dialog v-model="futureVisitDate" activator="parent" width="350" >
      <v-card class="col-md-12">
        <div class="row text-center justify-center orange-underline-wizard">
          <div class="text-center justify-center col-md-10 font-weight-bold mt-3 mb-3">
            <span class="ml-6"> Set a future visit date </span>
          </div>
          <div class="col-md-1 mt-3 mr-3">
            <span class="text-end"
              ><i
                class="fa fa-times text-gray pointer"
                style="font-size: 22px"
                @click="futureVisitDate = false"
              ></i>
            </span>
          </div>
        </div>

        <div class="row justify-center mt-10">
          <v-card>
            <v-date-picker
              header-color="black"
              v-model.trim="futureDate"
              show-adjacent-months
              no-title
              id="custom-date-picker"
              scrollable
              :show-current="true"
              :color="'#084ba7'"
              :weekday-format="parseDate"
              class="custom-date-picker"
              next-icon="fa-solid fa-caret-right"
              prev-icon="fa-solid fa-caret-left"
            >
            </v-date-picker>
          </v-card>
        </div>
        <div class="justify-center row mt-2 text-center padding-on-mobile">
          <div
            class="green-success-modal col-md-10 mt-10 mb-5 justify-center pointer"
            @click="setFutureDate"
          >
            <img src="/svg/calendar-plus.svg" />
            <div class="success-modal-green-text mt-3 mb-2">Set Future Visit Date</div>
          </div>
        </div>
      </v-card>
    </v-dialog>

    
    <successModal ref="success_modal" v-on:confirm="closeSuccessModal"></successModal>
    <!-- <removeDialog ref="dialog_delete" v-on:confirm="confirmRemove"></removeDialog> -->

    <errorModal ref="error_modal" v-on:confirm="closeErrorModal"> </errorModal>
  </div>
</template>
<script>
import api from "../../../../services/api";
import successModal from "../../../TablesTemplate/SuccessDialog.vue";
import removeDialog from "../../../TablesTemplate/RemoveDialog.vue";
import errorModal from "../../../TablesTemplate/ErrorDialog.vue";

import {mapGetters} from "vuex";

export default {
  components: { successModal, removeDialog, errorModal },
  props:{
    memberData:{
        required:true,
        type:Object
    }
  },
  data() {
    return {
      
      futureDate: "",
      futureVisitDate: false,
        checkNowDialog: false,
        futureVisitDate:false

    };
  },
  computed: {
    ...mapGetters(["getLocationId"]),

  },
  methods: {
    setDate() {
      this.futureVisitDate = true;
    },
    setFutureDate() {
      let data = {
        date: this.futureDate,
        //memberId: this.$route.params.id,
      };
      if (this.getLocationId) {
        data.location = this.getLocationId;
      }
      api()
        .post(`/dpcproviders/checkfuture/` + this.$route.params.id, data)
        .catch((err) => {
          if (err.response.status == 500) {
            this.$refs.error_modal.openPopUp(
              "There was an error, system admin is notified. Please try again later."
            );
          } else {
            this.$refs.error_modal.openPopUp(err.response.data);
          }
        })
        .then((res) => {
          if (res) {
            this.$refs.success_modal.openPopUp("Success");
          }
        });
    },
    parseDate(date) {
      const daysOfWeek = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];
      let i = new Date(date).getDay(date);
      return daysOfWeek[i];
    },
    checkNow() {
      this.checkNowDialog = true;
    },
    checkMemberNow() {
      console.log(this.getLocationId)
      let data = {
        firstName: this.memberData.memberInfo.firstName,
        lastName: this.memberData.memberInfo.lastName,
        email: this.memberData.memberInfo.email ? this.memberData.memberInfo.email : this.email,
        phone: this.memberData.memberInfo.phone ? this.memberData.memberInfo.phone : this.phone,
        location: this.getLocationId ? this.getLocationId : this.memberData.memberInfo.location,
        // memberId: this.memberId,
      };

      if (this.getLocationId) {
        data.location = this.getLocationId;
      }
      api()
        .post(`/dpcproviders/checkin/` + this.$route.params.id, data)
        .catch((err) => {
          if (err.response.status == 500) {
            this.$refs.error_modal.openPopUp(
              "There was an error, system admin is notified. Please try again later."
            );
            this.futureVisitDate = false;
          } else {
            this.$refs.error_modal.openPopUp(err.response.data);
            this.futureVisitDate = false;
          }
        })
        .then((res) => {
          if (res) {
            this.$refs.success_modal.openPopUp("Success");
          }
        });
    },
    singleUserVisit(item) {
      console.log('item', item)
      this.$store.commit("setSingleMemberProvider", item);
      this.$router.push("/dpc-providers/members/visit-reporting/"+this.$route.params.id);
    },

    closeSuccessModal() {
      this.$refs.success_modal.closePopUp();
      this.$router.push("/dpc-providers/members/");
    },
    closeErrorModal() {
      this.$refs.error_modal.closePopUp();
    },
  },
};
</script>
<style lang="scss">
.member-actions {
  flex-wrap: wrap;
}
.member-actions-card {
  background-color: #f5f6f8;
  margin-right: 10px;
  text-align: center;
  padding: 15px;
  max-width: 150px;
  min-width: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .member-actions-card{
    margin-top: 10px;
  }
}
</style>