<template>
  <div>
    <div class="row col-md-12 font-weight-bold light-blue-text">
      General Information
      <v-divider class="mt-3 ml-3"></v-divider>
    </div>
    <div class="row mt-10">
      <div class="col-md-12 ml-3">
        <div class="row">
          <div class="col-md-12 d-flex">
              <div class="col-md-4 col-3">
                <span class="text-gray font-weight-bold">Name:</span>
              </div>
              <div class="col-md-8 col-6">
                <span class="text-gray font-weight-bold ml-4">{{item.name ?  item.name : '/' }}</span>
              </div>
          </div>
          <br>
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-4 col-3">
                <span class="text-gray font-weight-bold">Type:</span>
              </div>
              <div class="col-md-8 col-6">
                <span class="text-gray font-weight-bold ml-4">{{item.type }}</span>
              </div>
            </div>
          </div> <br>
          
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-4 col-3">
                <span class="text-gray font-weight-bold">Category:</span>
              </div>
              <div class="col-md-8 col-6">
                <span class="text-gray font-weight-bold ml-4">{{item.category }}</span>
              </div>
            </div>
          </div> <br>
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-4 col-3">
                <span class="text-gray font-weight-bold">URL:</span>
              </div>
              <div class="col-md-8 col-6">
                <a class="font-weight-bold ml-4" :href="item.url">{{ item.url }}</a>
              </div>
            </div>
          </div> <br>
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-4 col-3">
                <span class="text-gray font-weight-bold">Description:</span>
              </div>
              <div class="col-md-8 col-6">
                <span class="text-gray font-weight-bold ml-4">{{ item.description }}</span>
              </div>
            </div>
          </div> <br>
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-4 col-3">
                <span class="text-gray font-weight-bold">Definition:</span>
              </div>
              <div class="col-md-8 col-6">
                <a class="font-weight-bold ml-4" :href="item.definition">{{ item.definition }}</a>
              </div>
            </div>
          </div> <br>
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-4">
                <span class="text-gray font-weight-bold">Vendor / Provider Participation:</span>
              </div>
              <div class="col-md-8">
                <span class="text-gray font-weight-bold ml-4">
                  {{ item.participation == "Requires Approval" ? 'Requires Approval from Solution Facilitator' : 'Open' }}
                </span>
              </div>
            </div>
          </div> <br>
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-4">
                <span class="text-gray font-weight-bold">Employer Participation:</span>
              </div>
              <div class="col-md-8">
                <span class="text-gray font-weight-bold ml-4">
                  {{ item.participation == "Requires Approval" ? 'Requires Approval from Solution Facilitator' : 'Open' }}
                </span>
              </div>
            </div>
          </div>
        
        </div>
      </div>
    </div>

    <v-dialog v-model="dialog" max-width="800px">
      <v-card>
        <v-card-title>
          <span class="text-h5 text-underline col-md-12 font-weight-bolder" style="font-weight:bold !important">Send Reminder Email to Client for Approval</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form class="row" ref="form" v-model="valid">
              <v-col cols="12" sm="12" md="12">
                <label for="">Company Name</label>
                <v-text-field :rules="requiredRules" v-model="companyName" solo required></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <label for="">Contact First Name</label>
                <v-text-field solo :rules="requiredRules" required v-model="firstName"></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <label for="">Contact Last Name</label>
                <v-text-field :rules="requiredRules" solo required v-model="lastName"></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <label for="">Email</label>
                <v-text-field solo :rules="emailRules" required v-model="email"></v-text-field>
              </v-col>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <div class="col-md-12 mb-3" align="end">
            <span class="text-underline text-gray mr-10 pointer" @click="dialog = false">Cancel</span>
            <span class="text-underline text-gray pointer" @click="inviteSolutions">Invite</span>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <successModal ref="success_modal" v-on:confirm="closeSuccessModal"></successModal>
    <errorModal ref="error_modal" v-on:confirm="closeErrorModal"></errorModal>
  </div>
</template>

<script>
import api from "@/services/api";
import successModal from "../../../../../../../TablesTemplate/SuccessDialog.vue";
import errorModal from "../../../../../../../TablesTemplate/ErrorDialog.vue";

export default {
  name: "GeneralInformation",
  props:['item'],
  components:{
    successModal,
    errorModal
  },
  data(){
    return{
      dialog:false,
      phoneRules: [
        (v) =>
          /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(v) ||
          "Phone number must be in a valid format (XXX XXX XXXX)",
      ],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || "E-mail must be valid",
      ],
      requiredRules: [(v) => !!v || "This Field is required"],
      valid: false,
      removeSolutionModal: false,
      companyName: "",
      firstName: "",
      lastName: "",
      email: "",
    }
  },
  computed: {
    clientId(){return this.$route.params.id},
    solutionId(){return this.$route.params.solutionId}
  },
  watch:{
    dialog(val){
      if(val === true){        
        if(this.item.acceptedByContact){
          this.firstName = this.item.acceptedByContact.firstName,
          this.lastName = this.item.acceptedByContact.lastName,
          this.email = this.item.acceptedByContact.email
        }
      }
    }
  },
  methods:{
    closeErrorModal() {
      this.$refs.success_modal.closePopUp();
      this.$refs.error_modal.closePopUp();
    },
    closeSuccessModal() {
      this.$refs.success_modal.closePopUp();
      this.$router.push("/tpa/clients/solutions/"+this.$route.params.id);
    },
    deactivateSolution(){
      if(this.item.status == 'pending'){
        this.removeSolutionModal = true
      }
    },
    inviteSolutions() {
      this.$refs.form.validate();
      if (this.valid) {
        this.dialog = false
        let postData = {
          companyName: this.companyName,
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email
        }
        api()
          .post(`/tpas/clients/${this.$route.params.id}/solutions/${this.$route.params.solutionId}/remind`, postData)
          .then(res => {
            if(res)this.$refs.success_modal.openPopUp(res.data);
          })
      }
    },
  }
}
</script>

<style scoped>

</style>