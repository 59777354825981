<template>
 <v-row class="add-client-form no-padding" >
    <successModal  ref="success_modal" v-on:confirm="sendMail"></successModal>
   <div class="col-md-12">
     <div class="col-md-12">
      <div class="row">
           <h3 class="font ">Add New Member</h3>
      </div>
    </div>
    <v-form class="row mt-3" ref="form" v-model.trim="valid">
        <div class="col-md-6 no-padding">
            <div class="col-md-12">
               <label class="font text-gray" for="">First Name</label>
                <v-text-field
                tabindex="1"
                required
                solo
                :rules="requiredRules"
                v-model.trim="firstName"
                ></v-text-field>
           </div>
            <div class="col-md-12">
              <label class="font text-gray" for="">Last Name</label>
                <v-text-field
                tabindex="2"
                required
                solo
                v-model.trim="lastName"
                :rules="requiredRules"
                ></v-text-field>
           </div>
            <div class="col-md-12">
                <label class="font text-gray" for="">Email</label>
                <v-text-field
                 tabindex="3"
                required
                v-model.trim="email"
                solo
                 :rules="emailRules"
                ></v-text-field>
            </div>
            <div class="col-md-12">
                <label class="font text-gray" for="">Sex</label>
                <div class="row col-md-12 justify-space-between"  >
                    <div  :class="gender == 'male' ? 'col-md-4 blue-buttons  text-center  border-radius ' : 'col-md-4 gender-buttons  text-center  text-gray'" @click="getGender('male')" tabindex="4">Male</div>
                    <div :class="gender == 'female' ? 'col-md-4 blue-buttons  text-center  border-radius ' : 'col-md-4 gender-buttons  text-center  text-gray'" @click="getGender('female')" tabindex="5">Female</div> 
                </div>
           </div>
           <div class="col-md-12 row">
            <div class="col-md-6">
                <label class="font text-gray" for="">Birth Month</label>
                <v-text-field
                required
                tabindex="6"
                v-model.trim="birthMonth"
                :rules="requiredRules"
                solo
                ></v-text-field>
            </div>
             <div class="col-md-3">
                <label class="font text-gray" for="">Day</label>
                <v-text-field
                required
                tabindex="7"
                type="number"
                v-model.trim="birthDay"
                :rules="requiredRules"
                solo
                ></v-text-field>
            </div>
             <div class="col-md-3">
                <label class="font text-gray" for="">Year</label>
                <v-text-field
                required
                type="number"
                v-model.trim="birthYear"
                :rules="requiredRules"
                tabindex="8"
                solo
                ></v-text-field>
            </div>
         </div>
            <div class="col-md-12">
                <label class="font text-gray" for="">Social Security Number</label>
                <v-text-field
              
                tabindex="9"
                :rules="requiredRules"
                v-model.trim="securityNumber"
                required
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show1 ? 'text' : 'password'"
                solo
                @click:append="show1 = !show1"

                ></v-text-field>
            </div>
        </div>
        <div class="col-md-6 no-padding" >
              <div class="col-md-12">
               <label class="font text-gray" for="">Member Type</label>
                <v-select
                tabindex="10"
                v-model.trim="memberType"
                :rules="requiredRules"
                required
                solo
                :items="[{text:'Employee', value:'employee'},{text:'Dependent', value:'dependent'}]"
                ></v-select>
           </div>
            <div class="col-md-12">
               <label class="font text-gray" for="">Home Address 1</label>
                <v-text-field
                tabindex="10"
                v-model.trim="address1"
                 :rules="requiredRules"
                required
                solo
                ></v-text-field>
           </div>
          <div class="col-md-12">
            <label class="font text-gray" for="">Address 2</label>
                <v-text-field
                tabindex="11"
                v-model.trim="address2"
                :rules="requiredRules"
                required
                solo
                ></v-text-field>
           </div>
          <div class="col-md-12 row">
            <div class="col-md-6">
                <label class="font text-gray" for="">City</label>
                <v-text-field
                required
                v-model.trim="city"
                :rules="requiredRules"
                tabindex="12"
                solo
                ></v-text-field>
            </div>
             <div class="col-md-3">
                <label class="font text-gray" for="">Zip</label>
                <v-text-field
                required
                v-model.trim="zip"
                :rules="requiredRules"
                type="number"
                tabindex="13"
                solo
                ></v-text-field>
            </div>
             <div class="col-md-3">
                <label class="font text-gray" for="">State</label>
                <v-select
                    required
                    :rules="requiredRules"
                    :items="states"
                    tabindex="14"
                    item-text="name"
                    item-value='name'
                    v-model.trim="state"
                    solo
                ></v-select>
                 <!-- <v-select
                v-model.trim="state"
                :rules="requiredRules"
                required
                tabindex="14"
                solo
                :options="{text:'Any', value:null}"
                ></v-select> -->
            </div>
        </div>
  
        </div>           
        <div class="col-md-12 d-flex align-end flex-column">
            <div>
                
                <v-btn  tabindex="16" class="blue-buttons mr-6 mb-2" @click="$router.push(`/employer-advisor/employers/${routeParams}/members`)">
                  Cancel
                </v-btn>
                 <v-btn  tabindex="16" class="blue-buttons mr-6 mb-2" @click="addMember">
                  Add Member
                </v-btn>
            </div>
        </div>
      </v-form>
     </div>
   <errorModal  ref="error_modal" v-on:confirm="closeErrorModal"></errorModal>
 </v-row>
</template>
<script>
import api from '../../../../../services/api'
import successModal from '../../../../TablesTemplate/SuccessDialog.vue'
import errorModal from '../../../../TablesTemplate/ErrorDialog.vue'
 export default{
    data(){
        return{
            show1:false,
            dialog:false,
            dialog1:false,
            firstName:'',
            lastName:'',
            email:'',
            gender:'',
            birthMonth:'',
            birthDay:'',
            birthYear:'',
            memberType:'',
            address1:'',
            address2:'',
            city:'',
            zip:'',
            state:'',
            securityNumber:'',
            valid:false,
            requiredRules:[
                v => !!v || 'This Field is required',
            ],
            states:[],
             emailRules: [
              v => !!v || 'This Field is required',
              v => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || 'E-mail must be valid',
            ],
        }
    },
    components:{
      successModal,errorModal
    },
    computed:{
        singleMember(){
          return this.$store.getters.getMemberGetter;
        },
         singleClient(){
          return this.$store.getters.getClientGetter;
        },
        routeParams(){
          let parsed = this.$route.params.name.replace(/ /g, '%20')
          return parsed
        }
    },
    created(){
      
      this.getStates()
    },
    methods:{
      getStates(){
        api().get('/api/states').then((res)=>{
            res.data.forEach((el)=>{
                if(el.active){
                    this.states.push(el);
                }
            })
        })
      },
      getGender(gender){
             this.gender = gender
      },
      sendMail(){
             this.$router.push(`/employer-advisor/employers/${this.routeParams}/members`)
      },
      getDependets(){
         
           let user = JSON.parse(localStorage.getItem('user'));
           let advisorId = user.groupPortal[0].organization.employerAdvisor
           let employer = JSON.parse(localStorage.getItem('employer'))
                    
           api().get(`/advisors/${advisorId}/employers/${employer._id}/members/${item._id}/associated`).then((res)=>{
            
             if(res){
                this.$store.commit('setSingleEmployerMemberDependent', res.data.associatedDependents);
                this.$store.commit('setSingleEmployerMemberEmployee', res.data.associatedEmployee);
                
                this.$router.push(`/employer-advisor/employers/${this.routeParams}/members/member`);
             }
           });
      },
     closeErrorModal(){
         this.$refs.error_modal.closePopUp();
      },
      addMember(){
        this.$refs.form.validate();
        if(this.valid){
          let data = {
              "firstName":this.firstName,
              "lastName": this.lastName,
              "email": this.email,
              "zipCode": this.zip,
              "sex": this.gender,
              "birthdate": `${this.birthDay}/${this.birthMonth}/${this.birthYear}`,
              "ssn": this.securityNumber,
              "memberType": this.memberType,
              "address1": this.address1,
              "address2": this.address2,
              "city": this.city,
              "state": this.state
          };

          let user = JSON.parse(localStorage.getItem('user'));
          let advisorId = user.groupPortal[0].organization.employerAdvisor
          let employer = JSON.parse(localStorage.getItem('employer'))
          api().post(`/advisors/${advisorId}/employers/${employer._id}/members`, data).catch((err)=>{
              if(err){
                this.$refs.error_modal.openPopUp(err.response.data.message)
              }
          }).then((res)=>{
            if(res){
          
                  this.$refs.success_modal.openPopUp('Member created and invited to Apaly Portal successfully')
                 
            }
          })
          
        }
       }
    }
 }
</script>
<style>
.border-radius{
  border: 1px solid white;
  border-radius: 0.8rem !important;
}
</style>