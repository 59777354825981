<template>
  <div :class="windowWidth ? 'mainSideBarDiv' : 'mainSideBarMobile'">
    <v-navigation-drawer
      v-if="windowWidth"
      :width="!mini ? '800' : '69'"
      permanent
      class="white-sidebar"
    >
      <div class="mt-2">
        <!-- <v-list-item class="pa-2">
          <button class="blue-buttons gray-border-buttons ma-2 pa-2">Create a solution</button>
        </v-list-item>
        <v-divider></v-divider> -->
        <v-list-item
        two-line
        class="mt-0 mb-0 pb-0"
        :class="
          watchActiveItem == 'home'
            ? 'pointer active'
            : 'pointer inactive'
        "
        @click="activeItem('home')"
      >
        <v-list-item-icon
          class="list-item-icon no-padding"
          :class="
          !mini
            ? 'ml-xl-4 ml-lg-4  ml-md-1 mt-xl-4 mt-lg-3 mt-md-2'
            : 'ml-4 mt-xl-4 mt-lg-3 mt-md-2'
        " >
        
        
          <svg
          class="mt-2"
            width="17"
            height="17"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.7319 6.49L9.00356 0.659668L1.26809 6.49C1.10817 6.63755 1.01201 6.84157 1 7.05881V15.9622C1.00056 16.1464 1.07402 16.323 1.20432 16.4532C1.33462 16.5835 1.51119 16.657 1.69547 16.6575H6.33333V12.9268C6.33333 12.2197 6.61429 11.5415 7.11438 11.0415C7.61448 10.5414 8.29276 10.2605 9 10.2605C9.70724 10.2605 10.3855 10.5414 10.8856 11.0415C11.3857 11.5415 11.6667 12.2197 11.6667 12.9268V16.6597H16.3038C16.4882 16.6591 16.6648 16.5857 16.7952 16.4554C16.9256 16.3252 16.9993 16.1486 17 15.9643V7.05881C16.9877 6.84165 16.8916 6.63773 16.7319 6.49Z"
              stroke="#707070"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </v-list-item-icon>
        <v-list-item-content class="no-padding">
          <v-list-item-title
            class="d-flex align-center font-quicksand-medium-12 text-gray-darker mt-2"
          >
            HOME
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
        <v-list-item
          two-line
          class="mt-0 mb-0 pb-0"
          :class="
            watchActiveItem == 'solutions'
              ? 'pointer active'
              : 'pointer inactive'
          "
          @click="activeItem('solutions')"
          :disabled="!activeEntity() || (user.entity && !user.entity.marketplaceVisibility)"
        >
          <v-list-item-icon
            class="list-item-icon no-padding"
            :class=" !mini ? 'ml-xl-4 ml-lg-4  ml-md-1 mt-xl-3 mt-lg-2 mt-md-2'
             : 'ml-4 mt-xl-4 mt-lg-2 mt-md-1' "  >
          
          
<svg width=16 height=17 class=mt-1 viewBox="0 0 20 20" fill=none xmlns=http://www.w3.org/2000/svg>
  <path d="M16.6322 12.9873L12.2002 14.4873" stroke=#707070 stroke-width=1.5 stroke-linecap=round stroke-linejoin=round />
  <path d="M3.34375 13.0002H5.30375L8.11175 18.3729C8.21361 18.5636 8.33692 18.7173 8.47436 18.8251C8.61179 18.9329 8.76054 18.9924 8.91175 19.0002C10.5465 16.9945 12.0823 14.7644 13.5037 12.332C13.5917 11.9093 13.4237 11.4866 13.2718 11.132L11.2637 6.71387" fill=#707070 />
  <path d="M3.34375 13.0002H5.30375L8.11175 18.3729C8.21361 18.5636 8.33692 18.7173 8.47436 18.8251C8.61179 18.9329 8.76054 18.9924 8.91175 19.0002C10.5465 16.9945 12.0823 14.7644 13.5037 12.332C13.5917 11.9093 13.4238 11.4866 13.2718 11.132L11.2637 6.71387" stroke=#707070 stroke-width=1.5 stroke-linecap=round stroke-linejoin=round />
  <path d="M8.86439 3.29146L8.58439 2.88237C8.29678 2.5335 7.95338 2.34315 7.60039 2.33692C7.38661 2.33615 7.17475 2.40559 6.97639 2.54147L3.40039 4.96873" fill=#707070 />
  <path d="M8.86439 3.29146L8.58439 2.88237C8.29678 2.5335 7.95338 2.34315 7.60039 2.33692C7.38661 2.33615 7.17475 2.40559 6.97639 2.54147L3.40039 4.96873" stroke=#707070 stroke-width=1.5 stroke-linecap=round stroke-linejoin=round />
  <path d="M1 2.63673H2.6C2.80154 2.63562 2.99589 2.76422 3.14421 2.99681C3.29253 3.2294 3.38388 3.54884 3.4 3.89127V12.6458C3.38198 12.9858 3.2898 13.3018 3.14168 13.5316C2.99356 13.7614 2.80027 13.8881 2.6 13.8867H1" fill=#707070 />
  <path d="M1 2.63673H2.6C2.80154 2.63562 2.99589 2.76422 3.14421 2.99681C3.29253 3.2294 3.38388 3.54884 3.4 3.89127V12.6458C3.38198 12.9858 3.2898 13.3018 3.14168 13.5316C2.99356 13.7614 2.80027 13.8881 2.6 13.8867H1" stroke=#707070 stroke-width=1.5 stroke-linecap=round stroke-linejoin=round />
  <path d="M18.9996 13.8867H17.3996C17.1993 13.8881 17.0061 13.7614 16.8579 13.5316C16.7098 13.3018 16.6176 12.9858 16.5996 12.6458V3.89127C16.6157 3.54884 16.7071 3.2294 16.8554 2.99681C17.0037 2.76422 17.1981 2.63562 17.3996 2.63673H18.9996" fill=#707070 />
  <path d="M18.9996 13.8867H17.3996C17.1993 13.8881 17.0061 13.7614 16.8579 13.5316C16.7098 13.3018 16.6176 12.9858 16.5996 12.6458V3.89127C16.6157 3.54884 16.7071 3.2294 16.8554 2.99681C17.0037 2.76422 17.1981 2.63562 17.3996 2.63673H18.9996" stroke=#707070 stroke-width=1.5 stroke-linecap=round stroke-linejoin=round />
  <path d="M12.2001 6.18188L9.44014 7.73642C9.13574 7.91784 8.80248 7.89803 8.50623 7.68089C8.20998 7.46376 7.97229 7.06509 7.84014 6.56369C7.69777 6.04133 7.67992 5.44515 7.79035 4.90079C7.90078 4.35644 8.13088 3.9064 8.43214 3.64553L11.1201 1.34099C11.3827 1.11328 11.6735 0.996365 11.9681 1.00009C12.1862 1.00259 12.4025 1.06711 12.6081 1.19099L16.6081 3.6728" fill=#707070 />
  <path d="M12.2001 6.18188L9.44014 7.73642C9.13574 7.91784 8.80248 7.89803 8.50623 7.68089C8.20998 7.46376 7.97229 7.06509 7.84014 6.56369C7.69777 6.04133 7.67992 5.44515 7.79035 4.90079C7.90078 4.35643 8.13088 3.9064 8.43214 3.64553L11.1201 1.34099C11.3827 1.11328 11.6735 0.996365 11.9681 1.00009C12.1862 1.00259 12.4025 1.06711 12.6081 1.19099L16.6081 3.6728" stroke=#707070 stroke-width=1.5 stroke-linecap=round stroke-linejoin=round />
  </svg>
          </v-list-item-icon>
          <v-list-item-content class="no-padding">
            <v-list-item-title
              class="d-flex align-center font-quicksand-medium-12 text-gray-darker"
            >
              MARKETPLACE
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          two-line
          :class="
            watchActiveItem == 'clients' ? 'pointer active' : 'pointer inactive'
          "
          @click="activeItem('clients')"
          :disabled="!activeEntity()"
        >
          <v-list-item-icon
            class="list-item-icon no-padding"
            :class="
              !mini
                ? 'ml-xl-4 ml-lg-4  ml-md-1 mt-xl-2 mt-lg-2 mt-md-2 '
                : 'ml-4 mt-xl-4 mt-lg-2 mt-md-1'
            "
          >
          <svg width=16 height=17 class=mt-1 viewBox="0 0 18 18" fill=none xmlns=http://www.w3.org/2000/svg>
            <path d="M6.33333 12.7334H2.06667C1.78377 12.7334 1.51246 12.8458 1.31242 13.0458C1.11238 13.2459 1 13.5172 1 13.8001V17.0001H6.33333V12.7334Z" fill=#707070 stroke=#707070 stroke-width=1.5 stroke-linecap=round stroke-linejoin=round />
            <path d="M15.9327 12.7334H11.666V17.0001H16.9993V13.8001C16.9993 13.5172 16.887 13.2459 16.6869 13.0458C16.4869 12.8458 16.2156 12.7334 15.9327 12.7334Z" fill=#707070 stroke=#707070 stroke-width=1.5 stroke-linecap=round stroke-linejoin=round />
            <path d="M10.6007 8.4668H7.40065C7.11775 8.4668 6.84644 8.57918 6.6464 8.77922C6.44636 8.97925 6.33398 9.25057 6.33398 9.53346V17.0001H11.6673V9.53346C11.6673 9.25057 11.5549 8.97925 11.3549 8.77922C11.1549 8.57918 10.8835 8.4668 10.6007 8.4668Z" fill=#707070 stroke=#707070 stroke-width=1.5 stroke-linecap=round stroke-linejoin=round />
            <path d="M7.13379 2.86667C7.13379 3.36174 7.33046 3.83653 7.68052 4.1866C8.03059 4.53667 8.50539 4.73333 9.00046 4.73333C9.49553 4.73333 9.97032 4.53667 10.3204 4.1866C10.6705 3.83653 10.8671 3.36174 10.8671 2.86667C10.8671 2.3716 10.6705 1.8968 10.3204 1.54673C9.97032 1.19667 9.49553 1 9.00046 1C8.50539 1 8.03059 1.19667 7.68052 1.54673C7.33046 1.8968 7.13379 2.3716 7.13379 2.86667Z" fill=#707070 stroke=#707070 stroke-width=1.5 stroke-linecap=round stroke-linejoin=round />
            <path d="M11.3097 6.333C11.0247 5.99838 10.6705 5.72964 10.2715 5.54536C9.87243 5.36107 9.43814 5.26563 8.99861 5.26562C8.55908 5.26563 8.1248 5.36107 7.72577 5.54536C7.32674 5.72964 6.97249 5.99838 6.6875 6.333" fill=#707070 />
            <path d="M11.3097 6.333C11.0247 5.99838 10.6705 5.72964 10.2715 5.54536C9.87243 5.36107 9.43814 5.26563 8.99861 5.26562C8.55908 5.26563 8.1248 5.36107 7.72577 5.54536C7.32674 5.72964 6.97249 5.99838 6.6875 6.333" stroke=#707070 stroke-width=1.5 stroke-linecap=round stroke-linejoin=round />
            <path d="M12.8223 7.66647C12.8223 8.16154 13.0189 8.63634 13.369 8.9864C13.7191 9.33647 14.1939 9.53314 14.6889 9.53314C15.184 9.53314 15.6588 9.33647 16.0089 8.9864C16.3589 8.63634 16.5556 8.16154 16.5556 7.66647C16.5556 7.1714 16.3589 6.69661 16.0089 6.34654C15.6588 5.99647 15.184 5.7998 14.6889 5.7998C14.1939 5.7998 13.7191 5.99647 13.369 6.34654C13.0189 6.69661 12.8223 7.1714 12.8223 7.66647Z" fill=#707070 stroke=#707070 stroke-width=1.5 stroke-linecap=round stroke-linejoin=round />
            <path d="M16.9998 11.1331C16.616 10.6818 16.1083 10.353 15.5395 10.1873C14.9708 10.0216 14.3659 10.0263 13.7998 10.2008" fill=#707070 />
            <path d="M16.9998 11.1331C16.616 10.6818 16.1083 10.353 15.5395 10.1873C14.9708 10.0216 14.3659 10.0263 13.7998 10.2008" stroke=#707070 stroke-width=1.5 stroke-linecap=round stroke-linejoin=round />
            <path d="M1.44336 7.66647C1.44336 7.91161 1.49164 8.15434 1.58545 8.38081C1.67926 8.60729 1.81676 8.81307 1.99009 8.9864C2.16343 9.15974 2.36921 9.29724 2.59568 9.39105C2.82216 9.48486 3.06489 9.53314 3.31003 9.53314C3.55516 9.53314 3.79789 9.48486 4.02437 9.39105C4.25084 9.29724 4.45662 9.15974 4.62996 8.9864C4.80329 8.81307 4.94079 8.60729 5.0346 8.38081C5.12841 8.15434 5.17669 7.91161 5.17669 7.66647C5.17669 7.42134 5.12841 7.1786 5.0346 6.95213C4.94079 6.72565 4.80329 6.51987 4.62996 6.34654C4.45662 6.1732 4.25084 6.03571 4.02437 5.9419C3.79789 5.84809 3.55516 5.7998 3.31003 5.7998C3.06489 5.7998 2.82216 5.84809 2.59568 5.9419C2.36921 6.03571 2.16343 6.1732 1.99009 6.34654C1.81676 6.51987 1.67926 6.72565 1.58545 6.95213C1.49164 7.1786 1.44336 7.42134 1.44336 7.66647Z" fill=#707070 stroke=#707070 stroke-width=1.5 stroke-linecap=round stroke-linejoin=round />
            <path d="M1 11.1331C1.28558 10.7992 1.63997 10.531 2.03887 10.3469C2.43778 10.1628 2.87177 10.0671 3.31111 10.0664C3.61123 10.0666 3.90964 10.1117 4.19644 10.2001" fill=#707070 />
            <path d="M1 11.1331C1.28558 10.7992 1.63997 10.531 2.03887 10.3469C2.43778 10.1628 2.87177 10.0671 3.31111 10.0664C3.61123 10.0666 3.90964 10.1117 4.19644 10.2001" stroke=#707070 stroke-width=1.5 stroke-linecap=round stroke-linejoin=round />
            </svg>
          </v-list-item-icon>
          <v-list-item-content class="no-padding">
            <v-list-item-title
              class="d-flex align-center font-quicksand-medium-12 text-gray-darker "
            >
              CLIENTS
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          two-line
          :class="
            watchActiveItem == 'invoices'
              ? 'pointer active'
              : 'pointer inactive'
          "
          @click="activeItem('invoices')"
          :disabled="!activeEntity()"
        >
          <v-list-item-icon
            class="list-item-icon no-padding"
            :class="
              !mini
                ? 'ml-xl-4 ml-lg-4  ml-md-1 mt-xl-2 mt-lg-2 mt-md-2 '
                : 'ml-4 mt-xl-3 mt-lg-2 mt-md-1'
            "
          >
          <svg width=18 height=19 class=mt-1 viewBox="0 0 20 21" fill=none xmlns=http://www.w3.org/2000/svg>
            <path d="M1 2.67383L2.2 4.1877V7.76193C2.168 9.11257 3.7336 10.3224 5.1992 11.5642" stroke=#707070 stroke-width=1.5 stroke-linecap=round stroke-linejoin=round />
            <path d="M4.58411 6.78734L5.99931 8.2372C6.08861 8.32888 6.19509 8.40207 6.31267 8.45259C6.43025 8.50311 6.55662 8.52997 6.68458 8.53164C6.81254 8.53331 6.93958 8.50976 7.05843 8.46233C7.17729 8.4149 7.28565 8.34452 7.37731 8.2552C7.46897 8.16589 7.54215 8.05939 7.59266 7.94179C7.64317 7.82419 7.67003 7.69779 7.6717 7.56981C7.67337 7.44182 7.64983 7.31477 7.6024 7.19589C7.55498 7.07701 7.48461 6.96863 7.39531 6.87696L5.19451 4.63096V3.46035C5.07528 2.7589 4.80861 2.09074 4.41211 1.5" stroke=#707070 stroke-width=1.5 stroke-linecap=round stroke-linejoin=round />
            <path d="M5.19922 7.41162V17.1029C5.19922 17.4213 5.32565 17.7265 5.55069 17.9516C5.77573 18.1767 6.08096 18.3032 6.39922 18.3032H12.3992" stroke=#707070 stroke-width=1.5 stroke-linecap=round stroke-linejoin=round />
            <path d="M19.0006 18.3287L17.8006 16.8148V13.2414C17.8286 12.074 16.6638 11.0122 15.4006 9.94482V14.2L14.0006 12.7661C13.8203 12.581 13.5738 12.4751 13.3153 12.4717C13.0569 12.4683 12.8077 12.5677 12.6226 12.7481C12.4375 12.9285 12.3316 13.175 12.3282 13.4335C12.3248 13.692 12.4243 13.9412 12.6046 14.1264L14.8086 16.3732V17.543C14.9284 18.2433 15.1951 18.9103 15.591 19.5001" stroke=#707070 stroke-width=1.5 stroke-linecap=round stroke-linejoin=round />
            <path d="M10.782 15.3022H7.59961" stroke=#707070 stroke-width=1.5 stroke-linecap=round stroke-linejoin=round />
            <path d="M8.79961 12.3018H7.59961" stroke=#707070 stroke-width=1.5 stroke-linecap=round stroke-linejoin=round />
            <path d="M16.6004 5.10064C16.6004 5.81278 16.3893 6.50893 15.9937 7.10105C15.5981 7.69317 15.0359 8.15467 14.3781 8.4272C13.7202 8.69972 12.9964 8.77103 12.2981 8.6321C11.5997 8.49316 10.9583 8.15024 10.4548 7.64668C9.95134 7.14312 9.60847 6.50155 9.46957 5.80309C9.33066 5.10463 9.40195 4.38067 9.67443 3.72273C9.9469 3.0648 10.4083 2.50246 11.0003 2.10682C11.5924 1.71117 12.2884 1.5 13.0004 1.5" stroke=#707070 stroke-width=1.5 stroke-linecap=round stroke-linejoin=round />
            <path d="M16.5996 2.39941L13.3196 5.68C13.2777 5.72219 13.2278 5.75567 13.1729 5.77852C13.118 5.80137 13.0591 5.81313 12.9996 5.81313C12.9402 5.81313 12.8813 5.80137 12.8263 5.77852C12.7714 5.75567 12.7216 5.72219 12.6796 5.68L11.6484 4.64781" stroke=#707070 stroke-width=1.5 stroke-linecap=round stroke-linejoin=round />
            </svg>
          </v-list-item-icon>
          <v-list-item-content class="no-padding">
            <v-list-item-title
              class="d-flex align-center font-quicksand-medium-12 text-gray-darker "
            >
              INVOICES
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          two-line
          :class="
            watchActiveItem == 'income' ? 'pointer active' : 'pointer inactive'
          "
          @click="activeItem('income')"
          :disabled="!activeEntity()"
        >
          <v-list-item-icon
            class="list-item-icon no-padding"
            :class="
              !mini
                ? 'ml-xl-4 ml-lg-4  ml-md-1 mt-xl-2 mt-lg-2 mt-md-2 '
                : 'ml-4 mt-xl-3 mt-lg-2 mt-md-1'
            "
          >
          <svg width=18 height=19 class=mt-1 viewBox="0 0 18 19" fill=none xmlns=http://www.w3.org/2000/svg>
            <path d="M9 17.5625C13.4183 17.5625 17 13.9808 17 9.5625C17 5.14422 13.4183 1.5625 9 1.5625C4.58172 1.5625 1 5.14422 1 9.5625C1 13.9808 4.58172 17.5625 9 17.5625Z" stroke=#707070 stroke-width=1.5 stroke-linecap=round stroke-linejoin=round />
            <path d="M11.1339 6.36234H8.14011C7.96941 6.34836 7.79771 6.37168 7.63693 6.43071C7.47615 6.48973 7.33013 6.58303 7.20903 6.70414C7.08792 6.82525 6.99461 6.97126 6.93559 7.13205C6.87657 7.29283 6.85324 7.46453 6.86722 7.63523C6.86722 9.05745 11.1339 10.0957 11.1339 11.4895C11.1368 11.6574 11.1058 11.8242 11.0429 11.98C10.9799 12.1357 10.8863 12.2772 10.7675 12.396C10.6487 12.5147 10.5072 12.6084 10.3515 12.6713C10.1958 12.7343 10.029 12.7652 9.861 12.7623H6.86722" stroke=#707070 stroke-width=1.5 stroke-linecap=round stroke-linejoin=round />
            <path d="M9 6.36245V4.76245" stroke=#707070 stroke-width=1.5 stroke-linecap=round stroke-linejoin=round />
            </svg>
          </v-list-item-icon>
          <v-list-item-content class="no-padding">
            <v-list-item-title
              class="d-flex align-center font-quicksand-medium-12 text-gray-darker"
            >
              INCOME
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </div>

      <div>
        <v-divider></v-divider>
        <v-list-item two-line v-if="!mini" class="mb-2">
          <span class="blue--text mt-1 ml-1 font-bold" style="font-size: 12px">
            Powered by:
          </span>

          <v-list-item-content
            class="no-padding pointer justify-center mt-2 mb-2"
          >
            <v-list-item-title
              class="d-flex mb-3 align-center text-center text-gray-darker"
            >
              <img
                src="/newapalylogoblue.png"
                alt=""
                class="mt-1 ml-1"
                width="110"
                height="45"
              />
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item two-line v-else class="mt-4">
          <v-list-item-content class="no-padding pointer">
            <v-list-item-title
              class="d-flex mb-3 align-center text-center text-gray-darker"
            >
              <img
                src="/newapalylogoblue.png"
                class="ml-1"
                width="95"
                height="45"
                alt=""
              />
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </div>
    </v-navigation-drawer>
    <!-- Mobile -->
    <v-navigation-drawer v-else permanent class="white-sidebar-onmobile mt-3">
      <div class="ml-3">
        <v-divider></v-divider>
        <v-list-item
          two-line
          class="mt-3"
          :class="
            watchActiveItem == 'solutions'
              ? 'pointer active'
              : 'pointer inactive'
          "
          @click="activeItem('solutions')"
        >
          <v-list-item-icon
            class="list-item-icon no-padding"
            :class="
              !mini
                ? 'ml-xl-4 ml-lg-4  ml-md-1 mt-xl-4 mt-lg-2 mt-md-1'
                : 'ml-4 mt-xl-4 mt-lg-2 mt-md-1'
            "
          >
          <svg width=18 height=18 viewBox="0 0 20 20" fill=none xmlns=http://www.w3.org/2000/svg>
            <path d="M16.6312 12.9863L12.1992 14.4863" stroke=#707070 stroke-width=1.5 stroke-linecap=round stroke-linejoin=round />
            <path d="M3.34375 13.0002H5.30375L8.11175 18.3729C8.21361 18.5636 8.33692 18.7173 8.47436 18.8251C8.61179 18.9329 8.76054 18.9924 8.91175 19.0002C10.5465 16.9945 12.0823 14.7644 13.5037 12.332C13.5917 11.9093 13.4238 11.4866 13.2718 11.132L11.2637 6.71387" stroke=#707070 stroke-width=1.5 stroke-linecap=round stroke-linejoin=round />
            <path d="M8.86439 3.29097L8.58439 2.88188C8.29678 2.53301 7.95338 2.34266 7.60039 2.33643C7.38661 2.33566 7.17475 2.4051 6.97639 2.54098L3.40039 4.96824" stroke=#707070 stroke-width=1.5 stroke-linecap=round stroke-linejoin=round />
            <path d="M1 2.63624H2.6C2.80154 2.63514 2.99589 2.76373 3.14421 2.99632C3.29253 3.22891 3.38388 3.54835 3.4 3.89078V12.6453C3.38198 12.9853 3.2898 13.3014 3.14168 13.5311C2.99356 13.7609 2.80027 13.8876 2.6 13.8862H1" stroke=#707070 stroke-width=1.5 stroke-linecap=round stroke-linejoin=round />
            <path d="M18.9996 13.8862H17.3996C17.1993 13.8876 17.0061 13.7609 16.8579 13.5311C16.7098 13.3014 16.6176 12.9853 16.5996 12.6453V3.89078C16.6157 3.54835 16.7071 3.22891 16.8554 2.99632C17.0037 2.76373 17.1981 2.63514 17.3996 2.63624H18.9996" stroke=#707070 stroke-width=1.5 stroke-linecap=round stroke-linejoin=round />
            <path d="M12.2001 6.18188L9.44014 7.73642C9.13574 7.91784 8.80248 7.89803 8.50623 7.68089C8.20998 7.46376 7.97229 7.06509 7.84014 6.56369C7.69777 6.04133 7.67992 5.44515 7.79035 4.90079C7.90078 4.35643 8.13088 3.9064 8.43214 3.64553L11.1201 1.34099C11.3827 1.11328 11.6735 0.996365 11.9681 1.00009C12.1862 1.00259 12.4025 1.06711 12.6081 1.19099L16.6081 3.6728" stroke=#707070 stroke-width=1.5 stroke-linecap=round stroke-linejoin=round />
            </svg>
          </v-list-item-icon>
          <v-list-item-content class="no-padding">
            <v-list-item-title
              class="d-flex align-center font-quicksand-medium-12 text-gray-darker mt-1"
            >
              MARKETPLACE
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          two-line
          :class="
            watchActiveItem == 'clients' ? 'pointer active' : 'pointer inactive'
          "
          @click="activeItem('clients')"
        >
          <v-list-item-icon
            class="list-item-icon no-padding"
            :class="
              !mini
                ? 'ml-xl-4 ml-lg-4  ml-md-1 mt-xl-3 mt-lg-2 mt-md-1 '
                : 'ml-4 mt-xl-3 mt-lg-2 mt-md-1'
            "
          >
          <svg width=17 height=17 viewBox="0 0 18 18" fill=none xmlns=http://www.w3.org/2000/svg>
            <path d="M6.33333 12.7334H2.06667C1.78377 12.7334 1.51246 12.8458 1.31242 13.0458C1.11238 13.2459 1 13.5172 1 13.8001V17.0001H6.33333V12.7334Z" stroke=#707070 stroke-width=1.5 stroke-linecap=round stroke-linejoin=round />
            <path d="M15.9327 12.7334H11.666V17.0001H16.9993V13.8001C16.9993 13.5172 16.887 13.2459 16.6869 13.0458C16.4869 12.8458 16.2156 12.7334 15.9327 12.7334Z" stroke=#707070 stroke-width=1.5 stroke-linecap=round stroke-linejoin=round />
            <path d="M10.6007 8.4668H7.40065C7.11775 8.4668 6.84644 8.57918 6.6464 8.77922C6.44636 8.97925 6.33398 9.25057 6.33398 9.53346V17.0001H11.6673V9.53346C11.6673 9.25057 11.5549 8.97925 11.3549 8.77922C11.1549 8.57918 10.8835 8.4668 10.6007 8.4668Z" stroke=#707070 stroke-width=1.5 stroke-linecap=round stroke-linejoin=round />
            <path d="M7.13281 2.86667C7.13281 3.36174 7.32948 3.83653 7.67955 4.1866C8.02961 4.53667 8.50441 4.73333 8.99948 4.73333C9.49455 4.73333 9.96934 4.53667 10.3194 4.1866C10.6695 3.83653 10.8661 3.36174 10.8661 2.86667C10.8661 2.3716 10.6695 1.8968 10.3194 1.54673C9.96934 1.19667 9.49455 1 8.99948 1C8.50441 1 8.02961 1.19667 7.67955 1.54673C7.32948 1.8968 7.13281 2.3716 7.13281 2.86667V2.86667Z" stroke=#707070 stroke-width=1.5 stroke-linecap=round stroke-linejoin=round />
            <path d="M11.3097 6.33349C11.0247 5.99887 10.6705 5.73013 10.2715 5.54584C9.87243 5.36156 9.43814 5.26611 8.99861 5.26611C8.55908 5.26611 8.1248 5.36156 7.72577 5.54584C7.32674 5.73013 6.97249 5.99887 6.6875 6.33349" stroke=#707070 stroke-width=1.5 stroke-linecap=round stroke-linejoin=round />
            <path d="M12.8223 7.66647C12.8223 8.16154 13.0189 8.63634 13.369 8.9864C13.7191 9.33647 14.1939 9.53314 14.6889 9.53314C15.184 9.53314 15.6588 9.33647 16.0089 8.9864C16.3589 8.63634 16.5556 8.16154 16.5556 7.66647C16.5556 7.1714 16.3589 6.69661 16.0089 6.34654C15.6588 5.99647 15.184 5.7998 14.6889 5.7998C14.1939 5.7998 13.7191 5.99647 13.369 6.34654C13.0189 6.69661 12.8223 7.1714 12.8223 7.66647V7.66647Z" stroke=#707070 stroke-width=1.5 stroke-linecap=round stroke-linejoin=round />
            <path d="M17.0008 11.1331C16.617 10.6818 16.1093 10.353 15.5405 10.1873C14.9718 10.0216 14.3669 10.0263 13.8008 10.2008" stroke=#707070 stroke-width=1.5 stroke-linecap=round stroke-linejoin=round />
            <path d="M1.44336 7.66647C1.44336 7.91161 1.49164 8.15434 1.58545 8.38081C1.67926 8.60729 1.81676 8.81307 1.99009 8.9864C2.16343 9.15974 2.36921 9.29724 2.59568 9.39105C2.82216 9.48486 3.06489 9.53314 3.31003 9.53314C3.55516 9.53314 3.79789 9.48486 4.02437 9.39105C4.25084 9.29724 4.45662 9.15974 4.62996 8.9864C4.80329 8.81307 4.94079 8.60729 5.0346 8.38081C5.12841 8.15434 5.17669 7.91161 5.17669 7.66647C5.17669 7.42134 5.12841 7.1786 5.0346 6.95213C4.94079 6.72565 4.80329 6.51987 4.62996 6.34654C4.45662 6.1732 4.25084 6.03571 4.02437 5.9419C3.79789 5.84809 3.55516 5.7998 3.31003 5.7998C3.06489 5.7998 2.82216 5.84809 2.59568 5.9419C2.36921 6.03571 2.16343 6.1732 1.99009 6.34654C1.81676 6.51987 1.67926 6.72565 1.58545 6.95213C1.49164 7.1786 1.44336 7.42134 1.44336 7.66647V7.66647Z" stroke=#707070 stroke-width=1.5 stroke-linecap=round stroke-linejoin=round />
            <path d="M1 11.1336C1.28558 10.7997 1.63997 10.5315 2.03887 10.3474C2.43778 10.1633 2.87177 10.0676 3.31111 10.0669C3.61123 10.0671 3.90964 10.1122 4.19644 10.2006" stroke=#707070 stroke-width=1.5 stroke-linecap=round stroke-linejoin=round />
            </svg>
          </v-list-item-icon>
          <v-list-item-content class="no-padding">
            <v-list-item-title
              class="d-flex align-center font-quicksand-medium-12 text-gray-darker mt-2"
            >
              CLIENTS
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          two-line
          :class="
            watchActiveItem == 'invoices'
              ? 'pointer active'
              : 'pointer inactive'
          "
          @click="activeItem('invoices')"
        >
          <v-list-item-icon
            class="list-item-icon no-padding"
            :class="
              !mini
                ? 'ml-xl-4 ml-lg-4  ml-md-1 mt-xl-3 mt-lg-2 mt-md-1 '
                : 'ml-4 mt-xl-3 mt-lg-2 mt-md-1'
            "
          >
          <svg width=18 height=20 viewBox="0 0 20 21" fill=none xmlns=http://www.w3.org/2000/svg>
            <path d="M1 2.67383L2.2 4.1877V7.76193C2.168 9.11257 3.7336 10.3224 5.1992 11.5642" stroke=#707070 stroke-width=1.5 stroke-linecap=round stroke-linejoin=round />
            <path d="M4.58411 6.78734L5.99931 8.2372C6.08861 8.32888 6.19509 8.40207 6.31267 8.45259C6.43025 8.50311 6.55662 8.52997 6.68458 8.53164C6.81254 8.53331 6.93958 8.50976 7.05843 8.46233C7.17729 8.4149 7.28565 8.34452 7.37731 8.2552C7.46897 8.16589 7.54215 8.05939 7.59266 7.94179C7.64317 7.82419 7.67003 7.69779 7.6717 7.56981C7.67337 7.44182 7.64983 7.31477 7.6024 7.19589C7.55498 7.07701 7.48461 6.96863 7.39531 6.87696L5.19451 4.63096V3.46035C5.07528 2.7589 4.80861 2.09074 4.41211 1.5" stroke=#707070 stroke-width=1.5 stroke-linecap=round stroke-linejoin=round />
            <path d="M5.19922 7.41162V17.1029C5.19922 17.4213 5.32565 17.7265 5.55069 17.9516C5.77573 18.1767 6.08096 18.3032 6.39922 18.3032H12.3992" stroke=#707070 stroke-width=1.5 stroke-linecap=round stroke-linejoin=round />
            <path d="M19.0006 18.3287L17.8006 16.8148V13.2414C17.8286 12.074 16.6638 11.0122 15.4006 9.94482V14.2L14.0006 12.7661C13.8203 12.581 13.5738 12.4751 13.3153 12.4717C13.0569 12.4683 12.8077 12.5677 12.6226 12.7481C12.4375 12.9285 12.3316 13.175 12.3282 13.4335C12.3248 13.692 12.4243 13.9412 12.6046 14.1264L14.8086 16.3732V17.543C14.9284 18.2433 15.1951 18.9103 15.591 19.5001" stroke=#707070 stroke-width=1.5 stroke-linecap=round stroke-linejoin=round />
            <path d="M10.782 15.3022H7.59961" stroke=#707070 stroke-width=1.5 stroke-linecap=round stroke-linejoin=round />
            <path d="M8.79961 12.3018H7.59961" stroke=#707070 stroke-width=1.5 stroke-linecap=round stroke-linejoin=round />
            <path d="M16.6004 5.10064C16.6004 5.81278 16.3893 6.50893 15.9937 7.10105C15.5981 7.69317 15.0359 8.15467 14.3781 8.4272C13.7202 8.69972 12.9964 8.77103 12.2981 8.6321C11.5997 8.49316 10.9583 8.15024 10.4548 7.64668C9.95134 7.14312 9.60847 6.50155 9.46957 5.80309C9.33066 5.10463 9.40195 4.38067 9.67443 3.72273C9.9469 3.0648 10.4083 2.50246 11.0003 2.10682C11.5924 1.71117 12.2884 1.5 13.0004 1.5" stroke=#707070 stroke-width=1.5 stroke-linecap=round stroke-linejoin=round />
            <path d="M16.5996 2.39941L13.3196 5.68C13.2777 5.72219 13.2278 5.75567 13.1729 5.77852C13.118 5.80137 13.0591 5.81313 12.9996 5.81313C12.9402 5.81313 12.8813 5.80137 12.8263 5.77852C12.7714 5.75567 12.7216 5.72219 12.6796 5.68L11.6484 4.64781" stroke=#707070 stroke-width=1.5 stroke-linecap=round stroke-linejoin=round />
            </svg>
          </v-list-item-icon>
          <v-list-item-content class="no-padding">
            <v-list-item-title
              class="d-flex align-center font-quicksand-medium-12 text-gray-darker mt-2"
            >
              INVOICES
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          two-line
          :class="
            watchActiveItem == 'income' ? 'pointer active' : 'pointer inactive'
          "
          @click="activeItem('income')"
        >
          <v-list-item-icon
            class="list-item-icon no-padding"
            :class="
              !mini
                ? 'ml-xl-4 ml-lg-4  ml-md-1 mt-xl-3 mt-lg-2 mt-md-1 '
                : 'ml-4 mt-xl-3 mt-lg-2 mt-md-1'
            "
          >
          <svg width=18 height=18 viewBox="0 0 18 19" fill=none xmlns=http://www.w3.org/2000/svg>
            <path d="M9 17.5625C13.4183 17.5625 17 13.9808 17 9.5625C17 5.14422 13.4183 1.5625 9 1.5625C4.58172 1.5625 1 5.14422 1 9.5625C1 13.9808 4.58172 17.5625 9 17.5625Z" stroke=#707070 stroke-width=1.5 stroke-linecap=round stroke-linejoin=round />
            <path d="M11.1339 6.36234H8.14011C7.96941 6.34836 7.79771 6.37168 7.63693 6.43071C7.47615 6.48973 7.33013 6.58303 7.20903 6.70414C7.08792 6.82525 6.99461 6.97126 6.93559 7.13205C6.87657 7.29283 6.85324 7.46453 6.86722 7.63523C6.86722 9.05745 11.1339 10.0957 11.1339 11.4895C11.1368 11.6574 11.1058 11.8242 11.0429 11.98C10.9799 12.1357 10.8863 12.2772 10.7675 12.396C10.6487 12.5147 10.5072 12.6084 10.3515 12.6713C10.1958 12.7343 10.029 12.7652 9.861 12.7623H6.86722" stroke=#707070 stroke-width=1.5 stroke-linecap=round stroke-linejoin=round />
            <path d="M9 6.36245V4.76245" stroke=#707070 stroke-width=1.5 stroke-linecap=round stroke-linejoin=round />
            <path d="M9 14.3625V12.7625" stroke=#707070 stroke-width=1.5 stroke-linecap=round stroke-linejoin=round />
            </svg>
          </v-list-item-icon>
          <v-list-item-content class="no-padding">
            <v-list-item-title
              class="d-flex align-center font-quicksand-medium-12 text-gray-darker mt-2"
            >
              INCOME
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </div>
      <div>
        <v-divider></v-divider>
        <v-list-item style="position: absolute; bottom: 32px">
          <v-list-item-content class="no-padding">
            <v-list-item-title
              class="d-flex align-center font-quicksand-medium-12 text-gray-darker"
            >
              <img
                id="logo-img"
                :src="'/newapalylogoblue.png'"
                class="apaly-pic pointer"
              />
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </div>
    </v-navigation-drawer>
  </div>
</template>
<script>
export default {
  name: "Tables",
  data() {
    return {
      miniVersion: true,
      item: "home",
      route: this.$route.path,
      user: {}
    };
  },
  props: {
    windowWidth: {
      type: Boolean,
    },
    mini: {
      type: Boolean,
    },
  },

  computed: {
    routeParams() {
      return this.$route.params.name;
    },
    path() {
      return this.$route.path.replace(/%20/g, " ");
    },
    watchActiveItem() {
      if (this.$route.path.includes(this.$route.path.split("/")[2])) {
        this.item = this.$route.path.split("/")[2];
      }

      return this.item;
    },
  },
  created() {
    this.user = JSON.parse(localStorage.getItem('user'))
  },
  methods: {
    activeItem(tab) {
      if (this.$route.path != `/tpa/${tab}`) {
        this.$router.push(`/tpa/${tab}`);
      }
      if (!this.windowWidth) {
        this.$emit("closeSideBar");
      }
    },
    activeEntity() {
        return this.user.entity && this.user.entity.status === "active"
    }
  },
};
</script>

