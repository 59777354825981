<template>
  <v-row class="add-client-form no-padding">
    <div class="col-md-12 form-box">
      <div class="row">
        <div class="col-md-12">
          <h3 class="form-title font text-underline" v-if="singleClient">
            {{ singleClient.name }}
          </h3>
          <h3 v-else class="font">Employer</h3>
        </div>
      </div>
      <form class="row form-row-height">
        <div class="col-md-6">
          <label class="font text-gray" for="">Company Name</label>
          <v-text-field tabindex="1" required solo></v-text-field>
        </div>
        <div class="col-md-6">
          <label class="font text-gray" for=""
            >Current Network Vendor
            <i class="fas fa-question-circle yellow-icons"></i
          ></label>
          <v-text-field tabindex="6" required solo></v-text-field>
        </div>
        <div class="col-md-6">
          <label class="font text-gray" for=""
            >Tax ID <i class="fas fa-question-circle yellow-icons"></i
          ></label>
          <v-text-field
            tabindex="2"
            required
            placeholder="00-0000000"
            :rules="taxIdRules"
            counter="10"
            maxlength="10"
            solo
          ></v-text-field>
        </div>
        <div class="col-md-6">
          <label class="font text-gray" for=""
            >Current Network Discount Rate (i.e Percent Discount off Billed
            Charges)<i class="fas fa-question-circle yellow-icons"></i
          ></label>
          <v-text-field tabindex="7" required solo></v-text-field>
        </div>
        <div class="col-md-6">
          <label class="font text-gray" for=""
            >Approximate Number of Employes</label
          >
          <v-text-field tabindex="3" required solo></v-text-field>
        </div>

        <div class="col-md-12 no-padding">
          <div class="col-md-6">
            <label class="font text-gray" for=""
              >Approximate Number of Plan Members </label
            >
            <v-text-field tabindex="4" required solo></v-text-field>
          </div>
        </div>
        
      </form>
      
      <div class="col-md-12 d-flex align-end flex-column">
          <div>
            <v-btn
              class="blue-buttons mr-6 mb-2"
              @click="
                $router.push(
                  `/employer-advisor/employers/${routeParams}/employers`
                )
              "
            >
              Cancel
            </v-btn>
            <v-btn
              class="blue-buttons mb-2"
              @click="
                $router.push(
                  `/employer-advisor/employers/${routeParams}/employers`
                )
              "
            >
              Remove Employer
            </v-btn>
          </div>
        </div>
    </div>
  </v-row>
</template>
<script>
export default {
  data() {
    return {
      taxIdRules: [
        (v) => !!v || "This Field is required",
        (v) =>
          !!/[0-9]{2}\-[0-9]{7}/.test(v) ||
          "Tax ID should contain 9 digits, Please enter your Tax ID in XX-XXXXXXX format",
      ],
    };
  },
  computed: {
    singleClient() {
      return this.$store.getters.getClientGetter;
    },
  },
  computed: {
    route() {
      return this.$route.path;
    },
    routeParams() {
      return this.$route.params.name;
    },
  },
  methods: {
    showSlash(event){
      if (this.taxId.length == 2 && event.keyCode != 8) {
        this.taxId += "-";
      }
      if (this.taxId.length == 10) {
        api()
          .get("auth/isValidTaxId?taxId=" + this.taxId)
          .catch((res) => {
            if (res) {
              this.taxIdRules = [res.response.data.message];
            }
          });
      } else {
        this.taxIdRules = [
          (v) => !!v || "This Field is required",
          (v) =>
            !!/[0-9]{2}\-[0-9]{7}/.test(v) ||
            "Tax ID should contain 9 digits, Please enter your Tax ID in XX-XXXXXXX format",
        ];
      }
    },
  },
};
</script>
<style>
.add-client-form {
  padding: 1.5rem;
}

.yellow-icons {
  color: rgb(224, 171, 36);
}
.blue-buttons {
  background: #4072b7 !important;
  color: white !important;
}
.no-padding {
  margin: 0;
  padding: 0px;
}
.underline-text {
  text-decoration: underline !important;
}
</style>