<template>
  <div class="">
    <Header :title="'Settings'"></Header>

    <!-- <div class="col-md-12 text-center justify-center" v-if="readyToShow">
      <v-progress-circular
        indeterminate
        :size="100"
        color="primary"
        class="text-center"
      ></v-progress-circular>
    </div> -->
    <div class="col-md-12">
      <div class="col-md-12 gray-border-form">
        <div class="form-row-height col-md-12">
          <div class="row">
            <v-form
              class="col-md-12 row"
              ref="form"
              v-model.trim="valid"
              lazy-validation
            >
              <div class="row" cols="12">
                <div class="col-md-6">
                  <h4 class="text-gray font mt-6 col-md-12" for="">
                    <span class="text-underline">
                      New Solution Notifications</span
                    >
                    <br />
                    <br />
                    <span class="text-gray"
                      >Whenever a solution is added to the client’s account, an
                      email will be sent to notify the client and request their
                      approval. Please enter the email address(es) where these
                      notifications should be sent.</span
                    >
                  </h4>
                </div>
                <div class="col-md-6">
                  <div class="col-md-12 pb-0 mt-10">
                    <label for="">Client Contact First Name</label>
                    <v-text-field
                      solo
                      v-model="firstName"
                      :rules="requiredRules"
                    ></v-text-field>
                  </div>
                  <div class="col-md-12 pt-0 pb-0">
                    <label for="">Client Contact Last Name</label>
                    <v-text-field
                      solo
                      v-model="lastName"
                      :rules="requiredRules"
                    ></v-text-field>
                  </div>
                  <div class="col-md-12 pt-0 pb-0">
                    <label for="">Client Contact Email</label>
                    <v-text-field
                      solo
                      v-model="email"
                      :rules="emailRules"
                    ></v-text-field>
                  </div>
                </div>
              </div>
            </v-form>
          </div>
          <br />
        </div>
      </div>
      <div class="col-md-12">
        <div class="col-md-12 mb-4" align="end">
          <span
            @click="$router.push('/digital-invoicing/clients')"
            class="
              light-blue-text
              text-underline
              pointer
              font-weight-bold
              mr-10
              font-18
            "
            >Cancel</span
          >
          <span
            @click="submit"
            class="
              light-blue-text
              text-underline
              pointer
              font-weight-bold font-18
            "
            >Save</span
          >
        </div>
      </div>
      {{ settings }}
    </div>

    <v-snackbar v-model.trim="snackbar" :timeout="1000" color="red">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <successModal
      ref="success_modal"
      v-on:confirm="closeSuccessModal"
    ></successModal>
    <errorModal ref="error_modal" v-on:confirm="closeErrorModal"></errorModal>
  </div>
</template>
<script>
import api from "../../../../../../services/api";
import successModal from "../../../../../TablesTemplate/SuccessDialog.vue";
import errorModal from "../../../../../TablesTemplate/ErrorDialog.vue";
import Header from "../../../Layout/HeaderPage.vue";

export default {
  components: { successModal, errorModal, Header },
  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      valid: false,
      snackbar: false,
      snackbarText: "",
      settings: null,
      requiredRules: [(v) => !!v || "This Field is required"],
      domainRules: [
        (v) => !!v || "This Field is required",
        (v) =>
          /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/.test(v) ||
          "Domain must be valid",
      ],
      emailRules: [
        (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || "E-mail must be valid",
      ],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      api()
        .get("/tpas/clients/" + this.$route.params.id)
        .then((res) => {
          if (res) {
            this.settings =
              res.data.settings && res.data.settings.solutionApprovalNotify
                ? res.data.settings.solutionApprovalNotify
                : null;

            if (this.settings) {
              this.firstName = this.settings.firstName;
              this.lastName = this.settings.lastName;
              this.email = this.settings.email;
            }
          }
        });
    },
    submit() {
      this.$refs.form.validate();
      if (this.valid) {
        let data = {
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
        };

        api()
          .put(`/tpas/clients/${this.$route.params.id}/settings`, data)
          .then((res) => {
            let text = "Settings updated successfully";
            this.$refs.success_modal.openPopUp(text);
          });
      }
    },

    closeErrorModal() {
      this.$refs.error_modal.closePopUp();
    },

    closeSuccessModal() {
      this.$refs.success_modal.closePopUp();
    },
    removeFromArray(email) {
      this.myDeleteArray.push(email);
      for (let i = 0; i < this.emails.length; i++) {
        if (this.emails[i] == email) {
          this.emails.splice(i, 1);
        }
      }
      this.$refs.success_modal.openPopUp("Domain was successfully deleted!");
    },
  },
};
</script>







    