<template>
  <div class="col-md-12" v-if="wizzardData != null">
    <div
      style="
        border-bottom: 6px solid darkorange;
        margin-left: -23px;
        margin-right: -23px;
      "
    >
      <div class="row">
        <div
          class="col-lg-3 col-md-10 col-3"
          style="padding: 25px 35px 35px 35px"
        >
          <b>{{ wizzardData.stepTitle[wizzardData.step] }}</b>
        </div>

        <div
          class="col-1"
          style="padding: 25px 35px 35px 35px"
          v-if="wizzardData.step != 0"
        >
          <b>{{ wizzardData.step }}</b
          >/4
        </div>
        <div v-else class="col-1"></div>
        <div
          class="col-6"
          v-if="!display && wizzardData.step == 3"
          style="padding: 25px 35px 35px 35px; text-align: right"
        >
          <i
            class="fa fa-times"
            style="color: #929292; font-size: 20px; cursor: pointer"
            @click="$router.push('/employer/home')"
          ></i>
        </div>

        <div
          class="col-md-4"
          style="padding: 25px 35px 35px 35px"
          v-if="wizzardData.step == 3"
        >
          <b
            >Number of Providers added to Provider List:
            <span
              v-html="
                wizzardData.providersList && wizzardData.providersList.length
                  ? wizzardData.providersList.length
                  : 0
              "
              style="font-size: 20px"
            ></span
          ></b>
        </div>

        <div
          v-if="!display && wizzardData.step != 3"
          class="col-6 col-lg-8"
          style="padding: 25px 35px 35px 35px; text-align: right"
        >
          <i
            class="fa fa-times"
            style="color: #929292; font-size: 20px; cursor: pointer"
            @click="$router.push('/employer/home')"
          ></i>
        </div>
        <div
          v-if="display"
          :class="wizzardData.step != 3 ? 'col-8' : 'col-4'"
          style="padding: 25px 35px 35px 35px; text-align: right"
        >
          <i
            class="fa fa-times"
            style="color: #929292; font-size: 20px; cursor: pointer"
            @click="$router.push('/employer/home')"
          ></i>
        </div>
      </div>
    </div>

    <div class="row" style="padding: 25px 15px; min-height: 70vh">
      <div class="col-md-12 no-padding" style="min-height: 60vh">
        <div v-if="wizzardData.step == 0">
          <div class="row">
            <div class="col-lg-2 col-md-4 font">
              <b>Employers </b>
            </div>
            <div class="col-lg-8 col-md-12">
              <input
                type="text"
                @input="searchTable"
                placeholder=" Search Employeers"
                style="
                  width: 30%;
                  border: 1px solid rgba(169, 169, 169, 0.34);
                  border-radius: 5px;
                  padding: 2px;
                  background-color: rgba(211, 211, 211, 0.06);
                "
                v-model.trim="search"
              />
            </div>
            <div class="col-lg-2 col-md-4">
              <v-btn class="button-blue width-100" @click="addNew"
                >Add New</v-btn
              >
            </div>
          </div>
          <div
            class="row"
            style="border-bottom: 1px solid rgba(169, 169, 169, 0.34)"
          >
            <div class="font wizz-table-header">Employer Name</div>
            <div class="font wizz-table-header">Contact Info</div>
            <div class="font wizz-table-header">Providers on List</div>
            <div class="font wizz-table-header">Step</div>
            <div class="font wizz-table-header">Status</div>
            <div class="font wizz-table-header"></div>
          </div>
          <div
            class="row"
            style="border-bottom: 1px solid rgba(169, 169, 169, 0.34)"
            v-for="(data, index) in savedDataLocal"
            :key="index"
          >
            <div
              style="color: #0573f3; cursor: pointer"
              class="wizz-table-row text-underline"
              @click="continueToWizzard(data)"
              v-if="data.data.step1data"
            >
              <span> {{ data.data.step1data.company_display_name }}</span>
            </div>
            <div class="wizz-table-row" v-if="data.data.step1data">
              {{ data.data.step1data.first_name }}
              {{ data.data.step1data.last_name }}
            </div>
            <div class="wizz-table-row" v-if="data.data.step1data">
              <span v-if="data.data.step2data.number_of_plan_members != null">{{
                data.data.step2data.number_of_plan_members
              }}</span>
              <span v-else>NA</span>
            </div>
            <div class="wizz-table-row" v-if="data.data.step1data">
              Step {{ data.data.step }}
            </div>
            <div
              class="wizz-table-row"
              v-if="data.data.step1data && data.status"
            >
              {{ data.status.charAt(0).toUpperCase() + data.status.slice(1) }}
            </div>
            <div class="wizz-table-row" v-else>/</div>
            <div style="color: #0573f3; cursor: pointer" class="wizz-table-row">
              <span class="text-underline" @click="continueToWizzard(data)"
                >Continue</span
              >
              /
              <span class="text-underline" @click="removeEmployer(data)"
                >Remove Employer</span
              >
            </div>
          </div>
        </div>
        <div v-if="wizzardData.step == 1">
          <form action="#" ref="step1form">
            <h5 class="text-underline pt-8 pb-8">Company Information</h5>
            <div class="row">
              <div class="wizard-inputs" style="width: 18%; margin: 0 1%">
                <label class="font text-gray" style="font-size: 12px"
                  >Company Legal Name</label
                >
                <v-text-field
                  required
                  :rules="wizzardData.requiredRules"
                  v-model.trim="wizzardData.step1data.company_legal_name"
                  solo
                ></v-text-field>
              </div>
              <div class="wizard-inputs" style="width: 18%; margin: 0 1%">
                <label class="font text-gray" style="font-size: 12px"
                  >Company Display Name</label
                >
                <v-text-field
                  required
                  :rules="wizzardData.requiredRules"
                  v-model.trim="wizzardData.step1data.company_display_name"
                  solo
                ></v-text-field>
              </div>
              <div
                style="width: 18%; margin: 0 1%"
                class="zip-field-height wizard-inputs"
              >
                <label class="font text-gray" style="font-size: 12px"
                  >Tax ID</label
                >
                <v-text-field
                  required
                  @keyup="showSlash($event)"
                  :rules="wizzardData.taxIdRules"
                  v-model.trim="wizzardData.step1data.tax_id"
                  maxlength="10"
                  max="10"
                  counter="10"
                  solo
                ></v-text-field>
              </div>
            </div>

            <h5 class="text-underline pt-8 pb-8">
              Company Representative Information
            </h5>
            <div class="row">
              <div class="wizard-inputs" style="width: 18%; margin: 0 1%">
                <label class="font text-gray" style="font-size: 12px"
                  >First Name</label
                >
                <v-text-field
                  required
                  :rules="wizzardData.requiredRules"
                  v-model.trim="wizzardData.step1data.first_name"
                  solo
                ></v-text-field>
              </div>
              <div class="wizard-inputs" style="width: 18%; margin: 0 1%">
                <label class="font text-gray" style="font-size: 12px"
                  >Last Name</label
                >
                <v-text-field
                  required
                  :rules="wizzardData.requiredRules"
                  v-model.trim="wizzardData.step1data.last_name"
                  solo
                ></v-text-field>
              </div>
              <div class="wizard-inputs" style="width: 18%; margin: 0 1%">
                <label class="font text-gray" style="font-size: 12px"
                  >Title</label
                >
                <v-text-field
                  required
                  :rules="wizzardData.requiredRules"
                  v-model.trim="wizzardData.step1data.title"
                  solo
                ></v-text-field>
              </div>
              <div class="wizard-inputs" style="width: 18%; margin: 0 1%">
                <label class="font text-gray" style="font-size: 12px"
                  >Email</label
                >
                <v-text-field
                  required
                  @input="checkEmailValidation"
                  :rules="wizzardData.emailRules"
                  v-model.trim="wizzardData.step1data.email"
                  solo
                ></v-text-field>
              </div>
            </div>
          </form>
        </div>

        <div v-if="wizzardData.step == 2">
          <form action="#" ref="step2form">
            <h5 class="text-underline pt-8 pb-8">Network Information</h5>
            <div class="row">
              <div class="wizard-inputs" style="width: 18%; margin: 0 1%">
                <label class="font text-gray" style="font-size: 12px"
                  >Provider Network Vendor</label
                >
                <v-text-field
                  required
                  :rules="wizzardData.requiredRules"
                  v-model.trim="wizzardData.step2data.provider_network_vendor"
                  solo
                ></v-text-field>
              </div>
              <div
                style="margin: 0 1%"
                class="col-md-5 col-xl-2 col-lg-3 no-padding wizard-inputs"
              >
                <label class="font text-gray" style="font-size: 12px"
                  >Contracted Network Discount Rate
                </label>
                <div class="row no-padding">
                  <v-text-field
                    required
                    :rules="wizzardData.discountRateRules"
                    v-model.trim="
                      wizzardData.step2data.Contracted_network_discount_rate
                    "
                    solo
                    @keyup="
                      checkNumberInputs('2', 'Contracted_network_discount_rate')
                    "
                    type="number"
                    placeholder="0-100%"
                  ></v-text-field>
                  <h2 class="mt-3 ml-3">%</h2>
                </div>
              </div>
            </div>

            <h5 class="text-underline pt-8 pb-8">Plan Information</h5>
            <div class="row">
              <div class="wizard-inputs" style="width: 18%; margin: 0 1%">
                <label class="font text-gray" style="font-size: 12px"
                  >Health Plan Name</label
                >
                <v-text-field
                  required
                  :rules="wizzardData.requiredRules"
                  v-model.trim="wizzardData.step2data.health_plan_name"
                  solo
                ></v-text-field>
              </div>
              <div class="wizard-inputs" style="width: 18%; margin: 0 1%">
                <label class="font text-gray" style="font-size: 12px"
                  >Number of Plan Members</label
                >
                <v-text-field
                  required
                  type="number"
                  :rules="wizzardData.requiredRules"
                  v-model.trim="wizzardData.step2data.number_of_plan_members"
                  solo
                ></v-text-field>
              </div>
              <div class="wizard-inputs" style="width: 18%; margin: 0 1%">
                <label class="font text-gray" style="font-size: 12px"
                  >Number of Employees</label
                >
                <v-text-field
                  required
                  type="number"
                  :rules="wizzardData.requiredRules"
                  v-model.trim="wizzardData.step2data.number_of_employees"
                  solo
                ></v-text-field>
              </div>
            </div>

            <h5 class="text-underline pt-8 pb-8">
              Plan Administrator Information
            </h5>
            <div class="row">
              <div class="wizard-inputs" style="width: 18%; margin: 0 1%">
                <label class="font text-gray" style="font-size: 12px"
                  >Plan Administrator Company Name</label
                >
                <v-text-field
                  required
                  :rules="wizzardData.requiredRules"
                  v-model.trim="
                    wizzardData.step2data.plan_administrator_company_name
                  "
                  solo
                ></v-text-field>
              </div>
              <div class="wizard-inputs" style="width: 18%; margin: 0 1%">
                <label class="font text-gray" style="font-size: 12px"
                  >Administrator Contact First Name</label
                >
                <v-text-field
                  required
                  :rules="wizzardData.requiredRules"
                  v-model.trim="
                    wizzardData.step2data.administrator_contact_first_name
                  "
                  solo
                ></v-text-field>
              </div>
              <div class="wizard-inputs" style="width: 18%; margin: 0 1%">
                <label class="font text-gray" style="font-size: 12px"
                  >Administrator Contact Last Name</label
                >
                <v-text-field
                  required
                  :rules="wizzardData.requiredRules"
                  v-model.trim="
                    wizzardData.step2data.administrator_contact_last_name
                  "
                  solo
                ></v-text-field>
              </div>
              <div class="wizard-inputs" style="width: 18%; margin: 0 1%">
                <label class="font text-gray" style="font-size: 12px"
                  >Administrator Contact Email</label
                >
                <v-text-field
                  required
                  :rules="wizzardData.emailRules"
                  v-model.trim="
                    wizzardData.step2data.administrator_contact_email
                  "
                  solo
                ></v-text-field>
              </div>
              <div
                style="width: 18%; margin: 0 1%"
                class="zip-field-height wizard-inputs"
              >
                <label class="font text-gray" style="font-size: 12px"
                  >Administrator Contact Phone</label
                >
                <v-text-field
                  required
                  :rules="wizzardData.phoneRules"
                  v-model.trim="
                    wizzardData.step2data.administrator_contact_phone
                  "
                  solo
                ></v-text-field>
              </div>
            </div>
          </form>
        </div>

        <div v-if="wizzardData.step == 3">
          <v-row class="padding">
            <v-col
              md="5"
              cols="12"
              class="justify-center align-center border-right"
            >
              <div class="row col-md-12">
                <h4 class="font">Add Member Zip Codes</h4>
              </div>
              <v-form class="row" ref="step3form">
                <div class="col-md-6">
                  <label for="" class="text-gray">Enter Zip Codes</label>
                  <v-text-field
                    :rules="wizzardData.zip ? wizzardData.zipRules : []"
                    solo
                    v-model.trim="wizzardData.zip"
                    type="number"
                  ></v-text-field>
                </div>
                <div class="col-md-6 mt-7 text-center">
                  <v-btn class="blue-buttons" @click="pushToArray()"
                    >Add to List
                  </v-btn>
                </div>
              </v-form>
              <div class="row text-center justify-center">
                <div
                  class="col-md-9 upload-provider-box pointer"
                  @click="addItemExcel"
                >
                  <input
                    ref="fileInput"
                    type="file"
                    style="display: none"
                    @change="uploadExcel"
                  />
                  <img src="/filee.png" class="apaly-pic" alt="" />
                  <h3 class="text-gray font">Drag & Drop</h3>
                  <h5 class="text-gray font">
                    Your files here or
                    <a>Browse</a>
                    to upload
                  </h5>
                  <h5 class="text-gray font">
                    <a>Excel or CSV files Only</a> <br />
                    <img
                      v-if="wizzardData.arrayLength"
                      src="/success.png"
                      width="30"
                      height="30"
                      alt=""
                    />
                  </h5>
                </div>
                <div class="btn-box col-md-10">
                  <div class="downoald-file-btn-box">
                    <h3>
                      <a class="underline-text" @click="downloadExcel"
                        >Download File Template</a
                      >
                    </h3>
                  </div>
                </div>
              </div>
            </v-col>
            <v-col cols="12" md="7">
              <h3>Zip Code List</h3>
              <div>
                <v-simple-table
                  :class="
                    wizzardData.zipCodeList.length > 10 ? 'y-scroll-zip' : ''
                  "
                >
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Zip Codes</th>
                        <th
                          class="text-end"
                          @click="
                            (wizzardData.zipCodeList = []),
                              (wizzardData.providersList = [])
                          "
                        >
                          Clear All
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item, index) in wizzardData.zipCodeList"
                        :key="item.Zip ? item.Zip : item"
                      >
                        <td v-if="item.Zip">{{ item.Zip }}</td>
                        <td v-else>{{ item }}</td>
                        <td
                          @click="removeFromArray(item, index)"
                          class="text-end pointer"
                        >
                          <svg
                            width="28"
                            height="28"
                            viewBox="0 0 28 28"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1 13.7206C1 17.0943 2.35661 20.3298 4.77138 22.7154C7.18615 25.101 10.4613 26.4412 13.8763 26.4412C17.2913 26.4412 20.5664 25.101 22.9812 22.7154C25.396 20.3298 26.7526 17.0943 26.7526 13.7206C26.7526 10.3469 25.396 7.11134 22.9812 4.72577C20.5664 2.3402 17.2913 1 13.8763 1C10.4613 1 7.18615 2.3402 4.77138 4.72577C2.35661 7.11134 1 10.3469 1 13.7206V13.7206Z"
                              stroke="#D00000"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M8.72607 18.8093L19.0271 8.63281"
                              stroke="#D00000"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M19.0271 18.8093L8.72607 8.63281"
                              stroke="#D00000"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
            </v-col>
            <v-col md="12" class="text-end">
              <v-progress-circular
                indeterminate
                v-if="load"
                color="primary"
              ></v-progress-circular>
              <v-btn
                class="blue-buttons"
                v-if="
                  wizzardData.zipCodeList && wizzardData.zipCodeList.length > 0
                "
                :disabled="load"
                @click="getProvidersByZip"
                >Get Providers</v-btn
              >
            </v-col>
          </v-row>
        </div>

        <div v-if="wizzardData.step == 4">
          <form action="#" ref="step4form">
            <div class="row">
              <div class="col-6">
                <h3 class="font">What services do you want to access?</h3>
                <div class="font">
                  <v-checkbox
                    v-model.trim="
                      wizzardData.step4data.digital_direct_healthcare_contract
                    "
                  >
                    <template v-slot:label>
                      <span
                        class="ml-2 text-underline"
                        style="color: #0573f3"
                        @click="generatePdf"
                        >Digital Direct Healthcare Contract</span
                      >
                    </template>
                  </v-checkbox>
                  <span class="ml-9">
                    Direct contracting with all types of healthcare providers
                    through the platform.
                  </span>
                </div>
              </div>

              <div class="col-6">
                <h3 class="font">
                  What are the maximum rates that you are willing to pay?
                </h3>
                <div class="row">
                  <div class="col-md-6">
                    <label style="float: left" class="text-gray"
                      >Inpatient</label
                    >
                    <br />
                    <v-text-field
                      placeholder="0%"
                      @keyup="checkNumberInputs('4', 'inpatient')"
                      type="number"
                      solo
                      v-model.trim="wizzardData.step4data.inpatient"
                      style="width: 50%; float: left"
                    ></v-text-field>
                    <b style="margin-left: 15px; line-height: 3" class="font">
                      % of Medicare</b
                    >
                  </div>
                  <div class="col-md-6">
                    <label style="float: left" class="text-gray"
                      >Outpatient</label
                    >
                    <br />
                    <v-text-field
                      solo
                      placeholder="0%"
                      @keyup="checkNumberInputs('4', 'outpatient')"
                      type="number"
                      v-model.trim="wizzardData.step4data.outpatient"
                      style="width: 50%; float: left"
                    ></v-text-field>
                    <b style="margin-left: 15px; line-height: 3" class="font">
                      % of Medicare</b
                    >
                  </div>
                </div>
              </div>
            </div>
            <hr style="margin: 30px 0" />
            <div class="row">
              <div class="col-6">
                <div class="font">
                  <v-checkbox
                    v-model.trim="wizzardData.step4data.acp_and_dcp_service"
                  >
                    <template v-slot:label>
                      <span
                        class="ml-2 text-underline"
                        style="color: #0573f3"
                        @click="generatePdf"
                        >Direct Primary Care (DPC) and Advanced Primary Care
                        (APC) Services Addendum</span
                      >
                    </template>
                  </v-checkbox>
                  <span class="ml-9">
                    Direct contracting with Advanced (APC) or Direct (DPC)
                    Primary Care Providers only.
                  </span>
                </div>
              </div>
              <div class="col-6">
                <div class="row">
                  <div class="col-md-6">
                    <label style="float: left" class="text-gray">DPC</label>
                    <br />
                    <div class="row no-padding col-md-8 mt-2">
                      <div class="mr-2 mt-2">$</div>
                      <v-text-field
                        :rules="wizzardData.requiredRules"
                        solo
                        v-model.trim="wizzardData.step4data.dpc"
                        type="text"
                        style="width: 50%; float: left"
                      ></v-text-field>
                      <b
                        style="
                          margin-left: 15px;
                          line-height: 3;
                          font-weight: bolder;
                        "
                        class="font"
                        >PMPM</b
                      >
                    </div>
                  </div>
                  <div class="col-md-6">
                    <label style="float: left" class="text-gray">APC</label>
                    <br />
                    <div class="row no-padding col-md-8 mt-2">
                      <div class="mr-2 mt-2">$</div>
                      <v-text-field
                        :rules="wizzardData.requiredRules"
                        solo
                        v-model.trim="wizzardData.step4data.apc"
                        type="text"
                        style="width: 50%; float: left"
                      ></v-text-field>
                      <b
                        style="
                          margin-left: 15px;
                          line-height: 3;
                          font-weight: bolder;
                        "
                        class="font"
                        >PMPM</b
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>

        <span :style="'color: red; display: ' + wizzardData.displayAllErrors"
          >All fields are required*</span
        >
        <span :style="'color: red; display: ' + wizzardData.displayEmailErrors"
          >Emails are not correct!</span
        >
      </div>

      <div
        class="row mt-3"
        style="
          border-top: 2px solid rgba(146, 146, 146, 0.39);
          margin-left: -25px;
          margin-right: -30px;
        "
        v-if="wizzardData.step > 0"
      >
        <div class="col-10" style="padding: 25px 55px 15px; text-align: left">
          <span
            v-if="wizzardData.step > 1"
            class="font text-gray text-underline"
            style="cursor: pointer"
            @click="goBack"
            >Go Back
          </span>
        </div>
        <div class="col-1 text-end" style="padding: 25px 55px 15px">
          <span
            class="font text-gray text-underline"
            @click="saveWizzardPosition"
            style="cursor: pointer"
            >Save</span
          >
        </div>
        <div
          class="col-1"
          style="padding: 25px 55px 15px"
          v-if="wizzardData.step != 4"
        >
          <span
            class="font text-gray text-underline"
            style="cursor: pointer"
            @click="checkAndContinue"
            >Next</span
          >
        </div>
      </div>

      <div
        class="row"
        style="
          border-top: 2px solid rgba(146, 146, 146, 0.39);
          margin-left: -25px;
          margin-right: -25px;
        "
        v-if="wizzardData.step == 0"
      >
        <div class="col-8" style="padding: 25px 55px 15px; text-align: left">
          <span
            class="font text-gray text-underline"
            style="cursor: pointer"
            @click="goBack"
          ></span>
        </div>
        <div class="col-lg-2 col-6 text-end" style="padding: 25px 55px 15px">
          <span class="text-gray mr-6" style="color: rgba(128, 128, 128, 0.47)"
            >rows per page
          </span>
          {{ rows }} <i class="fa fa-caret-down"></i>
        </div>
        <div class="col-lg-2 col-6 text-center" style="padding: 25px 55px 15px">
          <span class="text-gray mr-6" style="color: rgba(128, 128, 128, 0.47)"
            >{{ tablePage * rows - (rows - 1) }}-{{ tablePage * rows }}</span
          >
          <i
            class="fa fa-chevron-left mr-4"
            style="color: rgba(128, 128, 128, 0.47); cursor: pointer"
            @click="lastTablePage"
          ></i>
          <i
            class="fa fa-chevron-right"
            style="color: rgba(128, 128, 128, 0.47); cursor: pointer"
            @click="nextTablePage"
          ></i>
        </div>
      </div>
    </div>

    <v-dialog v-model.trim="providersDialog" width="1200" scrollable>
      <v-card>
        <v-card-title
          class="text-h5 row justify-space-between ml-3 mb-5"
          style="height: 20px; width: 99%"
        >
          <div style="margin-left: 0px">Search Result - Provider List</div>

          <h4 @click="providersDialog = false" class="pointer">x</h4>
        </v-card-title>
        <v-card-text>
          <div>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Provider/Organization</th>
                    <th class="text-left">Type</th>
                    <th class="text-left">Taxonomy</th>
                    <th class="text-left">City</th>
                    <th class="text-left">State</th>
                    <th class="text-left">Actions</th>
                  </tr>
                </thead>
                <tbody v-if="items.length > 0">
                  <tr
                    v-for="(item, index) in items"
                    :key="index"
                    :id="'id' + index"
                  >
                    <td
                      class="text-gray"
                      v-if="
                        item['Provider Organization Name (Legal Business Name)']
                      "
                    >
                      <a @click="singleProvider(item)">{{
                        item["Provider Organization Name (Legal Business Name)"]
                      }}</a>
                    </td>
                    <td
                      class="text-gray"
                      v-else-if="
                        item['Provider Last Name (Legal Name)'] ||
                        item['Provider First Name']
                      "
                    >
                      <a>
                        {{ item["Provider First Name"] }}
                        {{ item["Provider Last Name (Legal Name)"] }}</a
                      >
                    </td>
                    <td class="text-gray text-left">
                      Type {{ item["Entity Type Code"] }}
                    </td>
                    <td class="text-left">
                      {{ item["Healthcare Provider Taxonomy Code_1"] }}
                    </td>
                    <td
                      class="text-gray text-left"
                      v-html="
                        item[
                          'Provider Business Practice Location Address City Name'
                        ]
                          ? item[
                              'Provider Business Practice Location Address City Name'
                            ]
                          : '/'
                      "
                    ></td>
                    <td
                      class="text-gray"
                      v-html="
                        item['Provider Business Mailing Address State Name']
                          ? item['Provider Business Mailing Address State Name']
                          : '/'
                      "
                    ></td>
                    <td>
                      <svg
                        class="ml-2 pointer"
                        @click="removeProvider(item)"
                        width="28"
                        height="26"
                        viewBox="0 0 28 26"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.35547 13C1.35547 16.1826 2.65524 19.2348 4.96884 21.4853C7.28244 23.7357 10.4204 25 13.6923 25C16.9642 25 20.1021 23.7357 22.4157 21.4853C24.7293 19.2348 26.0291 16.1826 26.0291 13C26.0291 9.8174 24.7293 6.76516 22.4157 4.51472C20.1021 2.26428 16.9642 1 13.6923 1C10.4204 1 7.28244 2.26428 4.96884 4.51472C2.65524 6.76516 1.35547 9.8174 1.35547 13V13Z"
                          stroke="#D00000"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M8.75781 17.8002L18.6273 8.2002"
                          stroke="#D00000"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M18.6273 17.8002L8.75781 8.2002"
                          stroke="#D00000"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td>No Result Found</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
        </v-card-text>
        <v-card-actions>
          <div class="col-md-12 text-end">
            <v-btn
              v-if="showButton"
              :disabled="loadData"
              class="blue-buttons mb-2 mt-2"
              @click="loadMoreData()"
            >
              Load More Data
            </v-btn>
            <v-btn
              class="blue-buttons text-end mb-2 mt-2 ml-3"
              :disabled="loadData"
              @click="(providersDialog = false), (page = 1)"
              >Close</v-btn
            >
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <contractPdf
      v-on:confirm="confirmClose"
      ref="contract_pdf"
      :name="wizzardData.step1data.company_display_name"
      :inpatient="wizzardData.step4data.inpatient"
      :outpatient="wizzardData.step4data.outpatient"
    ></contractPdf>
    <v-dialog v-model.trim="showFinalDialog" width="600">
      <v-card>
        <v-card-title class="text-h5"> CONGRATULATIONS...! </v-card-title>
        <v-card-text>
          <p style="white-space: break-spaces; line-height: 3">
            Wizard From Saved Successfully
          </p>
        </v-card-text>
        <v-card-actions>
          <div class="col-md-12 text-end">
            <v-btn class="blue-buttons text-end" @click="finish(false)"
              >Close</v-btn
            >
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <errorModal ref="error_modal" v-on:confirm="closeErrorModal"></errorModal>
    <removeDialog
      ref="dialog_delete"
      v-on:confirm="confirmRemove"
    ></removeDialog>
    <v-snackbar v-model.trim="snackbar" :timeout="2000" :color="snackbarColor">
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import XLSX from "xlsx";
import errorModal from "../../TablesTemplate/ErrorDialog.vue";
import removeDialog from "../../TablesTemplate/RemoveDialog.vue";
import contractPdf from "../../TablesTemplate/ContractPDF.vue";
import api from "../../../services/api";
import { jsPDF } from "jspdf";
export default {
  name: "WizardForm",
  components: { errorModal, removeDialog, contractPdf },
  data() {
    return {
      search: null,
      snackbar: false,
      savedDataLocal: [],
      tablePage: 1,
      snackbarText: "",
      snackbarColor: "red",
      rows: 5,
      load: false,
      wizzardData: null,
      updateData: false,
      items: [],
      onboardId: "",
      requestId: "",
      showInviteButton: true,
      showFinalDialog: false,
      providersDialog: false,
      emailExist: false,
      allItems: [],
      nextPageItems: [],
      backPageItems: [],
      validateTax: true,
      totalPages: "",
      allItems: [],
      currentPage: 1,
      showButton: false,
      page: 1,
      loadData: false,
      responseZips: [],
      display: true,
      windowWidth: window.innerWidth,
      taxIdRules: [
        (v) => !!v || "This Field is required",
        (v) =>
          !!/[0-9]{2}\-[0-9]{7}/.test(v) ||
          "Tax ID should contain 9 digits, Please enter your Tax ID in XX-XXXXXXX format",
      ],
    };
  },
  computed: {
    filterData() {
      return this.allItems;
    },
  },

  methods: {
    loadMoreData() {
      this.loadData = true;
      this.page++;
      let arr = [];

      this.responseZips.forEach((el) => {
        if (el.Zip) {
          arr.push(Number(el.Zip));
        } else {
          arr.push(Number(el));
        }
      });
      let data = {
        zipCodes: arr,
      };
      api()
        .post(`/locations/npi?page=${this.page}&limit=100`, data)
        .then((res) => {
          if (res) {
            if (res.data.items.length < 100) {
              this.showButton = false;
            }
            res.data.items.forEach((el) => {
              this.items.push(el);
              this.wizzardData.providersList.push(el.NPI);
            });
            this.loadData = false;
          }
        });
    },
    getProvidersByZip() {
      if (this.wizzardData.zipCodeList) {
        api()
          .get(
            `/locations/nearLocations?zipCode=` + this.wizzardData.zipCodeList
          )
          .catch((err) => {
            if (err) {
              this.$refs.error_modal.openPopUp(err.response.data.error);
            } else {
              this.$refs.error_modal.openPopUp(
                "Something went wrong, please try again"
              );
            }
          })
          .then((res) => {
            if (res) {
              if (res.data && res.data.length > 0) {
                this.load = true;
                let arr = [];
                this.responseZips = res.data;
                res.data.forEach((el) => {
                  if (el.Zip) {
                    arr.push(Number(el.Zip));
                  } else {
                    arr.push(Number(el));
                  }
                });
                let data = {
                  zipCodes: arr,
                };
                api()
                  .post(`/locations/npi?page=${this.page}&limit=100`, data)
                  .then((res) => {
                    if (res) {
                      this.items = res.data.items;
                      this.wizzardData.providersList = res.data.items.map(
                        (t) => t.NPI
                      );
                      this.load = false;
                      this.providersDialog = true;

                      if (res.data.items.length % 100 == 0) {
                        this.showButton = true;
                      } else if (res.data.items.length < 100) {
                        this.showButton = false;
                      } else {
                        this.showButton = true;
                      }
                    }
                  });
              } else {
                this.$refs.error_modal.openPopUp("First Add Zip Code To List");
              }
            }
          });
      }
    },
    confirmClose() {
      this.$refs.contract_pdf.closePopUp();
    },
    removeProvider(item) {
      this.items.forEach((el) => {
        if (el._id == item._id) {
          this.items.splice(this.items.indexOf(el), 1);
        }
      });
      this.wizzardData.providersList = this.items.map((t) => t.NPI);
    },
    checkEmailValidation() {
      api()
        .get(`/onboard/emailExists?email=` + this.wizzardData.step1data.email)
        .catch((err) => {
          if (err) {
            this.wizzardData.emailRules.push(["Email Already Exists"]);
            this.wizzardData.displayEmailErrors = "block";
          }
        })
        .then((res) => {
          if (res) {
            if (res.data.exists) {
              this.wizzardData.emailRules = ["Email Already Exists"];
              this.wizzardData.displayEmailErrors = "block";
              this.emailExist = true;
            } else {
              this.wizzardData.emailRules = [
                (v) => /.+@.+/.test(v) || "E-mail must be valid",
              ];
              this.wizzardData.displayEmailErrors = "none";
              this.emailExist = false;
            }
          }
        });
    },
    checkNumberInputs(step, field) {
      if (this.wizzardData["step" + step + "data"][field] < 0) {
        this.wizzardData["step" + step + "data"][field] = 0;
      }
      if (
        this.wizzardData["step2data"]["Contracted_network_discount_rate"] > 100
      ) {
        this.wizzardData["step2data"]["Contracted_network_discount_rate"] = 100;
      }
    },
    validateEmail(email) {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        return false;
      } else {
        return true;
      }
    },

    validateTaxId(taxId) {
      if (/[0-9]{2}\-[0-9]{7}/.test(taxId) && this.validateTax) {
        return false;
      } else {
        return true;
      }
    },
    validatePhone(phone) {
      if (/^[0-9\ \+\-\/]+$/.test(phone)) {
        return false;
      } else {
        return true;
      }
    },
    makeid(length) {
      var result = "";
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    },
    async finish(another = false) {
      this.wizzardData.showFinalDialog = false;
      this.showFinalDialog = false;
      // await this.$store.commit('removeWizzardData',this.wizzardData.uniqid)
      if (another) {
        await this.addNew();
      } else {
        await this.$router.push(`/employer/home`);
      }
    },
    async addNew() {
      this.requestId = "";
      await this.$store.commit("setCleanWizzardData");
      this.wizzardData = this.$store.getters.getWizzardData;
      // this.wizzardData.uniqid = this.makeid(32)
      this.wizzardData.step++;
    },
    async continueToWizzard(data) {
      await this.$store.commit("continueWizzardData", data.data);
      this.wizzardData = this.$store.getters.getWizzardData;

      this.updateData = true;
      this.currentPage = this.wizzardData.step;

      api()
        .get(`/onboard/${data._id}`)
        .then((res) => {
          if (res) {
            if (res.data.status == "draft") {
              this.showInviteButton = true;
            } else {
              this.showInviteButton = false;
            }
            this.requestId = res.data._id;
          }
        });

      if (this.wizzardData == null) {
        alert("Data is broken please add new");
        await this.addNew();
      }
    },
    async saveWizzardPosition() {
      let continueStep = true;
      let user = JSON.parse(localStorage.getItem("user"));
      let advisorId = user.groupPortal[0].organization.employerAdvisor;
      // if(this.currentPage > this.wizzardData.step){
      //   this.wizzardData.step  = this.currentPage;
      // }
      // console.log('steppp',this.wizzardData.step)
      let data = {
        accountId: user.id, // string representing account id (account collection)
        companyName: this.wizzardData.step1data.company_legal_name, // from step 1
        taxId: this.wizzardData.step1data.tax_id,
        email: this.wizzardData.step1data.email,
        data: this.wizzardData,
      };

      if (!this.requestId) {
        continueStep = this.$refs["step1form"].checkValidity();
        if (continueStep) {
          // set one of these enum
          api()
            .post(`/onboard`, data)
            .then((res) => {
              this.$store.commit("saveWizzardData", this.wizzardData);
              this.snackbar = true;
              this.snackbarText = "Progress Saved";
              this.snackbarColor = "green";
              this.requestId = res.data._id;
            });
        }
      } else {
        api()
          .put(`/onboard/${this.requestId}`, data)
          .then((res) => {
            this.$store.commit("saveWizzardData", this.wizzardData);
            this.snackbar = true;
            this.snackbarText = "Progress Saved";
            this.snackbarColor = "green";
            // this.requestId = res.data._id
          });
      }
      // else{
      //   alert('please fill at least step 1 to save draft')
      // }
    },
    generatePdf() {
      this.$refs.contract_pdf.openPopUp();
    },

    lastTablePage() {
      if (this.tablePage == 1) return;
      this.tablePage--;
      this.savedDataLocal = this.allItems.slice(
        this.tablePage * this.rows - this.rows,
        this.tablePage * this.rows
      );
    },
    nextTablePage() {
      if (this.tablePage >= this.allItems.length / this.rows) return;
      this.tablePage++;
      this.savedDataLocal = this.allItems.slice(
        this.tablePage * this.rows - this.rows,
        this.tablePage * this.rows
      );
    },

    searchTable() {
      if (this.search != null && this.search != "") {
        this.savedDataLocal = this.filterData.filter((el) => {
          return (
            el.data.step1data.company_display_name
              .toString()
              .toLowerCase()
              .includes(this.search.toLowerCase()) ||
            el.data.step1data.last_name
              .toString()
              .toLowerCase()
              .includes(this.search.toLowerCase()) ||
            el.data.step1data.first_name
              .toString()
              .toLowerCase()
              .includes(this.search.toLowerCase())
          );
        });
      }
      //  if(this.savedDataLocal.length < 1){
      //     this.savedDataLocal = this.allItems
      //  }
    },
    checkAndContinue() {
      if (this.wizzardData.step == 4) {
        let continueStep4 = true;
        this.wizzardData.displayAllErrors = "none";
        if (
          !this.wizzardData.step4data.digital_direct_healthcare_contract &&
          !this.wizzardData.step4data.acp_and_dcp_service
        ) {
          this.snackbar = true;
          this.snackbarText = "Please Select Service";
          this.snackbarColor = "red";
          return;
        }
        if (this.wizzardData.step4data.digital_direct_healthcare_contract) {
          continueStep4 = false;
          if (
            this.wizzardData.step4data.inpatient == null ||
            this.wizzardData.step4data.inpatient == "" ||
            this.wizzardData.step4data.outpatient == null ||
            this.wizzardData.step4data.outpatient == ""
          ) {
            this.wizzardData.displayAllErrors = "block";
            return;
          } else {
            this.wizzardData.displayAllErrors = "none";
            continueStep4 = true;
          }
        }
        if (this.wizzardData.step4data.acp_and_dcp_service) {
          continueStep4 = false;
          if (
            this.wizzardData.step4data.dpc == null ||
            this.wizzardData.step4data.dpc == "" ||
            this.wizzardData.step4data.apc == null ||
            this.wizzardData.step4data.apc == ""
          ) {
            this.wizzardData.displayAllErrors = "block";
            return;
          } else {
            this.wizzardData.displayAllErrors = "none";
            continueStep4 = true;
          }
        }
        if (continueStep4) {
          let user = JSON.parse(localStorage.getItem("user"));
          let data = {
            accountId: user.id, // string representing account id (account collection)
            companyName: this.wizzardData.step1data.company_legal_name, // from step 1
            taxId: this.wizzardData.step1data.tax_id,
            email: this.wizzardData.step1data.email,
            data: this.wizzardData,
          };
          this.showFinalDialog = true;
          this.wizzardData.showFinalDialog = true;
          // api()
          //   .post(`/onboard/${this.requestId}/invite`, data)
          //   .catch((err) => {
          //     if (err) {
          //       if(err.response && err.response.data && err.response.data.error){
          //          this.$refs.error_modal.openPopUp(err.response.data.error);
          //       }else if(err.response && err.response.data && err.response.data.message){
          //          this.$refs.error_modal.openPopUp(err.response.data.message);

          //       }
          //     }
          //   })
          //   .then((res) => {
          //     if (res) {
          //       this.showFinalDialog = true;
          //       this.wizzardData.showFinalDialog = true;

          //     }
          //   });
        }
      } else {
        let continueStep = false;
        if (this.wizzardData.step == 3) {
          if (this.wizzardData.providersList.length > 0) {
            this.snackbarText = "Provider List Fetched";
            this.snackbarColor = "green";
            this.snackbar = true;
          } else {
            if (this.wizzardData.zipCodeList.length < 1) {
              this.snackbarText = "Add Zip Code To List";
              this.snackbarColor = "red";
              this.snackbar = true;
              return;
            } else {
              this.snackbarText = "Please Click on Get Providers Button";
              this.snackbarColor = "red";
              this.snackbar = true;
              return;
            }
          }
        } else {
          continueStep =
            this.$refs["step" + this.wizzardData.step + "form"].checkValidity();
          if (continueStep) {
            this.wizzardData.displayAllErrors = "none";
            if (this.wizzardData.step == 1) {
              if (
                this.validateEmail(this.wizzardData.step1data.email) ||
                this.emailExist
              ) {
                this.wizzardData.displayEmailErrors = "block";
                return;
              } else {
                this.wizzardData.displayEmailErrors = "none";
              }
              if (this.validateTaxId(this.wizzardData.step1data.tax_id)) {
                this.wizzardData.displayAllErrors = "block";
                return;
              } else {
                this.wizzardData.displayAllErrors = "none";
              }
            } else if (this.wizzardData.step == 2) {
              if (
                this.validateEmail(
                  this.wizzardData.step2data.administrator_contact_email
                )
              ) {
                this.wizzardData.displayEmailErrors = "block";
                return;
              } else {
                this.wizzardData.displayEmailErrors = "none";
              }
              if (
                this.validatePhone(
                  this.wizzardData.step2data.administrator_contact_phone
                )
              ) {
                this.wizzardData.displayAllErrors = "block";
                return;
              } else {
                this.wizzardData.displayAllErrors = "none";
              }
            }
          } else {
            this.wizzardData.displayAllErrors = "block";
            return;
          }
        }

        this.wizzardData.step++;
      }
      this.saveWizzardPosition();
    },
    showSlash(event) {
      if (
        this.wizzardData.step1data.tax_id &&
        this.wizzardData.step1data.tax_id.length == 2 &&
        event.keyCode != 8
      ) {
        this.wizzardData.step1data.tax_id += "-";
      }
      if (this.wizzardData.step1data.tax_id.length == 10) {
        api()
          .get("auth/isValidTaxId?taxId=" + this.wizzardData.step1data.tax_id)
          .catch((res) => {
            this.validateTax = false;
            if (res) {
              this.wizzardData.taxIdRules = [res.response.data.message];
            }
          });
      } else {
        this.validateTax = true;
        this.wizzardData.taxIdRules = [
          (v) => !!v || "This Field is required",
          (v) =>
            !!/[0-9]{2}\-[0-9]{7}/.test(v) ||
            "Tax ID should contain 9 digits, Please enter your Tax ID in XX-XXXXXXX format",
        ];
      }
    },
    goBack() {
      if (this.wizzardData.step == 0) return;
      this.wizzardData.step--;
    },
    //step3
    closeErrorModal() {
      this.wizzardData.zipCodeList = [];
      this.$refs.error_modal.closePopUp();
    },
    downloadExcel() {
      const data = XLSX.utils.json_to_sheet(this.wizzardData.jsondata);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, "data");
      XLSX.writeFile(wb, "providerList.xlsx");
    },
    addItemExcel() {
      this.$refs.fileInput.click();
    },
    uploadExcel(e) {
      const files = e.target.files;
      if (!files.length) {
        return;
      } else if (!/\.(xls|xlsx|csv)$/.test(files[0].name.toLowerCase())) {
        return alert(
          "The upload format is incorrect. Please upload xls, xlsx or csv format"
        );
      }
      const fileReader = new FileReader();
      fileReader.onload = (ev) => {
        wizzardData.step;
        try {
          const data = ev.target.result;
          const workbook = XLSX.read(data, {
            type: "binary",
          });
          const wsname = workbook.SheetNames[0];
          const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname]);
          const excellist = [];

          for (var i = 0; i < ws.length; i++) {
            if (ws[i]["Zip Code"]) {
              this.wizzardData.zipCodeList.push(ws[i]["Zip Code"]);
              excellist.push(ws[i]["Zip Code"]);
              this.wizzardData.excelError = false;
              this.wizzardData.arrayLength = true;
            } else {
              this.wizzardData.zipCodeList = [];
              this.wizzardData.excelError = true;
              this.wizzardData.arrayLength = false;
            }
          }
          this.wizzardData.zipCodeList = excellist;
          if (this.wizzardData.zipCodeList.length < 1) {
            this.$refs.error_modal.openPopUp(
              "Something Went Wrong, Please Download the File Template First"
            );
          }
        } catch (e) {
          return alert("Read failure!");
        }
      };

      fileReader.readAsBinaryString(files[0]);
    },
    changeZipRules() {
      this.wizzardData.zipRules = [
        (v) => (v && v.length == 5) || " Zip Code should be five digits long",
      ];
    },
    pushToArray() {
      if (
        this.wizzardData.zip != "" &&
        this.wizzardData.zip != null &&
        this.wizzardData.zip.length == 5
      ) {
        if (this.wizzardData.zipCodeList.includes(this.wizzardData.zip)) {
          alert("Zip Code Already Exists!");
          return;
        }
        this.wizzardData.zipCodeList.push(this.wizzardData.zip);
        this.wizzardData.zip = "";
      }
    },
    removeFromArray(item, index) {
      let removeItem = this.wizzardData.zipCodeList.find((x) => x == item);
      this.wizzardData.zipCodeList.splice(
        this.wizzardData.zipCodeList.indexOf(removeItem),
        1
      );
      if (this.wizzardData.zipCodeList.length < 1) {
        this.wizzardData.providersList = [];
      }
    },
    removeEmployer(data) {
      this.onboardId = data._id;
      this.$refs.dialog_delete.openPopUp(
        "Are you sure that you want to delete this Employer?"
      );
    },
    confirmRemove() {
      let user = JSON.parse(localStorage.getItem("user"));
      let advisorId = user.groupPortal[0].organization.employerAdvisor;
      api()
        .delete(`/onboard/${this.onboardId}`)
        .then((res) => {
          if (res) {
            this.$refs.dialog_delete.closePopUp();
            this.savedData();
          }
        });
    },
    onResize() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth < 600) {
        this.display = false;
      } else {
        this.display = true;
      }
    },
    savedData() {
      this.$store.commit("setCleanWizzardData");
      this.wizzardData = this.$store.getters.getWizzardData;
      this.wizzardData.step = 1;
      let user = JSON.parse(localStorage.getItem("user"));
      api()
        .get(`/onboard/account/${user.id}`)
        .then((res) => {
          if (res.data.length > 0) {
            this.requestId = res.data[0]._id;
            this.wizzardData = res.data[0].data;
          }
          if (this.wizzardData.zipCodeList.length < 1) {
            this.wizzardData.providersList = [];
          }
          this.allItems = res.data;

          this.wizzardData.requiredRules = [
            (v) => !!v || "This Field is required",
          ];
          this.wizzardData.zipRules = [
            (v) =>
              (v && v.length == 5) || " Zip Code should be five digits long",
          ];
          this.wizzardData.emailRules = [
            (v) => !!v || "This Field is required",
            (v) => /.+@.+/.test(v) || "E-mail must be valid",
          ];
          this.wizzardData.taxIdRules = [
            (v) => !!v || "This Field is required",
            (v) =>
              /[0-9]{2}\-[0-9]{7}/.test(v) ||
              "Tax ID should contain 9 digits, Please enter your Tax ID in XX-XXXXXXX format",
          ];
          this.wizzardData.discountRateRules = [
            (v) => /[0-9]/.test(v) || "The value should be from 0 to 100%",
          ];
          this.wizzardData.phoneRules = [
            (v) =>
              /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(v) ||
              "Phone number must be in a valid format (XXX XXX XXXX)",
          ];

          this.wizzardData.step = 1;
          this.totalPages = Math.round(res.data.length / 5);
          this.savedDataLocal = this.allItems.slice(
            this.tablePage * this.rows - this.rows,
            this.tablePage * this.rows
          );
        });
    },
  },
  async mounted() {
    this.savedData();
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  created() {
    if (this.windowWidth < 600) {
      this.display = false;
    } else {
      this.display = true;
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>
<style scoped>
.wizz-table-header {
  width: 14%;
  margin: 0 1%;
  font-size: 14px;
  color: rgba(169, 169, 169, 0.54);
}
.wizz-table-row {
  width: 14%;
  margin: 0 1%;
  padding: 30px 0 30px 0;
  font-weight: bold;
}
</style>