<template>
  <div class="">
    <div v-if="!isChildren && $route.meta.type == 'invoices'" class="row-height">
      <Header
        :title="'Invoices'"
        :searchInput="true"
        :exportButton="true"
        :placeholder="'Search Invoices'"
        @searchInput="searchInput"
        @export="exportFun"
      ></Header>
      <div class="mt-3">
        <v-simple-table fixed-header height="70vh" class="no-padding tr-custom-height">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">INVOICE #</th>
                <th class="text-left">CLIENT</th>
                <th class="text-left">SOLUTION</th>
                <th class="text-left">DATE</th>
                <th class="text-left">AMOUNT</th>
                <th class="text-left">STATUS</th>
              </tr>
            </thead>

            <tbody v-if="invoices.length > 0" class="text-gray tr-custom-height">
              <tr class="text-gray" id="remove-hover" v-for="(item, index) in invoices" :key="index">
                <td v-if="item.type = 'vendor'">
                  <a @click="vendorInvoice(item)">
                    {{ item.invoiceNo }}
                  </a>
                </td>
                <td v-else>
                  <a @click="dpcInvoice(item)">
                    {{ item.invoiceNo }}
                  </a>
                </td>
                <td>{{ item.client }}</td>
                <td>{{ item.solution }}</td>
                <td>{{ item.date | moment('DD/MM/YYYY') }}</td>
                <td>{{ getInvoiceAmount(item) }}</td>
                <td>{{ item.status.charAt(0).toUpperCase() + item.status.slice(1) }}</td>
              </tr>
            </tbody>
            <tbody v-else-if="filterItems">
              <v-col md="12" cols="12">
                <v-alert
                  border="bottom"
                  colored-border
                  color="primary"
                  elevation="2"
                  class="text-gray"
                >
                  Sorry! No results found
                </v-alert>
              </v-col>
            </tbody>
            <tbody v-else>
                 <v-alert border="bottom" colored-border color="primary" elevation="2" class="text-gray mt-2 ml-2">
                  There are no invoices that have been received for any of your clients.
              </v-alert>
              </tbody>
           
          </template>
        </v-simple-table>
      </div>
      <table id="tblData" style="display: none">
        <thead>
          <tr>
            <th class="text-left">INVOICE</th>
            <th class="text-left">CLIENT</th>
            <th class="text-left">SOLUTION</th>
            <th class="text-left">DATE</th>
            <th class="text-left">AMOUNT</th>
            <th class="text-left">STATUS</th>
          </tr>
        </thead>

        <tbody v-if="invoices.length > 0 && !filterItems" class="text-gray tr-custom-height">
          <tr class="text-gray" id="remove-hover" v-for="(item, index) in invoices" :key="index">
            <td>
              {{ item.invoiceNo }}
            </td>
            <td>{{ item.client }}</td>
            <td>{{ item.solution }}</td>
            <td>{{ item.date | moment('DD/MM/YYYY') }}</td>
            <td>{{ item.amount }}</td>
            <td>{{ item.status.charAt(0).toUpperCase() + item.status.slice(1) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <router-view></router-view>
  </div>
</template>
<script>
import Header from "../Layout/HeaderPage.vue";
import api from "../../../../services/api";
export default {
  data() {
    return {
      showDetails: false,
      dialog: false,
      invoices: [],
      filterArray: [],
      singleItem: null,
      filterItems: false,
      load: false,
    };
  },
  components: {
    Header,
  },
  computed: {
    isChildren() {
      let bool = false;
      if (this.$route.meta && this.$route.meta.isChildren) {
        bool = true;
      }
      return bool;
    },
  },
  created() {
    this.getData();
  },
  methods: {
    vendorInvoice(item){
      this.$router.push(`/tpa/invoices/vendor-details/${item.id}`);
    },
    dpcInvoice(){
      this.$router.push('/tpa/invoices/dpc-details')
    },
    getInvoiceAmount(item){
      let amount = 0;
      this.invoices.forEach(function(i){
        amount += i.amount
      })
      return '$ '+item.amount.toFixed(2)
    },
    exportFun() {
      this.exportData("tblData");
    },
    exportData(tableID, filename = "") {
      let elt = document.getElementById(tableID);
      let downloadLink;
      let dataType = "application/vnd.ms-excel";
      let tableSelect = elt;
      let tableHTML = tableSelect.outerHTML.replace(/ /g, "%20");

      // Specify file name
      filename = filename ? filename + ".xls" : "excel_data.xls";

      // Create download link element
      downloadLink = document.createElement("a");

      document.body.appendChild(downloadLink);

      if (navigator.msSaveOrOpenBlob) {
        let blob = new Blob(["\ufeff", tableHTML], {
          type: dataType,
        });
        navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        // Create a link to the file
        downloadLink.href = "data:" + dataType + ", " + tableHTML;

        // Setting the file name
        downloadLink.download = filename;

        //triggering the function
        downloadLink.click();
      }
    },
    getData() {
      this.load = true;
      api().get(`/tpas/invoices`)
        .catch((err) => {
          this.load = false;
        })
        .then((res) => {
          if (res && res.data) {
            this.invoices = res.data;
            this.filterArray = this.invoices;
            this.load = false;
          }
        });
    },
    searchInput(val) {
      this.invoices = this.filterArray.filter((el) => {
       if (
          el.client
            .toLowerCase()
            .includes(val.toLowerCase())
        ) {
          return el.client
            .toLowerCase()
            .includes(val.toLowerCase());
        }  else if (
          el.invoiceNo &&
          el.invoiceNo.toString().toLowerCase().includes(val.toLowerCase())
        ) {
          return el.invoiceNo
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase());
        } else if (
          el.status &&
          el.status.toString().toLowerCase().includes(val.toLowerCase())
        ) {
          return el.status.toLowerCase().includes(val.toLowerCase());
        } else if (
          el.solution &&
          el.solution.toLowerCase().includes(val.toLowerCase())
        ) {
          return el.solution.toLowerCase().includes(val.toLowerCase());
        } else if (
          el.amount &&
          el.amount.toString().toLowerCase().includes(val.toLowerCase())
        ) {
          return el.amount.toString().toLowerCase().includes(val.toLowerCase());
        }
      });
      if (this.invoices.length < 1) {
        this.filterItems = true;
      }
     
    },
  },
};
</script>
<style>
.border-blue-bottom {
  border-bottom: 6px solid #4072b7;
  margin-left: -1px;
  margin-right: -2px;
  margin-top: -1px;
}

.tr-custom-height > .v-data-table__wrapper > table > tbody > tr > td {
  height: 75px;
}
</style>