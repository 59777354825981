import { render, staticRenderFns } from "./BlueSideBar.vue?vue&type=template&id=51f8e51e"
import script from "./BlueSideBar.vue?vue&type=script&lang=js"
export * from "./BlueSideBar.vue?vue&type=script&lang=js"
import style0 from "./BlueSideBar.vue?vue&type=style&index=0&id=51f8e51e&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports