

    const state = {
        provider: null,
        providerName:'',
    }
    
    const getters = {
      getProviderGetter(state){
        return state.provider
      },
      getProviderName(){
        return state.providerName
      }
    }
    
    const mutations = {
        setSingleProvider(state, provider){
            state.provider = provider
        },
        setProviderName(state,name){
           state.providerName = name
        }
    }
    
    const actions = {
    }
    
    export default {
        state,
        getters,
        mutations,
        actions
    }
  