<template>
  <div>
    <div v-if="!load" :class="showOverlay ? ' postion-relative' : ''">
      <div
        v-if="showOverlay"
        class="wizard-absolute"
        style="border-radius: 7px"
      >
        <div class="col-md-12">
          <Dialog
            @closeDialog="closeDialog"
            :plans="plans"
            @getData="getSolutions"
            @refreshData="refreshData"
          ></Dialog>
        </div>
        <br />
        <br />
      </div>
      <div v-if="!isChildren">
        <Header
          :title="'Solutions'"
          :searchInput="true"
          :inviteButton="false"
          :newButton="true"
          @invite="invite"
          @add="add"
          @searchInput="searchInput"
          :placeholder="'Search Solutions'"
          :sort="true"
          :type="true"
          :types="typeItems"
          :sorts="sortItems"
          @filterItemsByType="filterItemsByType"
          @sortItemsByDate="sortItemsByDate"
        ></Header>
        <div class="mt-3">
          <v-simple-table
            fixed-header
            height="900"
            class="no-padding tr-custom-height"
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">SOLUTION / PACKAGE NAME</th>
                  <th class="text-left">CATEGORY</th>
                  <th class="text-left">PLANS FOR SOLUTION</th>
                  <th class="text-left">STATUS</th>
                </tr>
              </thead>
              <tbody
                v-if="items.length > 0 && !filterItems"
                class="text-gray tr-custom-height"
              >
                <tr v-for="(item, index) in items" :key="index">
                  <td v-if="item.solution" @click="showSolutionDetails(item)">
                    <a v-if="item.solution.vendor">{{
                      item.solution.vendor.companyName
                    }}</a>
                  </td>
                  <td v-else><a>/</a></td>
                  <td
                    v-html="
                      item.solution && item.solution.category
                        ? item.solution.category
                        : '/'
                    "
                  ></td>
                  <td>
                    <v-select
                      style="width: 300px !important"
                      solo
                      hide-details
                      multiple
                      item-value="_id"
                      item-text="name"
                      v-model="item.plans"
                      :items="plans"
                      @focusout="changePlans(item)"
                    ></v-select>
                  
                  </td>
                  <td>
                    <span>{{
                      item.status
                        ? item.status.charAt(0).toUpperCase() +
                          item.status.slice(1)
                        : "/"
                    }}</span>
                  </td>
                </tr>
              </tbody>
              <tbody v-else-if="items.length < 1 && filterItems">
                <v-col md="12" cols="12">
                  <v-alert
                    border="bottom"
                    colored-border
                    type="warning"
                    elevation="2"
                    class="text-gray"
                  >
                    Sorry! No results found
                  </v-alert>
                </v-col>
              </tbody>

              <tbody v-else>
                <v-col md="12"> There are no solutions currenlty. </v-col>
              </tbody>
            </template>
          </v-simple-table>
          <br /><br />
        </div>
      </div>
      <successModal
        ref="success_modal"
        v-on:confirm="closeSuccessModal"
      ></successModal>

      <errorModal ref="error_modal" v-on:confirm="closeErrorModal"></errorModal>
    </div>
    <div v-else>
      <div class="col-md-12 row-height d-flex align-center justify-center">
        <v-progress-circular
          class="text-center"
          size="90"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>
<script>
import Header from "../../../Layout/HeaderPage.vue";
import Dialog from "./SolutionsDialog.vue";
import api from "../../../../../../services/api";
import successModal from "../../../../../TablesTemplate/SuccessDialog.vue";
import errorModal from "../../../../../TablesTemplate/ErrorDialog.vue";
export default {
  components: { Header, Dialog, successModal, errorModal },
  data() {
    return {
      showDetails: false,
      tab: "solution",
      dialog: false,
      items: [],
      singleItem: null,
      singleItem: null,
      typeItems: [
        { text: "All Categories" },
        { text: "DPC" },
        { text: "Others" },
      ],
      sortItems: [{ text: "Newest" }, { text: "Oldest" }],
      sortBy: "",
      type: "",
      solutions: [],
      favorites: [],
      filterArray: [],
      showOverlay: false,
      allSolutions: [],
      plans: [],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || "E-mail must be valid",
      ],
      requiredRules: [(v) => !!v || "This Field is required"],
      valid: false,
      companyName: "",
      firstName: "",
      lastName: "",
      email: "",
      filterItems: false,
      load: false,
    };
  },
  created() {
    this.getSolutions();
    this.getPlans();
  },
  computed: {
    isChildren() {
      let bool = false;
      if (this.$route.meta && this.$route.meta.isChildren) {
        bool = true;
      }
      return bool;
    },
  },
  methods: {
    sortItemsByDate(type) {
      if (type == "Oldest") {
        this.items = this.filterArray.sort(
          (a, b) =>
            Number(new Date(a.solution.createdAt)) -
            Number(new Date(b.solution.createdAt))
        );
      } else {
        this.items = this.filterArray.sort(
          (a, b) =>
            Number(new Date(b.solution.createdAt)) -
            Number(new Date(a.solution.createdAt))
        );
      }
    },
    filterItemsByType(type) {
      if (type != "All Categories") {
        this.items = this.filterArray.filter((el) => {
          return el.solution.category == type;
        });
      } else {
        this.items = this.filterArray;
      }
    },
    refreshData() {},
    getPlans() {
      api()
        .get(`/tpas/clients/${this.$route.params.id}/plans`)
        .then((res) => {
          this.plans = res.data;
        });
    },
    add() {
      this.showOverlay = true;
    },
    invite() {},

    closeErrorModal() {
      this.dialog = false;
      this.companyName = "";
      this.firstName = "";
      this.lastName = "";
      this.email = "";
      this.$refs.success_modal.closePopUp();
      this.$refs.error_modal.closePopUp();
    },
    closeSuccessModal() {
      this.dialog = false;
      this.companyName = "";
      this.firstName = "";
      this.lastName = "";
      this.email = "";
      this.$refs.success_modal.closePopUp();
    },
    showSolutionDetails(item) {
      this.singleItem = item;
      this.$store.commit("setSingleSolution", item);
      this.$router.push(
        "/digital-invoicing/clients/solutions/" +
          this.$route.params.id +
          "/details"
      );
    },
    changePlans(item) {
      let data = {
        solutions: [{ id: item.solution._id, plans: item.plans }],
      };
      api()
        .put(`/tpas/clients/${this.$route.params.id}/solutions`, data)
        .catch((err) => {
          if (res) {
            this.$refs.error_modal.openPopUp(res.response.data);
          }
        })
        .then((res) => {
          this.$refs.success_modal.openPopUp("Updated Successfully");
        });
    },
    getSolutions() {
      this.load = true;
      api()
        .get(`/tpas/clients/${this.$route.params.id}/solutions`)
        .catch((err) => {
          this.showOverlay = false;
          this.load = false;
        })
        .then((res) => {
          this.showOverlay = false;
          this.items = res.data;
          this.filterArray = res.data;
          this.load = false;
        });
    },
    fillArray(item, index) {
      if (!item.rating) {
        this.favorites.push(item);
      } else {
        this.favorites.splice(this.favorites.indexOf(item), 1);
      }
    },
    closeDialog() {
      this.showOverlay = false;
    },
    searchInput(val) {
      this.items = this.filterArray.filter((el) => {
        if (
          el.solution &&
          el.solution.vendor &&
          el.solution.vendor.companyName &&
          el.solution.vendor.companyName
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase())
        ) {
          return el.solution.vendor.companyName
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase());
        } else if (
          el.solution.category &&
          el.solution.category
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase())
        ) {
          return el.solution.category
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase());
        }
      });
      if (this.items.length < 1) {
        this.filterItems = true;
      } else {
        this.filterItems = false;
      }
    },
  },
};
</script>
