<template>
  <div class="">
    <Header :title="'Account'"></Header>
    <successModal
      ref="success_modal"
      v-on:confirm="closeSuccessModal"
    ></successModal>
    <div class="col-md-12">
      <v-form v-model.trim="valid" ref="form" class="row">
        <div class="col-md-6 pb-0">
          <div class="col-md-12 pb-0">
            <label class="font text-gray" for="">Company Legal Name</label>
            <v-text-field
              required
              :rules="requiredRules"
              v-model.trim="companyName"
              solo
            ></v-text-field>
          </div>
          <div class="col-md-12 pb-0 pt-0">
            <label class="font text-gray" for="">Company Display Name</label>
            <v-text-field
              required
              :rules="requiredRules"
              v-model.trim="displayName"
              solo
            ></v-text-field>
          </div>

          <div class="col-md-12 pt-0 pb-0">
            <label class="font text-gray" for="">Tax ID / EIN</label>
            <v-text-field
              required
              v-model.trim="taxId"
              @keyup="showSlash($event)"
              solo
              placeholder="00-0000000"
              :rules="taxIdRules"
              counter="10"
              maxlength="10"
            ></v-text-field>
          </div>

          <div class="col-md-12 pt-0 pb-0">
            <label class="font text-gray" for=""
              >Company Logo (Image Size - Width = 100px Height = 50px)</label
            >
            <div class="row mt-1">
              <div class="col-xl-10 col-md-9 col-xs-12 col-sm-12" cols="12">
                <v-file-input
                  @change="Preview_image"
                  v-model.trim="dataComponents.image"
                  required
                  solo
                  truncate-length="15"
                  ref="fileInput"
                ></v-file-input>
              </div>
              <div class="col-xl-2 col-md-3 col-xs-12 col-sm-12 mt-3" cols="12">
                <a @click="activateFileInput">Browse</a>
              </div>
            </div>
          </div>
          <div class="col-md-12 pt-0 pb-0">
            <label class="font text-gray" for=""
              >Profile Picture (Image Size - Width = 100px Height = 50px)</label
            >
            <div class="row mt-1">
              <div class="col-xl-10 col-md-9 col-xs-12 col-sm-12" cols="12">
                <v-file-input
                  @change="Preview_profileImage"
                  v-model.trim="dataComponents.profileImage"
                  required
                  solo
                  truncate-length="15"
                  ref="profileInput"
                ></v-file-input>
              </div>
              <div class="col-xl-2 col-md-3 col-xs-12 col-sm-12 mt-3" cols="12">
                <a @click="activateProfileInput">Browse</a>
              </div>
            </div>
          </div>
          <div class="col-md-12 pt-0 pb-0">
            <label class="font text-gray" for="">HQ Address Line 1</label>
            <v-text-field
              required
              v-model.trim="address1"
              :rules="requiredRules"
              solo
            ></v-text-field>
          </div>
          <div class="col-md-12 pt-0 pb-0">
            <label class="font text-gray" for="">HQ Address Line 2</label>
            <v-text-field required v-model.trim="address2" solo></v-text-field>
          </div>
          <v-row class="no-padding">
            <div class="col-md-6 pt-0 pb-0">
              <label class="font text-gray" for="">City</label>
              <v-text-field
                required
                v-model.trim="city"
                solo
                :rules="requiredRules"
              ></v-text-field>
            </div>
            <div class="col-md-3 pt-0 pb-0">
              <label class="font text-gray" for="">State</label>
              <v-select
                :items="states"
                item-text="name"
                item-value="name"
                required
                v-model.trim="state"
                solo
                :rules="requiredRules"
              ></v-select>
            </div>
            <div class="col-md-3 pt-0 pb-0">
              <label class="font text-gray" for="">Zip</label>
              <v-text-field
                required
                type="number"
                v-model.trim="zip"
                solo
                :rules="zipRules"
              ></v-text-field>
            </div>
          </v-row>
        </div>
        <div class="col-md-6">
          <div class="col-md-12 pb-0">
            <label class="font text-gray" for=""
              >Primary Contact First Name
            </label>
            <v-text-field
              required
              v-model.trim="firstName"
              solo
              :rules="requiredRules"
            ></v-text-field>
          </div>
          <div class="col-md-12 pt-0 pb-0">
            <label class="font text-gray" for=""
              >Primary Contact Last Name</label
            >
            <v-text-field
              required
              v-model.trim="lastName"
              solo
              :rules="requiredRules"
            ></v-text-field>
          </div>
          <div class="col-md-12 pt-0 pb-0">
            <label class="font text-gray" for="">Primary Contact Title</label>
            <v-text-field
              required
              v-model.trim="title"
              solo
              :rules="requiredRules"
            ></v-text-field>
          </div>
          <div class="col-md-12 pt-0 pb-0">
            <label class="font text-gray" for="">Primary Contact Email</label>
            <v-text-field
              required
              :rules="emailRules"
              v-model.trim="email"
              solo
            ></v-text-field>
          </div>
          <div class="col-md-12 pt-0 pb-0">
            <label class="font text-gray" for="">Primary Contact Phone</label>
            <v-text-field
              required
              v-model.trim="phone"
              solo
              :rules="phoneRules"
            ></v-text-field>
          </div>
          <div class="col-md-12 pt-0 pb-0">
            <label class="font text-gray" for="">Description</label>
            <v-textarea
              rows="5"
              required
              v-model.trim="description"
              solo
            ></v-textarea>
          </div>
        </div>
      </v-form>
    </div>
    <div class="text-end justify-end gray-underline-wizard mt-2 col-md-12">
      <div class="col-md-12 mt-4 mb-4">
        <a
          class="text-underline text-gray col-md-12 mr-10"
          @click="$router.push('/solution-vendor/home')"
          >Cancel</a
        >
        <a class="text-underline text-gray col-md-12 mr-5" @click="update"
          >Save</a
        >
      </div>
    </div>
  </div>
</template>
<script>
import api from "../../../../services/api";
import successModal from "../../../TablesTemplate/SuccessDialog.vue";
import Header from "../Layout/HeaderPage.vue";

export default {
  components: { successModal, Header },
  data() {
    return {
      companyName: "",
      taxId: "",
      website: "",
      logo: "",
      phone: "",
      title: "",
      firstName: "",
      lastName: "",
      email: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      zip: "",
      website: "",
      displayName: "",
      companyLogo: {
        name: "",
      },
      phoneRules: [
        (v) =>
          /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(v) ||
          "Phone number must be in a valid format (XXX XXX XXXX)",
      ],
      states: [],
      discountRate: "",
      valid: false,
      zipRules: [
        (v) => (v && v.length == 5) || " Zip Code should be five digits long",
      ],
      requiredRules: [(v) => !!v || "This Field is required"],
      imageEvent: null,
      emailRules: [
        (v) => !!v || "This Field is required",
        (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || "E-mail must be valid",
      ],
      logoChange: false,
      profileChange: false,
      imageProfleEvent: null,
      description: "",
      dataComponents: {
        image: {
          name: "",
        },
        profileImage: {
          name: "",
        },
      },
      taxIdRules: [
        (v) => !!v || "This Field is required",
        (v) =>
          !!/[0-9]{2}\-[0-9]{7}/.test(v) ||
          "Tax ID should contain 9 digits, Please enter your Tax ID in XX-XXXXXXX format",
      ],
    };
  },
  computed: {
    singleEmp() {
      let provider = "";
      if (localStorage.getItem("employer")) {
        provider = JSON.parse(localStorage.getItem("employer"));
      }

      return provider;
    },
  },
  created() {
    this.getStates();
    this.getInfo();
  },
  methods: {
    generatePdf() {
      window.open("https://api.apaly.net/terms");
    },
    showSlash(event) {
      if (this.taxId.length == 2 && event.keyCode != 8) {
        this.taxId += "-";
      }
    },
    activateFileInput() {
      this.$refs.fileInput.$refs.input.click();
    },
    getStates() {
      api()
        .get("/api/states")
        .then((res) => {
          res.data.forEach((el) => {
            if (el.active) {
              this.states.push(el);
            }
          });
        });
    },
    getInfo() {
      api()
        .get(`/vendors`)
        .then((res) => {
          if (res) {
            this.companyName = res.data.companyName ? res.data.companyName : "";
            this.displayName = res.data.displayName ? res.data.displayName : "";
            this.description = res.data.description ? res.data.description : "";
            this.taxId = res.data.taxId ? res.data.taxId : "";
            this.address1 = res.data.address1 ? res.data.address1 : "";
            this.address2 = res.data.address2 ? res.data.address2 : "";
            this.website = res.data.website ? res.data.website : "";
            this.city = res.data.city ? res.data.city : "";
            this.state = res.data.state ? res.data.state : "";
            this.zip = res.data.zipCode ? res.data.zipCode : "";
            this.firstName = res.data.primaryContact.firstName
              ? res.data.primaryContact.firstName
              : "";
            this.lastName = res.data.primaryContact.lastName
              ? res.data.primaryContact.lastName
              : "";
            this.title = res.data.primaryContact.title
              ? res.data.primaryContact.title
              : "";
            this.phone = res.data.primaryContact.phone
              ? res.data.primaryContact.phone
              : "";
            this.email = res.data.primaryContact.email
              ? res.data.primaryContact.email
              : "";

            this.dataComponents.image.name = res.data.logo ? res.data.logo : "";
            this.dataComponents.profileImage.name = res.data.primaryContact
              .profile
              ? res.data.primaryContact.profile
              : "";
          }
        });
    },
    closeSuccessModal() {
      this.$refs.success_modal.closePopUp();
    },

    update() {
      this.$refs.form.validate();

      if (this.valid) {
        let data = {
          companyName: this.companyName,
          providerName: this.companyName,
          displayName: this.displayName,
          address1: this.address1,
          address2: this.address2,
          website: this.website,
          city: this.city,
          state: this.state,
          zipCode: this.zip,
          taxId: this.taxId,
          description: this.description,
          primaryContact: {
            firstName: this.firstName,
            lastName: this.lastName,
            title: this.title,
            phone: this.phone,
            email: this.email,
          },
        };

        let formData = new FormData();
        formData.append("_method", "PUT");
        formData.append("image", this.imageEvent);

        api()
          .put(`/vendors`, data)
          .then((res) => {
            if (res) {
              if (this.profileChange) {
                if (this.imageProfleEvent) {
                  let formData = new FormData();
                  formData.append("_method", "PUT");
                  formData.append("image", this.imageProfleEvent);
                  api()
                    .put(`/vendors/profile`, formData)
                    .then((res) => {
                      document.getElementById("profile-img").click();
                    });
                } else {
                  api()
                    .delete(`/vendors/profile`)
                    .then((res) => {
                      document.getElementById("profile-img").click();
                    });
                }
              }
              if (this.logoChange) {
                if (this.imageEvent) {
                  let formData = new FormData();
                  formData.append("_method", "PUT");
                  formData.append("image", this.imageEvent);
                  api()
                    .put(`/vendors/logo`, formData)
                    .then((res) => {
                      document.getElementById("logo-img").click();
                    });
                } else {
                  api()
                    .delete(`/vendors/logo`)
                    .then((res) => {
                      document.getElementById("logo-img").click();
                    });
                }
              }

              this.$refs.success_modal.openPopUp(
                "Account updated successfully"
              );
              this.logoChange = false;
              this.profileChange = false;
            }
          });
      }
    },
    activateProfileInput() {
      this.$refs.profileInput.$refs.input.click();
    },
    Preview_image(event) {
      this.logoChange = true;
      this.imageEvent = event;
    },
    Preview_profileImage(event) {
      this.profileChange = true;
      this.imageProfleEvent = event;
    },
  },
};
</script>