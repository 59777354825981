import { render, staticRenderFns } from "./ProvidersTemplate.vue?vue&type=template&id=65c42b8c"
import script from "./ProvidersTemplate.vue?vue&type=script&lang=js"
export * from "./ProvidersTemplate.vue?vue&type=script&lang=js"
import style0 from "./ProvidersTemplate.vue?vue&type=style&index=0&id=65c42b8c&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports