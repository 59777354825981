<template>
  <div class="">
    <Header :title="'General Information'"></Header>
    <div class="col-md-12">
      <div class="col-md-12 pb-10">
        <v-form ref="form" v-model="valid" class="row gray-border-form">
          <div class="col-md-6">
            <div class="col-md-10">
              <div class="col-md-12 pb-0">
                <label for="">Company Name</label>
                <v-text-field
                  required
                  :rules="requiredRules"
                  v-model.trim="companyName"
                  solo
                ></v-text-field>
              </div>
              <div class="col-md-12 pb-0 pt-0">
                <label for="">Tax ID</label>
                <v-text-field
                  required
                  v-model.trim="taxId"
                  @keyup="showSlash($event)"
                  solo
                  placeholder="00-0000000"
                  :rules="taxIdRules"
                  counter="10"
                  maxlength="10"
                ></v-text-field>
              </div>
              <div class="col-md-12 pb-0 pt-0">
                <label for="">HQ Address Line 1</label>
                <v-text-field
                  required
                  v-model.trim="address1"
                  solo
                ></v-text-field>
              </div>
              <div class="col-md-12 pb-0 pt-0">
                <label for="">HQ Address Line 2</label>
                <v-text-field
                  required
                  v-model.trim="address2"
                  solo
                ></v-text-field>
              </div>
              <v-row class="no-padding">
                <div class="col-md-6 pt-0 pb-0">
                  <label class="font text-gray" for="">City</label>
                  <v-text-field
                    required
                    v-model.trim="city"
                    solo
                    :rules="requiredRules"
                  ></v-text-field>
                </div>
                <div class="col-md-3 pt-0 pb-0">
                  <label class="font text-gray" for="">State</label>
                  <v-select
                    :items="states"
                    item-text="name"
                    item-value="name"
                    required
                    v-model.trim="state"
                    solo
                    :rules="requiredRules"
                  ></v-select>
                </div>
                <div class="col-md-3 pt-0 pb-0 zip-field-height">
                  <label class="font text-gray" for="">Zip</label>
                  <v-text-field
                    required
                    type="number"
                    v-model.trim="zip"
                    solo
                    :rules="zipRules"
                  ></v-text-field>
                </div>
              </v-row>
              <div class="col-md-12 pb-0 pt-0">
                <label for=""
                  >Payer ID
                  <i>(Only applies to TPA / Carrier Accounts)</i></label
                >
                <v-text-field
                  solo
                  v-model="payerId"
                  :rules="requiredRules"
                ></v-text-field>
              </div>
            </div>
          </div>
          <div class="col-md-6 d-flex justify-content-end">
            <div class="col-md-10 mb-6">
              <div class="col-md-12 pb-0">
                <label class="font text-gray" for=""
                  >Primary Contact First Name
                </label>
                <v-text-field
                  required
                  v-model.trim="firstName"
                  solo
                  :rules="requiredRules"
                ></v-text-field>
              </div>
              <div class="col-md-12 pt-0 pb-0">
                <label class="font text-gray" for=""
                  >Primary Contact Last Name</label
                >
                <v-text-field
                  required
                  v-model.trim="lastName"
                  solo
                  :rules="requiredRules"
                ></v-text-field>
              </div>
              <div class="col-md-12 pt-0 pb-0">
                <label class="font text-gray" for=""
                  >Primary Contact Title</label
                >
                <v-text-field
                  required
                  v-model.trim="title"
                  solo
                  :rules="requiredRules"
                ></v-text-field>
              </div>
              <div class="col-md-12 pt-0 pb-0">
                <label class="font text-gray" for=""
                  >Primary Contact Email</label
                >
                <v-text-field
                  required
                  :rules="emailRules"
                  v-model.trim="email"
                  solo
                ></v-text-field>
              </div>
              <div class="col-md-12 pt-0 pb-0">
                <label class="font text-gray" for=""
                  >Primary Contact Phone</label
                >
                <v-text-field
                  required
                  v-model.trim="phone"
                  solo
                  :rules="phoneRules"
                ></v-text-field>
              </div>
              <div class="row col-md-12 no-padding">
                <div class="mt-5 text-end">
                  <a
                    class="
                      text-underline
                      light-blue-text
                      font-weight-bold font-18
                    "
                    >View & Print Apaly Terms of Use Agreement</a
                  >
                </div>
              </div>
            </div>
          </div>
        </v-form>
      </div>
    </div>

    <div class="">
      <div class="col-md-12" align="end">
        <span
          @click="$router.push('/digital-invoicing/home')"
          class="light-blue-text text-underline mr-10 font-weight-bold pointer"
          >Cancel</span
        >
        <span
          @click="update"
          class="light-blue-text text-underline mr-10 font-weight-bold pointer"
          >Save</span
        >
      </div>
    </div>
    <br /><br />
    <successModal
      ref="success_modal"
      v-on:confirm="closeSuccessModal"
    ></successModal>
  </div>
</template>
<script>
import Header from "../Layout/HeaderPage.vue";
import api from "../../../../services/api";
import successModal from "../../../TablesTemplate/SuccessDialog.vue";

export default {
  data() {
    return {
      taxIdRules: [
        (v) => !!v || "This Field is required",
        (v) =>
          !!/[0-9]{2}\-[0-9]{7}/.test(v) ||
          "Tax ID should contain 9 digits, Please enter your Tax ID in XX-XXXXXXX format",
      ],
      zipRules: [
        (v) => (v && v.length == 5) || " Zip Code should be five digits long",
      ],
      states: [],
      phoneRules: [
        (v) =>
          /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(v) ||
          "Phone number must be in a valid format (XXX XXX XXXX)",
      ],
      requiredRules: [(v) => !!v || "This Field is required"],

      emailRules: [
        (v) => !!v || "This Field is required",
        (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || "E-mail must be valid",
      ],
      companyName: "",
      taxId: "",
      website: "",
      logo: "",
      phone: "",
      title: "",
      firstName: "",
      lastName: "",
      email: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      zip: "",
      payerId: "",
      valid:'',
    };
  },
  components: {
    Header,
    successModal,
  },
  computed: {},
  created() {
    this.getStates();
    this.getInfo();
  },
  methods: {
    showSlash(event) {
      if (this.taxId.length == 2 && event.keyCode != 8) {
        this.taxId += "-";
      }
      if (this.taxId.length == 10) {
        api()
          .get("auth/isValidTaxId?taxId=" + this.taxId)
          .catch((res) => {
            if (res) {
              this.taxIdRules = [res.response.data.message];
            }
          });
      } else {
        this.taxIdRules = [
          (v) => !!v || "This Field is required",
          (v) =>
            !!/[0-9]{2}\-[0-9]{7}/.test(v) ||
            "Tax ID should contain 9 digits, Please enter your Tax ID in XX-XXXXXXX format",
        ];
      }
    },
    getStates() {
      api()
        .get("/api/states")
        .then((res) => {
          res.data.forEach((el) => {
            if (el.active) {
              this.states.push(el);
            }
          });
        });
    },
    getInfo() {
      api()
        .get(`/tpas`)
        .then((res) => {
          if (res) {
            this.companyName = res.data.companyName ? res.data.companyName : "";
            this.displayName = res.data.displayName ? res.data.displayName : "";

            this.taxId = res.data.taxId ? res.data.taxId : "";
            this.address1 = res.data.address1 ? res.data.address1 : "";
            this.address2 = res.data.address2 ? res.data.address2 : "";
            this.website = res.data.website ? res.data.website : "";
            this.city = res.data.city ? res.data.city : "";
            this.state = res.data.state ? res.data.state : "";
            this.zip = res.data.zipCode ? res.data.zipCode : "";
            this.firstName = res.data.primaryContact.firstName
              ? res.data.primaryContact.firstName
              : "";
              this.payerId = res.data.payerId ? res.data.payerId : "";
            this.lastName = res.data.primaryContact.lastName
              ? res.data.primaryContact.lastName
              : "";
            this.title = res.data.primaryContact.title
              ? res.data.primaryContact.title
              : "";
            this.phone = res.data.primaryContact.phone
              ? res.data.primaryContact.phone
              : "";
            this.email = res.data.primaryContact.email
              ? res.data.primaryContact.email
              : "";
          }
        });
    },
    closeSuccessModal() {
      this.$refs.success_modal.closePopUp();
    },

    update() {
      this.$refs.form.validate();

      if (this.valid) {
        let data = {
          companyName: this.companyName,
          address1: this.address1,
          address2: this.address2,
          city: this.city,
          state: this.state,
          zipCode: this.zip,
          payerId: this.payerId,
          taxId: this.taxId,
          primaryContact: {
            firstName: this.firstName,
            lastName: this.lastName,
            title: this.title,
            phone: this.phone,
            email: this.email,
          },
        };

        api()
          .put(`/tpas`, data)
          .then((res) => {
            this.$refs.success_modal.openPopUp("Account updated successfully");
          });
      }
    },
  },
};
</script>
