import { render, staticRenderFns } from "./NewSolutions.vue?vue&type=template&id=55fb2c00&scoped=true"
import script from "./NewSolutions.vue?vue&type=script&lang=js"
export * from "./NewSolutions.vue?vue&type=script&lang=js"
import style0 from "./NewSolutions.vue?vue&type=style&index=0&id=55fb2c00&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55fb2c00",
  null
  
)

export default component.exports