import { render, staticRenderFns } from "./InvitePartner.vue?vue&type=template&id=7a201e5d"
import script from "./InvitePartner.vue?vue&type=script&lang=js"
export * from "./InvitePartner.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports