<template>
  <v-row class="add-client-form no-padding">
    <div class="col-md-12">
      <successModal
        ref="success_modal"
        v-on:confirm="closeSuccessModal"
      ></successModal>
      <errorModal ref="error_modal" v-on:confirm="closeErrorModal"></errorModal>
      <div class="row">
        <div class="col-md-12">
          <h3 class="form-title font col-md-12 no-padding">Add Provider</h3>
        </div>
      </div>
      <v-form v-model.trim="valid" ref="form" class="row mt-0 pt-0">
        <div class="col-md-6">
          <div class="col-md-12 pb-0">
            <label class="font text-gray" for="">Organization Name</label>
            <v-text-field
              required
              :rules="requiredRules"
              v-model.trim="companyName"
              solo
            ></v-text-field>
          </div>
          <div class="col-md-12 pb-0 pt-0">
            <label class="font text-gray" for="">Company Display Name</label>
            <v-text-field
              required
              :rules="requiredRules"
              v-model.trim="displayName"
              solo
            ></v-text-field>
          </div>
          <div class="col-md-12 pb-0 pt-0">
            <label class="font text-gray" for="">Tax ID</label>
            <v-text-field
              required
              @keyup="showSlash($event)"
              v-model.trim="taxId"
              placeholder="00-0000000"
              :rules="taxIdRules"
              counter="10"
              solo
              maxlength="10"
            ></v-text-field>
          </div>

          <div class="col-md-12 pb-0 pt-0">
            <label class="font text-gray" for=""
              >Company Logo (Image Size - Width = 100px Height = 50px)</label
            >
            <div class="row">
              <div class="col-xl-10 col-md-9 col-xs-12 col-sm-12" cols="12">
                <v-file-input
                  @change="Preview_image"
                  v-model.trim="companyLogo"
                  required
                  solo
                  truncate-length="15"
                  ref="fileInput"
                ></v-file-input>
              </div>
              <div class="col-xl-2 col-md-3 col-xs-12 col-sm-12 mb-3" cols="12">
                <v-btn
                  class="blue-buttons col-sm-12"
                  @click="activateFileInput"
                  style="padding: 23px"
                >
                  Browse
                </v-btn>
              </div>
            </div>
          </div>
          <!-- <div class="col-md-12">
               <label class="font text-gray" for="">Provider Type</label>
                <v-select
                    required
                    v-model.trim="providerType"
                    :rules="requiredRules"
                    solo
                    :items="providerTypeItems"
                ></v-select>
           </div> -->
          <div class="col-md-12 pb-0 pt-0">
            <label class="font text-gray" for="">HQ Address Line 1</label>
            <v-text-field
              required
              v-model.trim="address1"
              :rules="requiredRules"
              solo
            ></v-text-field>
          </div>
          <div class="col-md-12 pb-0 pt-0">
            <label class="font text-gray" for="">HQ Address Line 2</label>
            <v-text-field required v-model.trim="address2" solo></v-text-field>
          </div>
          <div class="col-md-12 pb-0 pt-0">
            <label class="font text-gray" for="">Taxonomy</label>
            <v-text-field required v-model.trim="taxonomy" solo></v-text-field>
          </div>
          <v-row class="no-padding">
            <div class="col-md-6 pb-0 pt-0">
              <label class="font text-gray" for="">City</label>
              <v-text-field
                required
                v-model.trim="city"
                solo
                :rules="requiredRules"
              ></v-text-field>
            </div>
            <div class="col-md-3 pb-0 pt-0">
              <label class="font text-gray" for="">State</label>
              <v-select
                :items="states"
                item-text="name"
                item-value="name"
                required
                v-model.trim="state"
                solo
                :rules="requiredRules"
              ></v-select>
            </div>
            <div class="col-md-3 zip-field-heigh pb-0 pt-0">
              <label class="font text-gray" for="">Zip</label>
              <v-text-field
                required
                type="number"
                v-model.trim="zip"
                solo
                :rules="zipRules"
              ></v-text-field>
            </div>

            <v-form class="col-md-12" v-model.trim="valid1" ref="form1">
              <label class="text-gray font" for=""
                >Organizations Email Domain White List , Enter and click
                Add</label
              >
              <div class="row mt-1 col-md-11">
                <v-text-field
                  @keyup="fillDomainRules"
                  :rules="newEmail ? domainRules : []"
                  v-model.trim="newEmail"
                  solo
                ></v-text-field>
                <v-btn large class="ml-3 mt-1 blue-buttons" @click="pushToArray"
                  >Add</v-btn
                >
              </div>
            </v-form>
            <div
              class="col-md-12 mb-10"
              :class="emails.length > 8 ? 'y-scroll' : ''"
              style="overflow-x: hidden"
            >
              <label for="" class="text-gray font"
                >Email domain white list:</label
              >
              <div class="col-md-12 no-padding">
                <v-card
                  v-for="(email, index) in emails"
                  :key="index"
                  style="padding: 1rem"
                  class="mt-2 mb-2"
                >
                  <span v-if="email.domain">{{ email.domain }}</span>
                  <span v-else>{{ email }}</span>
                  <span class="float-right">
                    <svg
                      @click="removeFromArray(email)"
                      class="pointer"
                      width="28"
                      height="26"
                      viewBox="0 0 28 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.35547 13C1.35547 16.1826 2.65524 19.2348 4.96884 21.4853C7.28244 23.7357 10.4204 25 13.6923 25C16.9642 25 20.1021 23.7357 22.4157 21.4853C24.7293 19.2348 26.0291 16.1826 26.0291 13C26.0291 9.8174 24.7293 6.76516 22.4157 4.51472C20.1021 2.26428 16.9642 1 13.6923 1C10.4204 1 7.28244 2.26428 4.96884 4.51472C2.65524 6.76516 1.35547 9.8174 1.35547 13V13Z"
                        stroke="#D00000"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M8.75781 17.7992L18.6273 8.19922"
                        stroke="#D00000"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M18.6273 17.7992L8.75781 8.19922"
                        stroke="#D00000"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                </v-card>
              </div>
            </div>
          </v-row>
          <!-- <div class="col-md-12">
               <label class="font text-gray" for="">Organizations Email Domain White List (separate domains with semi-colon)</label>
                <v-text-field
                    required
                    v-model.trim="emails"
                    solo
                   :rules="requiredRules"
                ></v-text-field>
                </div> -->
        </div>
        <div class="col-md-6">
          <div class="col-md-12 pb-0">
            <label class="font text-gray" for=""
              >Primary Contact First Name
            </label>
            <v-text-field
              required
              v-model.trim="contactFirstName"
              solo
              :rules="requiredRules"
            ></v-text-field>
          </div>
          <div class="col-md-12 pb-0 pt-0">
            <label class="font text-gray" for=""
              >Primary Contact Last Name</label
            >
            <v-text-field
              required
              v-model.trim="contactLastName"
              solo
              :rules="requiredRules"
            ></v-text-field>
          </div>
          <div class="col-md-12 pb-0 pt-0">
            <label class="font text-gray" for="">Primary Contact Title</label>
            <v-text-field
              required
              v-model.trim="contactTitle"
              solo
              :rules="requiredRules"
            ></v-text-field>
          </div>
          <div class="col-md-12 pb-0 pt-0">
            <label class="font text-gray" for="">Primary Contact Email</label>
            <v-text-field
              required
              :rules="emailRules"
              v-model.trim="contactEmail"
              solo
            ></v-text-field>
          </div>
          <div class="col-md-12 pb-0 pt-0">
            <label class="font text-gray" for="">Primary Contact Phone</label>
            <v-text-field
              required
              v-model.trim="contactPhone"
              solo
              :rules="phoneRules"
            ></v-text-field>
          </div>
        </div>
        <div class="col-md-12 d-flex align-end flex-column">
          <v-btn class="blue-buttons" :disabled="loadData" @click="addProvider">
            Add Provider
          </v-btn>
        </div>
      </v-form>
    </div>
  </v-row>
</template>
<script>
import api from "../../../../services/api";
import errorModal from "../../../TablesTemplate/ErrorDialog.vue";
import successModal from "../../../TablesTemplate/SuccessDialog.vue";
export default {
  components: { successModal, errorModal },
  data() {
    return {
      companyName: "",
      taxId: "",
      contactFirstName: "",
      providerNpi: "",
      logo: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      zip: "",
      taxonomy: "",
      displayName: "",
      companyLogo: {
        name: "",
      },
      contactLastName: "",
      contactTitle: "",
      contactEmail: "",
      states: [],
      contactPhone: "",
      valid: false,
      loadData: false,
      valid1: false,
      providerType: "",
      providerTypeItems: [
        {
          text: "Group",
          value: "group",
        },
        {
          text: "Hospital",
          value: "hospital",
        },
        {
          text: "Affiliate",
          value: "affiliate",
        },
      ],
      email: "",
      newEmail: "",
      emails: [],
      emailsForDelete: [],
      domains: [],
      array: [],
      myDeleteArray: [],
      myAddArray: [],
      domainRules: [
        (v) => !!v || "This Field is required",
        (v) =>
          /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/.test(v) ||
          "Domain must be valid",
      ],
      valid: false,
      taxIdRules: [
        (v) => !!v || "This Field is required",
        (v) =>
          !!/[0-9]{2}\-[0-9]{7}/.test(v) ||
          "Tax ID should contain 9 digits, Please enter your Tax ID in XX-XXXXXXX format",
      ],
      requiredRules: [(v) => !!v || "This Field is required"],
      phoneRules: [
        (v) =>
          /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(v) ||
          "Phone number must be in a valid format (XXX XXX XXXX)",
      ],
      imageEvent: null,
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || "E-mail must be valid",
      ],
      zipRules: [
        (v) => !!v || "This field is required",
        (v) => (v && v.length == 5) || " Zip Code should be five digits long",
      ],
    };
  },
  computed: {
    singleEmp() {
      let provider = "";
      if (localStorage.getItem("employer")) {
        provider = JSON.parse(localStorage.getItem("employer"));
      }

      return provider;
    },
  },
  created() {
    this.getStates();
    this.getInfo();
  },
  methods: {
    fillDomainRules() {
      this.domainRules = [
        (v) => !!v || "This Field is required",
        (v) =>
          /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/.test(v) ||
          "Email domain is not valid!",
      ];
    },
    showSlash(event) {
      if (this.taxId.length == 2 && event.keyCode != 8) {
        this.taxId += "-";
      }
      if (this.taxId.length == 10) {
        api()
          .get("auth/isValidTaxId?taxId=" + this.taxId)
          .catch((res) => {
            if (res) {
              this.taxIdRules = [res.response.data.message];
            }
          });
      } else {
        this.taxIdRules = [
          (v) => !!v || "This Field is required",
          (v) =>
            !!/[0-9]{2}\-[0-9]{7}/.test(v) ||
            "Tax ID should contain 9 digits, Please enter your Tax ID in XX-XXXXXXX format",
        ];
      }
    },
    activateFileInput() {
      this.$refs.fileInput.$refs.input.click();
    },
    getInfo() {
      this.$store.commit("setEmailWhiteList", this.emails);
    },

    pushToArray() {
      this.$refs.form1.validate();
      if (this.valid1) {
        let data = {
          domain: this.newEmail,
        };
        api()
          .post("/auth/validateEmail", data)
          .catch((err) => {
            if (err) {
              this.domainRules = [err.response.data.message];
            }
          })
          .then((res) => {
            if (res) {
              api()
                .get("/auth/isValidDomain?domain=" + this.newEmail)
                .catch((err) => {
                  if (err) {
                    this.domainRules = [err.response.data.message];
                    exist = true;
                  }
                })
                .then((res) => {
                  if (res) {
                    let regex =
                      /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/;
                    let check = regex.test(this.newEmail);
                    if (this.newEmail && check) {
                      this.emails.push({ domain: this.newEmail });
                      this.myAddArray.push({ domain: this.newEmail });
                      this.newEmail = "";
                    }
                  }
                });
            }
          });
      }
    },
    removeFromArray(email) {
      this.myDeleteArray.push(email);
      for (let i = 0; i < this.emails.length; i++) {
        if (this.emails[i] == email) {
          this.emails.splice(i, 1);
        }
      }
    },
    getStates() {
      api()
        .get("/api/states")
        .then((res) => {
          res.data.forEach((el) => {
            if (el.active) {
              this.states.push(el);
            }
          });
        });
    },
    closeSuccessModal() {
      this.$refs.success_modal.closePopUp();
      this.$router.push("/provider-advisor/providers");
    },
    closeErrorModal() {
      this.$refs.error_modal.closePopUp();
    },
    addProvider() {
      this.$refs.form.validate();
      if (this.valid) {
        let email = {
          email: this.contactEmail,
        };
        api()
          .post(`/auth/emailExists`, email)
          .catch((err) => {
            if (err) {
              this.$refs.error_modal.openPopUp(err.response.data.message);
            }
          })
          .then((res) => {
            this.loadData = true;
            let newAddArr = [];
            if (this.emails.length > 0) {
              this.emails.forEach((el) => {
                if (el.domain) {
                  newAddArr.push(el.domain);
                } else {
                  newAddArr.push(el);
                }
              });
            }
            if (res) {
              let data = {
                companyName: this.companyName,
                displayName: this.displayName,
                taxId: this.taxId,
                address1: this.address1,
                address2: this.address2,
                city: this.city,
                state: this.state,
                zipCode: this.zip,
                firstName: this.contactFirstName,
                lastName: this.contactLastName,
                email: this.contactEmail,
                phone: this.contactPhone,
                title: this.contactTitle,
                companyLogo: this.imageEvent,
                taxonomy: this.taxonomy,
                domainWhiteList: newAddArr,
              };
              let user = JSON.parse(localStorage.getItem("user"));
              let advisorId = user.groupPortal[0].organization.providerAdvisor;
              api()
                .post(`/advisors/${advisorId}/providers`, data)
                .catch((err) => {
                  if (err) {
                    this.$refs.error_modal.openPopUp(err.response.data.error);
                  }
                })
                .then((res) => {
                  if (res) {
                    let text = "Provider added successfully";
                    this.$refs.success_modal.openPopUp(text);
                  }
                });
            }
          });
      }
    },
    Preview_image(event) {
      this.url = URL.createObjectURL(this.companyLogo);
      this.imageEvent = event;
    },
  },
};
</script>