<template>
  <div class="col-md-12 text-center justify-center" v-if="readyToShow">
    <v-progress-circular
      indeterminate
      :size="100"
      color="primary"
      class="text-center"
    ></v-progress-circular>
  </div>
  <div v-else>
    <div class="organization-pending-info" v-show="!activeEntity">
      <img class="org-pending-icon" src="/svg/organizationPending.svg" />
      <span class="org-pending-title">The provider platform is under construction</span>
      <span class="org-pending-info">
        Thank you for registering with Apaly. We'll notify you as soon as our platform is live. 
        <br> If you have any questions please contact support@apaly.com
      </span>
    </div>
    <div v-if="activeEntity" class="row">
        <div
            v-for="item in items"
            :key="item.title"
            class="text-center justify-center align-center col-md-4 col-lg-3 col-xl-3"
            @click="clientsTable"
        >
        <v-card
            style="height: 13rem; cursor: pointer"
            @click="item.url ? $router.push(item.url) : ''"
        >
            <img
            :src="item.img"
            height="67"
            width="75"
            class="client-home-img-cards mt-xl-6"
            />
            <h3 class="text-center justify-center blue-text font">
            {{ item.title }}
            </h3>
            <v-card-text>
            <v-row align="center" class="mx-md-1 mx-lg-6 justify-center">
                <h4
                class="
                    text-center
                    card-desc-responsive
                    font-quicksand-medium-18
                    mt-4
                "
                style="font-weight: normal; line-height: 16px"
                >
                {{ item.desc }}
                </h4>
            </v-row>
            </v-card-text>
        </v-card>
        </div>
        <div
        class="text-center justify-center align-center col-md-8 col-lg-6 col-xl-6"
        >
        <v-card
            class="space-around-content"
            style="height: 13rem; cursor: pointer"
        >
            <v-row align="center" class="mx-0 justify-center">
            <h5 class="text-center text-underline mt-5 font-quicksand-medium-18">
                Easy Setup
            </h5>
            </v-row>
            <v-row class="text-center justify-center">
            <img
                src="/provider.png"
                id="setup-img-emp"
                width="800"
                height="110"
                alt=""
            />
            </v-row>
            <v-row align="center" class="mx-16 justify-center mb-2">
            <h5
                class="
                red-desc
                card-desc-responsive
                text-center
                font-quicksand-medium-18
                mb-2
                "
            >
                Instantly see those employers who are requesting to contract with
                you, and then contract to capture their business and sieze market
                share.
            </h5>
            </v-row>
        </v-card>
        </div>

        <div
        v-for="item in precentageCards"
        :key="item.title"
        class="col-md-4 col-lg-3 col-xl-3 text-center justify-center aling-center"
        >
        <v-card
            style="height: 13rem; cursor: pointer"
            v-if="statistics"
            class="space-around-content"
        >
            <v-row align="center" class="mx-0 justify-center">
            <h5
                class="text-center text-underline font-quicksand-medium-18"
                :class="
                item.title != 'Providers' &&
                item.title != 'Current Contract Rates'
                    ? 'mt-2'
                    : ''
                "
            >
                {{ item.title }}
            </h5>
            </v-row>
            <v-card-title
            class="text-center justify-center text-gray font percentage-num"
            >
            <div
                v-if="item.title == 'Employers Interested in Contracting'"
                class="row justify-space-around"
            >
                <h1 class="blue-text mr-4">
                {{ statistics.noEmployersInterested }} <br />
                </h1>
            </div>
            <div
                v-else-if="item.title == 'Current Contract Rates'"
                class="row justify-space-around blue-text"
            >
                <div class="font-weight-bold">
                <h1 class="blue-text mb-3">
                    <span v-if="statistics.rates && statistics.rates.outpatient">{{
                    statistics.rates.outpatient
                    }}</span>
                    <span v-else>0</span>% <br />
                </h1>
                Outpatient
                </div>
                <div class="ml-6 font-weight-bold">
                <h1 class="blue-text mb-3">
                    <span v-if="statistics.rates && statistics.rates.inpatient">{{
                    statistics.rates.inpatient
                    }}</span>
                    <span v-else>0</span>% <br />
                </h1>
                Inpatient
                </div>
            </div>
            <div v-else-if="item.title == 'Providers'" class="blue-text">
                <h1>
                {{ statistics.noProviders }}
                </h1>
            </div>
            <div v-else-if="item.title == 'Projected Savings'" class="blue-text">
                <h1>{{ statistics.projectedSavings }}%</h1>
            </div>
            <div v-else-if="item.title == 'Provider Coverage'" class="blue-text">
                <h1>{{ statistics.providerCoverage }}%</h1>
            </div>

            <div
                v-else-if="item.title == 'Members within Coverage Area'"
                class="blue-text"
            >
                <h1>
                {{ statistics.memberCoverage }}
                </h1>
            </div>
            </v-card-title>
            <v-row align="center" class="mx-12 justify-center">
            <h5
                class="blue-text text-center font-quicksand-medium-18 pb-4"
                style="line-height: 20px"
                v-if="item.desc"
            >
                {{ item.desc }}
            </h5>
            </v-row>
        </v-card>
        </div>
    </div>
   
  </div>
</template>
<script>
import api from "../.../../../../../services/api";
export default {
  data() {
    return {
        activeEntity: true,
      items: [
        {
          url: "/provider/contracts",
          img: "/cards/woman.png",
          title: "Employer Contract Requests",
          desc: "See the employers who are requesting to contract with you.",
        },
      ],
      precentageCards: [
        {
          title: "Employers Interested in Contracting",
          percentage: "500",
          desc: "number of self-funded employers who have added you to their provider list, and are requesting to contract",
        },

        {
          title: "Providers",
          percentage: "459",
          desc: "total number of providers that are currently entered into your Apaly Health account",
        },
        {
          title: "Current Contract Rates",
          percentage: "150%",
          percentage1: "180%",
          desc: "of Medicare benchmark rates",
          inpatient: true,
        },
      ],
      statistics: {},
      dialogm1: "",
      dialog: false,
      dialog1: false,
      readyToShow: false,
    };
  },
  methods: {
    getEntityStatus() {
        const user = JSON.parse(localStorage.getItem('user'));

        if (user.entity && user.entity.status !== "active") {
            this.activeEntity = false;
        }
    },
    clientsTable() {
      this.dialog = !this.dialog;
      this.dialog1 = !this.dialog1;
      this.$emit("showClient");
    },
    getStatistics() {
      this.readyToShow = true;
      let user = JSON.parse(localStorage.getItem("user"));
      let provider = user.entity._id;
      api()
        .get(`/providers/${provider}/statistics`)
        .then((res) => {
          this.statistics = res.data.statistics;
          this.readyToShow = false;
        })
        .catch((err) => {
          this.readyToShow = false;
        });
    },
  },
  created() {
    this.getStatistics();
    this.getEntityStatus()
  },
};
</script>

<style scoped>
.v-stepper--alt-labels .v-stepper__step {
  flex-basis: 0px !important;
}

.organization-pending-info {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.org-pending-icon {
  width: 4vw;
  margin-top: 3.5vh;
  margin-bottom: 2.2vh;
}

.org-pending-title {
  font-size: 4.5vh;
  font-weight: bold;
  margin-bottom: 0.5vh;
}

.org-pending-info {
  font-size: 2vh;
  font-weight: bold;
  color: var(--inactive-color);
  line-height: 2.5vh;
  text-align: center;
}
</style>

