<template>
  <v-row class="add-client-form no-padding pa-2">
    <div v-if="provider" class="col-md-12 form-box">
      <removeDialog ref="dialog_delete" v-on:confirm="confirmRemove"></removeDialog>
      <successModal ref="success_modal" v-on:confirm="updateModal"></successModal>
      <div class="form-row-height">
        <div class="row blue-underline">
          <div class="col-md-12">
            <h3 class="form-title col-md-12 pb-1 pt-2 font-18">Provider Detail</h3>
          </div>
        </div>
        <v-form class="row" ref="form" v-model.trim="valid">
          <div class="col-md-12 pb-0">
            <h4 class="form-title col-md-12 pb-0 text-underline">Provider Information</h4>
          </div>
          <div class="col-md-6">
            <div class="col-md-12 pb-0">
              <label class="font text-gray" for="">NPI</label>
              <v-text-field disabled required v-model.trim="provider.NPI" solo></v-text-field>
            </div>
            <div class="col-md-12 pb-0 pt-0">
              <label class="font text-gray" for="">First Name</label>
              <v-text-field required v-model.trim="provider.firstName" solo></v-text-field>
            </div>
            <div class="col-md-12 pb-0 pt-0">
              <label class="font text-gray" for="">Last Name </label>
              <v-text-field required v-model.trim="provider.lastName" solo></v-text-field>
            </div>
            <div class="col-md-12 pb-0 pt-0">
              <label class="font text-gray" for="">Gender</label>
              <v-select required :items="genders" v-model.trim="provider.gender" item-text="text" item-value="value"
                solo></v-select>
            </div>
            <div class="col-md-12 pb-0 pt-0">
              <label class="font text-gray" for="">Specialty</label>
              <v-text-field solo v-model.trim="provider.specialty"></v-text-field>
            </div>
            <div class="row no-padding">
              <div class="col-md-8 font text-gray">
                <label for="" class="small-text text-center ml-4">Provider Relationship Status</label>
                <div class="d-flex mt-2">
                  Inactive
                  <v-switch solo v-model="provider.status" true-value="active" false-value="inactive"
                    class="no-padding ml-2 mr-2 vendors-switch"></v-switch>
                  Active
                </div>
              </div>
              <div class="col-md-4 mt-5 text-end">
                <a class="text-underline" @click="removeProvider">Remove Provider</a>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="col-md-12 pb-0 row mt-4">
              <label class="font text-gray mt-3 mr-4" for="">Locations: </label>
              <v-select required :items="allLocations" multiple v-model.trim="provider.locations" item-text="name"
                item-value="id" solo persistent-hint hint="(hold ctrl key to select multiple)"></v-select>
            </div>
            <div class="col-md-12 row d-flex">
              <label class="font text-gray mt-3 mr-4" for="">Picture: </label>
              <v-file-input id="logo-img" v-model.trim="provider.logo" required solo truncate-length="38" ref="fileInput"
                @change="changeLogo()"></v-file-input>
              <div class="col-md-2 mt-0 pb-0">
                <a @click="updateLogo()" class="text-underline">Upload</a>
              </div>
            </div>
            <div class="col-md-12 row justify-center">
              <v-card width="fit-content">
                <img :src="provider.logo ? provider.logo : '/tpa/clinicians.svg'" ref="imagePreview" class="mt-0 mb-0"
                  height="70" />
              </v-card>
            </div>
          </div>
        </v-form>
      </div>
      <br />
      <div class="row gray-underline-wizard margin-l-11 mt-10 pr-10">
        <div class="col-md-12 row text-end justify-end mb-3 mt-2">
          <span style="color: #0573f3" class="text-underline pointer mr-10 mt-2"
            @click="$router.replace(`/dpc-providers/providers`)">Cancel</span>
          <span style="color: #0572f3" class="text-underline pointer mr-2 mt-2" @click="updateProvider">Save
          </span>
        </div>
      </div>
    </div>
    <v-dialog v-model.trim="dialog1" width="600">
      <v-card>
        <v-card-title class="text-h6 text-underline">
          Based on Provider Settings
        </v-card-title>

        <v-card-text class="black--text font-weight-black">
          For purposes of demo. <br />
          <br />
          The provider can choose one of two options in their settings, related to
          unmasking their rates for employers: <br /><br />
          <v-icon small class="mr-2 dot">fa fa-circle</v-icon> Automatically unmask rates
          upon employer request, and notify provider, or <br /><br />
          <v-icon small class="mr-2 dot">fa fa-circle</v-icon> Send notification to
          provider requesting approval to unmask the rates.
        </v-card-text>
        <v-card-actions class="justify-end">
          <div class="text-end justify-end col-md-12">
            <v-btn class="blue-buttons col-md-4 mb-34" @click="dialog1 = false">Proceed</v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <errorModal ref="error_modal" v-on:confirm="closeErrorModal"></errorModal>
  </v-row>
</template>
<script>
import successModal from "../../../../TablesTemplate/SuccessDialog.vue";
import errorModal from "../../../../TablesTemplate/ErrorDialog.vue";
import removeDialog from "../../../../TablesTemplate/RemoveDialog.vue";
import api from "../../../../../services/api";
export default {
  components: {
    successModal,
    removeDialog,
    errorModal,
  },
  data() {
    return {
      allLocations: [],
      rating: 4,
      dialog1: false,
      valid: false,
      genders: [
        {
          text: 'Female', value: 'F'
        },
        {
          text: 'Male', value: 'M'
        }
      ],
      provider: {
        // NPI: "",
        // primaryContact: {
        //   firstName: "",
        //   lastName: "",
        // },

        // sex: "",
        // specialty: "",
        // taxonomy: "",
        // status: "",
        // locations: "",
      },
      locations: [],
      zipRules: [(v) => (v && v.length == 5) || " Zip Code should be five digits long"],
      requiredRules: [(v) => !!v || "This Field is required"],
      taxIdRules: [
        (v) => !!v || "This Field is required",
        (v) =>
          !!/[0-9]{2}\-[0-9]{7}/.test(v) ||
          "Tax ID should contain 9 digits, Please enter your Tax ID in XX-XXXXXXX format",
      ],
      emailRules: [
        (v) => !!v || "This Field is required",
        (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || "E-mail must be valid",
      ],
      phoneRules: [
        (v) =>
          /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(v) ||
          "Phone number must be in a valid format (XXX XXX XXXX)",
      ],
    };
  },
  created() {

    this.getProvider();
    // this.getLocations();

  },
  computed: {
    route() {
      return this.$route.path;
    },
    getSingleProvider() {
      return this.$store.getters.getSingleProviderInemployerAdvisor;
    },
    routeParams() {
      return this.$route.params.name;
    },
    redColorInpatient() {
      let redColor = false;
      if (this.inpatient != this.providerInpatient) {
        redColor = true;
      }
      return redColor;
    },
    redColorOutpatient() {
      let redColor = false;
      if (this.outpatient != this.providerOutpatient) {
        redColor = true;
      }
      return redColor;
    },
  },
  methods: {
    getLocations() {
      // api()
      //   .get("/dpcproviders/locations")
      //   .then((res) => {
      //     this.locations = res.data;
      //     if(this.provider.locations.length > 0){
      //       this.allLocations = [...this.locations, ...this.provider.locations];
      //     }else{
      //       this.allLocations = this.locations;
      //     }

      //   });
    },
    getProvider() {
      api()
        .get(`/dpcproviders/provider/` + this.$route.params.id)
        .then((res) => {
          if (res) {
            this.provider = res.data;
            api()
              .get("/dpcproviders/locations")
              .then((res) => {
                this.locations = res.data;
                if (this.provider.locations && this.provider.locations.length > 0) {
                  this.allLocations = [...this.locations, ...this.provider.locations];
                } else {
                  this.allLocations = this.locations;
                }

              });
            // console.log('this.',this.locations)
            // let  tempLocations = [];
            // let locs = this.provider.locations;
            // locs.forEach((location) => {
            //   this.locations.forEach((tmp) => {
            //     if(location.id == tmp.id){
            //       tempLocations.push(location.id)
            //     }
            //   })
            // })
            // this.provider.locations = tempLocations;
            // console.log('temp',tempLocations)
            this.provider.logo = res.data.logo;

          }
        });
    },
    activateFileInput() {
      this.$refs.fileInput.$refs.input.click();
    },
    changeLogo() {
      const inputElement = this.$refs.fileInput.$refs.input;
      if (inputElement.files.length > 0) {
        const imagePreview = this.$refs.imagePreview;
        const reader = new FileReader();
        reader.onload = (e) => {
          imagePreview.src = e.target.result;
        };
        reader.readAsDataURL(inputElement.files[0]);
      }
    },
    updateLogo() {
      let file = this.$refs.fileInput.$refs.input.files[0];

      let formData = new FormData();
      formData.append("_method", "POST");
      formData.append("image", file);
      api()
        .post(`/dpcproviders/provider/${this.$route.params.id}/logo`, formData)
        .catch((err) => {
          if (err.response.status == 500) {
            this.$refs.error_modal.openPopUp(
              "There was an error, system admin is notified. Please try again later."
            );
          } else {
            this.$refs.error_modal.openPopUp(err.response.data);
          }
        })
        .then((res) => {
          if (res) {
            this.provider.logo = res.data.logo;
          }
        });
    },

    showSlash(event) {
      if (this.taxId.length == 2 && event.keyCode != 8) {
        this.taxId += "-";
      }
    },
    closeErrorModal() {
      this.$refs.error_modal.closePopUp();
    },

    updateModal() {
      this.$refs.success_modal.closePopUp();
      this.$router.replace(`/dpc-providers/providers`);
    },
    updateProvider() {
      const locationIds = this.provider.locations.map(location => {
    if (typeof location === 'object') {
        return location.id;
    } else {
        return location;
    }
});
this.provider.locations = locationIds;
      this.$refs.form.validate();
      if (this.valid) {
        api()
          .put("/dpcproviders/providers/" + this.provider.id, this.provider)
          .catch((err) => {
            if (err.response.status == 500) {
              this.$refs.error_modal.openPopUp(
                "There was an error, system admin is notified. Please try again later."
              );
            } else {
              this.$refs.error_modal.openPopUp(err.response.data);
            }
          })
          .then((res) => {
            if (res) {
              this.$refs.success_modal.openPopUp("Provider successfully updated");
            }
          });
      }
    },
    removeProvider() {
      this.$refs.dialog_delete.openPopUp(
        "Are you sure that you want to delete this provider?"
      );
    },
    confirmRemove() {
      this.$refs.dialog_delete.closePopUp();
      api()
        .delete(`/dpcproviders/providers/${this.$route.params.id}`)
        .catch((err) => {
          if (err.response.status == 500) {
            this.$refs.error_modal.openPopUp(
              "There was an error, system admin is notified. Please try again later."
            );
          } else {
            this.$refs.error_modal.openPopUp(err.response.data);
          }
        })
        .then((res) => {
          if (res) {
            this.$refs.success_modal.openPopUp("Provider successfully deleted");
            this.$parent.getAllProviders();
            this.$router.push("/dpc-providers/providers");
          }
        });
    },
    closePopUp() {
      this.dialog = false;
    },
  },
};
</script>
<style>
.rates-provider-inputs-test {
  border: 1px solid rgb(209, 209, 209) !important;
  width: 100px;
  height: 40px;
  border-radius: 5px;
  padding-left: 36px;
}

.rates-provider-inputs-test:active,
.rates-provider-inputs-test:focus,
.rates-provider-inputs-test:hover {
  border: 1px solid rgb(209, 209, 209) !important;
}

.rates-provider-inputs-color {
  color: red;
}
</style>
