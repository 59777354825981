<template>
  <v-row class="add-client-form no-padding">
    <successModal
      ref="success_modal"
      v-on:confirm="closeSuccessModal"
    ></successModal>
    <div class="col-md-12 form-box">
      <div class="row">
        <div class="col-md-12">
          <h3 class="font">Add New Provider</h3>
        </div>
      </div>
      <div class="form-row-height">
        <v-form class="row col-md-6" ref="form" v-model.trim="valid">
          
          <div class="col-md-12 col-lg-12 pt-0 pb-0">
            <label class="font text-gray" for=""
              >First Name</label
            >
            <v-text-field
              required :rules="requiredRules"
              type="text"
              v-model.trim="firstName"
              solo
              tabindex="1"
            ></v-text-field>
          </div>

          <div class="col-md-12 col-lg-12 pt-0 pb-0">
            <label class="font text-gray" for=""
              >Last Name</label
            >
            <v-text-field
              required :rules="requiredRules"
              v-model.trim="lastName"
              solo
              type="text"
              tabindex="2"
            ></v-text-field>
          </div>

          <div class="col-md-12 col-lg-12 pt-0 pb-0">
            <label class="font text-gray" for=""
              >Email*</label
            >
            <v-text-field
              required :rules="requiredRules"
              v-model.trim="email"
              solo
              type="email" @blur="checkAccountExist"
              tabindex="3"
            ></v-text-field>
            <v-btn v-if="accountExist" @click="accessAccount">Access Account</v-btn>
          </div>
          
          <div class="col-md-12 col-lg-12 pb-0">
            <label class="font text-gray" for="">Company Name </label>
            <v-text-field
              required
              solo
              tabindex="4"
              v-model.trim="companyName"
              :rules="requiredRules"
            ></v-text-field>
          </div>
          
          <div class="col-md-12 col-lg-12 pb-0 pt-0 pb-0">
            <label class="font text-gray" for=""
              >Tax ID </label>
            <v-text-field
              required
              @keyup="showSlash($event)"
              solo
              tabindex="5"
              v-model.trim="taxId"
              :rules="taxIdRules"
              counter="10"
              placeholder="00-0000000"
              maxlength="10"
            ></v-text-field>
          </div>

          <div class="col-md-12 col-lg-12 pb-0 pt-0 pb-0">
            <label class="font text-gray" for=""
              >Provider Category </label>
            
            <v-select
              required tabindex="6"
              v-model="providerCategory"
              :items="['DPC', 'affiliate', 'group', 'hospital']"
            ></v-select>
          </div>
          
          

        </v-form>
      </div>
      <div class="col-md-12 d-flex align-end flex-column">
        <div>
          <v-btn
            class="blue-buttons mr-6"
            @click="$router.push(`/superadmin/providers`)"
          >
            Cancel
          </v-btn>
          <v-btn class="blue-buttons" @click="submit">
            Add Provider
          </v-btn>
        </div>
      </div>
      <errorModal 
    ref="error_modal"
     v-on:confirm="closeErrorModal">
    </errorModal>
    </div>
  </v-row>
</template>
<script>
import api from "../../../../services/api";
import successModal from "../../../TablesTemplate/SuccessDialog.vue";
import errorModal from "../../../TablesTemplate/ErrorDialog.vue";
export default {
  components: {
    successModal, errorModal
  },
  data() {
    return {
      companyName: "", providerCategory: "DPC",
      taxId: "",
      firstName: "",
      lastName: "",
      email: "",
      requiredRules: [(v) => !!v || "This Field is required"],
      taxIdRules: [
        (v) => !!v || "This Field is required",
        (v) =>
          !!/[0-9]{2}\-[0-9]{7}/.test(v) ||
          "Tax ID should contain 9 digits, Please enter your Tax ID in XX-XXXXXXX format",
      ],
      valid: false, accountExist: false, existingAccount: {}
    };
  },
  methods: {
    showSlash(event){
      if (this.taxId.length == 2 && event.keyCode != 8) {
        this.taxId += "-";
      }
      if (this.taxId.length == 10) {
        api()
          .get("auth/isValidTaxId?taxId=" + this.taxId)
          .catch((res) => {
            if (res) {
              this.taxIdRules = [res.response.data.message];
            }
          });
      } else {
        this.taxIdRules = [
          (v) => !!v || "This Field is required",
          (v) =>
            !!/[0-9]{2}\-[0-9]{7}/.test(v) ||
            "Tax ID should contain 9 digits, Please enter your Tax ID in XX-XXXXXXX format",
        ];
      }
    },
    closeSuccessModal() {
      this.$router.push("/superadmin/providers");
    },
    closeErrorModal() {
      this.$refs.error_modal.closePopUp();
    },
    submit() {
     
      this.$refs.form.validate();
      if (this.valid) {
        let check = {email: this.email}
        api().post(`/superadmin/account/restricted`, check)
        .catch((err) => {
            if (err) {
              this.$refs.error_modal.openPopUp(err.response.data.error.message);
            }
          })
        .then(res => {
          if(res.data.restricted == false){
            if(!this.accountExist){
              let user = {
                           companyName: this.companyName, 
                           type: 'provider',
                           taxId: this.taxId,
                           providerCategory: this.providerCategory,
                           firstName: this.firstName, 
                           lastName: this.lastName, 
                           email: this.email
                         };
               api().post(`/superadmin/account`, user)
             .then((res) => {
                if (res) {
                let text = "Provider added successfully";
               this.$refs.success_modal.openPopUp(text);
            }
          });
            }
            else{
              this.$refs.error_modal.openPopUp('This account already exists!');
            }
          }
          else if(res.data.restricted == true){
            this.$refs.error_modal.openPopUp('This email domain is restricted!');
          }
          
        });
       
      }
    },
    checkAccountExist(){
      let request = {email: this.email}
      api()
        .post(`/superadmin/account/exist`, request)
        .then(res => {
          if(res && res.data){
            this.accountExist = res.data.exists
            if(this.accountExist)this.existingAccount = res.data.account
          }
        })
    },
    accessAccount(){
      let url = `/superadmin/account/${this.existingAccount._id}/token`
      api()
        .get(url)
        .then(res => {
          if(res && res.data){
            this.$cookies.set("token", res.data.accessToken);
            localStorage.setItem("user", JSON.stringify(res.data));
            this.$router.replace("/dpc-providers")
          }
        })
    }
  },
};
</script>
<style>
.add-client-form {
  padding: 1.5rem;
}

.yellow-icons {
  color: rgb(224, 171, 36);
}
.blue-buttons {
  background: #4072b7 !important;
  color: white !important;
}
</style>