<template>
    <div class="no-padding row-height">
      <FullscreenLoader v-if="loader" />
      <v-row class="col-md-12">
        <v-col class="col-xl-1 col-lg-4 col-md-4" md="4" cols="12">
          <h3>
            <span class="font">TPAs </span>
          </h3>
        </v-col>
        <v-col class="col-xl-2 col-lg-3 col-md-3" md="3" cols="12">
          <v-text-field
            class="input-control"
            v-model="search"
            prepend-inner-icon="mdi-magnify"
            light
            dense
            outlined
            placeholder="Search Providers"
          >
          </v-text-field>
        </v-col>
        <v-col
          class="col-xl-9 col-lg-5 col-md-5 text-end pr-0"
          v-if="$role != 'user'"
          cols="12"
          align="end"
        >
        <v-btn
         @click="$router.push('/superadmin/tpas/add-new')"
         class="my-1 blue-buttons">
          Add  TPA</v-btn
        >
        
          <!-- <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" class="my-1 blue-buttons">
                Add / Invite TPA</v-btn
              >
            </template>
            <v-list>
              <v-list-item>
                <a
                  class="a-tag underline-text"
                  @click="$router.push('/superadmin/tpas/add-new')"
                  >Add TPA</a
                >
              </v-list-item>
              <v-list-item>
                <a
                  class="a-tag underline-text"
                  @click="$router.push('/superadmin/tpas/invite-tpa')"
                  >Invite TPA</a
                >
              </v-list-item>
              <v-list-item>
                  <a class="a-tag underline-text" >Copy Invite Link</a>
              </v-list-item>
            </v-list>
          </v-menu> -->
        </v-col>
      </v-row>
      <div class="col-md-12 text-center justify-center" v-if="readyToShow">
        <v-progress-circular
          indeterminate
          :size="100"
          color="primary"
          class="text-center"
        ></v-progress-circular>
      </div>
      <div v-else>
        <v-row class="col-md-12" v-if="tpas.length < 1">
          <v-col md="12">
            No tpas to show
            <a
              v-if="$role != 'user'"
              @click="$router.push('/superadmin/tpas/add-new')"
              >- Click Here to Add TPA.</a
            >
          </v-col>
        </v-row>
        <v-data-table
          :headers="tableHeaders" 
          :items="tpas"
          :search="search"
          :rows-per-page-items="[5, 10, 20, 30]"
          :pagination.sync="pagination"
        >
          <template v-slot:body="{ items }">
            <tr v-for="item in items" :key="item._id">
              <td>{{ item.firstName }} </td>
              <td>{{ item.lastName }}</td>
              <td>{{ item.email }}</td>
              <td :class="item.companyName ? '' : 'font-italic text-gray'">{{ item.companyName ? item.companyName : 'No Organization' }}</td>
              <td>{{ item.taxId ? item.taxId : '/' }}</td>
              <td class="text-center">{{ item.status.charAt(0).toUpperCase() + item.status.slice(1) }}</td>
              <td class="text-center">{{ item.organizationStatus ? item.organizationStatus.charAt(0).toUpperCase() + item.organizationStatus.slice(1) : '/' }}</td>
              <td class="text-end">
                <v-list>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon class="row-pointer" v-bind="attrs" v-on="on"
                        @click="accessAccount(item)">
                        mdi-account-card
                      </v-icon>
                    </template>
                    <span>Access Account</span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="item.status === 'active' && item.organizationStatus != 'active'">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon class="row-pointer ml-2" v-bind="attrs" v-on="on"
                        @click="openActivateAccount(item)">mdi-checkbox-marked-circle</v-icon>
                    </template>
                    <span>Activate/Reject account</span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="item.status != 'active' ">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon class="row-pointer ml-2" v-bind="attrs" v-on="on"
                        @click="sendVerificationEmail(item)">mdi-email</v-icon>
                    </template>
                    <span>Send Verification Email</span>
                  </v-tooltip>
                  <!-- <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon class="row-pointer ml-2" v-bind="attrs" v-on="on"
                        @click="removeItem(item)">mdi-delete</v-icon>
                    </template>
                    <span>Delete Account</span>
                  </v-tooltip> -->
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon class="row-pointer ml-2" v-bind="attrs" v-on="on"
                        @click="editAccount(item)" >mdi-pencil</v-icon>
                    </template>
                    <span>Edit Account</span>
                  </v-tooltip>
                </v-list>
              </td>
              <td class="text-start">
                  <template>
                      <div>
                          <v-tooltip  bottom>
                          <template v-slot:activator="{ on, attrs }">
                              <div v-on="on" v-bind="attrs">
                              <v-switch
                               inset 
                               small 
                               dense 
                               class="marketplace-switch" 
                               @change="openMarketPlaceUpdate(item)"
                               v-model="item.marketplaceVisibility">
                              </v-switch>
                              </div>
                          </template>
                          {{ item.marketplaceVisibility ? 'Disable Marketplace' : 'Enable Marketplace'  }}
                          </v-tooltip>
                      </div>
                  </template>
              </td>
            </tr>
          </template>
          
        </v-data-table>
        <v-dialog v-model.trim="dialog" width="600">
          <v-card>
            <v-card-title class="text-h5"> PDF CONTRACT </v-card-title>
            <v-card-text>
              When user clicks “View Contract” on the Employer list from prior
              screen, then a PDF version of the contract is presented for the
              Provider user to view. This signature page of this PDF contract is
              populated with the Employer’s information (i.e. the Employer that
              they selected on the prior screen), and the compensation addendum is
              populated with the Provider’s contract rates. The user can view,
              print, and save this PDF.
            </v-card-text>
            <v-card-actions>
              <div class="col-md-12 text-end">
                <v-btn class="blue-buttons text-end" @click="dialog = false"
                  >Close</v-btn
                >
              </div>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model.trim="editModal"
          width="400"
          class="modal-wizard-check-members margin-top-wizard-card"
      >
        <v-card class="col-md-12">
          <div
              class="row text-center justify-center blue-underline"
          >
            <div
                class="
                text-center
                justify-center
                col-md-10
                font-weight-bold
                mt-3
                mb-3
              "
            >
              Edit Personal Information
            </div>
            <div class="col-md-1 mt-3 mr-3"  @click="closeEdit()">
              <span class="text-end"
              ><i
                 
                  class="fa fa-times text-gray pointer"
                  style="font-size: 22px"
              ></i>
              </span>
            </div>
          </div>
  
          <div class="row justify-center mt-10">
            <div style="width: 90%">
              <v-label class="text-gray">First Name</v-label>
              <v-text-field
                  solo
                  v-model="tmp.firstName"
              ></v-text-field>
              
            </div>
            <div style="width: 90%">
              <v-label class="text-gray">Last Name</v-label>
              <v-text-field
                  solo
                  v-model="tmp.lastName"
              ></v-text-field>
            </div>
            <div style="width: 90%">
              <v-label class="text-gray">Email</v-label>
              <v-text-field
                  solo
                  v-model="tmp.email"
                  @blur="checkAccountExist"
              ></v-text-field>
            </div>
            <div style="width: 90%">
              <v-label class="text-gray">Company Name</v-label>
              <v-text-field
                  solo
                  v-model="tmp.companyName"
              ></v-text-field>
            </div>
            <div style="width: 90%">
              <v-label class="text-gray">Tax ID</v-label>
              <v-text-field
                  solo
                  :rules="taxIdRules"
                  v-model="tmp.taxId"
              ></v-text-field>
            </div>
  
          </div>
  
          <div class="mt-5 justify-center row text-center">
            <div
                class="
                blue-success-modal
                mt-5
                col-md-10
                justify-center
                pointer
                mb-5
              "
              @click="updateInfo(tmp)"
            >
            <div class="success-modal-blue-text mb-1 mt-3 mr-3"
                   style="font-size: 17px;"
                   >
                Save Updated Information
              </div>
              <svg
                  class="mt-3"
                  width="30"
                  height="25"
                  viewBox="0 0 50 51"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <path
                    d="M14.2852 28.9142L21.6066 34.7714C21.8032 34.9333 22.0335 35.0493 22.2806 35.1111C22.5277 35.1729 22.7855 35.1788 23.0352 35.1285C23.2872 35.0812 23.5262 34.9802 23.7357 34.8323C23.9453 34.6844 24.1205 34.493 24.2494 34.2714L35.7137 14.6285"
                    stroke="#4072B7"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M24.9994 48.5571C37.8203 48.5571 48.2137 38.1637 48.2137 25.3428C48.2137 12.5219 37.8203 2.12849 24.9994 2.12849C12.1785 2.12849 1.78516 12.5219 1.78516 25.3428C1.78516 38.1637 12.1785 48.5571 24.9994 48.5571Z"
                    stroke="#4072B7"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
              </svg>
              
            </div>
          </div>
        </v-card>
          </v-dialog>
          <v-dialog v-model.trim="removeTpaDialog" max-width="600px" persistent>
            <v-card>
              <v-col>
                <h3>Remove TPA</h3>
              </v-col>
      
              <v-col>
                <span>Are you sure you want to delete this TPA? </span>
              </v-col>
      
              <v-col>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn class="blue-buttons float-class" @click="deleteAccount()"
                    >Confirm</v-btn
                  >
      
                  <v-btn class="button-style float-class" @click="removeTpa()"
                    >Close</v-btn
                  >
                </v-card-actions>
              </v-col>
            </v-card>
          </v-dialog>
  
          <v-dialog
              v-model.trim="activateAccountModal"
              width="400"
              class="modal-wizard-check-members margin-top-wizard-card"
          >
              <v-card class="col-md-12">
                  <div
                      class="row text-center justify-center blue-underline"
                  >
                  <div
                      class="
                      text-center
                      justify-center
                      col-md-10
                      font-weight-bold
                      mt-3
                      mb-3
                      "
                  >
                      Activate/Reject account
                  </div>
                  <div class="col-md-1 mt-3 mr-3"  @click="activateAccountModal = false">
                      <span class="text-end"
                      ><i
                      
                          class="fa fa-times text-gray pointer"
                          style="font-size: 22px"
                      ></i>
                      </span>
                  </div>
                  </div>
  
                  <div class="row justify-center mt-10" >
                  <div style="align-items: center; flex-direction: column; display: flex; justify-content: center;">
                          Do you want to approve or reject this account?<br> <br>
                          <b style="font-size: large;">
                              {{ activateAccountDetails.firstName }} {{ activateAccountDetails.lastName }}
                          </b>      
                  </div>
                  </div>
  
                  <div class="mt-10 mb-2 align-right">
                      <v-btn
                          outlined
                          class="gray-border-buttons"
                          @click="activateAccount(false)"
                      >
                          Reject
                      </v-btn>
  
                      <v-btn
                          outlined
                          class="blue-buttons ml-2"
                          @click="activateAccount(true)"
                      >
                          Approve
                      </v-btn>
                  </div>
              </v-card>
          </v-dialog>
  
          <v-dialog
              v-model.trim="updateMarketPlaceModal"
              width="400"
              class="modal-wizard-check-members margin-top-wizard-card"
          >
              <v-card class="col-md-12">
                  <div
                      class="row text-center justify-center blue-underline"
                  >
                  <div
                      class="
                      text-center
                      justify-center
                      col-md-10
                      font-weight-bold
                      mt-3
                      mb-3
                      "
                  >
                      Update Marketplace Visibility
                  </div>
                  <div class="col-md-1 mt-3 mr-3"  @click="updateMarketPlaceModal = false">
                      <span class="text-end"
                      ><i
                      
                          class="fa fa-times text-gray pointer"
                          style="font-size: 22px"
                      ></i>
                      </span>
                  </div>
                  </div>
  
                  <div class="row justify-center mt-10" >
                  <div style="align-items: center; flex-direction: column; display: flex; justify-content: center;">
                          {{ updateMarketPlaceDetails.marketplaceVisibility ? 'Enable' : 'Disable' }} Marketplace visibility for user? <br> <br>
                          <b style="font-size: large;">
                              {{ updateMarketPlaceDetails.firstName }} {{ updateMarketPlaceDetails.lastName }}
                          </b>      
                  </div>
                  </div>
  
                  <div class="mt-10 mb-2 align-right">
                      <v-btn
                          outlined
                          class="blue-buttons"
                          @click="updateMarketPlaceVisibility()"
                      >
                          Confirm
                      </v-btn>
                  </div>
              </v-card>
          </v-dialog>
  
          <errorModal 
      ref="error_modal"
       v-on:confirm="closeErrorModal">
      </errorModal>
      <successModal
        ref="success_modal"
        v-on:confirm="closeSuccessModal"
      ></successModal>
      </div>
    </div>
  </template>
  <script>
  import api from "../../../../services/api";
  import successModal from "../../../TablesTemplate/SuccessDialog.vue";
  import errorModal from "../../../TablesTemplate/ErrorDialog.vue";
  import FullscreenLoader from "../../../Common/FullscreenLoader.vue";
  
  export default {
    components: {
      successModal, errorModal, FullscreenLoader
    },
    data() {
      return {
        loader: false,
        activateAccountModal: false,
        activateAccountDetails: {},
        updateMarketPlaceModal: false,
        updateMarketPlaceDetails: {},
        pagination: {
          rowsPerPage: 30
        },
        items: [],
        tpas: [],
        response: [], search: '',
        tableHeaders: [
          {text: 'First Name', value:'firstName', align: 'start'},
          {text: 'Last Name', value:'lastName', align: 'start'},
          {text: 'Email', value:'email', align: 'start'},
          {text: 'Company Name', value:'companyName', align: 'start'},
          {text: 'Tax Id', value:'taxId', align: 'start'},
          {text: 'User Status', value:'status', align: 'start'},
          {text: 'Organization Status', value:'organizationStatus', align: 'start'},
          {text: 'Controls', align: 'end'},
          {text: '', align: 'start'},
        ],
        dialog: false,
        filterItems: false,
        readyToShow: false,
        editModal: false,
        tmp: {
          firstName: "",
          lastName: "",
          companyName: "",
          email: "",
          taxId: "",
        },
        itemToRemove: {},
        accountExist: false,
        hasOrganization: false,
        removeTpaDialog: false,
        taxIdRules: [
          (v) => !!v || "This Field is required",
          (v) =>
            !!/[0-9]{2}\-[0-9]{7}/.test(v) ||
            "Tax ID should contain 9 digits, Please enter your Tax ID in XX-XXXXXXX format",
        ],
      };
    },
    created() {
      this.getTPAs();
    },
    computed: {
      filteredArray() {
        return this.tpas.filter(item => {
          let search = this.search.toLowerCase()
          let match = false
          if(item.firstName)match = match || item.firstName.toLowerCase().includes(search)
          if(item.lastName)match = match || item.lastName.toLowerCase().includes(search)
          if(item.email)match = match || item.email.toLowerCase().includes(search)
          if(item.companyName)match = match || item.companyName.toLowerCase().includes(search)
          if(item.taxId)match = match || item.taxId.toLowerCase().includes(search)
          return match
        })
      },
    },
    methods: {
      openActivateAccount(item) {
          this.activateAccountDetails = item;
          this.activateAccountModal = true;
      },
      activateAccount(activate) {
          this.loader = true;
          const reqBody = {
              status: activate ? 'active' : 'inactive',
              type: this.activateAccountDetails.type,
              account: {
                  _id: this.activateAccountDetails._id,
                  firstName: this.activateAccountDetails.firstName,
                  lastName: this.activateAccountDetails.lastName,
                  email: this.activateAccountDetails.email,
                  companyName: this.activateAccountDetails.companyName
              },
          };
  
          api()
              .put(`/superadmin/entity/${this.activateAccountDetails.entityId}`, reqBody)
              .catch((err) => {
                  if (err) {
                      this.$refs.error_modal.openPopUp(err.response.data.message);
                  }
              })
              .then(res => {
                  if(res){
                      this.$refs.success_modal.openPopUp('Success');
                  }
              })
              .finally(() => {
                  this.activateAccountModal = false;
                  this.loader = false;
                  
              });
      },
      openMarketPlaceUpdate(item) {
          this.updateMarketPlaceDetails = item;
          this.updateMarketPlaceModal = true;
      },
      updateMarketPlaceVisibility() {
          this.loader = true;
          const reqBody = {
              marketplaceVisibility: this.updateMarketPlaceDetails.marketplaceVisibility,
              type: this.updateMarketPlaceDetails.type,
              account: {
                  firstName: this.updateMarketPlaceDetails.firstName,
                  lastName: this.updateMarketPlaceDetails.lastName,
                  email: this.updateMarketPlaceDetails.email
              }
          };
  
          api()
              .put(`/superadmin/entity/${this.updateMarketPlaceDetails.entityId}`, reqBody)
              .catch((err) => {
                  if (err) {
                      this.$refs.error_modal.openPopUp(err.response.data.message);
                  }
              })
              .then(res => {
                  if(res){
                      this.$refs.success_modal.openPopUp('Success');
                  }
              })
              .finally(() => {
                  this.updateMarketPlaceModal = false;
                  this.loader = false;
                  
              });
      },
      getTPAs() {
        this.readyToShow = true;
        let type = {type: "tpa"}
        api()
          .post(`/superadmin/accounts`, type)
          .then((res) => {
            this.tpas = res.data;
            if(this.tpas.organizationId)
            {
              this.hasOrganization = true;
            }
            else{
              this.hasOrganization = false;
            }
            this.readyToShow = false;
          })
          .catch((err) => {
            console.log(err.response);
            this.readyToShow = false
          });
      },
  
      accessAccount(item){
        let url = `/superadmin/account/${item._id}/token`
        api()
          .get(url)
          .then(res => {
            console.log("token", res.data)
            this.$cookies.set('token', res.data.accessToken)
            localStorage.setItem('user', JSON.stringify(res.data))
            this.$router.replace('/tpa')
          })
      },
      sendVerificationEmail(item){
        api()
          .get(`/superadmin/account/${item._id}/verification`)
          .then(res => {
            this.$refs.success_modal.openPopUp(res.data)
          })
      },
      deleteAccount(){
        api()
          .delete(`/superadmin/account/${this.itemToRemove._id}`)
          .then(res => {
            this.$refs.success_modal.openPopUp('Successfully Deleted');
            this.removeTpaDialog = false;
            this.getTPAs();
          })
      },
      removeItem(item) {
        this.removeTpaDialog = true;
        this.itemToRemove = item;
      },
      removeTpa(){
        this.removeTpaDialog = false;
      },
      editAccount(item){
        this.editModal = true;
        // this.tmp.firstName = item.firstName;
        // this.tmp.lastName = item.lastName;
        // this.tmp.companyName = item.companyName;
        // this.tmp.email = item.email;
        // this.tmp.taxId = item.taxId;
        this.tmp = item;
      },
      updateInfo(item){
          let check = {email: item.email}
          api().post(`/superadmin/account/restricted`, check)
          .catch((err) => {
              if (err) {
                this.$refs.error_modal.openPopUp(err);
              }
            })
          .then(res => {
            if(res.data.restricted == false){
              // if(!this.accountExist){
               let data = {
               firstName: item.firstName ? item.firstName : "",
               lastName: item.lastName ? item.lastName : "",
               companyName: item.companyName ? item.companyName : "",
               email: item.email ? item.email : "",
               taxId: item.taxId ? item.taxId : "",
           }
        api()
          .put(`/superadmin/account/${item._id}`, data)
          .catch((err) => {
              if (err) {
                if(err.response.status == 403)
                {
                   this.$refs.error_modal.openPopUp(`There's already another account associated with email ${item.email}`);
                }
                else if(err.response.status == 404){
                  this.$refs.error_modal.openPopUp(`No account found with id ${item._id}`);
                }
               
              }
            })
          .then(res => {
            if(res){
              this.$refs.success_modal.openPopUp('Success');
            }
            
          });
              // }
              // else{
              //   this.$refs.error_modal.openPopUp('This account already exists!');
              // }
            }
            else if(res.data.restricted == true){
              this.$refs.error_modal.openPopUp('This email domain is restricted!');
            }
          });
      },
      checkAccountExist(){
        let request = {email: this.email}
        api()
          .post(`/superadmin/account/exist`, request)
          .then(res => {
            if(res && res.data){
              this.accountExist = res.data.exists
              if(this.accountExist)this.existingAccount = res.data.account
            }
          })
      },
      closeEdit(){
      this.editModal = false;
      },
      closeSuccessModal() {
        this.$refs.success_modal.closePopUp();
        this.editModal = false;
        this.getTPAs();
      },
      closeErrorModal() {
        this.$refs.error_modal.closePopUp();
  
      },
    },
  };
  </script>
  <style>
  .marketplace-switch {
      transform: scale(0.75);
      width: 3vw;
      height: 1vh;
  }
  </style>