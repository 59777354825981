<template>
  <v-row class="no-padding row-height pa-0">
    
      <div class="blue-underline row pa-0">
        <div class="col-md-12">
          <h3 class="text-gray">Invoice Details</h3>
        </div>
        <div class="col-md-3 col-lg-3 d-flex">
          <h4
            class="mr-3 pointer"
            :class="invoicesPart ? 'light-blue-text' : 'text-gray'"
            @click="invoiceTab()"
          >
            Inovices
          </h4>
          <h4
            class="text-gray ml-1 pointer"
            :class="membersPart ? 'light-blue-text' : 'text-gray'"
            @click="memberTab()"
          >
            Member Details
          </h4>
        </div>
      </div>
      <div id="display-pdf" style="display: none"></div>
      <div class="col-12 pl-0 pr-0 pt-0">
        <div class="row col-12">
          <div v-if="invoicesPart" class="col-12 d-md-flex">
            <div class="col-md-6 col-12">
              <v-form
                ref="form"
                v-model="valid"
                class="col-xl-12 col-lg-12 col-md-12 col-sm-12 row justify-center"
              >
                <span class="mr-10 text-gray mt-4 font-weight-bold col-md-12">
                </span>
                <br />
                <div class="col-xl-8 col-lg-12 col-md-12 pb-0">
                  <div class="row">
                    <label
                      for=""
                      class="col-md-4 text-xl-right text-lg-right text-md-left"
                      >INVOICE NUMBER: </label
                    >
                  
                    <div class="col-md-6 pt-0">
                      <v-text-field
                        solo
                        :value="invoice.invoiceNo"
                        disabled
                      ></v-text-field>
                    </div>
                  </div>
                </div>
                <div class="col-xl-8 col-lg-12 col-md-12 pb-0">
                  <div class="row">
                    <label for="" class="col-md-4 text-lg-right text-md-left"
                      >CLIENT: </label
                    >
                    <div class="col-md-6 pt-0">
                      <v-text-field
                        disabled
                        solo
                        v-model="invoice.employer.companyName"
                      ></v-text-field>
                    </div>
                  </div>
                </div>
                <div class="col-xl-8 col-lg-12 col-md-12 pb-0">
                  <div class="row">
                    <label for="" class="col-md-4 text-lg-right text-md-left"
                      >INVOICE DATE: </label
                    >
                    <div class="col-md-6 pt-0">
                      <v-menu
                        ref="menu"
                        :close-on-content-click="true"
                        transition="scale-transition"
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            id="calendar"
                            solo
                            v-model="invoice.date"
                            placeholder="MM-DD-YYYY"
                            readonly
                            disabled
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          disabled
                          v-model="invoice.date"
                        ></v-date-picker>
                      </v-menu>
                    </div>
                  </div>
                </div>

                <div class="col-xl-8 col-lg-12 col-md-12 pb-0">
                  <div class="row">
                    <label for="" class="col-md-4 text-lg-right text-md-left"
                      >TOTAL CHARGE (USD):</label
                    >
                    <div class="col-md-6 pt-0">
                      <v-text-field
                        disabled
                        solo
                        v-model="invoice.total"
                      ></v-text-field>
                    </div>
                  </div>
                </div>
                <div class="col-xl-8 col-lg-12 col-md-12 pb-0">
                  <div class="row">
                    <label for="" class="col-md-4 text-lg-right text-md-left"
                      >STATUS:</label
                    >
                    <div class="col-md-6 pt-0">
                      <v-text-field
                        disabled
                        solo
                        v-model="invoice.status"
                      ></v-text-field>
                    </div>
                  </div>
                </div>
              </v-form>
            </div>
            <div class="col-md-6 col-12 pt-3">
              
              <div v-if="attachment">
                <iframe
                  :src="pdfUrl"
                  :style="{
                    height: $vuetify.breakpoint.xsOnly ? '300px' : '1000px',
                    width: $vuetify.breakpoint.xsOnly ? '100%' : '100%',
                  }"
                ></iframe>
              </div>
              <v-card v-else class="pl-3 pr-3 pt-3" id="generate-pdf">
                <div
                  class="col-xl-12 col-lg-12 col-md-12 pb-0 pt-3 d-md-flex"
                  ref="htmlContent"
                >
                  <div class="col-md-6 pt-2">
                    <span>
                      <h1 class="font-weight-bold">Invoice</h1>
                    </span>
                  </div>
                  <div class="col-md-6 pt-2">
                    <span>
                    
                      <h1 class="font-weight-bold">
                        No: {{ invoice.invoiceNo }}
                      </h1>
                    </span>
                    <br />
                    <span class="font-weight-bold"> Issued </span>
                    <br />
                    <span class="text-gray">
                      {{ invoice.date }}
                    </span>
                  </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 pb-0 pt-3 d-md-flex">
                  <div class="col-md-6 pt-2" >
                    <span class="font-weight-bold"> From </span>
                    <br />
                    <span>
                      {{ invoice.vendor ? invoice.vendor.companyName : '' }}
                    </span>
                    <br />
                    <span v-if="invoice.vendor.primaryContact">
                      {{ invoice.vendor.primaryContact.firstName }}
                      {{ invoice.vendor.primaryContact.lastName }}
                    </span>
                    <br />
                    <span  v-if="invoice.vendor.primaryContact">
                      {{ invoice.vendor.primaryContact.email }}
                    </span>
                    <br />
                    <span  v-if="invoice.vendor.primaryContact">
                      {{ invoice.vendor.primaryContact.phone }}
                    </span>
                    <br />
                  </div>
                  <div class="col-md-6 pt-2">
                    <span class="font-weight-bold"> To </span>
                    <br />
                    <span> {{ invoice.client ?  invoice.client.companyName : '' }} </span>
                    <br />
                    <span> {{ invoice.client.address1 ? invoice.client.address1 : '' }} </span>
                    <br />
                    <span> {{ invoice.client.city ? invoice.client.city : ''}}, 
                      {{ invoice.client.zipCode ? invoice.client.zipCode : '' }}
                     </span>
                     <br/>
                     <span>
                      {{ invoice.client.state ? invoice.client.state : '' }}
                     </span>
                  </div>
                </div>

                <div class="col-md-12 col-12">
                  <v-simple-table fixed-header height="150px">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-start">#</th>
                          <th class="text-start">Item</th>
                          <th class="text-end">Qty</th>
                          <th class="text-end">Amount</th>
                        </tr>
                      </thead>
                      <tbody class="mt-2">
                        <tr
                          class="border-top--2"
                          v-for="(item, index) in invoice.items"
                          :key="index"
                        >
                          <td class="border-top--2">
                            {{ index + 1 }}
                          </td>
                          <td  class="border-top--2">
                            {{ item.description }}
                          </td>
                          <td class="text-end border-top--2">1</td>
                          <td class="text-end border-top--2">$ {{ item.amount }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 pb-0 pt-3 d-flex">
                  <div class="col-md-4 pt-0"></div>
                  <div class="col-md-8 pt-2 d-flex justify-end border-top border-top--2">
                    <div class="col-md-5">
                      <span class="font-weight-bold"> Total </span>
                    </div>
                    <div class="col-md-3" align="end">
      <span class="font-weight-bold">
        ${{ invoice.total }}
      </span>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 d-md-flex pb-0 pt-4">
                  <div class="col-md-9 mobile-font">
                    <img
                      id="logo-img"
                      :src="'/newapalylogoblue.png'"
                      class="apaly-pic pointer"
                    />
                  </div>
                  <div class="col-md-3 justify-logo text-right pt-8">
                    <a class="text-underline" @click="generatePdf()">
                      Download</a
                    >
                  </div>
                </div>

                <v-divider horizontal></v-divider>
                <div class="col-md-12 d-md-flex pt-0">
                  <div class="col-md-9 mobile-font">
                    <p class="text-gray"></p>
                  </div>
                  <div class="col-md-3" align="end"></div>
                </div>
              </v-card>
            </div>
          </div>
          <div v-if="membersPart" class="col-12 col-md-12">
            <div class="col-md-12 pl-0 pr-0 pt-0">
              <div class="col-md-12 pl-0 pr-0 text-gray">
                <div class="col-md-12 pl-0 pr-0">
                  <v-simple-table fixed-header height="630px">
                    <template v-slot:default>
                      <thead>
                      
                        <tr>
                          <th class="text-left">MEMBER ID</th>
                          <th class="text-left">FIRST NAME</th>
                          <th class="text-left">LAST NAME</th>
                          <th class="text-left">GENDER</th>
                          <th class="text-left">DATE OF BIRTH</th>
                          <th class="text-left">RELATIONSHIP</th>
                          <th class="text-left">SERVICE DATE</th>
                          <th class="text-left">AMOUNT</th>
                          <th class="text-left">CLAIM NUMBER</th>
                          <th class="text-left">STATUS</th>
                        </tr>
                      </thead>
                      <tbody class="mt-2" v-if="invoice.members.length > 0">
                        <tr
                          v-for="(item, index) in invoice.members"
                          :key="index"
                        >
                          <td>{{ item.memberId }}</td>
                          <td>{{ item.firstName }}</td>
                          <td>{{ item.lastName }}</td>
                          <td v-if="item.gender == 'm'">Male</td>
                          <td v-if="item.gender == 'f'">Female</td>
                          <td>{{ item.dob.substring(0, 10) }}</td>
                          <td>{{ item.subscriberRelation }}</td>
                          <td>{{ item.serviceDate.substring(0, 10) }}</td>
                          <td>{{ item.amount }} $</td>
                          <td></td>
                          <td></td>
                        </tr>
                      </tbody>
                      <tbody v-else>
                        <v-col md="12" cols="12">
                          <v-alert border="bottom" colored-border type="primary" elevation="2" class="text-gray">
                           No members!
                          </v-alert>
                          </v-col>
                      </tbody>
                    </template>
                  </v-simple-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="gray-top-border row">
        <div class="col-md-12" align="end">
          <span
            class="blue--text text-underline mr-10 font-weight-bold pointer"
            @click="$router.push('/vendors/invoices')"
            >Go Back</span
          >
        </div>
      
    </div>
    <successModal ref="success_modal" v-on:confirm="updateModal"></successModal>
  </v-row>
</template>
  <script>
import Header from "../../DigitalVendorInvoices/Layout/HeaderPage.vue";
import api from "../../../../services/api";
import successModal from "../../../TablesTemplate/SuccessDialog.vue";
import html2pdf from "html2pdf.js";
import { mapGetters } from "vuex";
import { saveAs } from "file-saver";
import pdf from "jspdf";
import { jsPDF } from "jspdf";
export default {
  data() {
    return {
      pdfUrl: null,
      attachment: "",
      invoice: {},
      user: {},
      newMembers: [],
      menu: "",
      menu1: "",
      member: {
        id: "",
        firstName: "",
        lastName: "",
        gender: "",
        dob: "",
        relationship: "",
        service: "",
        amount: "",
      },
      genders: ["Male", "Female"],
      relationships: ["Self", "Spouse", "Dependent"],
      memberIdRules: [
        (v) => !!v || "This Field is required",
        (v) => (v && v.length == 9) || "Member ID should contain 9 digits",
      ],
      requiredRules: [(v) => !!v || "This Field is required"],
      invoicesPart: true,
      membersPart: false,
      requestDialog: false,
      item: {},
      tab: "invoice",
      billingPeriod: "",
      uploadDialog: false,
      arrayLength: false,
      emailDialog: false,
      memberDialog: false,
      invoiceNo: "",
      startDate: "",
      endDate: "",
      clients: [],
      client: "",
      planId: "",
      total: "",
      valid: false,
      uploadedFile: null,
      pdfEvent: null,
      dataComponents: {
        pdf: {
          name: "",
        },
      },
      rules: [
        (v) =>
          (v && v.length > 4) ||
          "The invoice should contain more than 4 digits",
      ],
    };
  },
  components: { Header, successModal },
  computed: {
    ...mapGetters(["getSingleItem"]),
    contact() {
      let contact = {};
      if (localStorage.getItem("user")) {
        contact = JSON.parse(localStorage.getItem("user"));
      }
      return contact;
    },
  },
  mounted() {
    this.createPdfUrl();
  },
  created() {
    // this.item = this.$store.state.Invoicing.singleSoulution;
    this.getClients();
    this.getInfo();
    this.getInvoice();
  },
  methods: {
    base64ToBlob(base64, type) {
      const binary = atob(base64);
      const binaryLength = binary.length;
      const bytes = new Uint8Array(binaryLength);
      for (let i = 0; i < binaryLength; i++) {
        bytes[i] = binary.charCodeAt(i);
      }
      return new Blob([bytes], { type: type });
    },
    createPdfUrl() {
      const pdfBlob = this.base64ToBlob( this.attachment, 'application/pdf');
      this.pdfUrl = URL.createObjectURL(pdfBlob);
    },
    addNewMember() {
      this.newMembers.push(this.member);
      this.member = {
        id: "",
        firstName: "",
        lastName: "",
        gender: "",
        dob: "",
        relationship: "",
        service: "",
        amount: "",
      };
    },
    removeMember(index) {
      this.newMembers.splice(index, 1);
    },
    invoiceTab() {
      this.invoicesPart = true;
      this.membersPart = false;
    },
    memberTab() {
      this.invoicesPart = false;
      this.membersPart = true;
    },
    Preview_pdf(event) {
      this.pdfEvent = event;
    },
    updateModal() {
      this.$router.push("/vendors/invoices");
    },
    getClients() {
      api()
        .get(`/vendors/clients`)
        .catch((err) => {
          this.load = false;
        })
        .then((res) => {
          if (res && res.data) {
            this.clients = res.data;
          }
        });
    },
    getInvoice() {
      api()
        .get(`/vendors/invoice/` + this.$route.params.id)
        .catch((err) => {
          console.log(err);
        })
        .then((res) => {
          if (res && res.data) {
            this.invoice = res.data;
            this.invoice.status =
              res.data.status.charAt(0).toUpperCase() +
              res.data.status.slice(1);
            this.invoice.date = res.data.date.substring(0,10);
            console.log('date',this.invoice.date)
            this.attachment = res.data.attachment;
            this.invoice.client = res.data.employer;
          }
        });
    },
    uploadInvoice() {
      this.uploadDialog = true;
    },
    generatePdf() {
      // html2pdf(document.getElementById('generate-pdf'));
      var element = document.getElementById("generate-pdf");
      html2pdf()
        .from(element)
        .set({
          margin: [2, 2],
          filename: "invoice-details.pdf",
          html2canvas: { dpi: 195, letterRendering: true },
        })
        .save();
  
    },
    downloadExcel() {
      const data = XLSX.utils.json_to_sheet(this.jsondata);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, "data");
      XLSX.writeFile(wb, "providerList.xlsx");
    },
    addItemExcel() {
      this.$refs.fileInput.click();
    },
    getInfo() {
      api()
        .get(`/vendors`)
        .then((res) => {
          if (res) {
            this.user = res.data;
          }
        });
    },
    uploadExcel(e) {
      this.uploadDialog = false;
      this.excelError = false;
      const files = e.target.files;

      if (!files.length) {
        return;
      } else if (!/\.(pdf)$/.test(files[0].name.toLowerCase())) {
        return alert(
          "The upload format is incorrect. Please upload pdf format"
        );
      }
      let originalFile = e.target.files[0];
      let reader = new FileReader();
      reader.readAsDataURL(originalFile);

      reader.onload = (ev) => {
        let json = JSON.stringify({ dataURL: reader.result });

        // View the file
        let fileURL = JSON.parse(json).dataURL;
        document.getElementById("display-pdf").innerHTML = "";
        document.getElementById("display-pdf").append(`<object data="${fileURL}"
        type="application/pdf" width="400px" height="200px">
      </object>`);
        // View the original file
        let originalFileURL = URL.createObjectURL(originalFile);
        this.uploadedFile = files;
        //  this.pdfs.push({})
        //     document
        //       .getElementById("display-pdf")
        //       .append(
        //         `<object data="${originalFileURL}"
        //   type="application/pdf" width="400px" height="200px">
        // </object>`
        //       )
        //       .onload(() => {
        //         URL.revokeObjectURL(originalFileURL);
        //       });
      };

      //   fileReader.readAsBinaryString(files[0]);
    },
  },
};
</script>
  <style scoped>
  
.submit-btn {
  color: white;
  width: 150px;
}
.display {
  display: grid;
}
.border-top--2 {
  border-top: 2px solid black;
}
@media screen and (max-width: 768px) {
  .justify-logo {
    justify-content: flex-start !important;
  }
  .mobile-font {
    font-size: 13px;
  }
  .apaly-pic {
    height: 40px;
    width: 85px;
  }
}
</style>
