<template>
  <div>
    <div class="row-height">
      <Header
        :title="'Admin Fee Payments'"
        :searchInput="true"
        :exportButton="true"
        @export="exportFun"
        @searchInput="searchInput"
        :placeholder="'Search Income'"
      ></Header>
      <div class="mt-3">
        <v-simple-table fixed-header id="tblData" height="70vh" class="no-padding tr-custom-height">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">SOLUTION</th>
                <th class="text-left">CLIENT</th>
                <th class="text-left">PAYMENT ID </th>
                <th class="text-left">REFERENCE INVOICE </th>
                <th class="text-left">DESCRIPTION </th>
                <th class="text-left">TPA FEE APPLIED</th>
                <th class="text-left">DATE</th>
                <th class="text-left">AMOUNT</th>
              </tr>
            </thead>
            <tbody v-if="items.length > 0" class="text-gray tr-custom-height">
              <tr v-for="(item, index) in items" :key="index">
                <td>
                  <a>{{ item.solution }}</a>
                </td>
                <td>
                  <a>{{ item.client }}</a>
                </td>
                <td>
                  {{ item.paymentId }}
                </td>
                <td>
                  {{ item.invoiceNo }}
                </td>
                <td>
                  <a>{{ item.description }}</a>
                </td>
                <td>
                  <a>{{ item.tpaFee }}%</a>
                </td>
                <td>
                  {{ item.date | moment('DD/MM/YYYY') }}
                </td>
                <td>
                  {{ item.amount }}
                </td>
              </tr>
            </tbody>
            <tbody v-else-if="filterItems">
              <v-col md="12" cols="12">
                <v-alert border="bottom" colored-border type="warning" elevation="2" class="text-gray">
                  Sorry! No results found
                </v-alert>
              </v-col>
            </tbody>
            <tbody v-else>
              <v-col md="12" cols="12">
                <v-alert border="bottom" colored-border type="primary" elevation="2" class="text-gray">
                  Payments will be displayed here soon.
                </v-alert>
              </v-col>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </div>
    <router-view></router-view>
    
  </div>
</template>
<script>
import Header from "../Layout/HeaderPage.vue";
import api from "../../../../services/api";

export default {
  data() {
    return {
      showDetails: false,
      dialog: false,
      items: [],
      singleItem: null,
      typeItems: [{ text: "Categories", value: "Categories" }],
      sortItems: [{ text: "Newest", value: "Newest" }],
      sortBy: "",
      type: "",
      solutions: [],
      favorites: [],
      filterItems: false,
      filteredArray: [],
    };
  },
  components: {
    Header,
  },
  computed: {
    isChildren() {
      let bool = false;
      if (this.$route.meta && this.$route.meta.isChildren) {
        bool = true;
      }
      return bool;
    },
  },
  methods: {
    exportData(tableID, filename = "") {
      let elt = document.getElementById(tableID);
      let downloadLink;
      let dataType = "application/vnd.ms-excel";
      let tableSelect = elt;
      let tableHTML = tableSelect.outerHTML.replace(/ /g, "%20");

      filename = filename ? filename + ".xls" : "excel_data.xls";
      downloadLink = document.createElement("a");
      document.body.appendChild(downloadLink);

      if (navigator.msSaveOrOpenBlob) {
        let blob = new Blob(["\ufeff", tableHTML], {
          type: dataType,
        });
        navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        downloadLink.href = "data:" + dataType + ", " + tableHTML;
        downloadLink.download = filename;
        downloadLink.click();
      }
    },
    exportFun() {
      this.exportData("tblData");
    },
    getInvoiceAmount(item){
      let amount = 0;
      item.items.forEach(function(i){
        amount += i.amount
      })
      return '$ '+item.amount.toFixed(2)
    },

    searchInput(val) {
      this.items = this.filteredArray.filter((el) => {
        return el.solution?.toLowerCase().includes(val.toLowerCase()) ||
               el.client?.toLowerCase().includes(val.toLowerCase());
      });
      this.filterItems = (this.items.length < 1)
    },
  },
  created() {
    api().get("/tpas/income")
      .then((res) => {
        if (res) {
          this.items = res.data;
          this.filteredArray = res.data;
        }
      });
  },
};
</script>
<style>
</style>