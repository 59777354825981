<template>
    <div class="col-md-12">
        <div class="col-md-12 gray-border-form">
          <div class="form-row-height col-md-12">
            <div class="row">
              <v-form
                class="col-md-12 row"
                ref="form"
                v-model.trim="valid"
                lazy-validation
              >
                <div class="col-lg-12 col-md-12 row d-flex" cols="12">
                  <div  class="text-gray font mt-6 col-xl-6 col-lg-6 col-md-12" >
                    <label>
                    <span class="text-underline">New Vendor Invoice Notification</span>
                    <br /><br />
                    <span>Whenever a new invoice is received for one of the TPA’s clients, a notification can be sent to notify the TPA representative of the new invoice.</span>
                  </label>
                  </div>

                  <div
                    class="
                      col-lg-6 col-xl-6 col-md-12 col-md-12
                      mt-10
                      no-padding
                    "
                  >
                    <label class="text-gray font ml-3" for=""
                      >Enter email(s) where notifications should be sent (seperate
                      with semi-colon):</label
                    >
                    <v-row justify="space-around">
                      <v-col cols="12">
                        <v-text-field
                          class="ml-3"
                          style="width: 85%; float: left"
                          :rules="email ? emailRules : []"
                          solo
                        >
                          <template v-slot:append>
                            <v-btn icon @click="pushToTags"
                              ><v-icon>mdi-plus</v-icon></v-btn
                            >
                          </template>
                        </v-text-field>

                        <v-chip-group
                          mandatory
                          class="primary--text"
                          style="height: 43px"
                        >
                          <v-chip
                            v-for="(tag, index) in tags"
                            :key="index"
                            class="primary--text v-chip--active ml-2"
                          >
                            {{ tag }}
                            <i
                              class="fa fa-times fa-2xs ml-1"
                              @click="removeFromTags(index)"
                            ></i>
                          </v-chip>
                        </v-chip-group>
                      </v-col>
                    </v-row>
                  </div>
                </div>
              </v-form>
            </div>
            <br />
            <v-form
              class="col-md-6 no-padding mt-5"
              ref="form1"
              v-model.trim="valid1"
              v-if="$role != 'user'"
            >
              <label class="text-gray font text-underline" for=""
                >Email Domains Associated with this Account</label
              >
              <br /><br />
              <label class="text-gray font" for=""
                >Enter email domain here, and click Add</label
              >
              <div class="row mt-5 col-md-11">
                <v-text-field
                  @keyup="fillDomainRules"
                  :rules="newEmail ? domainRules : []"
                  v-model.trim="newEmail"
                  solo
                ></v-text-field>
                <v-btn large class="addButton mb-4 blue-buttons" @click="pushToArray"
                  >Add</v-btn
                >
              </div>
            </v-form>
            <div
              class="col-md-6 no-padding mb-10"
              :class="emails.length > 8 ? 'y-scroll' : ''"
              style="overflow-x: hidden"
            >
              <label for="" class="text-gray font"
                >Email domain white list:</label
              >
              <div class="col-md-12">
                <v-card
                  v-for="(email, index) in emails"
                  :key="index"
                  style="padding: 1rem"
                  class="mt-2 mb-2"
                >
                  <span v-if="email.domain">{{ email.domain }}</span>
                  <span v-else>{{ email }}</span>
                  <span class="float-right" v-if="index != 0">
                    <svg
                      class="pointer"
                      @click="removeFromArray(email)"
                      width="28"
                      height="26"
                      viewBox="0 0 28 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.35547 13C1.35547 16.1826 2.65524 19.2348 4.96884 21.4853C7.28244 23.7357 10.4204 25 13.6923 25C16.9642 25 20.1021 23.7357 22.4157 21.4853C24.7293 19.2348 26.0291 16.1826 26.0291 13C26.0291 9.8174 24.7293 6.76516 22.4157 4.51472C20.1021 2.26428 16.9642 1 13.6923 1C10.4204 1 7.28244 2.26428 4.96884 4.51472C2.65524 6.76516 1.35547 9.8174 1.35547 13V13Z"
                        stroke="#D00000"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M8.75781 17.7992L18.6273 8.19922"
                        stroke="#D00000"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M18.6273 17.7992L8.75781 8.19922"
                        stroke="#D00000"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                </v-card>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="col-md-12 mb-4" align="end">
            <span
              class="
                light-blue-text
                text-underline
                pointer
                font-weight-bold
                mr-10
                font-18
              "
              @click="$router.go(-1)"
              >Cancel</span
            >
            <span
              class="
                light-blue-text
                text-underline
                pointer
                font-weight-bold font-18
              "
              @click="update"
              >Save</span
            >
          </div><v-snackbar v-model.trim="snackbar" :timeout="1000" color="red">
        {{ snackbarText }}
        <template v-slot:action="{ attrs }">
          <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
        </div>     <successModal
        ref="success_modal"
        v-on:confirm="closeSuccessModal"
      ></successModal>
      <errorModal ref="error_modal" v-on:confirm="closeErrorModal"></errorModal>
      </div>



  </template>
    <script>
  import api from "../../../../services/api";
  import successModal from "../../../TablesTemplate/SuccessDialog.vue";
  import errorModal from "../../../TablesTemplate/ErrorDialog.vue";

  export default {
    components: { successModal, errorModal },
    data() {
      return {
        snackbar: false,
        snackbarText: "",
        tags: "",
        valid1: false,
        newEmail: "",
      emails: [],
        companyName: "",
        taxId: "",
        website: "",
        logo: "",
        phone: "",
        title: "",
        firstName: "",
        lastName: "",
        email: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        zip: "",
        website: "",
        displayName: "",
        companyLogo: {
          name: "",
        },
        phoneRules: [
          (v) =>
            /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(v) ||
            "Phone number must be in a valid format (XXX XXX XXXX)",
        ],
        states: [],
        discountRate: "",
        valid: false,
        zipRules: [
          (v) => (v && v.length == 5) || " Zip Code should be five digits long",
        ],
        requiredRules: [(v) => !!v || "This Field is required"],
        imageEvent: null,
        emailRules: [
          (v) => !!v || "This Field is required",
          (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || "E-mail must be valid",
        ],
        logoChange: false,
        profileChange: false,
        imageProfleEvent: null,
        dataComponents: {
          image: {
            name: "",
          },
          profileImage: {
            name: "",
          },
        },
        taxIdRules: [
          (v) => !!v || "This Field is required",
          (v) =>
            !!/[0-9]{2}\-[0-9]{7}/.test(v) ||
            "Tax ID should contain 9 digits, Please enter your Tax ID in XX-XXXXXXX format",
        ],
      };
    },
    computed: {
      singleEmp() {
        let provider = "";
        if (localStorage.getItem("employer")) {
          provider = JSON.parse(localStorage.getItem("employer"));
        }

        return provider;
      },
    },
    created() {
      this.getStates();
      this.getInfo();
    },
    methods: {
      generatePdf() {
        window.open("https://api.apaly.net/terms");
      },
      showSlash(event) {
        if (this.taxId.length == 2 && event.keyCode != 8) {
          this.taxId += "-";
        }
      },
      activateFileInput() {
        this.$refs.fileInput.$refs.input.click();
      },
      getStates() {
        api()
          .get("/api/states")
          .then((res) => {
            res.data.forEach((el) => {
              if (el.active) {
                this.states.push(el);
              }
            });
          });
      },
      getInfo() {
        api()
          .get(`/vendors`)
          .then((res) => {
            if (res) {
              this.companyName = res.data.companyName ? res.data.companyName : "";
              this.displayName = res.data.displayName ? res.data.displayName : "";

              this.taxId = res.data.taxId ? res.data.taxId : "";
              this.address1 = res.data.address1 ? res.data.address1 : "";
              this.address2 = res.data.address2 ? res.data.address2 : "";
              this.website = res.data.website ? res.data.website : "";
              this.city = res.data.city ? res.data.city : "";
              this.state = res.data.state ? res.data.state : "";
              this.zip = res.data.zipCode ? res.data.zipCode : "";
              this.firstName = res.data.primaryContact.firstName
                ? res.data.primaryContact.firstName
                : "";
              this.lastName = res.data.primaryContact.lastName
                ? res.data.primaryContact.lastName
                : "";
              this.title = res.data.primaryContact.title
                ? res.data.primaryContact.title
                : "";
              this.phone = res.data.primaryContact.phone
                ? res.data.primaryContact.phone
                : "";
              this.email = res.data.primaryContact.email
                ? res.data.primaryContact.email
                : "";

              this.dataComponents.image.name = res.data.logo ? res.data.logo : "";
              this.dataComponents.profileImage.name = res.data.primaryContact
                .profile
                ? res.data.primaryContact.profile
                : "";
            }
          });
      },
      closeSuccessModal() {
        this.$refs.success_modal.closePopUp();
      },
      closeErrorModal() {
      this.$refs.error_modal.closePopUp();
    },
      update() {
        this.$refs.form.validate();

        if (this.valid) {
          let data = {
            companyName: this.companyName,
            providerName: this.companyName,
            displayName: this.displayName,
            address1: this.address1,
            address2: this.address2,
            website: this.website,
            city: this.city,
            state: this.state,
            zipCode: this.zip,
            taxId: this.taxId,
            primaryContact: {
              firstName: this.firstName,
              lastName: this.lastName,
              title: this.title,
              phone: this.phone,
              email: this.email,
            },
          };

          let user = JSON.parse(localStorage.getItem("user"));
          let provider = user.entity._id;
          let formData = new FormData();
          formData.append("_method", "PUT");
          formData.append("image", this.imageEvent);

          api()
            .put(`/vendors`, data)
            .catch((err) => {
            if (err) {
                this.$refs.error_modal.openPopUp(err.response);

            }
          })
            .then((res) => {
              if (res) {
                if (this.profileChange) {
                  if (this.imageProfleEvent) {
                    let formData = new FormData();
                    formData.append("_method", "PUT");
                    formData.append("image", this.imageProfleEvent);
                    api()
                      .put(`/vendors/profile`, formData)
                      .then((res) => {
                        document.getElementById("profile-img").click();
                      });
                  } else {
                    api()
                      .delete(`/vendors/profile`)
                      .then((res) => {
                        document.getElementById("profile-img").click();
                      });
                  }
                }
                if (this.logoChange) {
                  if (this.imageEvent) {
                    let formData = new FormData();
                    formData.append("_method", "PUT");
                    formData.append("image", this.imageEvent);
                    api()
                      .put(`/vendors/logo`, formData)
                      .then((res) => {
                        document.getElementById("logo-img").click();
                      });
                  } else {
                    api()
                      .delete(`/vendors/logo`)
                      .then((res) => {
                        document.getElementById("logo-img").click();
                      });
                  }
                }

                this.$refs.success_modal.openPopUp(
                  "Account updated successfully"
                );
                this.logoChange = false;
                this.profileChange = false;
              }
            });
        }
      },
      pushToTags() {
      if (this.email != null && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.email)) {
        let index = this.tags.findIndex((c) => c == this.email);
        if (index == -1) {
          this.tags.push(this.email);
          this.email = "";
        } else {
          this.snackbarText = "Email already exists";
          this.snackbar = true;
        }
      } else {
        this.snackbarText = "Email is not valid";
        this.snackbar = true;
      }
    },
    removeFromArray(email) {
      this.myAddArray.push(email);
      for (let i = 0; i < this.emails.length; i++) {
        if (this.emails[i] == email) {
          this.emails.splice(i, 1);
        }
      }
      this.$refs.success_modal.openPopUp("Domain was successfully deleted!");
    },
    pushToArray() {
      this.$refs.form1.validate();
      let data = {
        domain: this.newEmail,
      };
      if (this.valid1) {
        api()
          .post("/auth/validateEmail", data)
          .catch((err) => {
            if (err) {
              this.domainRules = ["Only company email domains are allowed"];
            }
          })
          .then((res) => {
            if (res) {
              api()
                .get("/auth/isValidDomain?domain=" + this.newEmail)
                .catch((err) => {
                  if (err) {
                    this.domainRules = [err.response.data.message];
                    exist = true;
                  }
                })
                .then((res) => {
                  let regex =
                    /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/;
                  let check = regex.test(this.newEmail);
                  if (this.newEmail && this.valid && check) {
                    let regex =
                      /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/;
                    let check = regex.test(this.newEmail);
                    if (this.newEmail && this.valid1 && check) {
                      this.emails.push({ domain: this.newEmail });
                      this.myAddArray.push({ domain: this.newEmail });
                      this.$refs.success_modal.openPopUp(
                        "Domain added successfully"
                      );
                      this.$refs.form.resetValidation();
                      this.newEmail = "";
                    }
                  }
                });
            }
          });
      }
    },
    fillDomainRules() {
      this.domainRules = [
        (v) => !!v || "This Field is required",
        (v) =>
          /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/.test(v) ||
          "Email domain is not valid!",
      ];
    },
    removeFromTags(index) {
      this.tags.splice(index, 1);
    },
      activateProfileInput() {
        this.$refs.profileInput.$refs.input.click();
      },
      Preview_image(event) {
        this.logoChange = true;
        this.imageEvent = event;
      },
      Preview_profileImage(event) {
        this.profileChange = true;
        this.imageProfleEvent = event;
      },
    },
  };
  </script>
  <style scoped>
  .submit {
      width: 130px;
      color: white;
    }
    .cancel {
      width: 130px;
    }
  @media screen and (max-width: 768px) {
    .addButton{
      margin-left: 100px;
    }
  }
  </style>
