<template>
  <div class="row row-height">
    <!-- <v-overlay v-if="loader">
            <v-progress-circular indeterminate :size="100" color="#326ab3" class="text-center mt-10 mb-5">
                <img
                  src="/newapalylogoonlyblue.png"
                  height="70"
                  class="img-mobile"
                />
            </v-progress-circular>

        </v-overlay> -->
    <!-- Fullscreen Loader -->
    <FullscreenLoader v-if="loader" />

    <v-sheet v-else-if="!loader && !isChildren" class="overflow-hidden col-md-12 border-r-8" style="position: relative">
      <div>
        <v-row align="center" justify="center">
          <div :class=" overlay ? 'hideOnMoblie background-opacity col-md-9' : 'col-md-12 navigationWidth no-padding'">
            <v-row class="blue-underline mb-1">
              <v-col class="col-xl-2 col-md-2 cols-4 pt-2 pb-0" cols="4">
                <div class="col-md-12">
                  <h3 class="form-title font col-md-12 font-18">Rate Groups</h3>
                </div>
              </v-col>
              <v-col class="col-xl-3 col-md-6 cols-8" cols="8">
                <div class="mt-4 d-flex no-padding" style="width: 80%">
                  <v-text-field
                    :disabled="overlay"
                    class="input-control mr-5"
                    prepend-inner-icon="mdi-magnify"
                    @input="search"
                    light dense outlined
                    placeholder="Search Rates"
                  ></v-text-field>
                </div>
              </v-col>
              <v-col
                class="col-xl-7 pr-6 pt-6 col-md-4 cols-12 text-end" cols="12" align="end">
                <v-btn class="my-1 mr-4 gray-border-buttons"
                  @click="exportData('tblData')" >
                <img class="pointer mr-1"  src="/svg/export.svg"/>
                  Export</v-btn>
                <v-btn class="my-1 gray-border-buttons" @click="dialog = true">
                  <img class="pointer mr-1"  src="/svg/add.svg"/>
                  New</v-btn>
              </v-col>
            </v-row>
            <div v-if="loader">
              <v-progress-linear
              class="text-center justify-center mt-0 "
              color="primary"
              indeterminate
              rounded
              height="6"
            ></v-progress-linear>
          </div>
              <v-row class="col-md-12" v-if="allRates.length < 1">
      <v-col md="12">
        No rates to show
      </v-col>
    </v-row>
      <v-data-table v-else
            :headers="tableHeaders" 
            :items="allRates"
            :search="searchValue"
            :hide-default-header="false"
            :mobile-breakpoint="0"
            
            class="elevation-1 pa-2"
          >
            <template v-slot:body="{ items }" >
              <tr v-for="item in items" :key="item.id" class="pa-1">
               <td  class="pa-1 font-14">
                        <a @click="singleClient(item)"
                          >{{ item.group }} 
                        </a>
                      </td>
                      <td  class="pa-1 text-gray font-14">
                        {{ item.baseRate ? item.baseRate : '/' }}
                      </td>
                      <td class="pa-1 text-gray font-14">
                        <span v-if="item.carveOut && item.carveOut.length > 0">
                          <span v-for="(i, index) in item.carveOut" :key="index">
                            {{ i.name ? i.name : i.group ? i.group : "/" }}
                            <span v-if="index != item.carveOut.length - 1"
                              >,</span>
                          </span>
                        </span>
                        <span v-else>/</span>
                      </td>
                      <td class="pa-1 text-gray text-center font-14">
                        {{item.carveOut.length}}                
                            </td>
                   
              </tr>
            </template>
          </v-data-table>
          </div>
        </v-row>
        <v-simple-table id="tblData" style="display: none">
                <thead>
                  <tr>
                    <th class="text-left">Rate group name</th>
                    <th class="text-left">Base rate</th>
                    <th class="text-left">Carve-outs</th>
                    <th class="text-left">Items</th>
                  </tr>
                </thead>
                <tbody id="remove-hover">
                  <tr
                    v-for="(item, index) in allRates"
                    :key="index"
                    class="text-gray"
                  >
                  <td  class="pa-1 font-14">
                        <a @click="singleClient(item)"
                          >{{ item.group }} 
                        </a>
                      </td>
                      <td  class="pa-1 text-gray font-14">
                        {{ item.baseRate ? item.baseRate : '/' }}
                      </td>
                      <td class="pa-1 text-gray font-14">
                        <span v-if="item.carveOut && item.carveOut.length > 0">
                          <span v-for="(i, index) in item.carveOut" :key="index">
                            {{ i.name ? i.name : i.group ? i.group : "/" }}
                            <span v-if="index != item.carveOut.length - 1"
                              >,</span>
                          </span>
                        </span>
                        <span v-else>/</span>
                      </td>
                      <td class="pa-1 text-gray text-center font-14">
                        {{item.carveOut.length}}                
                            </td>
                   
                  </tr>
                </tbody>
              </v-simple-table>
      </div>
      <v-navigation-drawer
        class="userFilterPosition"
        width="350"
        v-model.trim="drawer"
        absolute temporary>
        <div class="filter-sideBar">
          <div>
            <div class="row no-padding col-md-12 justify-space-between">
              <h3 class="font pl-3 mt-1">Clients Filters</h3>
              <h1 class="pointer mr-3" @click.stop="drawer = !drawer">x</h1>
            </div>
            <v-divider></v-divider>
            <div class="col-md-12">
              <div class="row col-md-12 justify-space-between no-padding no-margin">
                <div class="no-padding font filter-items-font font-weight-bold">
                  Selected Filter
                </div>
                <div class="underline-text pointer text-gray filter-items-font" @click="clearAll" >
                  Clear All
                </div>
              </div>
            </div>
            <div class="overflow-fitlers-buttons">
              <div class="row mb-1 col-md-12">
                <v-btn  v-for="(item, index) in selectedArray.concat(selectedTypeArray)" :key="index"
                  class="text-center mb-1 ml-2 mr-2 filter-btn" small>
                  <span v-html="item.text"></span>
                  <h5 class="ml-3" @click="removeFilter(item)">x</h5>
                </v-btn>
              </div>
              <v-divider></v-divider>
              <v-expansion-panels  accordion multiple
                v-model.trim="panel"
                class="no-shadow no-padding user-expansion-panels">
                <v-expansion-panel class="no-shadow no-padding">
                  <v-expansion-panel-header  class="font-weight-black  change-padding-filter filter-items-font">
                    Status</v-expansion-panel-header>
                  <v-expansion-panel-content class="no-padding content-filter">
                    <v-text-field
                      solo
                      class="filter-serach-input mb-4"
                      v-model.trim="status"
                      @input="searchStatus()"
                      dense
                      :hide-details="true"
                      placeholder="Search"
                      append-icon="fa fa-search"
                    ></v-text-field>
                    <v-checkbox
                      label="All"
                      color="primary"
                      v-if="showStatus.all"
                      v-model.trim="filter.status.all"
                      class="no-padding small-text radio-buttons-filters"
                      hide-details
                      :on-icon="'mdi-square'"
                      @click="pushFilter({ text: 'All', value: 'all' },filter.status.all)">
                    </v-checkbox>
                    <v-checkbox
                      label="Join Request"
                      color="primary"
                      v-if="showStatus['join request']"
                      v-model.trim="filter.status.join"
                      class="no-padding radio-buttons-filters"
                      hide-details
                      :on-icon="'mdi-square'"
                      @click="pushFilter({ text: 'Join Request', value: 'join' },filter.status.join)">
                    </v-checkbox>
                    <v-checkbox
                      label="Active"
                      color="primary"
                      v-if="showStatus.active"
                      :on-icon="'mdi-square'"
                      v-model.trim="filter.status.active"
                      class="no-padding radio-buttons-filters"
                      hide-details
                      @click="pushFilter({ text: 'Active', value: 'active' },filter.status.active)">
                    </v-checkbox>
                    <v-checkbox
                      label="In Active"
                      color="primary"
                      v-if="showStatus['in active']"
                      v-model.trim="filter.status.inactive"
                      :on-icon="'mdi-square'"
                      class="no-padding radio-buttons-filters"
                      hide-details
                      @click="pushFilter({ text: 'In Active', value: 'inactive' }, filter.status.inactive)">
                    </v-checkbox>
                    <v-checkbox
                      label="Invited"
                      color="primary"
                      v-if="showStatus.invited"
                      :on-icon="'mdi-square'"
                      v-model.trim="filter.status.invited"
                      class="no-padding radio-buttons-filters mb-2"
                      hide-details
                      @click="pushFilter({ text: 'Invited', value: 'invited' },filter.status.invited)">
                    </v-checkbox>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel class="no-shadow no-padding">
                  <v-expansion-panel-header
                    class="font-weight-black change-padding-filter filter-items-font mb-0 pb-0">Type</v-expansion-panel-header>
                  <v-expansion-panel-content class="no-padding content-filter">
                    <v-text-field
                      solo
                      class="filter-serach-input no-padding mb-4"
                      v-model.trim="type"
                      dense
                      :hide-details="true"
                      @input="searchType()"
                      placeholder="Search"
                      append-icon="fa fa-search"
                    ></v-text-field>
                    <v-checkbox
                      label="All"
                      color="primary"
                      v-if="showType.all"
                      :on-icon="'mdi-square'"
                      v-model.trim="filter.type.all"
                      class="no-padding radio-buttons-filters"
                      hide-details
                      @click="pushtoTypeFilter({ text: 'All', value: 'allTypes' },filter.type.all)">
                    </v-checkbox>
                    <v-checkbox
                      label="Admins"
                      color="primary"
                      v-if="showType.admin"
                      v-model.trim="filter.type.admin"
                      :on-icon="'mdi-square'"
                      class="no-padding radio-buttons-filters"
                      hide-details
                      @click="pushtoTypeFilter({ text: 'Admins', value: 'admin' },filter.type.admin)">
                    </v-checkbox>
                    <v-checkbox
                      label="General User"
                      color="primary"
                      :on-icon="'mdi-square'"
                      v-if="showType['general user']"
                      v-model.trim="filter.type.user"
                      class="no-padding radio-buttons-filters"
                      hide-details
                      @click="pushtoTypeFilter({ text: 'General User', value: 'user' },filter.type.user)">
                    </v-checkbox>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
          </div>
          <div>
            <v-divider></v-divider>
            <v-btn class="blue-buttons col-md-12 mt-3" @click="filterUsers">
              View Result</v-btn>
          </div>
        </div>
      </v-navigation-drawer>
    </v-sheet>
    <router-view></router-view>
    <v-dialog v-model.trim="dialog" max-width="490">
      <v-card>
        <v-card-title class="text-h6"> Add New Rate </v-card-title> <br />
        <v-card-text>
          <div>
            <v-text-field
              label="Rate Group Name"
              v-model="group">
            </v-text-field>
          </div>
          <div>
            <v-text-field
              label="Base Rate"
              type="number"
              v-model="baseRate"
            ></v-text-field>
          </div>
          <div class="rates_notes">
            NOTE: Apaly and solution facilitator fees apply and will be deducted from your PMPM. You can view detailed fee information when you enroll in a solution. 
          </div>
        </v-card-text>
        <v-card-actions style="padding-bottom: 2rem !important" class="mt-2">
          <v-spacer></v-spacer>
          <v-btn class="blue-buttons" block text @click="addNewRate">
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <errorModal 
    ref="error_modal"
     v-on:confirm="closeErrorModal">
    </errorModal>
  </div>

</template>

<script>
import api from "../../../../../services/api";
import errorModal from "../../../../TablesTemplate/ErrorDialog.vue";
import FullscreenLoader from "../../../../Common/FullscreenLoader.vue";

export default {
  components: { errorModal, FullscreenLoader },
  data() {
    return {
      searchValue: '',
      pagination: {
      rowsPerPage: 30
    },
      tableHeaders: [
        {text: 'RATE GROUP NAME ', value:'locationName', align: 'start', sortable: true},
        {text: 'BASE RATE', value:'address', align: 'start'},
        {text: 'CARVE-OUTS', value:'city', align: 'start'},
        {text: 'ITEMS', value:'state', align: 'center'},
      ],
      loader: false,
      items: [],
      group: "",
      allRates: [
        {
          companyName: "General Rate Group",
          noEmployees: "$60 PMPM*",
          providerCoverage: 2,
          noPlanMembers: 18,
        },
      ],
      response: [],
      resultsReady: true,
      drawer: null,
      showStatus: {
        all: true,
        active: true,
        "in active": true,
        invited: true,
        "join request": true,
      },
      showType: {
        all: true,
        "general user": true,
        admin: true,
      },
      dialog: false,
      type: "",
      status: "",
      panel: [0, 1],
      filterItems: false,
      overlay: false,
      selectedArray: [],
      selectedTypeArray: [],
      name: "",
      baseRate: "",
      filter: {
        status: {
          all: false,
          active: false,
          inactive: false,
          invited: false,
          join: false,
        },
        type: {
          all: false,
          user: false,
          admin: false,
        },
      },
    };
  },
  created() {
    this.getData();
  },
  computed: {
    isChildren() {
      let bool = false;
      if (this.$route.meta && this.$route.meta.isChildren) {
        bool = true;
      }
      return bool;
    },
    filteredRates() {
 return this.allRates.filter(item => {
   let search = this.searchValue.toLowerCase()
   let match = false
   if(item.group)match = match || item.group.toLowerCase().includes(search)

   return match
 })
},
  },
  methods: {
    exportData(tableID, filename = "") {
      var elt = document.getElementById(tableID);
      var downloadLink;
      var dataType = "application/vnd.ms-excel";
      var tableSelect = elt;
      var tableHTML = tableSelect.outerHTML.replace(/ /g, "%20");
      filename = filename ? filename + ".xls" : "excel_data.xls";
      downloadLink = document.createElement("a");
      document.body.appendChild(downloadLink);
      if (navigator.msSaveOrOpenBlob) {
        var blob = new Blob(["\ufeff", tableHTML], {
          type: dataType,
        });
        navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        downloadLink.href = "data:" + dataType + ", " + tableHTML;
        downloadLink.download = filename;
        downloadLink.click();
      }
    },
    addNewRate() {
      let data = {
        group: this.group,
        baseRate: this.baseRate,
        carveOut: [],
      };
      api()
        .post("/dpcproviders/rates", data)
        .catch((err) => {
                  if (err.response.status == 500) {
                    this.$refs.error_modal.openPopUp("There was an error, system admin is notified. Please try again later.");}
                   else{
                    this.$refs.error_modal.openPopUp(err.response.data)
                   }
                })
        .then((res) => {
          if (res) {
            this.getData();
            this.group= "";
            this.baseRate = "";
            this.dialog = false;
          }
        });
    },
    getLocationLength(locations) {
      let num = 0;
      if (locations) {
        locations.forEach((el) => {
          num += Number(el.locations.length);
        });
      }
      return num;
    },
    getData() {
      this.loader = true;
      api()
        .get("/dpcproviders/rates")
        .then((res) => {
          if (res) {
            this.loader = false;
            this.allRates = res.data;
            this.filteredArray = this.allRates;
          }
        })
        .finally(() => {
                this.loader = false
              });
    },
    pushtoTypeFilter(item, checked) {
      if (checked) {
        if (item.value == "allTypes") {
          this.selectedTypeArray = [];
          this.filter.type = {
            all: true,
            user: true,
            admin: true,
          };
          this.selectedTypeArray.push({ text: "All", value: "allTypes" });
        } else {
          this.selectedTypeArray.push(item);
        }
      } else {
        if (item.value == "allTypes" || this.filter.type.all) {
          this.filter.type = {
            all: false,
            user: false,
            admin: false,
          };
          this.selectedTypeArray = [];
        } else {
          this.selectedTypeArray.forEach((el, index) => {
            if (el.value == item.value) {
              this.selectedTypeArray.splice(index, 1);
            }
          });
        }
      }
    },
    pushFilter(item, checked) {
      if (checked) {
        if (item.value == "all") {
          this.selectedArray = [];
          this.filter.status = {
            all: true,
            active: true,
            inactive: true,
            invited: true,
            join: true,
          };
          this.selectedArray.push({ text: "All", value: "all" });
        } else {
          this.selectedArray.push(item);
        }
      } else {
        if (item.value == "all" || this.filter.status.all) {
          this.filter.status = {
            all: false,
            active: false,
            inactive: false,
            invited: false,
            join: false,
          };
          this.selectedArray = [];
        } else {
          this.selectedArray.forEach((el, index) => {
            if (el.value == item.value) {
              this.selectedArray.splice(index, 1);
            }
          });
        }
      }
    },
    removeFilter(item) {
      if (item.value == "allTypes") {
        this.selectedTypeArray = [];
        this.filter.type = {
          all: false,
          user: false,
          admin: false,
        };
      } else if (this.filter.status[item.value]) {
        this.filter.status[item.value] = false;
        this.selectedArray.splice(this.selectedArray.indexOf(item), 1);
      }

      if (item.value == "all") {
        this.selectedArray = [];
        this.filter.status = {
          all: false,
          active: false,
          inactive: false,
          invited: false,
          join: false,
        };
      } else if (this.filter.type[item.value]) {
        this.filter.type[item.value] = false;
        this.selectedTypeArray.splice(this.selectedTypeArray.indexOf(item), 1);
      }
    },
    searchStatus() {
      for (let key in this.showStatus) {
        if (key.toLowerCase().includes(this.status.toLowerCase())) {
          this.showStatus[key] = true;
        } else {
          this.showStatus[key] = false;
        }
      }
    },
    searchType() {
      for (let key in this.showType) {
        if (key.toLowerCase().includes(this.type.toLowerCase())) {
          this.showType[key] = true;
        } else {
          this.showType[key] = false;
        }
      }
    },
    filterUsers() {
      if (!this.filter.status && !this.filter.type) {
        this.getAllInvoices("all");
      } else {
        this.getAllInvoices(this.filter.status);
      }
      this.filterUse = true;
      this.drawer = false;
    },
    clearAll() {
      this.filter.status = {
        all: false,
        active: false,
        inactive: false,
        invited: false,
        join: false,
      };
      this.filter.type = {
        all: false,
        user: false,
        admin: false,
      };

      this.selectedArray = [];
      this.selectedTypeArray = [];
      this.getAllInvoices("all");
    },
    search(val) {
      this.allRates = this.filteredArray.filter((el) => {
        if (el.group && el.group.toLowerCase().includes(val.toLowerCase())) {
          return el.group.toLowerCase().includes(val.toLowerCase());
        }
      });
      if (this.allRates.length < 1) {
        this.filterItems = true;
      }
    },
    singleClient(item) {
      //this.$store.commit("setSingleItem", item);
      this.$router.push("/dpc-providers/rates/rate/"+item.id);
    },
    closeErrorModal() {
      this.$refs.error_modal.closePopUp();
    },
  
  },
};
</script>
<style></style>
