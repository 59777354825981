<template>
  <v-row class="add-client-form no-padding">
    <div class="col-md-12 form-box">
      <div class="row">
        <div class="col-md-12">
          <div class="col-md-12 row justify-space-between">
            <h3 class="form-title font">Partner Detail</h3>
          </div>
        </div>
      </div>

      <form class="row form-row-height">
        <div class="col-md-6 no-padding">
          <div class="col-md-12 pb-0">
            <label class="font text-gray" for="">First Name</label>
            <v-text-field
              required
              solo
              v-model.trim="firstName"
              disabled
            ></v-text-field>
          </div>
          <div class="col-md-12 pb-0 pt-0">
            <label class="font text-gray" for="">Last Name</label>
            <v-text-field
              required
              v-model.trim="lastName"
              disabled
              solo
            ></v-text-field>
          </div>
          <div class="col-md-12 pb-0 pt-0">
            <label class="font text-gray" for="">Email</label>
            <v-text-field
              required
              v-model.trim="email"
              disabled
              :rules="emailRules"
              solo
            ></v-text-field>
          </div>
          <div class="col-md-12 pb-0 pt-0">
            <label class="font text-gray" for="">Phone</label>
            <v-text-field
              required
              v-model.trim="phone"
              disabled
              solo
              :rules="phoneRules"
            ></v-text-field>
          </div>
        </div>
        <div class="col-md-6 no-padding">
          <div class="col-md-12 pb-0">
            <label class="font text-gray" for="">Company Name</label>
            <v-text-field
              required
              solo
              v-model.trim="companyName"
              disabled
            ></v-text-field>
          </div>
          <div class="col-md-12 pb-0 pt-0">
            <label class="font text-gray" for="">Title</label>
            <v-text-field
              required
              v-model.trim="title"
              disabled
              solo
            ></v-text-field>
          </div>
          <v-row class="no-padding">
            <div class="col-md-6 pb-0 pt-0">
              <label class="font text-gray" for="">City</label>
              <v-text-field
                required
                v-model.trim="city"
                solo
                disabled
              ></v-text-field>
            </div>
            <div class="col-md-3 pb-0 pt-0">
              <label class="font text-gray" for="">State</label>
              <v-select
                required
                disabled
                solo
                :items="states"
                item-text="name"
                item-value="name"
                v-model.trim="state"
              ></v-select>
            </div>
            <div class="col-md-3 pb-0 pt-0">
              <label class="font text-gray" for="">Zip</label>
              <v-text-field
                required
                solo
                disabled
                type="number"
                v-model.trim="zip"
              ></v-text-field>
            </div>
          </v-row>
          <div class="col-md-12 pb-0 pt-0">
            <label class="font text-gray" for="">Type</label>
            <v-text-field
              required
              solo
              v-model.trim="type"
              disabled
            ></v-text-field>
          </div>
        </div>
      </form>
      <div class="col-md-12 d-flex align-end flex-column">
        <div>
          <v-btn tabindex="16" class="blue-buttons mr-6 mb-2" @click="goBack()">
            Close
          </v-btn>
          <v-btn
            v-if="showDeleteBtn"
            tabindex="16"
            class="blue-buttons mb-2"
            @click="removePartner"
          >
            Remove Partner
          </v-btn>
        </div>
      </div>
    </div>
    <removeDialog
      ref="dialog_delete"
      v-on:confirm="confirmRemove"
    ></removeDialog>
  </v-row>
</template>
<script>
import api from "../../../../services/api";
import removeDialog from "../../../TablesTemplate/RemoveDialog.vue";

export default {
  components: { removeDialog },
  data() {
    return {
      show1: false,
      dialog: false,
      dialog1: false,
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      companyName: "",
      title: "",
      city: "",
      state: "",
      zip: "",
      states: [],
      type: "",
      itemToRemove: null,
      emailRules: [
        (v) => !!v || "This Field is required",
        (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || "E-mail must be valid",
      ],
      phoneRules: [
        (v) =>
          /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(v) ||
          "Phone number must be in a valid format (XXX XXX XXXX)",
      ],
    };
  },
  computed: {
    singlePartner() {
      return this.$store.getters.getSinglePartnerInEmployerAdvisor;
    },
    singleClient() {
      return this.$store.getters.getClientGetter;
    },
    routeParams() {
      return this.$route.params.name;
    },
    showDeleteBtn() {
      return this.$store.getters.showDeletePartnerButton;
    },
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    getStates() {
      api()
        .get("/api/states")
        .then((res) => {
          res.data.forEach((el) => {
            if (el.active) {
              this.states.push(el);
            }
          });
        });
    },
    confirmRemove() {
      let user = JSON.parse(localStorage.getItem("user"));
      let advisorId = user.groupPortal[0].organization.employerAdvisor;

      api()
        .delete(`/advisors/${advisorId}/partners/${this.singlePartner._id}`)
        .then((res) => {
          if (res) {
            api()
              .get(`/advisors/${advisorId}/partners`)
              .then((res) => {
                if (res) {
                  this.$store.commit("setAllPartners", res.data.partners);
                  this.$refs.dialog_delete.closePopUp();
                  this.$router.push("/employer-advisor/partners");
                }
              });
          }
        });
    },
    removePartner() {
      let text = "Are you sure you want to delete this Partner?";
      this.$refs.dialog_delete.openPopUp(text);
    },
  },

  created() {
    this.getStates();
    if (this.singlePartner) {
      this.firstName = this.singlePartner.primaryContact.firstName;
      this.lastName = this.singlePartner.primaryContact.lastName;
      this.companyName = this.singlePartner.companyName;
      this.email = this.singlePartner.primaryContact.email;
      this.phone = this.singlePartner.primaryContact.phone;
      this.title = this.singlePartner.title;
      this.city = this.singlePartner.city;
      this.state = this.singlePartner.state;
      this.zip = this.singlePartner.zipCode;
      this.type =
        this.singlePartner.advisorType == "brokerConsultant"
          ? "Broker Consultant"
          : this.singlePartner.advisorType == "analyticsConsultant"
          ? "Analytics Consultant"
          : this.singlePartner.advisorType;
    }
  },
};
</script>
