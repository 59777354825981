<template>
  <v-row class="row">
    <successModal
      ref="success_modal"
      v-on:confirm="closeSuccessModal"
    ></successModal>
    <errorModal 
    ref="error_modal"
     v-on:confirm="closeErrorModal">
    </errorModal>
    <div class="col-md-12">
      
      <v-form v-model.trim="valid" ref="form" class="row form-row-height">
        <div class="col-md-6">
          <div class="col-md-12 pb-0">
            <label class="font text-gray" for="">Bank Name</label>
            <v-text-field
              required
              :rules="requiredRules"
              v-model.trim="name"
              solo
            ></v-text-field>
          </div>
          <div class="col-md-12 pb-0 pt-0">
            <label class="font text-gray" for="">Inbound Routing Number</label>
            <v-text-field required solo v-model.trim="roundNo"></v-text-field>
          </div>
          <div class="col-md-12 pb-0 pt-0">
            <label class="font text-gray" for="">Inbound Account Number</label>
            <v-text-field required solo v-model.trim="accountNo"></v-text-field>
          </div>
          <!-- <div class="col-md-12 pb-0 pt-0">
            <label class="font text-gray" for="">Bank Branch</label>
            <v-text-field
              required
              :rules="requiredRules"
              v-model.trim="branch"
              solo
            ></v-text-field>
          </div>
          <div class="col-md-12 pb-0 pt-0">
            <label class="font text-gray" for=""> Address Line 1</label>
            <v-text-field
              required
              v-model.trim="address1"
              :rules="requiredRules"
              solo
            ></v-text-field>
          </div>
          <div class="col-md-12 pb-0 pt-0">
            <label class="font text-gray" for=""> Address Line 2</label>
            <v-text-field required v-model.trim="address2" solo></v-text-field>
          </div>
          <v-row class="no-padding">
            <div class="col-md-6 pb-0 pt-0">
              <label class="font text-gray" for="">City</label>
              <v-text-field
                required
                v-model.trim="city"
                solo
                :rules="requiredRules"
              ></v-text-field>
            </div>
            <div class="col-md-3 pb-0 pt-0">
              <label class="font text-gray" for="">State</label>
              <v-select
                :items="states"
                item-text="name"
                item-value="name"
                required
                v-model.trim="state"
                solo
                :rules="requiredRules"
              ></v-select>
            </div>
            <div class="col-md-3 pb-0 pt-0">
              <label class="font text-gray" for="">Zip</label>
              <v-text-field
                required
                type="number"
                solo
                v-model.trim="zip"
                :rules="zipRules"
              ></v-text-field>
            </div>
          </v-row>
          <div class="col-md-12 pb-0 pt-0">
            <label class="font text-gray" for="">
              Branch Manager First Name
            </label>
            <v-text-field
              required
              v-model="firstName"
              :rules="requiredRules"
              solo
            ></v-text-field>
          </div>
          <div class="col-md-12 pb-0 pt-0">
            <label class="font text-gray" for="">
              Branch Manager Last Name
            </label>
            <v-text-field
              required
              v-model.trim="lastName"
              :rules="requiredRules"
              solo
            ></v-text-field>
          </div> -->
        </div>
        <div class="col-md-6">
          <!-- <div class="col-md-12 pb-0">
            <label class="font text-gray" for="">Recipient Legal Name </label>
            <v-text-field
              required
              solo
              v-model.trim="recipient"
              :rules="requiredRules"
            ></v-text-field>
          </div>
          <div class="col-md-12 pb-0 pt-0">
            <label class="font text-gray" for="">EIN</label>
            <v-text-field
              required
              solo
              v-model.trim="taxId"
              :rules="requiredRules"
            ></v-text-field>
          </div>
          <div class="col-md-12 pb-0 pt-0">
            <label class="font text-gray" for="">Address 1</label>
            <v-text-field
              required
              solo
              v-model.trim="recAddress1"
              :rules="requiredRules"
            ></v-text-field>
          </div>
          <div class="col-md-12 pb-0 pt-0">
            <label class="font text-gray" for="">Address 2</label>
            <v-text-field
              required
              solo
              v-model.trim="recAddress2"
            ></v-text-field>
          </div>
          <v-row class="no-padding">
            <div class="col-md-6 pb-0 pt-0">
              <label class="font text-gray" for="">City</label>
              <v-text-field
                required
                solo
                v-model.trim="recCity"
                :rules="requiredRules"
              ></v-text-field>
            </div>
            <div class="col-md-3 pb-0 pt-0">
              <label class="font text-gray" for="">State</label>
              <v-select
                :items="states"
                item-text="name"
                item-value="name"
                required
                v-model.trim="recState"
                solo
                :rules="requiredRules"
              ></v-select>
            </div>
            <div class="col-md-3 pb-0 pt-0">
              <label class="font text-gray" for="">Zip</label>
              <v-text-field
                required
                type="number"
                solo
                v-model.trim="recZipCode"
                :rules="zipRules"
              ></v-text-field>
            </div>
          </v-row>
           -->
        </div>
      </v-form>
      <div
        class="row text-end justify-end "
        style="margin-left: -11px"
      >
        <div class="col-md-12 mt-6 mb-6 ">
          <span
            class="text-underline text-gray col-md-12 mr-5 pointer"
            @click="update"
            >Save</span
          >
        </div>
      </div>
    </div>
  </v-row>
</template>
<script>
import api from "../../../../../services/api";
import successModal from "../../../../TablesTemplate/SuccessDialog.vue";
import errorModal from "../../../../TablesTemplate/ErrorDialog.vue";

export default {
  components: { successModal, errorModal },
  data() {
    return {
      name: "",
      branch: "",
      taxId: "",
      address1: "",
      address2: "",
      city: "",
      state: { name: "Any", value: "any" },
      zip: "",
      firstName: "",
      lastName: "",
      recipient: "",
      recAddress1: "",
      recAddress2: "",
      recCity: "",
      recState: "",
      recZipCode: "",
      roundNo: "",
      accountNo: "",
      phoneRules: [
        (v) =>
          /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(v) ||
          "Phone number must be in a valid format (XXX XXX XXXX)",
      ],
      states: [],
      discountRate: "",
      valid: false,
      zipRules: [
        (v) => (v && v.length == 5) || " Zip Code should be five digits long",
      ],
      requiredRules: [(v) => !!v || "This Field is required"],
      emailRules: [
        (v) => !!v || "This Field is required",
        (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || "E-mail must be valid",
      ],

      taxIdRules: [
        (v) => !!v || "This Field is required",
        (v) =>
          !!/[0-9]{2}\-[0-9]{7}/.test(v) ||
          "Tax ID should contain 9 digits, Please enter your Tax ID in XX-XXXXXXX format",
      ],
    };
  },
  computed: {
    singleEmp() {
      let provider = "";
      if (localStorage.getItem("employer")) {
        provider = JSON.parse(localStorage.getItem("employer"));
      }

      return provider;
    },
  },
  created() {
    this.getStates();
    this.getInfo();
  },
  methods: {
    closeErrorModal() {
      this.$refs.error_modal.closePopUp();
    },
    closeSuccessModal() {
      this.$refs.success_modal.closePopUp();
    },
    generatePdf() {
      window.open("https://api.apaly.net/terms");
    },
    showSlash(event) {
      if (this.taxId.length == 2 && event.keyCode != 8) {
        this.taxId += "-";
      }
    },
    activateFileInput() {
      this.$refs.fileInput.$refs.input.click();
    },
    getStates() {
      api()
        .get("/api/states")
        .then((res) => {
          res.data.forEach((el) => {
            if (el.active) {
              this.states.push(el);
            }
          });
        });
    },

    getInfo() {
      api()
        .get(`/dpcproviders/bank`)
        .then((res) => {
          if (res) {
         
            if (res.data) {
              let bank = res.data;
              this.name = bank.name? bank.name : '';
              // this.branch =  bank.name? bank.name : '';
              // this.address1 = bank.bankAddress1 ? bank.bankAddress1 : '';
              // this.address2 = bank.bankAddress2 ? bank.bankAddress2 : '';
              // this.city = bank.bankCity ? bank.bankCity : '';
              // this.state = bank.bankState ? bank.bankState : {};
              // this.zip = bank.bankZipCode ? bank.bankZipCode : '';
              // this.firstName = bank.firstName ? bank.firstName : '';
              // this.lastName = bank.lastName ? bank.lastName : '';
              // this.recipient = bank.recipient ? bank.recipient : '';
              // this.taxId = bank.taxId ? bank.taxId : '';
              // this.recAddress1 = bank.recAddress1 ? bank.recAddress1 : '';
              // this.recAddress2 = bank.recAddress2 ? bank.recAddress2 : '';
              // this.recCity = bank.recCity ? bank.recCity : '';
              // this.recState = bank.recState ? bank.recState : '';
              // this.recZipCode = bank.recZipCode ? bank.recZipCode : '';
              this.roundNo = bank.roundNo ? bank.roundNo : '';
              this.accountNo = bank.accountNo ? bank.accountNo : '';
            }
          }
        });
    },
    closeSuccessModal() {
      this.$refs.success_modal.closePopUp();
    },

    update() {
      this.$refs.form.validate();
      if (this.valid) {
        let data = {
          name: this.name,
          // branch: this.branch,
          // bankAddress1: this.address1,
          // bankAddress2: this.address2,
          // bankCity: this.city,
          // bankState: this.state,
          // bankZipCode: this.zip,
          // firstName: this.firstName,
          // lastName: this.lastName,
          // recipient: this.recipient,
          // taxId: this.taxId,
          // recAddress1: this.recAddress1,
          // recAddress2: this.recAddress2,
          // recCity: this.recCity,
          // recState: this.recState,
          // recZipCode: this.recZipCode,
          roundNo: this.roundNo,
          accountNo: this.accountNo,
        };

        api()
          .put(`/dpcproviders/bank`, data)
          .catch((err) => {
                  if (err.response.status == 500) {
                    this.$refs.error_modal.openPopUp(
                      "There was an error, system admin is notified. Please try again later."
                    );}
                   else{
                    this.$refs.error_modal.openPopUp(err.response.data)
                   }
                  
                })
          .then((res) => {
            if (res) {
              this.$refs.success_modal.openPopUp(
                "Account updated successfully"
              );
            }
          });
      }
    },
    activateProfileInput() {
      this.$refs.profileInput.$refs.input.click();
    },
    Preview_image(event) {
      this.logoChange = true;
      this.imageEvent = event;
    },
    Preview_profileImage(event) {
      this.profileChange = true;
      this.imageProfleEvent = event;
    },
  },
};
</script>