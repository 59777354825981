<template>
  <v-row class="add-client-form no-padding">
    <successModal
      ref="success_modal"
      v-on:confirm="closeSuccessModal"
    ></successModal>
    <errorModal 
    ref="error_modal"
     v-on:confirm="closeErrorModal">
    </errorModal>
    <div class="col-md-12">
      <div class="row blue-underline mt-2 margin-l-11">
        <div class="col-md-12">
          <h3 class="form-title font ml-2 mb-2 font-18">New Client</h3>
        </div>
      </div>
      <v-form v-model.trim="valid" ref="form" class="row form-row-height">
        <div class="col-md-6">
          <div class="col-md-12 pb-0">
            <label class="font" for="">Company Name</label>
            <v-text-field
              required
              :rules="requiredRules"
              v-model.trim="client.companyName"
              solo
            ></v-text-field>
          </div>

          <div class="col-md-12 pb-0 pt-0">
            <label class="font text-gray" for="">Tax ID</label>
            <v-text-field
              required
              v-model.trim="client.taxId"
              @keyup="showSlash($event)"
              solo
              placeholder="00-0000000"
              :rules="taxIdRules"
              counter="10"
              maxlength="10"
            ></v-text-field>
          </div>
          <div class="col-md-12 pb-0 pt-0">
            <label class="font text-gray" for="">Number of Employees</label>
            <v-text-field
              required
              v-model.trim="client.employeesNo"
              solo
            ></v-text-field>
          </div>
          <div class="col-md-12 pb-0 pt-0">
            <label class="font text-gray" for="">Number of Plan Members</label>
            <v-text-field
              required
              v-model.trim="client.membersNo"
              solo
            ></v-text-field>
          </div>
          <div class="col-md-12 pb-0 pt-0">
            <label class="font text-gray" for="">HQ Address Line 1</label>
            <v-text-field
              required
              v-model.trim="client.address1"
              :rules="requiredRules"
              solo
            ></v-text-field>
          </div>
          <div class="col-md-12 pb-0 pt-0">
            <label class="font text-gray" for="">HQ Address Line 2</label>
            <v-text-field
              required
              v-model.trim="client.address2"
              solo
            ></v-text-field>
          </div>
          <v-row class="no-padding">
            <div class="col-md-6 pb-0 pt-0">
              <label class="font text-gray" for="">City</label>
              <v-text-field
                required
                v-model.trim="client.city"
                solo
                :rules="requiredRules"
              ></v-text-field>
            </div>
            <div class="col-md-3 pb-0 pt-0">
              <label class="font text-gray" for="">State</label>
              <v-select
                :items="states"
                item-text="name"
                item-value="name"
                required
                v-model.trim="client.state"
                solo
                :rules="requiredRules"
              ></v-select>
            </div>
            <div class="col-md-3 pb-0 pt-0">
              <label class="font text-gray" for="">Zip</label>
              <v-text-field
                required
                type="number"
                v-model.trim="client.zip"
                solo
                :rules="zipRules"
              ></v-text-field>
            </div>
          </v-row>
        </div>
        <div class="col-md-6">
          <div class="col-md-12 pb-0">
            <label class="font text-gray" for=""
              >Primary Contact First Name
            </label>
            <v-text-field
              required
              v-model.trim="client.firstName"
              solo
              :rules="requiredRules"
            ></v-text-field>
          </div>
          <div class="col-md-12 pb-0 pt-0">
            <label class="font text-gray" for=""
              >Primary Contact Last Name</label
            >
            <v-text-field
              required
              v-model.trim="client.lastName"
              solo
              :rules="requiredRules"
            ></v-text-field>
          </div>
          <div class="col-md-12 pb-0 pt-0">
            <label class="font text-gray" for="">Primary Contact Title</label>
            <v-text-field
              required
              v-model.trim="client.title"
              solo
              :rules="requiredRules"
            ></v-text-field>
          </div>
          <div class="col-md-12 pb-0 pt-0">
            <label class="font text-gray" for="">Primary Contact Email</label>
            <v-text-field
              required
              :rules="emailRules"
              v-model.trim="client.email"
              solo
            ></v-text-field>
          </div>
          <div class="col-md-12 pb-0 pt-0">
            <label class="font text-gray" for="">Primary Contact Phone</label>
            <v-text-field
              required
              v-model.trim="client.phone"
              solo
              :rules="phoneRules"
            ></v-text-field>
          </div>
        </div>
      </v-form>
      <div class="row gray-underline-wizard margin-l-11">
        <div class="col-md-12 row text-end justify-end mb-5 mt-2">
          <span
            class="text-underline pointer mr-10"
            style="color:#0572F3;"
            @click="$router.go(-1)"
            >Cancel</span
          >
          <span class="text-underline pointer mr-2"
          style="color:#0572F3;"
           @click="update"
            >Save & Invite</span
          >
        </div>
      </div>
    </div>
  </v-row>
</template>
<script>
import api from "../../../../../services/api";
import successModal from "../../../../TablesTemplate/SuccessDialog.vue";
import errorModal from "../../../../TablesTemplate/ErrorDialog.vue";
export default {
  components: { successModal, errorModal },
  data() {
    return {
      client: {
        companyName: "",
        taxId: "",
        logo: "",
        phone: "",
        title: "",
        firstName: "",
        lastName: "",
        email: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        zip: "",
        displayName: "",
        membersNo: "",
        employeesNo: "",
      },
      taxId: "",
      phoneRules: [
        (v) =>
          /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(v) ||
          "Phone number must be in a valid format (XXX XXX XXXX)",
      ],
      states: [],
      discountRate: "",
      valid: false,
      zipRules: [
        (v) => (v && v.length == 5) || " Zip Code should be five digits long",
      ],
      requiredRules: [(v) => !!v || "This Field is required"],
      emailRules: [
        (v) => !!v || "This Field is required",
        (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || "E-mail must be valid",
      ],
      taxIdRules: [
        (v) => !!v || "This Field is required",
        (v) =>
          !!/[0-9]{2}\-[0-9]{7}/.test(v) ||
          "Tax ID should contain 9 digits, Please enter your Tax ID in XX-XXXXXXX format",
      ],
    };
  },
  computed: {
    singleMember() {
      return this.$store.getters.getSingleMember;
    },
  },
  created() {
    this.getStates();
    // this.getInfo();
    // this.member = this.singleMember
  },
  methods: {
    generatePdf() {
      window.open("https://api.apaly.net/terms");
    },
    showSlash(event) {
      if (this.client.taxId.length == 2 && event.keyCode != 8) {
        this.client.taxId += "-";
      }
      if (this.client.taxId.length == 10) {
        api()
          .get("auth/isValidTaxId?taxId=" + this.client.taxId)
          .catch((res) => {
            if (res) {
              this.taxIdRules = [res.response.data.message];
            }
          });
      } else {
        this.taxIdRules = [
          (v) => !!v || "This Field is required",
          (v) =>
            !!/[0-9]{2}\-[0-9]{7}/.test(v) ||
            "Tax ID should contain 9 digits, Please enter your Tax ID in XX-XXXXXXX format",
        ];
      }
    },
    activateFileInput() {
      this.$refs.fileInput.$refs.input.click();
    },
    getStates() {
      api()
        .get("/api/states")
        .catch((err) => {
                  if (err.response.status == 500) {
                    this.$refs.error_modal.openPopUp(
                      "There was an error, system admin is notified. Please try again later."
                    );}
                   else{
                    this.$refs.error_modal.openPopUp(err.response.data)
                   }
                  
                })
        .then((res) => {
          res.data.forEach((el) => {
            if (el.active) {
              this.states.push(el);
            }
          });
        });
    },

    closeSuccessModal() {
      
      this.$refs.success_modal.closePopUp();
      this.$router.push("/dpc-providers/clients");
    },
    
 closeErrorModal() {
      this.$refs.error_modal.closePopUp();
    },

    update() {
      this.$refs.form.validate();
      if (this.valid) {
        api()
          .post("/dpcproviders/clients", this.client)
           .catch((err) => {
                  if (err.response.status == 500) {
                    this.$refs.error_modal.openPopUp(
                      "There was an error, system admin is notified. Please try again later."
                    );}
                   else{
                    this.$refs.error_modal.openPopUp(err.response.data)
                   }
                  
                })
          .then((res) => {
            if (res) {
              this.$refs.success_modal.openPopUp("Employer invited to Apaly Portal!");
            }
          });
      }
    },
  },
};
</script>
