<template>
  <div class="white-background border-radius">
    <div v-if="!isChildren">
      <div class="row border-blue-bottom">
        <div class="col-md-4 row">
          <div
            class="col-md-4 pt-5 text-center pointer font-weight-bold"
            @click="getAllSolutions('solution')"
            :class="tab == 'solution' ? 'light-blue-text' : 'text-gray'"
          >
            Solutions
          </div>
          <v-divider vertical class="dividers-margin"></v-divider>
          <div
            class="col-md-4 pt-5 text-center pointer font-weight-bold"
            @click="getAllSolutions('favorites')"
            :class="tab == 'favorites' ? 'light-blue-text' : 'text-gray'"
          >
            Favorites
          </div>
          <v-divider vertical class="dividers-margin"></v-divider>
        </div>
        <div class="col-md-2">
          <v-text-field
            class="input-control mr-5"
            prepend-inner-icon="mdi-magnify"
            @input="searchMembers"
            light
            dense
            outlined
            placeholder="Search Solutions"
          ></v-text-field>
        </div>
        <div class="col-md-4 row">
          <v-col class="d-flex aling-items-center col-md-6" cols="12">
            Type:
            <v-select
              :items="typeItems"
              solo
              dense
              v-model="type"
              hide-details
              class="ml-3"
            ></v-select>
          </v-col>

          <v-col class="d-flex aling-items-center col-md-6" cols="12">
            Sort By:
            <div>
              <v-select
                :items="sortItems"
                solo
                dense
                v-model="sortBy"
                hide-details
                class="ml-3"
              ></v-select>
            </div>
          </v-col>
        </div>
        <div class="col-md-2" align="end">
          <v-btn class="my-1 gray-border-buttons" @click="dialog = true">
            <svg
              class="mr-2"
              width="15"
              height="16"
              viewBox="0 0 15 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.2361 10.7639C14.0329 10.7639 13.838 10.8434 13.6937 10.985C13.5495 11.1267 13.4676 11.319 13.4659 11.5202V12.6764C13.4659 12.9367 13.3615 13.1862 13.1756 13.3703C12.9897 13.5543 12.7376 13.6577 12.4747 13.6577H2.52525C2.26238 13.6577 2.01027 13.5543 1.8244 13.3703C1.63852 13.1862 1.53409 12.9367 1.53409 12.6764V11.5202C1.51671 11.3307 1.42838 11.1544 1.28645 11.0261C1.14452 10.8978 0.959257 10.8266 0.767046 10.8266C0.574834 10.8266 0.389569 10.8978 0.247642 11.0261C0.105715 11.1544 0.0173841 11.3307 0 11.5202V12.6764C0 13.3395 0.266053 13.9753 0.739629 14.4442C1.21321 14.913 1.85551 15.1764 2.52525 15.1764H12.4747C13.1445 15.1764 13.7868 14.913 14.2604 14.4442C14.7339 13.9753 15 13.3395 15 12.6764V11.5202C15 11.3196 14.9195 11.1272 14.7763 10.9854C14.633 10.8436 14.4387 10.7639 14.2361 10.7639Z"
                fill="#707070"
              />
              <path
                d="M7.49971 0.176392C7.17488 0.176392 6.86336 0.304137 6.63368 0.531525C6.404 0.758913 6.27496 1.06732 6.27496 1.38889V7.05139C6.27496 7.09283 6.25833 7.13257 6.22873 7.16188C6.19913 7.19118 6.15899 7.20764 6.11713 7.20764H4.43784C4.32437 7.21533 4.2151 7.25322 4.12158 7.31731C4.02807 7.3814 3.95378 7.46931 3.90657 7.57175C3.85935 7.6742 3.84097 7.78736 3.85337 7.89929C3.86576 8.01122 3.90847 8.11776 3.97698 8.20764L7.03885 11.6951C7.0979 11.7576 7.16927 11.8074 7.24855 11.8414C7.32782 11.8754 7.4133 11.893 7.49971 11.893C7.58611 11.893 7.6716 11.8754 7.75087 11.8414C7.83014 11.8074 7.90151 11.7576 7.96057 11.6951L11.0224 8.20764C11.0909 8.11776 11.1337 8.01122 11.146 7.89929C11.1584 7.78736 11.1401 7.6742 11.0928 7.57175C11.0456 7.46931 10.9713 7.3814 10.8778 7.31731C10.7843 7.25322 10.675 7.21533 10.5616 7.20764H8.88228C8.84042 7.20764 8.80028 7.19118 8.77068 7.16188C8.74108 7.13257 8.72445 7.09283 8.72445 7.05139V1.38889C8.72445 1.06732 8.59542 0.758913 8.36573 0.531525C8.13605 0.304137 7.82453 0.176392 7.49971 0.176392V0.176392Z"
                fill="#707070"
              />
            </svg>

            Invite
          </v-btn>
        </div>
      </div>
      <div class="mt-3">
        <v-simple-table
          fixed-header
          height="830"
          class="no-padding tr-custom-height"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">SOLUTION / PACKAGE NAME</th>
                <th class="text-left">CATEGORY</th>
                <th class="text-left">PLANS FOR SOLUTION</th>
                <th class="text-left">SELECT</th>
              </tr>
            </thead>

            <tbody v-if="items.length > 0" class="text-gray tr-custom-height">
              <tr v-for="(item, index) in items" :key="index">
                <td v-if="item.vendor" @click="showSetails(item)">
                  <a v-if="item.vendor">{{ item.vendor.companyName }}</a>
                </td>
                <td v-else><a>/</a></td>
                <td v-html="item.category ? item.category : '/'"></td>
                <td>
                  <v-select
                   style="width: 300px !important"
                    solo
                    hide-details
                    item-value="_id"
                    item-text="name"
                    multiple
                    v-model="item.plans"
                    :items="plans"
                  ></v-select>
                </td>

                <td>
                  <div
                    class="pointer blue-checkbox"
                    :id="'selected-' + index"
                    @click="selectItem(item, index)"
                  ></div>
                </td>
              </tr>
            </tbody>

            <tbody v-else>
              <v-col md="12"> There are no solutions currenlty. </v-col>
            </tbody>
          </template>
        </v-simple-table>

        <v-dialog v-model="dialog" persistent max-width="800px">
          <v-card>
            <v-card-title>
              <span class="text-h5 text-underline col-md-12 font-weight-bolder"
                >Invite a Solution Vendor</span
              >
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-form class="row" ref="form" v-model="valid">
                  <v-col cols="12" sm="12" md="12">
                    <label for="">Company Name</label>
                    <v-text-field
                      hide-details
                      :rules="requiredRules"
                      v-model="companyName"
                      solo
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <label for="">Contact First Name</label>
                    <v-text-field
                      hide-details
                      solo
                      :rules="requiredRules"
                      required
                      v-model="firstName"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <label for="">Contact Last Name</label>
                    <v-text-field
                      hide-details
                      :rules="requiredRules"
                      solo
                      required
                      v-model="lastName"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <label for="">Email</label>
                    <v-text-field
                      hide-details
                      solo
                      :rules="emailRules"
                      required
                      v-model="email"
                    ></v-text-field>
                  </v-col>
                </v-form>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <div class="col-md-12" align="end">
                <span
                  class="text-underline text-gray mr-10 pointer"
                  @click="dialog = false"
                  >Cancel</span
                >
                <span
                  class="text-underline text-gray pointer"
                  @click="inviteSolutions"
                  >Invite</span
                >
              </div>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <div class="col-md-12">
          <div class="col-md-12 mb-4" align="end">
            <span
              class="
                light-blue-text
                text-underline
                pointer
                font-weight-bold
                mr-10
                font-18
              "
              @click="$emit('closeDialog')"
              >Cancel</span
            >
            <span
              class="
                light-blue-text
                text-underline
                pointer
                font-weight-bold
                mr-5
                font-18
              "
              @click="addSelected"
              >Add Selected</span
            >
          </div>
        </div>
      </div>
      <successModal
        ref="success_modal"
        v-on:confirm="closeSuccessModal"
      ></successModal>

      <errorModal ref="error_modal" v-on:confirm="closeErrorModal"></errorModal>
    </div>
    <router-view></router-view>
  </div>
</template>
<script>
import api from "../../../../../../services/api";
import successModal from "../../../../../TablesTemplate/SuccessDialog.vue";
import errorModal from "../../../../../TablesTemplate/ErrorDialog.vue";
export default {
  components: { successModal, errorModal },
  props: ["plans"],
  data() {
    return {
      showDetails: false,
      tab: "solution",
      dialog: false,
      items: [],
      singleItem: null,
      typeItems: [{ text: "Categories", value: "Categories" }],
      sortItems: [{ text: "Newest", value: "Newest" }],
      sortBy: "",
      type: "",
      solutions: [],
      favorites: [],
      selectedItems: [],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || "E-mail must be valid",
      ],
      requiredRules: [(v) => !!v || "This Field is required"],
      valid: false,
      companyName: "",
      firstName: "",
      lastName: "",
      email: "",
    };
  },
  computed: {
    isChildren() {
      let bool = false;
      if (this.$route.meta && this.$route.meta.isChildren) {
        bool = true;
      }
      return bool;
    },
  },

  methods: {
    closeErrorModal() {
      this.$emit("closeDialog");
      this.dialog = false;
      this.companyName = "";
      this.firstName = "";
      this.lastName = "";
      this.email = "";
      this.$refs.success_modal.closePopUp();
      this.$refs.error_modal.closePopUp();
    },
    closeSuccessModal() {
      setTimeout(() => {
        this.$emit("getSolutions");
        this.dialog = false;
        this.companyName = "";
        this.firstName = "";
        this.lastName = "";
        this.email = "";
        this.$refs.success_modal.closePopUp();
        this.$emit("closeDialog");
      }, "3000");
    },
    selectItem(item, index) {
      let el = document.getElementById("selected-" + index);
      if (el.classList.contains("blue-checkbox")) {
        el.classList.add("fill-blue-checkbox");
        el.classList.remove("blue-checkbox");
        let data = {
          id: item._id,
          plans: item.plans,
        };
        this.selectedItems.push(data);
      } else {
        el.classList.remove("fill-blue-checkbox");
        el.classList.add("blue-checkbox");
        this.selectedItems.splice(this.selectedItems.indexOf(item), 1);
      }
    },
    getAllSolutions(tab) {
      this.tab = tab;

      api()
        .get("/tpas/solutions")
        .then((res) => {
          this.items = [];

          if (this.tab == "favorites") {
            res.data.solutions.forEach((el) => {
              res.data.favorites.forEach((response) => {
                if (el._id == response) {
                  this.items.push(el);
                }
              });
            });
          } else {
            this.items = res.data.solutions;
          }
        });
    },
    addSelected() {
      ///tpas/solutions/favorite

      if (this.selectedItems.length > 0) {
        let data = {
          solutions: this.selectedItems,
        };
        api()
          .post(`/tpas/clients/${this.$route.params.id}/solutions`, data)
          .then((res) => {
            if (res) {
              this.$emit("refreshData");
              this.$refs.success_modal.openPopUp("Added Successfully");
            }
          });
      }
    },
    showSetails(item) {
      this.singleItem = item;
      this.$store.commit("setSingleSolution", item);
      this.$router.push("/digital-invoicing/solutions/details");
    },
    fillArray(item, index) {
      if (!item.rating) {
        this.favorites.push(item);
      } else {
        this.favorites.splice(this.favorites.indexOf(item), 1);
      }
    },
    searchMembers() {},
    inviteSolutions() {
      this.$refs.form.validate();
      if (this.valid) {
        let data = {
          companyName: this.companyName,
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
        };
        api()
          .post("/tpas/solutions/vendor", data)
          .catch((res) => {
            if (res) {
              this.$refs.error_modal.openPopUp(res.response.data);
            }
          })
          .then((res) => {
            if (res) {
              this.$refs.success_modal.openPopUp("Invited Successfully");
            }
            //ratof41600@sopulit.com
          });
      }
    },
  },
  created() {
    this.getAllSolutions();
  },
};
</script>
<style>
.blue-checkbox {
  width: 25px;
  height: 25px;
  border: 2px solid gray;
  border-radius: 3px;
}
.fill-blue-checkbox {
  width: 25px;
  height: 25px;
  border: 2px solid gray;
  border-radius: 3px;
  background: #4072b7;
}
</style>