<template>
    <!-- <v-overlay v-if="loader">
            <v-progress-circular indeterminate :size="100" color="#326ab3" class="text-center mt-10 mb-5">
                <img
                  src="/newapalylogoonlyblue.png"
                  height="70"
                  class="img-mobile"
                />
            </v-progress-circular>

        </v-overlay> -->
  <!-- Fullscreen Loader -->
  <FullscreenLoader v-if="loader" />

  <v-row v-else class="add-client-form no-padding pa-3" :class="showOverlay ? ' postion-relative' : ''">
    <div class="col-md-12 form-row-height">
      <div class="row blue-underline margin-l-11">
        <v-col class="col-xl-4 col-lg-8 col-md-12 cols-12 pb-0 pt-2" cols="12">
          <div class="d-flex col-md-12">
            <h6 class="ml-2 mb-2 mt-2 mr-3">Rate Group Name:</h6>
            <h3 v-if="!editRate" class="form-title font ml-2 mb-2 mobile-desing-rate-title" >
              {{ rate.group }}
             <img class="pointer" @click="editRate = true" src="/svg/editRate.svg"/>
            </h3>
            <h3 v-else class="ml-2 mb-2 mobile-desing-rate-title">
              <div class="row">
                <div class="mt-3">
                  <input type="text" style="width: 100%"  v-model="rate.group" class="input-rates-edit-name"/>
                </div>
                <img class="pointer ml-1 mt-4" @click="editRateFunction()" src="/svg/finishEditRate.svg"/>
               
              </div>
            </h3>
          </div>
        </v-col>
        <v-col class="col-xl-8 col-lg-4 cols-12 col-md-12 pt-3 pb-0 text-lg-end" cols="12">
          <v-btn class="my-1 mr-2 gray-border-buttons"
          @click="exportData('rateDetails')">
          <img class="pointer mr-1"  src="/svg/export.svg"/>
            Export</v-btn>
        </v-col>
      </div>
      <br />
      <div class="row col-md-12">
        <div class="row col-md-12">
          <div class="col-md-4 row ml-1">
            <a class="mt-3">BASE RATE </a>
            <span class="mt-3 ml-1 text-gray"> (required):</span>
            <div class="mt-3 ml-3 d-flex">
              <h3  v-if="!editBaseRate" class="form-title font ml-2 mb-2 mobile-desing-rate-title">
              {{ rate.baseRate }}
              <svg class=pointer @click="editBaseRate = true" width=17 height=17 viewBox="0 0 17 17" fill=none xmlns=http://www.w3.org/2000/svg>
                <path d="M1 16H15.9933" stroke=#707070 stroke-width=1.71429 stroke-linecap=round stroke-linejoin=round />
                <path d="M7.92094 11.9634L4.46094 12.5861L5.03761 9.08002L12.7995 1.34115C12.9068 1.23305 13.0343 1.14725 13.1748 1.0887C13.3154 1.03015 13.4662 1 13.6184 1C13.7707 1 13.9214 1.03015 14.0619 1.0887C14.2024 1.14725 14.3301 1.23305 14.4373 1.34115L15.6598 2.56368C15.7679 2.67091 15.8537 2.79847 15.9122 2.939C15.9708 3.07955 16.0009 3.23029 16.0009 3.38255C16.0009 3.5348 15.9708 3.68555 15.9122 3.82609C15.8537 3.96664 15.7679 4.0942 15.6598 4.20142L7.92094 11.9634Z" stroke=#707070 stroke-width=1.71429 stroke-linecap=round stroke-linejoin=round />
                </svg>
            </h3>
            <h3 v-else class="ml-2 mb-2 mobile-desing-rate-title">
              <div class="row">
                <div class="mt-3">
                  <input  type="text"  v-model="rate.baseRate"  class="input-rates-edit-name" />
                </div>
                <svg class="pointer ml-2 mt-3" @click="editRateFunction()" width=26 height=25 viewBox="0 0 26 25" fill=none xmlns=http://www.w3.org/2000/svg>
                  <path d="M19.079 1H7.07176C4.22971 1 1.92578 3.30254 1.92578 6.14286V18.1429C1.92578 20.9832 4.22971 23.2857 7.07176 23.2857H19.079C21.9211 23.2857 24.225 20.9832 24.225 18.1429V6.14286C24.225 3.30254 21.9211 1 19.079 1Z" stroke=#09951F stroke-width=2 stroke-linecap=round stroke-linejoin=round />
                  <path d="M7.92969 13.8571L11.4461 16.6686C11.5406 16.7463 11.6512 16.802 11.7698 16.8317C11.8885 16.8613 12.0123 16.8642 12.1323 16.84C12.2533 16.8173 12.3681 16.7688 12.4687 16.6978C12.5694 16.6268 12.6535 16.535 12.7155 16.4286L18.2217 7" stroke=#09951F stroke-width=2 stroke-linecap=round stroke-linejoin=round />
                  </svg>
              </div>
            </h3>
              <span class="mt-0 ml-2 text-gray">PMPM*</span>
            </div>
          </div>
        </div>
      </div>
      <div class="row gray-underline-wizard margin-l-11 form-row-small-height">
        <div class="col-md-12 no-padding">
          <v-simple-table fixed-header height="830px">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">CARVE-OUT RATE NAME</th>
                  <th class="text-left">CATEGORY</th>
                  <th class="text-left">LOCATION</th>
                  <th class="text-left">RATE (USD)</th>
                  <th class="text-left">RATE TYPE</th>
                  <th></th>
                </tr>
              </thead>
              <tbody class="text-gray">
                <tr v-for="(item, index) in rate.carveOut" :key="index">
                  <td>
                    <span v-if="editRateInputs && row == index">
                      <v-text-field class="mt-1 mb-1" 
                         hide-details
                        solo
                        v-model="item.name"
                      ></v-text-field>
                    </span>
                    <span v-else>
                      <a>{{ item.name }}</a>
                    </span>
                  </td>
                  <td>
                    <span v-if="editRateInputs && row == index">
                      <v-text-field
                        solo
                        class="mt-1 mb-1"
                        hide-details
                        disabled
                        v-model="item.category"
                      ></v-text-field>
                    </span>
                    <span v-else>
                      <a>{{ item.category }}</a>
                    </span>
                  </td>
                  <td v-if="item.locations.length > 0">
                    {{ item.locations[0] ? item.locations[0].name : ''}}
                    <span v-if="editRateInputs && row == index">
                      <v-select
                        class="mt-1 mb-1"
                        hide-details
                        :items="locations"
                        item-text="name"
                        item-value="id"
                        v-model="item.locations[0]"
                        placeholder="Select Location"
                      ></v-select>
                    </span>
                    <span v-else-if="!item.locations && !editRateInputs">/</span>
                  </td>
             <td v-else>
              /
              <span v-if="editRateInputs && row == index">
                      <v-select
                        class="mt-1 mb-1"
                        hide-details
                        :items="locations"
                        v-model="item.locations"
                        item-text="name"
                        item-value="id"
                        placeholder="Select Location"
                      ></v-select>
                    </span>
             </td>
                  <td>
                    <span v-if="editRateInputs && row == index">
                      <v-text-field
                        solo
                        v-model="item.rate"
                        class="mt-1 mb-1"
                        hide-details
                      ></v-text-field>
                    </span>
                    <span v-else>
                      <a>{{ item.rate }}</a>
                    </span>
                  </td>
                  <td>
                    <span v-if="editRateInputs && row == index">
                      <v-text-field
                        solo
                        v-model="item.type"
                        class="mt-1 mb-1"
                        hide-details
                      ></v-text-field>
                    </span>
                    <span v-else>
                      <a>{{ item.type }}</a>
                    </span>
                  </td>
                  <td v-if="editRateInputs && row == index">
                    <svg class="pointer mr-3" @click="editRow(item, row)" width=26 height=26 viewBox="0 0 26 26" fill=none xmlns=http://www.w3.org/2000/svg>
                      <path d="M8 14.8571L11.5143 17.6686C11.6087 17.7463 11.7192 17.802 11.8378 17.8317C11.9564 17.8613 12.0801 17.8642 12.2 17.84C12.321 17.8173 12.4357 17.7688 12.5363 17.6978C12.6368 17.6268 12.721 17.535 12.7829 17.4286L18.2857 8" stroke=#09951F stroke-width=3 stroke-linecap=round stroke-linejoin=round />
                      <path d="M13.1429 24.2857C19.2969 24.2857 24.2857 19.2969 24.2857 13.1429C24.2857 6.98883 19.2969 2 13.1429 2C6.98883 2 2 6.98883 2 13.1429C2 19.2969 6.98883 24.2857 13.1429 24.2857Z" stroke=#09951F stroke-width=3 stroke-linecap=round stroke-linejoin=round />
                      </svg>
                  </td>
                  <td v-else>
                    <svg @click="editItem(item, index)" class="mr-4 pointer" width=24 height=24 viewBox="0 0 24 24" fill=none xmlns=http://www.w3.org/2000/svg>
                      <path d="M9.79357 17.3147L6.16406 17.8339L6.68228 14.2034L14.0753 6.81038C14.4878 6.3978 15.0474 6.16602 15.6309 6.16602C16.2144 6.16602 16.774 6.3978 17.1866 6.81038C17.5991 7.22297 17.8309 7.78255 17.8309 8.36603C17.8309 8.94951 17.5991 9.50909 17.1866 9.92167L9.79357 17.3147Z" stroke=#4072B7 stroke-width=2 stroke-linecap=round stroke-linejoin=round />
                      <path d="M1 12C1 14.9174 2.15893 17.7153 4.22183 19.7782C6.28473 21.8411 9.08262 23 12 23C14.9174 23 17.7153 21.8411 19.7782 19.7782C21.8411 17.7153 23 14.9174 23 12C23 9.08262 21.8411 6.28473 19.7782 4.22183C17.7153 2.15893 14.9174 1 12 1C9.08262 1 6.28473 2.15893 4.22183 4.22183C2.15893 6.28473 1 9.08262 1 12V12Z" stroke=#4072B7 stroke-width=2 stroke-linecap=round stroke-linejoin=round />
                      </svg>
                      <svg @click="removeThis(item, index)" class=pointer width=25 height=26 viewBox="0 0 25 26" fill=none xmlns=http://www.w3.org/2000/svg>
                        <path d="M16.1095 9.3335L8.6875 16.6668" stroke=#DC0101 stroke-width=2.4 stroke-linecap=round stroke-linejoin=round />
                        <path d="M8.6875 9.3335L16.1095 16.6668" stroke=#DC0101 stroke-width=2.4 stroke-linecap=round stroke-linejoin=round />
                        <path d="M18.5837 2H6.21364C3.48093 2 1.26562 4.18883 1.26562 6.88889V19.1111C1.26562 21.8112 3.48093 24 6.21364 24H18.5837C21.3164 24 23.5317 21.8112 23.5317 19.1111V6.88889C23.5317 4.18883 21.3164 2 18.5837 2Z" stroke=#DC0101 stroke-width=2.4 stroke-linecap=round stroke-linejoin=round />
                        </svg>
                  </td>
                </tr>
                <tr v-if="!addNewRow">
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <svg
                      class="pointer"
                      @click="addNewRow = true"
                      width="26"
                      height="24"
                      viewBox="0 0 26 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.0059 6.97266V16.1714"
                        stroke="#09951F"
                        stroke-width="2.4"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M8.11328 11.5723H17.8967"
                        stroke="#09951F"
                        stroke-width="2.4"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M19.1208 1.22363H6.89164C4.19006 1.22363 2 3.28284 2 5.82299V17.3214C2 19.8616 4.19006 21.9208 6.89164 21.9208H19.1208C21.8223 21.9208 24.0124 19.8616 24.0124 17.3214V5.82299C24.0124 3.28284 21.8223 1.22363 19.1208 1.22363Z"
                        stroke="#09951F"
                        stroke-width="2.4"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </td>
                </tr>
                <tr v-else>
                <td class="pt-2">
                    <v-text-field
                     solo 
                     v-model="newItem.name" 
                      >
                    </v-text-field>
                  </td>
                  <td class="pt-2">
                    <v-text-field
                      solo
                      v-model="newItem.category"
                      disabled
                    ></v-text-field>
                 
                  </td>
                  <td class="pt-2">
                    <v-select
                      :items="locations"
                      solo
                      v-model="newItem.locations"
                      :rules="requiredRules"
                      item-text="name"
                      item-value="id"
                      placeholder="Select Location"
                    ></v-select>
                   
                  </td>
                  <td class="pt-2">
                    <v-text-field
                      solo
                      v-model="newItem.rate"
                      type="number"
                    ></v-text-field>
        
                  </td>
                  <td class="pt-2">
                    <v-text-field
                      solo
                      v-model="newItem.type"
                      disabled
                    ></v-text-field>
                  </td>
                
                  <td>
                    <svg
                      class="pointer mr-3"
                      @click="addNewRate()"
                      width="26"
                      height="26"
                      viewBox="0 0 26 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8 14.8571L11.5143 17.6686C11.6087 17.7463 11.7192 17.802 11.8378 17.8317C11.9564 17.8613 12.0801 17.8642 12.2 17.84C12.321 17.8173 12.4357 17.7688 12.5363 17.6978C12.6368 17.6268 12.721 17.535 12.7829 17.4286L18.2857 8"
                        stroke="#09951F"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M13.1429 24.2857C19.2969 24.2857 24.2857 19.2969 24.2857 13.1429C24.2857 6.98883 19.2969 2 13.1429 2C6.98883 2 2 6.98883 2 13.1429C2 19.2969 6.98883 24.2857 13.1429 24.2857Z"
                        stroke="#09951F"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>

                    <svg
                    @click="cancelNewRate()"
                      class="pointer"
                      width="25"
                      height="26"
                      viewBox="0 0 25 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16.1095 9.3335L8.6875 16.6668"
                        stroke="#DC0101"
                        stroke-width="2.4"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M8.6875 9.3335L16.1095 16.6668"
                        stroke="#DC0101"
                        stroke-width="2.4"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M18.5837 2H6.21364C3.48093 2 1.26562 4.18883 1.26562 6.88889V19.1111C1.26562 21.8112 3.48093 24 6.21364 24H18.5837C21.3164 24 23.5317 21.8112 23.5317 19.1111V6.88889C23.5317 4.18883 21.3164 2 18.5837 2Z"
                        stroke="#DC0101"
                        stroke-width="2.4"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <br/>
                    <span v-if="requiredLocation" class="mt-0 pt-0" style="color: red;">All fields required!</span>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-simple-table fixed-header height="830px" style="display: none;" id="rateDetails">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">CARVE-OUT RATE NAME</th>
                  <th class="text-left">CATEGORY</th>
                  <th class="text-left">LOCATION</th>
                  <th class="text-left">RATE (USD)</th>
                  <th class="text-left">RATE TYPE</th>
                  <th></th>
                </tr>
              </thead>
           
              <tbody class="text-gray">
       
                <tr v-for="(item, index) in rate.carveOut" :key="index">
               
                  <td>
                    <span v-if="editRateInputs && row == index">
                      <v-text-field
                        class="mt-1 mb-1"
                        hide-details
                        solo
                        v-model="item.name"
                      ></v-text-field>
                    </span>
                    <span v-else>
                      <a>{{ item.name }}</a>
                    </span>
                  </td>
                  <td>
                    <span v-if="editRateInputs && row == index">
                      <v-text-field
                        solo
                        class="mt-1 mb-1"
                        hide-details
                        v-model="item.category"
                      ></v-text-field>
                    </span>
                    <span v-else>
                      <a>{{ item.category }}</a>
                    </span>
                  </td>
                 
                  <td v-if="item.locations.length > 0">
                    {{ item.locations[0]}}
                    
                    <span v-if="editRateInputs && row == index">
                      <v-select
                        class="mt-1 mb-1"
                        hide-details
                        :items="locations"
                        item-text="name"
                        item-value="id"
                        return-object
                        v-model="item.locations"
                        placeholder="Select Location"
                      ></v-select>
                    </span>
                    <span v-else-if="!item.locations && !editRateInputs">/</span>
                  </td>
             <td v-else>
              /
              <span v-if="editRateInputs && row == index">
                      <v-select
                        class="mt-1 mb-1"
                        hide-details
                        :items="locations"
                        v-model="item.locations"
                        return-object
                        item-text="name"
                        item-value="id"
                        placeholder="Select Locationss"
                      ></v-select>
                    </span>
             </td>
                  <td>
                    <span v-if="editRateInputs && row == index">
                      
                      <v-text-field
                        solo
                        v-model="item.rate"
                        class="mt-1 mb-1"
                        hide-details
                      ></v-text-field>
                    </span>
                    <span v-else>
                      <a>{{ item.rate }}</a>
                    </span>
                  </td>
                  <td>
                    <span v-if="editRateInputs && row == index">
                      <v-text-field
                        solo
                        v-model="item.type"
                        class="mt-1 mb-1"
                        hide-details
                      ></v-text-field>
                    </span>
                    <span v-else>
                      <a>{{ item.type }}</a>
                    </span>
                  </td>
                  
                </tr>

              
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </div>
    </div>
    <div class="col-md-12 gray-underline-wizard mt-15 pr-10">
      <div class="col-md-12 row text-end mt-4 mb-4 justify-end">
        <span
          class="text-underline pointer mr-15"
          style="color:#0572F3;"
          @click="cancel()"
          >Cancel</span
        >
        <span
          class="text-underline pointer mr-6"
          style="color:#0572F3;"
          @click="saveRateChanges()"
          >Save</span
        >
      </div>
    </div>
    <v-dialog v-model.trim="removeRate"
    height="320"
    width="400"
    style="margin-top:30vh;"
    content-class="modal-member-check">
      <v-card>
        <v-col>
          <h3>Remove Rate</h3>
        </v-col>

        <v-col>
          <span>Are you sure you want to delete this rate? </span>
        </v-col>

        <v-col>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="blue-buttons float-class" @click="removeItem(item,index)"
              >Confirm</v-btn
            >

            <v-btn class="button-style float-class" @click="removeRate=false"
              >Close</v-btn
            >
          </v-card-actions>
        </v-col>
      </v-card>
    </v-dialog>
    <successModal
      ref="success_modal"
      v-on:confirm="closeSuccessModal"
    ></successModal>
    
  <errorModal 
    ref="error_modal"
     v-on:confirm="closeErrorModal">
    </errorModal>
  
  </v-row>
</template>
<script>
import api from "../../../../../services/api";
import successModal from "../../../../TablesTemplate/SuccessDialog.vue";
import errorModal from "../../../../TablesTemplate/ErrorDialog.vue";
import FullscreenLoader from "../../../../Common/FullscreenLoader.vue";
import XLSX from "xlsx";
export default {
  components: { successModal, errorModal, FullscreenLoader },
  data() {
    return {
      loader: false,
      addValid: false,
      removeRate: false,
      currentItem: "",
      currentIndex: "",
      accepted: true,
      editRate: false,
      editBaseRate: false,
      editRateInputs: false,
      addNewRow: false,
      dialog: false,
      showOverlay: false,
      locations: [],
      rate:null,
      newItem: {
        locations: "",
        name: "",
        category: "locations",
        rate: "",
        type: "PMPM",
      },

      valid: false,
      row: "",
      singleItem: null,
      requiredLocation: false,
      requiredRules: [(v) => !!v || "This Field is required"],
      finished: false,
    };
  },
  computed: {
    singleMember() {
      return this.$store.getters.getSingleItem;
    },
  },
  created() {
    this.getItems();
    this.getSingleRate()
    // if (this.singleMember) {
    //   this.rate = this.singleMember;
    // }
  },
  methods: {
    cancel(){
      window.location = '/dpc-providers/rates'
    },
    saveRateChanges(){
      this.saveEditRateFunction();
      this.$refs.success_modal.openPopUp('Rate Updated Successfully');
      this.finished = true;
    },
    getSingleRate() {
      this.loader = true;
      api()
        .get(`/dpcproviders/rate/` + this.$route.params.id)
        .then((res) => {
          if (res) {
            this.loader = false;
              this.rate = res.data;
              console.log('rate',this.rate)

          }
        })
        .finally(()=>{
          this.loader = false;
        });
    },
    cancelNewRate(){
      this.addNewRow = false;
      this.requiredLocation = false
    },
    exportData(rateDetails, filename = "rateDetails") {
      var elt = document.getElementById(rateDetails);
      var downloadLink;
      var dataType = "application/vnd.ms-excel";
      var tableSelect = elt;
      var tableHTML = tableSelect.outerHTML.replace(/ /g, "%20");

      // Specify file name
      filename = filename ? filename + ".xls" : "excel_data.xls";

      // Create download link element
      downloadLink = document.createElement("a");

      document.body.appendChild(downloadLink);

      if (navigator.msSaveOrOpenBlob) {
        var blob = new Blob(["\ufeff", tableHTML], {
          type: dataType,
        });
        navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        // Create a link to the file
        downloadLink.href = "data:" + dataType + ", " + tableHTML;

        // Setting the file name
        downloadLink.download = filename;

        //triggering the function
        downloadLink.click();
      }
    },
    getLocationById(id){
        let location = this.locations.find(item => item._id === id);
      if(location != undefined){
        return location.name
      }
      return '';
    },
    closeSuccessModal() {
      if(this.finished){
        this.$refs.success_modal.closePopUp();
        window.location = '/dpc-providers/rates';
      }
      else{
        this.$refs.success_modal.closePopUp();
      }
    },
    closeErrorModal() {
      this.$refs.error_modal.closePopUp();
    },
    addNewRate() {
      if(this.newItem.locations == '' || this.newItem.name == '' || this.newItem.rate == ''){
        this.addValid = false;  
          this.requiredLocation = true;
      }
      else{
        this.addValid = true;
    
      }
      if(this.addValid){   
      // let data = JSON.stringify(this.rate)
      // data = JSON.parse(data)
    console.log('newitem',this.newItem)
    // this.newItem.locations = [ this.newItem.locations.id]
      api()
        .post(`/dpcproviders/rates/${this.rate.id}/carve`, this.newItem)
        .catch((err) => {
                  if (err.response.status == 500) {
                    this.$refs.error_modal.openPopUp(
                      "There was an error, system admin is notified. Please try again later."
                    );}
                   else{
                    this.$refs.error_modal.openPopUp(err.response.data)
                   }
                  
                })
        .then((res) => {
          if (res) {
            this.rate.carveOut.push(this.newItem);
            this.newItem = {
              locations: "",
              name: "",
              category: "locations",
              rate: "",
              type: "PMPM",
            };
            this.$refs.success_modal.openPopUp('Success');
            this.addNewRow = false;
            this.requiredLocation = false;
            this.getSingleRate()
          }
        });
      
      }
 
    },
    editRateFunction(){
            this.editRate = false;
            this.editBaseRate = false;
    },
    saveEditRateFunction(){
      api()
        .put(`/dpcproviders/rates/${this.$route.params.id}`, {group: this.rate.group, baseRate: this.rate.baseRate})
        .catch((err) => {
                  if (err.response.status == 500) {
                    this.$refs.error_modal.openPopUp(
                      "There was an error, system admin is notified. Please try again later."
                    );}
                   else{
                    this.$refs.error_modal.openPopUp(err.response.data)
                   }
                  
                })
        .then((res) => {
          if (res) {
          }
        });
    },
    removeThis(item,index){
      this.removeRate = true;
      this.currentIndex = index;
      this.currentItem = item;
    },
  
    removeItem(item, index) {
     
     item = this.currentItem;
     console.log('item',item)
     index = this.currentIndex;
           this.editRateInputs = false;
      let indx = this.rate.carveOut.findIndex((t) => t.id == item.id);
      api()
        .delete(`/dpcproviders/rates/${this.rate.id}/carve/${item.id}`)
        .catch((err) => {
                  if (err.response.status == 500) {
                    this.$refs.error_modal.openPopUp(
                      "There was an error, system admin is notified. Please try again later."
                    );}
                   else{
                    this.$refs.error_modal.openPopUp(err.response.data)
                   }
                  
                })
        .then((res) => {
          if(res){
            this.rate.carveOut.splice(indx,1);
          this.removeRate = false;
          this.$refs.success_modal.openPopUp('Carve Out successfully deleted');
          this.getSingleRate()
          }
          
         });
      
   
    },
    editRow(item, row) {
      let data = {
          id:item.id,
          name:item.name,
          locations:  item.locations[0].id ? item.locations[0].id : item.locations,
          rate:item.rate,
          category: "locations",
      }
      console.log(data)
      api()
        .put(`/dpcproviders/rates/${this.$route.params.id}/carve/${item.id}`,data)
        .catch((err) => {
                  if (err.response.status == 500) {
                    this.$refs.error_modal.openPopUp(
                      "There was an error, system admin is notified. Please try again later."
                    );}
                   else{
                    this.$refs.error_modal.openPopUp(err.response.data)
                   }
                  
                })
        .then((res) => {
          if(res){
             this.row = "";
          this.editRateInputs = false;
          this.getSingleRate()
          }
         
        });
    },
    editItem(item, index) {
      this.row = index;
      this.editRateInputs = true;
    },
    getItems() {
      api()
        .get("/dpcproviders/locations")
        .then((res) => {
          this.locations = res.data;

        });
    },
  },
};
</script>
<style>
.form-row-small-height {
  min-height: 40rem !important;
}
.locations-provider-dialog {
  margin-left: 0.5%;
  width: 99%;
  margin-top: 1%;
}
</style>

