<template>
  <div class="">
    <div class="col-md-12 pl-0 pr-0">
      <v-form v-model.trim="account.valid" ref="form" class="row">
        <div class="col-md-12 pb-0 pr-0 pl-0">
          <div class="col-md-12 d-md-flex pl-0 pr-0">
            <div class="col-md-3">
              <h3>Company Details</h3>
              <p>Enter some basic company information</p>
            </div>
            <div class="col-md-12">
              <div class="col-md-12 d-md-flex pt-0 pb-0">
                <div class="col-md-6  pt-0 pb-0">
                  <label class="font text-gray" for="">Company Name</label>
                  <v-text-field
                    required
                    :rules="requiredRules"
                    v-model.trim="account.companyName"
                    solo
                  ></v-text-field>
                </div>
                <div class="col-md-3  pt-0 pb-0">
                  <label class="font text-gray" for="">Tax ID</label>
                  <v-text-field
                  v-model.trim="account.taxId"
                    required
                    @keyup="showSlash($event)"
                    solo
                    placeholder="00-0000000"
                    :rules="taxIdRules"
                    counter="10"
                    maxlength="10"
                  ></v-text-field>
                </div>
              </div>
              <div class="col-md-12 d-md-flex pt-0 pb-0">
                <div class="col-md-6  pt-0 pb-0">
                  <label class="" for="">Website</label>
                  <v-text-field
                    v-model.trim="account.website"
                    :rules="websiteRules"
                    solo
                  ></v-text-field>
                </div>
                <div class="col-md-3  pt-0 pb-0">
                  <label class="font text-gray" for="">Payer ID</label>
                  <v-text-field
                    v-model="account.payerId"
                    :rules="requiredRules"
                    max="5"
                    requried
                    solo
                  ></v-text-field>
                </div>
              </div>
              <div class="col-md-12 d-md-flex pt-0 pb-0">
                <div class="col-md-2 pt-0 pb-0">
                  <img 
                  style="max-width: 200px; height: 80px;"
                  id="logo-img"
                  :src="account.logo ? account.logo : '/no_logo_uploaded.png'" />
                </div>
                
                <div class="col-md-4  pt-2 pb-0">
                  <label class="font text-gray" for=""></label>
                  <v-file-input
                  @change="Preview_image"
                  v-model.trim="dataComponents.image"
                  solo
                  truncate-length="38"
                  ref="fileInput"
                ></v-file-input>
                </div>
                <div class="col-md-1  pt-5 pb-0">
                    <a @click="activateFileInput" class="text-underline">Browse</a>
                  </div>
              </div>
            </div>
          </div>
   <v-divider horizontal class="mb-5"></v-divider>
   <div class="col-md-12 d-md-flex pr-0 pl-0">
    <div class="col-md-3">
      <h3>Address Details</h3>
      <p>Enter the address of your headquarters</p>
    </div>
    <div class="col-md-9">
        <div class="col-md-8 pt-0 pb-0">
            <label class="font text-gray" for="">Address Line 1</label>
            <v-text-field
              :rules="requiredRules"
              required
              v-model.trim="account.address1"
              solo
            ></v-text-field>
          </div>
          <div class="col-md-8 pt-0 pb-0">
            <label class="font text-gray" for="">Address Line 2</label>
            <v-text-field 
            v-model.trim="account.address2"
            required 
            solo>
          </v-text-field>
          </div>
          <v-row class="no-padding">
            <div class="col-md-3 pt-0 pb-0">
              <label class="font text-gray" for="">City</label>
              <v-text-field
              v-model.trim="account.city"
                required
                solo
                :rules="requiredRules"
              ></v-text-field>
            </div>
            <div class="col-md-3 pt-0 pb-0">
              <label class="font text-gray" for="">State</label>
              <v-select
              v-model.trim="account.state"
                :items="states"
                item-text="name"
                item-value="name"
                required
                solo
                :rules="requiredRules"
              ></v-select>
            </div>
            <div class="col-md-2 pt-0 pb-0">
              <label class="font text-gray" for="">Zip Code</label>
              <v-text-field
              v-model.trim="account.zipCode"
                required
                type="number"
                solo
                :rules="zipRules"
              ></v-text-field>
            </div>
          </v-row>
    </div>
  </div>
          <v-divider horizontal class="mb-5"></v-divider>
          <div class="col-md-12 d-md-flex pl-0 pr-0">
            <div class="col-md-3">
              <h3>Contact Details</h3>
              <p>Enter details of your primary contact person.</p>
            </div>
            <div class="col-md-9">
              <div class="col-md-12 d-md-flex pt-0 pb-0">
                <div class="col-md-6 pt-0 pb-0">
                    <label class="font text-gray pb-0" for=""
                    >First Name
                  </label>
                  <v-text-field
                    v-model.trim="account.primaryContact.firstName"
                    required
                    solo
                    :rules="requiredRules"
                  ></v-text-field>
                </div>
                <div class="col-md-6 pt-0 pb-0">
                    <label class="font text-gray" for=""
                    >Primary Contact Last Name</label>
                  <v-text-field
                    v-model.trim="account.primaryContact.lastName"
                    required
                    solo
                    :rules="requiredRules"
                  ></v-text-field>
                </div>
              </div>
              <div class="col-md-12 d-md-flex pt-0 pb-0">
                <div class="col-md-6 pt-0 pb-0">
                    <label class="font text-gray" for="">Title</label>
                    <v-text-field
                      v-model.trim="account.primaryContact.title"
                      solo
                    ></v-text-field>
                </div>
                <div class="col-md-6 pt-0 pb-0">
                    <label class="font text-gray" for=""> Email</label>
                    <v-text-field
                       v-model.trim="account.primaryContact.email"
                      required
                      :rules="emailRules"
                      solo
                    ></v-text-field>
                </div>
              </div>
              <div class="col-md-12 d-md-flex pt-0 pb-0">
                <div class="col-md-6 pt-0 pb-0">
                    <label class="font text-gray" for="">Phone</label>
                    <v-text-field
                     v-model.trim="account.primaryContact.phone"
                      solo
                      :rules="phoneRules"
                    ></v-text-field>
                </div>
              </div>
            </div>
          </div>
          <v-divider horizontal class="mb-5"></v-divider>
          <div class="col-md-12 d-md-flex pl-0 pr-0">
            <div class="col-md-3">
              <h3>Bank Details</h3>
              <p>Enter details of your bank account.</p>
            </div>
            <div class="col-md-9">
              <div class="col-md-12 d-md-flex pt-0 pb-0">
                <div class="col-md-6 pt-0 pb-0">
                    <label class="font text-gray pb-0" for=""
                    >Bank Name
                  </label>
                  <v-text-field
                    v-model.trim="account.bank.name"
                    solo
                  ></v-text-field>
                </div>
              </div>
              <div class="col-md-12 d-md-flex pt-0 pb-0">
                <div class="col-md-6 pt-0 pb-0">
                    <label class="font text-gray" for="">Inbound Routing Number</label>
                    <v-text-field
                      inputmode="numeric"
                      v-model.trim="account.bank.routeNo"
                      solo
                    ></v-text-field>
                </div>
                <div class="col-md-6 pt-0 pb-0">
                    <label class="font text-gray" for=""> Inbound Account Number</label>
                    <v-text-field
                      v-model.trim="account.bank.accountNo"
                      solo
                    ></v-text-field>
                </div>
              </div>
            </div>
          </div>
          <v-divider horizontal class="mb-5"></v-divider>
        </div>
      </v-form>
    </div>
    <div class="row text-end justify-end">
      <div class="col-md-8 col-12 d-md-flex mt-6 justify-end">
        <span class="mt-3">
           <a @click="generatePdf()" large class="text-underline mt-3 mr-14">
          View & Print Apaly Terms of Use Agreement
            </a>
        </span>
      <span class="d-flex">
        <v-btn class="cancel mt-2 mr-5 col-md-5"
         @click="$router.go(-1)"
         > Cancel </v-btn>
        <v-btn class="submit mt-2 col-md-5"
         color="#16407A"
         @click="update"
          > Save </v-btn>
        </span>
      </div>
    </div>
    <br /><br />
    <successModal ref="success_modal" v-on:confirm="closeSuccessModal"></successModal>
    <errorModal ref="error_modal" v-on:confirm="closeErrorModal"></errorModal>

  </div>
</template>
<script>
import Header from "../Layout/HeaderPage.vue";
import api from "../../../../services/api";
import successModal from "../../../TablesTemplate/SuccessDialog.vue";
import errorModal from "../../../TablesTemplate/ErrorDialog.vue";
export default {
  data() {
    return {
      taxIdRules: [
        (v) => !!v || "This Field is required",
        (v) =>
          !!/[0-9]{2}\-[0-9]{7}/.test(v) ||
          "Tax ID should contain 9 digits, Please enter your Tax ID in XX-XXXXXXX format",
      ],
      zipRules: [
        (v) => (v && v.length == 5) || " Zip Code should be five digits long",
      ],
      payerRules: [
        (v) => (v && v.length == 5) || " Payer ID should be five digits long",
      ],
      states: [],
      phoneRules: [
      v => {
        if (v === '' || /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(v)) {
          return true; // No error
        } else {
          return 'Phone number must be in a valid format (XXX XXX XXXX)';
        }
      }
    ],
      requiredRules: [(v) => !!v || "This Field is required"],

      emailRules: [
        (v) => !!v || "This Field is required",
        (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || "E-mail must be valid",
      ],
      websiteRules: [
      v => {
        if (v === '' || /^(https?:\/\/)?[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/.test(v)) {
          return true; // No error
        } else {
          return 'Website must be valid';
        }
      }
    ],
    account : {
      companyName: "",
      taxId: "",
      website: "",
      logo: "",
      primaryContact : {
        firstName : "",
        lastName : "",
        title : "",
        email : "",
        phone : ""
      },
      bank: {
        accountNo: "",
        name: "",
        routeNo: "",
      },
      address1: "",
      address2: "",
      city: "",
      state: "",
      zip: "",
      payerId: "",
      valid:'',
      logoChange: false,
      imageEvent: null,
      
    },
     dataComponents: {
        image: {
          name: "",
        },
        profileImage: {
          name: "",
        },
      },
    image: "",
    hasChanged: false,
   
    };
  },
  components: {
    Header,
    successModal,
    errorModal,
  },
  computed: {},
  created() {
    this.getStates();
    this.getInfo();
    setTimeout(() => {
                  this.hasChanged = false;
                }, 1000);
  },
  watch: {
    account:{
      handler: function(newValue) {
                this.hasChanged = true;
            },
            deep: true
    }
  },
  methods: {
    generatePdf(){
      window.open( `https://apalycdn.s3.us-west-1.amazonaws.com/public/uploads/admin/contracts/PlatformAccessAgreement.pdf`, "_blank");
    },
    Preview_image(event) {
      this.hasChanged = true;
      this.logoChange = true;
      this.imageEvent = event;
    },
    activateFileInput() {
      this.$refs.fileInput.$refs.input.click();
    },
    showSlash(event) {
      if (this.account.taxId.length == 2 && event.keyCode != 8) {
        this.account.taxId += "-";
      }
      if (this.account.taxId.length == 10) {
        api()
          .get("auth/isValidTaxId?taxId=" + this.account.taxId)
          .catch((res) => {
            if (res) {
              this.taxIdRules = [res.response.data.message];
            }
          });
      } else {
        this.taxIdRules = [
          (v) => !!v || "This Field is required",
          (v) =>
            !!/[0-9]{2}\-[0-9]{7}/.test(v) ||
            "Tax ID should contain 9 digits, Please enter your Tax ID in XX-XXXXXXX format",
        ];
      }
    },
    getStates() {
      api()
        .get("/api/states")
        .then((res) => {
          res.data.forEach((el) => {
            if (el.active) {
              this.states.push(el);
            }
          });
        });
    },
    getInfo() {
  
      api()
        .get(`/tpas`)
        .then((res) => {
          if (res) {
            this.account = res.data;
            this.account.bank = res.data.bank ? res.data.bank : {};
            this.account.logo = res.data.logo;
            this.dataComponents.image.name = res.data.logo ? res.data.logo : "";

          }
        });
    },
    closeSuccessModal() {
      this.$refs.success_modal.closePopUp();
      this.$router.push('/tpa/home');
    },
    closeErrorModal() {
      this.$refs.error_modal.closePopUp();
    },
    update() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        console.log(this.dataComponents)
        let data = {
          companyName: this.account.companyName,
          address1: this.account.address1,
          address2: this.account.address2,
          city: this.account.city,
          state: this.account.state,
          zipCode: this.account.zipCode,
          payerId: this.account.payerId,
          taxId: this.account.taxId,
          primaryContact: this.account.primaryContact,
          website: this.account.website,
          logo: this.dataComponents.image ?  this.dataComponents.image.name : '',
          bank: this.account.bank
        };
        if (this.logoChange) {
              if (this.imageEvent) {
                let formData = new FormData();
                formData.append("_method", "PUT");
                formData.append("image", this.imageEvent);
                api().put(`/tpas/logo`, formData)
                .catch((err) => {
              this.$refs.error_modal.openPopUp(err.response.data);
          })
          .then((res) => {
               console.log(res.data)
                });
              }
            }
        api().put(`/tpas`, data)
        .catch((err) => {
              this.$refs.error_modal.openPopUp(err.response.data);
          })
          .then((res) => {
            if(res){
              if(this.hasChanged){
                if(this.dataComponents.image != null){
                    this.$store.commit("setMainLogo", this.dataComponents.image.name);
                }
                this.$refs.success_modal.openPopUp("Account updated successfully");
              }
              else{
                this.$router.push('/tpa/home');
              }
        
            }
          
          });
        
      }
    },
  },
};
</script>
<style scoped>
.submit {
  width: 140px;
  color: white;
}
.cancel {
  width: 140px;
}
</style>