import { render, staticRenderFns } from "./Personal.vue?vue&type=template&id=d5e540bc&scoped=true"
import script from "./Personal.vue?vue&type=script&lang=js"
export * from "./Personal.vue?vue&type=script&lang=js"
import style0 from "./../../../../assets/mobileStyle.css?vue&type=style&index=0&id=d5e540bc&prod&scoped=true&lang=css&external"
import style1 from "./Personal.vue?vue&type=style&index=1&id=d5e540bc&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d5e540bc",
  null
  
)

export default component.exports