<template>
  <v-row v-if="singleEmployer" class="add-client-form no-padding">
    <div class="col-md-12">
      <removeDialog
        ref="dialog_delete"
        v-on:confirm="confirmRemove"
      ></removeDialog>
      <successModal
        ref="success_modal"
        v-on:confirm="updateModal"
      ></successModal>
      <div class="row">
        <div class="col-md-12">
          <h3 class="form-title font">
            {{ singleEmployer.companyName }}
          </h3>
        </div>
      </div>
      <div class="form-row-height">
        <form class="row">
          <div class="col-md-6">
            <div class="col-md-12 pb-0 ">
              <label class="font text-gray" for="">Company Name</label>
              <v-text-field
                tabindex="1"
                required
                solo
                v-model.trim="companyName"
              ></v-text-field>
            </div>

            <div class="col-md-12 pb-0 pt-0">
              <label class="font text-gray" for=""
                >Tax ID </label>
              <v-text-field
                tabindex="2"
                @keyup="showSlash($event)"
                required
                solo
                :rules="taxIdRules"
                counter="10"
                v-if="!viewContract"
                maxlength="10"
                v-model.trim="taxId"
              ></v-text-field>
              <v-text-field
                :value="'Contract to View'"
                v-else
                required
                @click="showContract"
                solo
                class="contract-anchor-inputs pointer"
              >
                <template #label>
                  <a class="pointer">Contract to View</a>
                </template>
              </v-text-field>
            </div>
            <div class="col-md-12 pb-0 pt-0">
              <label class="font text-gray" for=""
                >Approximate Number of Employees
              </label>
              <v-text-field
                tabindex="3"
                required
                v-if="!viewContract"
                v-model.trim="noEmployees"
                solo
                type="number"
              ></v-text-field>
              <v-text-field
                :value="'Contract to View'"
                v-else
                required
                @click="showContract"
                solo
                class="contract-anchor-inputs pointer"
              >
                <template #label>
                  <a class="pointer">Contract to View</a>
                </template>
              </v-text-field>
            </div>
            <div class="col-md-12 pb-0 pt-0">
              <label class="font text-gray" for=""
                >Approximate Number of Plan Members</label
              >
              <v-text-field
                v-if="!viewContract"
                tabindex="3"
                v-model.trim="noPlanMembers"
                required
                solo
                type="number"
              ></v-text-field>
              <v-text-field
                :value="'Contract to View'"
                v-else
                required
                @click="showContract"
                solo
                class="contract-anchor-inputs pointer"
              >
                <template #label>
                  <a class="pointer">Contract to View</a>
                </template>
              </v-text-field>
            </div>
            <!-- <div class="row no-padding" v-if="!viewContract">
              <label class="text-gray col-md-12 font" for=""
                >Employer Relationship Status</label
              >
              <div class="d-flex ml-3 text-gray">
                In Active
                <v-switch
                  class="no-padding vendors-switch"
                  v-model.trim="active"
                ></v-switch>
                Active
              </div>
            </div> -->
          </div>
          <!-- <div class="col-md-6">
            <div class="col-md-12">
               <label class="font text-gray" for="">Current Network Vendor <i class="fas fa-question-circle  yellow-icons"></i></label>
                <v-text-field
                tabindex="10"
                required
                v-model.trim="networkVendor"
                solo
               
                ></v-text-field>
           </div>
          <div class="col-md-12">
            <label class="font text-gray " style="font-size:15px" for="">Current Network Discount Rate (i.e. Precent Discount off Billed Charges)
                <i class="fas fa-question-circle  yellow-icons"></i>
            </label>
                <v-text-field
                class="placeholder-style"
                tabindex="11"
                required
                type="number"
                 v-model.trim="networkDiscountRate"
                solo
                ></v-text-field>
           </div>
          

          
        </div>            -->
        </form>
      </div>

      <div class="row justify-md-end mt-2" v-if="$role != 'user'">
        <div>
          <v-btn
            tabindex="16"
            class="blue-buttons mr-6 mb-2"
            @click="removeEmployer()"
          >
            Remove Employer
          </v-btn>
        </div>
        <div>
          <v-btn tabindex="16" class="blue-buttons mr-6 mb-2" @click="closeEmp">
            Close
          </v-btn>
        </div>
      </div>
      <v-dialog v-model.trim="dialog" width="600">
        <v-card>
          <v-card-title class="text-h5"> PDF CONTRACT </v-card-title>
          <v-card-text>
            When user clicks “View Contract” on the Employer list from prior
            screen, then a PDF version of the contract is presented for the
            Provider user to view. This signature page of this PDF contract is
            populated with the Employer’s information (i.e. the Employer that
            they selected on the prior screen), and the compensation addendum is
            populated with the Provider’s contract rates. The user can view,
            print, and save this PDF.
          </v-card-text>
          <v-card-actions>
            <div class="col-md-12 text-end">
              <v-btn class="blue-buttons text-end" @click="dialog = false"
                >Close</v-btn
              >
            </div>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-row>
</template>
<script>
import api from "../../../../../services/api";
import successModal from "../../../../TablesTemplate/SuccessDialog.vue";
import removeDialog from "../../../../TablesTemplate/RemoveDialog.vue";
export default {
  components: {
    successModal,
    removeDialog,
  },
  data() {
    return {
      dialog: false,
      companyName: "",
      active: true,
      taxId: "",
      noEmployees: 0,
      noPlanMembers: 0,
      networkVendor: "",
      viewContract: false,
      networkDiscountRate: "",
      taxIdRules: [
        (v) => !!v || "This Field is required",
        (v) =>
          !!/[0-9]{2}\-[0-9]{7}/.test(v) ||
          "Tax ID should contain 9 digits, Please enter your Tax ID in XX-XXXXXXX format",
      ],
    };
  },
  computed: {
    singleEmployer() {
      return this.$store.getters.getSingleEmployer;
    },
    getStatusContract() {
      return this.$store.getters.getEmpContractStatus;
    },
  },
  created() {
    if (this.getStatusContract) {
      this.viewContract = false;
      this.companyName = this.singleEmployer.companyName;
      this.taxId = this.singleEmployer.taxId;
      this.noEmployees = this.singleEmployer.noEmployees
        ? this.singleEmployer.noEmployees
        : 0;
      this.noPlanMembers = this.singleEmployer.noPlanMembers
        ? this.singleEmployer.noPlanMembers
        : 0;
      this.networkVendor = this.singleEmployer.networkVendor;
      this.networkDiscountRate = this.singleEmployer.networkDiscountRate
        ? this.singleEmployer.networkDiscountRate
        : 0;
    } else {
      this.companyName = this.singleEmployer.companyName;
      this.viewContract = true;
    }
  },
  methods: {
    showContract() {
      this.$router.push(
        `/provider-advisor/providers/${this.routeParams}/contracts`
      );
    },
    showSlash(event){
      if (this.taxId.length == 2 && event.keyCode != 8) {
        this.taxId += "-";
      }
      if (this.taxId.length == 10) {
        api()
          .get("auth/isValidTaxId?taxId=" + this.taxId)
          .catch((res) => {
            if (res) {
              this.taxIdRules = [res.response.data.message];
            }
          });
      } else {
        this.taxIdRules = [
          (v) => !!v || "This Field is required",
          (v) =>
            !!/[0-9]{2}\-[0-9]{7}/.test(v) ||
            "Tax ID should contain 9 digits, Please enter your Tax ID in XX-XXXXXXX format",
        ];
      }
    },
    closeEmp() {
      this.$router.push(
        `/provider-advisor/providers/${this.routeParams}/employers`
      );
    },
    updateModal() {
      this.$refs.success_modal.closePopUp();
    },
    editEmp() {
      let data = {
        companyName: this.companyName,
        taxId: this.taxId,
        noEmployees: Number(this.noEmployees),
        noPlanMembers: Number(this.noPlanMembers),
        networkVendor: this.networkVendor,
        networkDiscountRate: Number(this.networkDiscountRate),
      };

      let user = JSON.parse(localStorage.getItem("user"));
      let advisorId = user.groupPortal[0].organization.providerAdvisor;
      let provider = JSON.parse(localStorage.getItem("provider"));
      api()
        .put(
          `/advisors/${advisorId}/providers/${provider._id}/employers/${this.singleEmployer._id}`,
          data
        )
        .then((res) => {
          if (res) {
            this.$refs.success_modal.openPopUp("Employer successfully updated");
          }
        });
    },
    confirmRemove() {
      let user = JSON.parse(localStorage.getItem("user"));
      let advisorId = user.groupPortal[0].organization.providerAdvisor;
      let provider = JSON.parse(localStorage.getItem("provider"));
      api()
        .delete(
          `/advisors/${advisorId}/providers/${provider._id}/employers/${this.singleEmployer._id}`
        )
        .then((res) => {
          if (res) {
            this.$router.push(
              `/provider-advisor/providers/${this.routeParams}/employers`
            );
          }
        });
    },
    removeEmployer() {
      let text = "Are you sure that you want to remove this Employer?";
      this.$refs.dialog_delete.openPopUp(text);
    },

    // done(){
    //   this.dialog = false;
    //    this.$router.push(`/provider-advisor/providers/${routeParams}/employers`)
    // }
  },
};
</script>
<style>
.gender-buttons {
  border: 1px solid rgb(197, 185, 185);
  border-radius: 10px;
  cursor: pointer;
}
</style>