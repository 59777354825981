<template>
  <v-row class="add-client-form no-padding">
    <successModal
      ref="success_modal"
      v-on:confirm="closeSuccessModal"
    ></successModal>
    <div class="col-md-12">
      <div class="row">
        <div
          class="col-sm-12 col-xs-12 col-md-12 col-lg-2 col-xl-2 border-right"
        >
          <div>
            <v-list-item class="px-2 orange-underline-wizard">
              <v-list-item-avatar>
                <v-img
                  @click="changeProfileImg"
                  :src="
                    profileImg
                      ? profileImg
                      : 'https://www.pngkit.com/png/full/128-1280585_user-icon-fa-fa-user-circle.png'
                  "
                ></v-img>
              </v-list-item-avatar>
              <v-list-item-title
                >{{ showFirstName }} {{ showLastName }}</v-list-item-title
              >
            </v-list-item>
            <v-list dense class="pt-0">
              <v-list-item-group v-model="selectedItem" color="primary">
                <v-list-item
                  v-for="item in items"
                  :key="item.title"
                  link
                  @click="changeTab(item)"
                >
                  <v-list-item-icon>
                    <v-icon>{{ item.icon }} </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </div>
        </div>
        <div class="col-md-12 col-lg-10 col-xl-10 edit-profile-form-height">
          <div class="row col-md-12" v-if="tab == 'edit'">
            <div class="col-md-12">
              <div class="mx-auto">
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="indigo"> mdi-account </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      >{{ firstName }} {{ lastName }}</v-list-item-title
                    >
                    <v-list-item-subtitle>User</v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-icon>
                    <v-icon>mdi-account</v-icon>
                  </v-list-item-icon>
                </v-list-item>
                <v-divider inset></v-divider>
                <v-list two-line>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon color="indigo"> mdi-domain </v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title>{{ companyName }}</v-list-item-title>
                      <v-list-item-subtitle
                        >Company Legal Name</v-list-item-subtitle
                      >
                    </v-list-item-content>

                    <v-list-item-icon>
                      <v-icon>mdi-message-text</v-icon>
                    </v-list-item-icon>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-action></v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>{{ displayName }}</v-list-item-title>
                      <v-list-item-subtitle
                        >Company Display Name</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="logo">
                    <v-list-item-action></v-list-item-action>
                    <v-list-item-content>
                      <v-list-item class="ml-0 pl-0">
                        <img
                          style="max-height: 50px; max-width: 100px"
                          @click="getLogoImg"
                          id="logo-img"
                          :src="logo ? logo : '/apaly1.png'"
                          alt=""
                        />
                      </v-list-item>

                      <v-list-item-subtitle>Company Logo</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-divider inset></v-divider>

                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon color="indigo"> mdi-email </v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title>{{ user.email }}</v-list-item-title>
                      <v-list-item-subtitle>Email</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-divider inset></v-divider>
                  <v-list-item v-if="website">
                    <v-list-item-icon>
                      <v-icon color="indigo"> mdi-web </v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title>{{ website }}</v-list-item-title>
                      <v-list-item-subtitle>Website</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <v-divider inset></v-divider>
                <v-list two-line>
                  <v-list-item v-if="phone">
                    <v-list-item-icon>
                      <v-icon color="indigo"> mdi-phone </v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title>{{ phone }}</v-list-item-title>
                      <v-list-item-subtitle>Phone</v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-icon>
                      <v-icon>mdi-message-text</v-icon>
                    </v-list-item-icon>
                  </v-list-item>

                  <v-divider inset v-if="phone"></v-divider>

                  <v-list-item v-if="address1 || city">
                    <v-list-item-icon>
                      <v-icon color="indigo"> mdi-map-marker </v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title
                        >{{ address1 }} <span v-if="address1">/</span>
                        {{ address2 }}</v-list-item-title
                      >
                      <v-list-item-subtitle
                        >{{ city }}, {{ state }} {{ zip }}</v-list-item-subtitle
                      >
                      <v-list-item-subtitle>Address</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </div>
            </div>
          </div>
          <v-form
            v-model.trim="valid"
            ref="form"
            class="row col-md-12"
            v-if="tab == 'account'"
          >
            <div class="col-md-12 pb-0 pl-0">
              <h3 class="col-md-12 mb-0 pb-0 dark-blue">Edit Profile</h3>
            </div>
            <div class="row pt-0 mt-0 form-row-height">
              <div class="col-md-4">
                <div class="col-md-12 pb-0 pt-0">
                  <label class="font text-gray" for="">First Name</label>
                  <v-text-field
                    required
                    :rules="requiredRules"
                    v-model.trim="firstName"
                    solo
                  ></v-text-field>
                </div>
                <div class="col-md-12 pb-0 pt-0">
                  <label class="font text-gray" for="">Last Name</label>
                  <v-text-field
                    required
                    :rules="requiredRules"
                    v-model.trim="lastName"
                    solo
                  ></v-text-field>
                </div>
                <div class="col-md-12 pb-0 pt-0">
                  <label class="font text-gray" for="">Email</label>
                  <v-text-field
                    disabled
                    required
                    :rules="requiredRules"
                    v-model.trim="email"
                    solo
                  ></v-text-field>
                </div>
                <div
                  class="col-md-12 pb-0 pt-0"
                  v-if="$route.path.includes('advisor')"
                >
                  <label class="font text-gray" for="">Account Type</label>
                  <v-select
                    :items="typeOptions"
                    required
                    v-model.trim="accountType"
                    solo
                  ></v-select>
                </div>
                <div class="col-md-12 pb-0 pt-0">
                  <label class="font text-gray" for=""
                    >Company Legal Name</label
                  >
                  <v-text-field
                    required
                    :rules="requiredRules"
                    v-model.trim="companyName"
                    solo
                  ></v-text-field>
                </div>
                <div class="col-md-12 pb-0 pt-0">
                  <label class="font text-gray" for=""
                    >Company Display Name</label
                  >
                  <v-text-field
                    required
                    :rules="requiredRules"
                    v-model.trim="displayName"
                    solo
                  ></v-text-field>
                </div>
                <div
                  class="col-md-12 pb-0 pt-0"
                  v-if="!$route.path.includes('advisor')"
                >
                  <label class="font text-gray" for="">Phone Number </label>
                  <v-text-field
                    required
                    :rules="phone.length > 0 ? phoneRules : []"
                    v-model.trim="phone"
                    solo
                  ></v-text-field>
                </div>
              </div>
              <div class="col-md-4">
                <div class="col-md-12 pb-0 pt-0 zip-field-height">
                  <label class="font text-gray" for="">Tax ID</label>
                  <v-text-field
                    required
                    v-model.trim="taxId"
                    @keyup="showSlash($event)"
                    solo
                    placeholder="00-0000000"
                    :rules="taxId.length > 0 ? taxIdRules : []"
                    counter="10"
                    maxlength="10"
                  ></v-text-field>
                </div>
                <div class="col-md-12 pb-0 pt-0">
                  <label class="font text-gray" for="">Website </label>
                  <v-text-field
                    required
                    v-model.trim="website"
                    solo
                  ></v-text-field>
                </div>
                <div class="col-md-12 pb-0 pt-0">
                  <label class="font text-gray" for="">HQ Address Line 1</label>
                  <v-text-field
                    required
                    v-model.trim="address1"
                    solo
                  ></v-text-field>
                </div>
                <div class="col-md-12 pb-0 pt-0">
                  <label class="font text-gray" for="">HQ Address Line 2</label>
                  <v-text-field
                    required
                    v-model.trim="address2"
                    solo
                  ></v-text-field>
                </div>
                <div
                  class="col-md-12 pb-0 pt-0"
                  v-if="$route.path.includes('advisor')"
                >
                  <label class="font text-gray" for="">Phone Number</label>
                  <v-text-field
                    required
                    :rules="phone.length > 0 ? phoneRules : []"
                    v-model.trim="phone"
                    solo
                  ></v-text-field>
                </div>
                <v-row class="no-padding">
                  <div class="col-md-6 pb-0 pt-0">
                    <label class="font text-gray" for="">City </label>
                    <v-text-field
                      required
                      v-model.trim="city"
                      solo
                    ></v-text-field>
                  </div>
                  <div class="col-md-3 pb-0 pt-0">
                    <label class="font text-gray" for="">State</label>
                    <v-select
                      :items="states"
                      item-text="name"
                      item-value="name"
                      required
                      v-model.trim="state"
                      solo
                    ></v-select>
                  </div>
                  <div class="col-md-3 zip-field-height pb-0 pt-0">
                    <label class="font text-gray" for="">Zip</label>
                    <v-text-field
                      :rules="zip.length > 0 ? zipRules : []"
                      type="number"
                      v-model.trim="zip"
                      solo
                    ></v-text-field>
                  </div>
                </v-row>
              </div>
              <div class="col-md-4">
                <div class="col-md-12 pb-0 pt-0">
                  <label class="font text-gray" for=""
                    >Company Logo (Image Size - Width = 100px Height =
                    50px)</label
                  >
                  <div class="row mt-1">
                    <div
                      class="col-xl-10 col-md-9 col-xs-12 col-sm-12"
                      cols="12"
                    >
                      <v-file-input
                        @change="Preview_image"
                        v-model.trim="dataComponents.image"
                        required
                        solo
                        truncate-length="15"
                        ref="fileInput"
                      ></v-file-input>
                    </div>
                    <div
                      class="col-xl-2 col-md-3 col-xs-12 col-sm-12"
                      cols="12"
                    >
                      <a
                        class="text-underline col-sm-12"
                        @click="activateFileInput"
                        style="padding: 23px"
                      >
                        Browse
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 pb-0 pt-0">
                  <label class="font text-gray" for=""
                    >Profile Picture (Image Size - Width = 100px Height =
                    50px)</label
                  >
                  <div class="row mt-1">
                    <div
                      class="col-xl-10 col-md-9 col-xs-12 col-sm-12"
                      cols="12"
                    >
                      <v-file-input
                        @change="Preview_profileImage"
                        v-model.trim="dataComponents.profileImage"
                        required
                        solo
                        truncate-length="15"
                        ref="profileInput"
                      ></v-file-input>
                    </div>
                    <div
                      class="col-xl-2 col-md-3 col-xs-12 col-sm-12"
                      cols="12"
                    >
                      <a
                        class="text-underline col-sm-12"
                        @click="activateProfileInput"
                        style="padding: 23px"
                      >
                        Browse
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div class="col-md-12 d-flex align-end flex-column">
              <a class="text-underline" @click="update"> Update & Save </a>
            </div>
          </v-form>
        </div>
      </div>
    </div>
  </v-row>
</template>
<script>
import api from "../../services/api";
import successModal from "../TablesTemplate/SuccessDialog.vue";

export default {
  components: { successModal },
  data() {
    return {
      companyName: "",
      displayName: "",
      taxId: "",
      website: "",
      logo: "",
      address1: "",
      address2: "",
      city: "",
      selectedItem: 0,
      state: "",
      zip: "",
      phone: "",
      accountType: "",
      typeItems: [],
      dataComponents: {
        image: {
          name: "",
        },
        profileImage: {
          name: "",
        },
      },
      typeOptions: [
        {
          text: "TPA",
          value: "TPA",
        },
        {
          text: "Broker Consultant",
          value: "brokerConsultant",
        },
        {
          text: "Analytics Consultant",
          value: "analyticsConsultant",
        },
      ],
      profileImg: "",
      logo: "",
      logoChange: false,
      profileChange: false,
      numberOfEmp: "",
      numberOfMembers: "",
      vendor: "",
      states: [],
      discountRate: "",
      fullName: "",
      valid: false,
      lastName: "",
      firstName: "",
      requiredRules: [(v) => !!v || "This Field is required"],
      imageEvent: null,
      imageProfleEvent: null,
      taxIdRules: [
        (v) => !!v || "This Field is required",
        (v) =>
          !!/[0-9]{2}\-[0-9]{7}/.test(v) ||
          "Tax ID should contain 9 digits, Please enter your Tax ID in XX-XXXXXXX format",
      ],
      phoneRules: [
        (v) =>
          /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(v) ||
          "Phone number must be in a valid format (XXX XXX XXXX)",
      ],
      zipRules: [
        (v) => (v && v.length == 5) || " Zip Code should be five digits long",
      ],
      showLastName: "",
      showFirstName: "",
      drawer: true,
      tab: "edit",
      items: [
        {
          title: "Profile Info",
          icon: "mdi-account",
          tab: "edit",
          active: true,
        },
        {
          title: "Edit Profile",
          icon: "mdi-account-edit",
          tab: "account",
          active: false,
        },
        // { title: "Users", icon: "mdi-account-group-outline", tab: "users" },
      ],
      mini: true,
    };
  },
  computed: {
    singleEmp() {
      let provider = "";
      if (localStorage.getItem("employer")) {
        provider = JSON.parse(localStorage.getItem("employer"));
      }

      return provider;
    },
    user() {
      let user = {
        firstName: "Bill",
        lastName: "Smith",
        email: "brian.mcnilty@usi.com",
      };
      if (localStorage.getItem("user")) {
        user = JSON.parse(localStorage.getItem("user"));
      }
      return user;
    },
  },
  created() {
    this.getStates();
    this.getInfo();
  },
  methods: {
    getLogoImg() {
      let user = JSON.parse(localStorage.getItem("user"));
      let url = "";
      if (this.$route.path.includes("employer-advisor")) {
        let advisorId = user.groupPortal[0].organization.employerAdvisor;
        url = `/advisors/${advisorId}`;
      } else if (this.$route.path.includes("provider-advisor")) {
        let advisorId = user.groupPortal[0].organization.providerAdvisor;
        url = `/advisors/${advisorId}/PA`;
      } else if (this.$route.path.includes("provider")) {
        let provider = user.entity._id;
        url = `/providers/${provider}`;
      } else if (this.$route.path.includes("employers")) {
        let employerId = user.groupPortal[0].organization.employer;
        url = `/employers/${employerId}`;
      }
      api()
        .get(url)
        .then((res) => {
          if (res.data.advisor.logo) {
            this.logo = res.data.advisor.logo;
          } else {
            this.logo = null;
          }
          if (
            res.data.advisor.primaryContact &&
            res.data.advisor.primaryContact.profile
          ) {
            this.profileImg = res.data.advisor.primaryContact.profile;
          } else {
            this.profileImg;
          }
        });
    },
    changeProfileImg() {
      let user = JSON.parse(localStorage.getItem("user"));
      let url = "";
      if (this.$route.path.includes("employer-advisor")) {
        let advisorId = user.groupPortal[0].organization.employerAdvisor;
        url = `/advisors/${advisorId}`;
      } else if (this.$route.path.includes("provider-advisor")) {
        let advisorId = user.groupPortal[0].organization.providerAdvisor;
        url = `/advisors/${advisorId}/PA`;
      } else if (this.$route.path.includes("provider")) {
        let provider = user.entity._id;
        url = `/providers/${provider}`;
      } else if (this.$route.path.includes("employers")) {
        let employerId = user.groupPortal[0].organization.employer;
        url = `/employers/${employerId}`;
      }
      api()
        .get(url)
        .then((res) => {
          if (res.data.advisor && res.data.advisor.primaryContact) {
            if (res.data.advisor.primaryContact.profile) {
              this.profileImg = res.data.advisor.primaryContact.profile;
            } else {
              this.profileImg = null;
            }
          } else {
            this.profileImg = null;
          }
        });
    },
    generatePdf() {
      window.open("https://api.apaly.net/terms");
    },
  showSlash(event){
      if (this.taxId.length == 2 && event.keyCode != 8) {
        this.taxId += "-";
      }
      if (this.taxId.length == 10) {
        api()
          .get("auth/isValidTaxId?taxId=" + this.taxId)
          .catch((res) => {
            if (res) {
              this.taxIdRules = [res.response.data.message];
            }
          });
      } else {
        this.taxIdRules = [
          (v) => !!v || "This Field is required",
          (v) =>
            !!/[0-9]{2}\-[0-9]{7}/.test(v) ||
            "Tax ID should contain 9 digits, Please enter your Tax ID in XX-XXXXXXX format",
        ];
      }
    },
    activateFileInput() {
      this.$refs.fileInput.$refs.input.click();
    },
    activateProfileInput() {
      this.$refs.profileInput.$refs.input.click();
    },
    getStates() {
      api()
        .get("/api/states")
        .then((res) => {
          res.data.forEach((el) => {
            if (el.active) {
              this.states.push(el);
            }
          });
        });
    },
    getInfo() {
      let user = JSON.parse(localStorage.getItem("user"));
      let url = "";
      if (this.$route.path.includes("employer-advisor")) {
        let advisorId = user.groupPortal[0].organization.employerAdvisor;
        url = `/advisors/${advisorId}`;
      } else if (this.$route.path.includes("provider-advisor")) {
        let advisorId = user.groupPortal[0].organization.providerAdvisor;
        url = `/advisors/${advisorId}/PA`;
      } else if (this.$route.path.includes("provider")) {
        let provider = user.entity._id;
        url = `/providers/${provider}`;
      } else if (this.$route.path.includes("employer")) {
        let employerId = user.groupPortal[0].organization.employer;
        url = `/employers/${employerId}`;
      }

      api()
        .get(url)
        .then((res) => {
          if (res) {
            let type = null;
            if (res.data.advisor) {
              type = res.data.advisor;
            } else if (res.data.provider) {
              type = res.data.provider;
            } else if (res.data.employer) {
              type = res.data.employer;
            }

            this.companyName = type.companyName;
            this.displayName = type.displayName ? type.displayName : "";
            this.taxId = type.taxId;
            this.address1 = type.address1 ? type.address1 : "";
            this.address2 = type.address2 ? type.address2 : "";
            this.website = type.website ? type.website : "";
            this.phone =
              type.primaryContact && type.primaryContact.phone
                ? type.primaryContact.phone
                : "";
            this.city = type.city ? type.city : "";
            this.state = type.state ? type.state : "";
            this.zip = type.zipCode ? type.zipCode : "";
            this.accountType = type.advisorType ? type.advisorType : "";
            this.dataComponents.image.name = type.logo ? type.logo : "";
            this.logo = type.logo ? type.logo : "";
            this.profileImg = type.primaryContact.profile
              ? type.primaryContact.profile
              : "";
            this.dataComponents.profileImage.name = type.primaryContact.profile
              ? type.primaryContact.profile
              : "";

            this.showFirstName =
              type.primaryContact && type.primaryContact.firstName
                ? type.primaryContact.firstName
                : user.firstName;
            this.showLastName =
              type.primaryContact && type.primaryContact.lastName
                ? type.primaryContact.lastName
                : user.lastName;

            this.firstName =
              type.primaryContact && type.primaryContact.firstName
                ? type.primaryContact.firstName
                : user.firstName;
            this.lastName =
              type.primaryContact && type.primaryContact.lastName
                ? type.primaryContact.lastName
                : user.lastName;
          }
        });

      this.email = this.user.email;
    },
    changeTab(item) {
      this.tab = item.tab;
    },
    closeSuccessModal() {
      this.$refs.success_modal.closePopUp();
    },
    update() {
      this.$refs.form.validate();
      if (this.valid) {
        let data = {
          firstName: this.firstName,
          lastName: this.lastName,
          phone: this.phone,
          companyName: this.companyName,
          displayName: this.displayName,
          address1: this.address1,
          address2: this.address2,
          website: this.website,
          city: this.city,
          state: this.state,
          zipCode: this.zip,
          taxId: this.taxId,
          advisorType: this.accountType,
        };
        let user = JSON.parse(localStorage.getItem("user"));
        let url = "";
        if (this.$route.path.includes("employer-advisor")) {
          let advisorId = user.groupPortal[0].organization.employerAdvisor;
          url = `/advisors/${advisorId}`;
        } else if (this.$route.path.includes("provider-advisor")) {
          let advisorId = user.groupPortal[0].organization.providerAdvisor;
          url = `/advisors/${advisorId}/PA`;
        } else if (this.$route.path.includes("provider")) {
          let provider = user.entity._id;
          url = `/providers/${provider}`;
        } else if (this.$route.path.includes("employer")) {
          let employerId = user.groupPortal[0].organization.employer;
          url = `/employers/${employerId}`;
        }

        api()
          .put(url, data)
          .then((res) => {
            if (res) {
              if (this.profileChange) {
                if (this.imageProfleEvent) {
                  let formData = new FormData();
                  formData.append("_method", "PUT");
                  formData.append("image", this.imageProfleEvent);
                  api()
                    .put(url + "/profile", formData)
                    .then((res) => {
                      document.getElementById("profile-img").click();
                    });
                } else {
                  api()
                    .delete(url + "/profile")
                    .then((res) => {
                      document.getElementById("profile-img").click();
                    });
                }
              }
              if (this.logoChange) {
                if (this.imageEvent) {
                  let formData = new FormData();
                  formData.append("_method", "PUT");
                  formData.append("image", this.imageEvent);
                  api()
                    .put(url + "/logo", formData)
                    .then((res) => {
                      document.getElementById("logo-img").click();
                    });
                } else {
                  api()
                    .delete(url + "/logo")
                    .then((res) => {
                      document.getElementById("logo-img").click();
                    });
                }
              }

              this.$refs.success_modal.openPopUp(
                "Account updated successfully"
              );
              if (this.$route.path.includes("employer-advisor")) {
                document.getElementById("changeDisplayName").click();
              } else if (this.$route.path.includes("provider-advisor")) {
                document.getElementById("changePADisplayName").click();
              } else if (this.$route.path.includes("provider")) {
                document.getElementById("changeProDisplayName").click();
              } else if (this.$route.path.includes("employer")) {
                document.getElementById("changeEmpDisplayName").click();
              }

              this.showFirstName = this.firstName;
              this.showLastName = this.lastName;

              this.logoChange = false;
              this.profileChange = false;
            }
          });
      }
    },
    Preview_image(event) {
      this.logoChange = true;
      this.imageEvent = event;
    },
    Preview_profileImage(event) {
      this.profileChange = true;
      this.imageProfleEvent = event;
    },
  },
};
</script>
<style>
.edit-profile-form-height {
  min-height: 78.2vh;
}
</style>