<template>
    <div>
    <div class="organization-pending-info" v-show="!activeEntity">
      <img class="org-pending-icon" src="/svg/organizationPending.svg" />
      <span class="org-pending-title">Your organization is pending</span>
      <span class="org-pending-info">
        Thank you for registering with Apaly.
      </span>
      <span class="org-pending-info">
        Your account is being reviewed and you will be notified once your
        account is approved.
      </span>
    </div>
      <v-row class="justify-center" v-if="activeEntity">
        <div v-for="item in items" :key="item.header" class="text-center justify-center align-center col-10 col-md-4 col-xl-3 col-lg-3">
          <v-card class="justify-center text-center" style="height: 15rem; cursor: pointer" @click="item.link ? $router.push(item.link) : null">
            <v-row class="mx-0 justify-center mt-1">
              <h5 class="text-center text-underline mt-4 font-quicksand-medium-18">
                {{ item.header }}
              </h5>
            </v-row>
            <v-row class="text-center justify-center mb-1">
              <v-card-title>
                <h3 class="dpc-cards">{{ item.title }}</h3>
              </v-card-title>
            </v-row>
            <v-row class="mx-5 justify-center my-0">
              <h5 class="blue-text card-desc-responsive text-center font-quicksand-medium-18">
                {{ item.desc }}
              </h5>
            </v-row>
          </v-card>
        </div>
        <div class="text-center justify-center align-center col-10 col-md-4 col-xl-3 col-lg-3">
          <v-card class="justify-center text-center" style="height: 15rem; cursor: pointer" @click="$router.push('/tpa/account')">
            <v-row class="mx-0 justify-center mt-1">
              <h5 class="text-center text-underline mt-4 font-quicksand-medium-18">
             Complete your account 
              </h5>
            </v-row>
            <v-row class="text-center justify-center mb-1">
              <v-card-title>
                <v-icon style="font-size: 45px; color:#0573f3">mdi-account</v-icon>
              </v-card-title>
            </v-row>
            <v-row class="mx-5 justify-center my-0 mt-4">
              <h5 class="blue-text card-desc-responsive text-center font-quicksand-medium-18">
              Click to enter your information
              </h5>
            </v-row>
          </v-card>
        </div>
        <div class="text-center justify-center align-center col-10 col-md-4 col-xl-3 col-lg-3">
          <v-card
          @click="openHeatMap()"
          class="justify-center text-center"
          style="height: 15rem; cursor: pointer"
        >
          <v-row class="mx-0 justify-center mt-1">
            <h5 class="text-center text-underline mt-4 font-quicksand-medium-18">
              Member Heat Map
            </h5>
          </v-row>
          <v-row class="mx-0 justify-center mt-3 mb-2">
            <h1 class="text-center text-underline mt-5">
              <v-icon style="font-size: 45px; color:#0573f3">mdi-map-marker</v-icon>
            </h1>
          </v-row>
          <v-row class="mx-5 justify-center my-0 mt-6">
            <h5
              class="blue-text card-desc-responsive-dpc text-center font-quicksand-medium-18 mb-5"
            >Click to view how many clinics are within driving distance of your clients' members.
            </h5>
          </v-row>
        </v-card>
        </div>
        <v-dialog v-model.trim="heatMapModal" width="100%" height="100%" class="success-modal">
          <v-card>
            <span align="end" class="pt-2 pr-0" role="span">
              <p class="font-weight-bold text-gray pr-3 pt-2" @click="heatMapModal = false">
                X
              </p>
            </span>
            <v-card-text style="padding:0 !important" class="success-modal pt-5">
              <iframe style="width:100%;height:600px" :src="mapLink"></iframe>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-row>
    </div>
</template>
<script>

import api from "@/services/api";

export default {
  data() {
    return {      
      mapLink:'https://www.easymapmaker.com/map/clinicsbyzip',
      loader: true,
      heatMapModal:false,
      items: [
        {
          link:'/tpa/income',
          header: "Total Admin Fees Earned",
          title: "",
          desc: "First create an employer profile, and then be able to explore savings and provider coverage.",
        },
     
      ],
      account: null,
      activeEntity: false,
    };
  },
  computed: {
    hasEmptyFields() {
      return this.fieldNames.some(fieldName => {
        const value = fieldName.split('.').reduce((obj, key) => obj[key], this.account);
        return !value;
      });
    },
  },
  created() {
     this.getTotal();
     this.getInfo();
     this.getEntityStatus();
  },
  methods: {
    getTotal(){
      api().get("/tpas/income/total")
        .then((res) => {
          if (res) {
            this.items[0].title = "$"+res.data.total.toFixed(2);
          }
        });
    },
    openHeatMap(){
      this.heatMapModal = true
    },
    getInfo() {
     api()
      .get(`/tpas`)
      .then((res) => {
       if (res) {
         this.account = res.data;
       }
       });
    },
    getEntityStatus() {
        const user = JSON.parse(localStorage.getItem('user'));
        
        if (user.entity && user.entity.status === "active") {
            this.activeEntity = true;
        }
        return this.activeEntity;
    },
  },
  
};
</script>