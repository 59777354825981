<template>
  <v-row class="add-client-form no-padding">
    <successModal
      ref="success_modal"
      v-on:confirm="closeSuccessModal"
    ></successModal>
    <errorModal ref="error_modal" v-on:confirm="closeErrorModal"></errorModal>
    <div class="col-md-12 form-box">
      <div class="row">
        <div class="col-lg-8 col-md-9 col-sm-12">
          <h3 class="form-title">
            <span
              v-if="this.$route.path.includes('affiliates')"
              class="form-title text-underline"
            >
              Invite Affiliate Providers
            </span>
            <span
              v-else-if="this.$route.path.includes('hospital-based')"
              class="form-title text-underline"
            >
              Invite Hospital Based Providers
            </span>
            <span v-else class="form-title ">
              Invite Providers
            </span>
          </h3>
        </div>
        <!-- <div class="col-md-4 col-sm-12 text-end" align="end">
          <v-btn class="blue-buttons">Copy and Email Invite Link</v-btn>
        </div> -->
      </div>
      <div class="form-row-height">
        <v-form ref="form" v-model.trim="valid">
          <div v-for="(n, index) in inviteArray" :key="index">
            <div class="row">
              <div class="col-md-4">
                <label class="font text-gray" for="">Email (required) </label>
                <v-text-field
                  required
                  :rules="n.email != '' ? emailRules : []"
                  v-model.trim="n.email"
                  solo
                ></v-text-field>
              </div>
              <div class="col-md-4">
                <label class="font text-gray" for=""
                  >First Name (optional)</label
                >
                <v-text-field
                  required
                  v-model.trim="n.firstName"
                  solo
                ></v-text-field>
              </div>
              <div class="col-md-4">
                <label class="font text-gray" for=""
                  >Last Name (optional)</label
                >
                <v-text-field
                  required
                  v-model.trim="n.lastName"
                  solo
                ></v-text-field>
              </div>
            </div>
            <v-divider class="mb-2"></v-divider>
          </div>
         <a @click="addAnotherRow" class="col-lg-10 col-md-10"
                >Add Another Row</a
              >
        </v-form>
      </div>
       <div class="row mt-2 mb-2">
            <div class="col-md-12 row justify-end">
              
              <v-btn class="blue-buttons" @click="goToTable()">
                Invite Providers
              </v-btn>
            </div>
          </div>
    </div>
  </v-row>
</template>
<script>
import successModal from "../../../TablesTemplate/SuccessDialog.vue";
import api from "../../../../services/api";
import errorModal from "../../../TablesTemplate/ErrorDialog.vue";
export default {
  components: { successModal, errorModal },
  data() {
    return {
      itemsLength: 3,
      emailRules: [
        (v) => !!v || "This Field is required",
        (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || "E-mail must be valid",
      ],
      valid: false,
      inviteArray: [
        {
          email: "",
          lastName: "",
          firstName: "",
        },
        {
          email: "",
          lastName: "",
          firstName: "",
        },
        {
          email: "",
          lastName: "",
          firstName: "",
        },
      ],
    };
  },
  computed: {
    singleMember() {
      return this.$store.getters.getMemberGetter;
    },
    singleClient() {
      return this.$store.getters.getClientGetter;
    },
    routeParams() {
      return this.$route.params.name;
    },
  },
  methods: {
    closeErrorModal() {
      this.$refs.error_modal.closePopUp();
    },
    closeSuccessModal() {
      if (this.$route.path.includes("affiliates")) {
        this.$router.push(`/provider/affiliates`);
      } else if (this.$route.path.includes("hospital-based")) {
        this.$router.push(`/provider/hospital-based`);
      } else {
        this.$router.push(`/provider/providers`);
      }
    },
    addAnotherRow() {
      this.inviteArray.push({
        email: "",
        lastName: "",
        firstName: "",
      });
    },
    goToTable() {
      this.$refs.form.validate();
      if (this.valid) {
        let user = JSON.parse(localStorage.getItem("user"));
        let provider = user.entity._id;

        let data = {
          providers: [],
        };
        for (let i = 0; i < this.inviteArray.length; i++) {
          if (this.inviteArray[i].email != "") {
            data.providers.push(this.inviteArray[i]);
          }
        }
        if (this.$route.path.includes("hospital-based")) {
          api()
            .post(`/providers/${provider}/inviteHospitalProviders`, data)
            .catch((err) => {
              if (err) {
                this.$refs.error_modal.openPopUp(err.response.data.message);
              }
            })
            .then((res) => {
              if (res) {
                let text = "Email sent sucessfully";
                this.$refs.success_modal.openPopUp(text);
              }
            });
        } else if (this.$route.path.includes("affiliates")) {
          api()
            .post(`/providers/${provider}/inviteAffiliateProviders`, data)
            .catch((err) => {
              if (err) {
                this.$refs.error_modal.openPopUp(err.response.data.message);
              }
            })
            .then((res) => {
              if (res) {
                let text = "Email sent sucessfully";
                this.$refs.success_modal.openPopUp(text);
              }
            });
        } else {
          api()
            .post(`/providers/${provider}/inviteGroupProviders`, data)
            .then((res) => {
              if (res) {
                let text = "Email sent sucessfully";
                this.$refs.success_modal.openPopUp(text);
              }
            });
        }
      }
    },
  },
};
</script>