<template>
    <div class="col-md-12">
      <div class="row blue-underline mb-3">
        <div class="col-md-12 mb-4 ml-4 mt-4 font-weight-bold text-gray d-flex">
          <svg
          @click="$router.go(-1)"
            class="mr-2 mt-1 cursor-pointer"
            width="14"
            height="16"
            viewBox="0 0 14 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.6269 23.764L1.99634 12.9562C1.92725 12.8861 1.87267 12.803 1.83571 12.7118C1.79875 12.6205 1.78014 12.5229 1.78095 12.4245C1.78176 12.326 1.80197 12.2287 1.84042 12.1381C1.87888 12.0474 1.93482 11.9653 2.00505 11.8963L12.8118 1.26475"
              stroke="gray"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <p @click="$router.go(-1)" class="cursor-pointer">Back to Solution </p>
        </div>
      </div>
      <div>
        <div class="row form-row-height pt-2 pb-2">
          <div
            class="col-md-2 ml-4 font-weight-bold"
            style="border-right: 1px solid #eeeded"
          >
            <div class="text-center justify-center mb-1 mt-1">
              <img
                id="logo-img"
                :src="singleSolution.logo ? singleSolution.logo : '/newapalylogoblue.png'"
                class="apaly-pic pointer mt-3 mb-2"
              />
            </div>
            <div class="text-center justify-center mb-1 mt-5" v-if="singleSolution.solution && singleSolution.solution.updatedAt ">
                Active since {{ singleSolution.solution.updatedAt ? singleSolution.solution.updatedAt : '' }}
              </div>
            <div class="text-center justify-center mb-1 mt-5">
              <v-btn
               class="participate mt-2" 
              color="#CB3B3B"
              @click="deactivationModal=true">
                Deactivate
              </v-btn>
            </div>
          </div>
          <div class="col-md-8">
            <div class="row col-md-12 font-weight-bold  light-blue-text">
            Closed  Solution Details
              <v-divider class="mt-3 ml-3"></v-divider>
              <div class="col-12 d-md-flex">
                <div class="col-lg-4 col-xl-4 col-12 text-gray">
                  General information about this solution
                </div>
                <div class="col-12">
                  <div class="col-md-12 d-flex pt-0 pb-0">
                    <span class="col-4 col-lg-3 col-xl-3 col-md-3 text-gray font-weight-bold"> Name:</span>
                    <span class="col-8 col-lg-9 col-xl-9 col-md-9 text-gray font-weight-bold" v-if="singleSolution.solution && singleSolution.solution.name"> {{singleSolution.solution.name}} </span>
                  </div>
                 
                  <div class="col-md-12 d-flex pt-0 pb-0">
                      <span class="col-4 col-lg-3 col-xl-3 col-md-3 text-gray font-weight-bold"> Type:</span>
                      <span class="col-8 col-lg-9 col-xl-9 col-md-9 text-gray" v-if="singleSolution.solution && singleSolution.solution.type"> {{singleSolution.solution.type}} </span>
                    </div>
                    <div class="col-md-12 d-flex pt-0 pb-0">
                        <span class="col-4 col-lg-3 col-xl-3 col-md-3 text-gray font-weight-bold">
                          Category:</span
                        >
                        <span class="col-8 col-lg-9 col-xl-9 col-md-9 text-gray" v-if="singleSolution.solution && singleSolution.solution.category"> {{singleSolution.solution.category}} </span>
                      </div>
                  <div class="col-md-12 d-flex pt-0 pb-0">
                    <span class="col-4 col-lg-3 col-xl-3 col-md-3 text-gray font-weight-bold">
                      Website:</span
                    >
                    <span class="col-8 col-lg-9 col-xl-9 col-md-9 text-gray" v-if="singleSolution.solution && singleSolution.solution.url">
                      <a :href="`${singleSolution.solution.url}`">{{singleSolution.solution.url}}</a>
                    </span>
                  </div>
                  <div class="col-md-12 d-flex pt-0 pb-0">
                    <span class="col-4 col-lg-3 col-xl-3 col-md-3 text-gray font-weight-bold">
                      Description:</span
                    >
                    <span class="col-8 col-lg-9 col-xl-9 col-md-9 text-gray" v-if="singleSolution.solution && singleSolution.solution.description">
                      {{singleSolution.solution.description}}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row col-md-12 font-weight-bold  light-blue-text">
              Solution Information
              <v-divider class="mt-3 ml-3"></v-divider>
              <div class="col-12 d-md-flex">
                <div class="col-lg-4 col-xl-4 col-12 text-gray">
                  Basic information about this contact
                </div>
                <div class="col-12"><div class="col-md-12 dsolution.-flex pt-0 pb-0">
                    <span class="col-4 col-lg-3 col-xl-3 col-md-3 text-gray font-weight-bold">
                     Point of  Contact:</span
                    >
                    <span class="col-8 col-lg-9 col-xl-9 col-md-9 text-gray"> 
                      {{ singleSolution.acceptedByContact ? singleSolution.acceptedByContact.firstName : "/" }}
                      {{ singleSolution.acceptedByContact ? singleSolution.acceptedByContact.lastName  : "/"}} 
                    </span>
                  </div>
                   <div class="col-md-12 d-flex pt-0 pb-0">
                    <span class="col-4 col-lg-3 col-xl-3 col-md-3 text-gray font-weight-bold"> Email:</span>
                    <span class="col-8 col-lg-9 col-xl-9 col-md-9 text-gray">
                      <a>{{ singleSolution.acceptedByContact ? singleSolution.acceptedByContact.email : "/"}}</a>
                    </span>
                  </div>
                  <div class="col-md-12 d-flex pt-0 pb-0">
                    <span class="col-4 col-lg-3 col-xl-3 col-md-3 text-gray font-weight-bold">
                      Address:</span
                    >
                    <span class="col-8 col-lg-9 col-xl-9 col-md-9 text-gray" v-if="singleSolution.solution && singleSolution.solution.facilitator">
                      {{  singleSolution.solution.facilitator ? singleSolution.solution.facilitator.address1 : "/"}}
                      </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row col-md-12 font-weight-bold  light-blue-text">
              Contract Details
              <v-divider class="mt-3 ml-3"></v-divider>
              <div class="col-12 d-md-flex">
                <div class="col-lg-4 col-xl-4 col-12 text-gray">
                  Information about contract
                </div>
                <div class="col-12">
                    <div class="col-md-12 d-flex pt-0 pb-0">
                        <span class="col-4 col-lg-3 col-xl-3 col-md-3 text-gray font-weight-bold"> Definition of service:</span>
                        <span class="col-8 col-lg-9 col-xl-9 col-md-9 text-gray font-weight-bold"> 
                          {{ singleSolution.solution?.serviceDefinition }}
                        </span>
                      </div>
                  <div class="col-md-12 d-flex pt-0 pb-0">
                    <span class="col-4 col-lg-3 col-xl-3 col-md-3 text-gray font-weight-bold">
                        Rates and Fees:</span
                    >
                    <span class="col-8 col-lg-9 col-xl-9 col-md-9 text-gray font-weight-bold">
                      {{singleSolution.solution?.serviceRate}}
                    </span>
                  </div>
                  <div class="col-md-12 d-flex pt-0 pb-0">
                    <span class="col-4 col-lg-3 col-xl-3 col-md-3 text-gray font-weight-bold">
                      TpaFee:</span
                    >
                    <span class="col-8 col-lg-9 col-xl-9 col-md-9 text-gray font-weight-bold">
                      {{singleSolution.tpaFee}}
                    </span>
                  </div>
                  <div class="col-md-12 d-flex pt-0 pb-0">
                    <span class="col-4 col-lg-3 col-xl-3 col-md-3 text-gray font-weight-bold">
                      Contract:</span>
                    <span class="col-8 col-lg-9 col-xl-9 col-md-9 text-gray">
                        <a class="text-underline">Link to contract</a> 
                    </span>
                  </div>
                 
                  <br/>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="gray-top-border">
        <div class="col-md-12" align="end">
          <!-- <span
            class="light-blue-text text-underline mr-10 font-weight-bold pointer"
            @click="addSolution"
            >Update</span
          > -->
          <span
            class="light-blue-text text-underline mr-10 ml-10 font-weight-bold pointer"
            @click="$router.go(-1)"
            >Close</span
          >
        </div>
      </div>
      <v-dialog v-model.trim="deactivationModal" width="700" class="success-modal">
        <v-card>
          <v-card-text class="success-modal justify-center pt-5">
            <h2 class="font-weight-bolder justify-center d-flex color-black mt-10 mb-5">Solution Deactivation Warning  </h2>
            <div class="col-12 display mt-3 text-center justify-center d-flex mb-3">
                <span class="text-center font-18 mt-2 color-black mt-5 col-10">
                  Deactivating a solution may have consequences on existing invoices and plan member services.
                   Please email <strong >support@apaly.com </strong> to deactivate this solution
            </span>
          </div>
            <div class="text-center col-12">
              <v-btn @click="deactivationModal = false" 
              color="#16407A"
              class="okay">Okay</v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
      <successModal
        ref="success_modal"
        v-on:confirm="closeSuccessModal"
      ></successModal>
    </div>
  </template>
    
    <script>
  import successModal from "../../../../../TablesTemplate/SuccessDialog.vue";
  import api from "../../../../../../services/api";
  import { mapGetters } from "vuex";

  export default {
    components: { successModal },
    data() {
      return {
        deactivationModal: false,
        logo: "",
        singleSolution: {},
          enrollment: true,
          contract: false,
          success: false,
          participation: false,
        item: {
          category: "DPC",
          url: "",
          contact: "",
          email: "",
          definition: "",
          description: "",
        },
        valid: false,
        emailRules: [
          (v) => !!v || "E-mail is required",
          (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || "E-mail must be valid",
        ],
        requiredRules: [(v) => !!v || "This Field is required"],
      };
    },
  
    computed: {
      ...mapGetters(["getTpaSingleSolution"]),
    },
    created() {
      this.getSolution();
    },
    methods: {
      closeSuccessModal() {
        this.$parent.getSolutions();
        this.$router.push("/tpa/solutions");
      },
      getSolution() {
        console.log('param',this.$route.params)
          api()
            .get(`/tpas/clients/${this.$route.params.clientId}/solutions/${this.$route.params.solutionId}`)
            .then((res) => {
              if (res) {
                console.log('here')
                this.singleSolution = res.data;
                const date = new Date(this.singleSolution.solution.updatedAt);
              this.singleSolution.solution.updatedAt = date.toLocaleDateString();
              }
            });
      },
   
    },
  };
  </script>
    <style scoped>
  .participate {
    width: 150px;
    color: white;
  }
  .next {
      width: 650px;
      color: white;
    }
    .okay {
      width: 250px;
      color: white;
    }
    .color-black{
        color: black;
    }
  </style>
    