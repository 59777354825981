<template>
  <v-row class="add-client-form no-padding">
    <div class="col-md-12 form-box">
      <removeDialog
        ref="dialog_delete"
        v-on:confirm="confirmRemove"
      ></removeDialog>
      <successModal
        ref="success_modal"
        v-on:confirm="updateModal"
      ></successModal>
      <div class="row">
        <div class="col-md-12">
          <h3
            class="form-title"
            v-if="getSingleProvider && getSingleProvider.type == 2"
          >
            Provider Oganization
          </h3>
          <h3 class="form-title" v-else>Provider Individual</h3>
        </div>
      </div>
    
      <v-form ref="form" v-model.trim="valid" class="row">
        <div class="col-md-6">
          <div class="col-md-12 pt-0 pb-0">
            <label
              v-if="getSingleProvider && getSingleProvider.type == 2"
              class="font text-gray"
              for=""
              >Organization Name</label
            >
            <label class="font text-gray" v-else for="">Provider Name</label>
            <v-text-field
              required
              v-model.trim="providerName"
              solo
            ></v-text-field>
          </div>
          <div class="col-md-12 pt-0 pb-0">
            <label class="font text-gray" for="">Address 1</label>
            <v-text-field required v-model.trim="address1" solo></v-text-field>
          </div>
          <div class="col-md-12 pt-0 pb-0">
            <label class="font text-gray" for="">Address 2</label>
            <v-text-field required v-model.trim="address2" solo></v-text-field>
          </div>
          <div class="col-md-12 row no-padding">
            <div class="col-md-4 pt-0 pb-0">
              <label class="font text-gray" for="">City</label>
              <v-text-field solo v-model.trim="providerCity"></v-text-field>
            </div>
            <div class="col-md-4 pt-0 pb-0">
              <label class="font text-gray" for="">State</label>
              <v-text-field solo v-model.trim="providerState"></v-text-field>
            </div>
            <div class="col-md-4 pt-0 pb-0">
              <label class="font text-gray" for="">Zip</label>
              <v-text-field
                type="number"
                solo
                v-model.trim="providerZip"
              ></v-text-field>
            </div>
          </div>
          <div class="col-md-12 pt-0 pb-0">
            <label class="font text-gray" for="">Tax ID</label>
            <v-text-field
              :rules="taxIdRules"
              @keyup="showSlash($event)"
              counter="10"
              maxlength="10"
              solo
              v-model.trim="taxId"
            ></v-text-field>
          </div>
          <div class="col-md-12 pt-0 pb-0">
            <label class="font text-gray" for="">Provider Type </label>
            <v-select
              :items="providerTypeItems"
              solo
              v-model.trim="providerType"
            ></v-select>
          </div>
          <div class="col-md-12 pt-0 pb-0">
            <label class="font text-gray" for="">Taxonomy</label>
            <v-text-field solo v-model.trim="taxonomy"></v-text-field>
          </div>
          <div class="col-md-12 pt-0 pb-0" v-if="showRates">
            <v-btn class="blue-buttons" @click="dialog1 = true"
              >View Contract</v-btn
            >
          </div>
        </div>
        <div class="col-md-6">
          <div class="col-md-12 pt-0 pb-0">
            <label class="font text-gray" for=""
              >Primary Contact First Name
            </label>
            <v-text-field
              required
              v-model.trim="firstName"
              solo
              :rules="requiredRules"
            ></v-text-field>
          </div>
          <div class="col-md-12 pt-0 pb-0">
            <label class="font text-gray" for=""
              >Primary Contact Last Name</label
            >
            <v-text-field
              required
              v-model.trim="lastName"
              solo
              :rules="requiredRules"
            ></v-text-field>
          </div>
          <div class="col-md-12 pt-0 pb-0">
            <label class="font text-gray" for="">Primary Contact Title</label>
            <v-text-field
              required
              v-model.trim="title"
              solo
              :rules="requiredRules"
            ></v-text-field>
          </div>
          <div class="col-md-12 pt-0 pb-0">
            <label class="font text-gray" for="">Primary Contact Email</label>
            <v-text-field
              required
              disabled
              v-model.trim="email"
              solo
            ></v-text-field>
          </div>
          <div class="col-md-12 pt-0 pb-0">
            <label class="font text-gray" for="">Primary Contact Phone </label>
            <v-text-field
              required
              v-model.trim="phone"
              solo
              type="number"
            ></v-text-field>
          </div>
          <div class="col-md-12 mt-6" v-if="showRates">
            <div class="row mt-2 col-md-12">
              <div class="col-md-12">
                <div class="row text-underline font">
                  Contract Rate Controls
                </div>
              </div>
              <div class="col-xl-5 col-lg-5 col-md-5 col-sm-5">
                <div class="text-gray row">Your Rates</div>
                <div class="row mt-3">
                  <div v-if="showInpatient">
                    <input
                      type="number"
                      :class="
                        redColorInpatient ? 'rates-provider-inputs-color' : ''
                      "
                      @focus="changeRates('inpatient')"
                      :disabled="rateStatus == 'matched' ? true : false"
                      v-model.trim="inpatient"
                      solo
                      class="mr-1 pointer rates-provider-inputs-test"
                    />
                    <div class="text-center small-text text-gray">
                      Inpatient
                    </div>
                  </div>
                  <div>
                    <input
                      :class="
                        redColorOutpatient ? 'rates-provider-inputs-color' : ''
                      "
                      @focus="changeRates('outpatient')"
                      type="number"
                      :disabled="rateStatus == 'matched' ? true : false"
                      class="rates-provider-inputs-test pointer"
                      v-model.trim="outpatient"
                    />
                    <div class="text-center small-text text-gray">
                      Outpatient
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-5 col-lg-5 col-md-5 col-sm-5">
                <div class="text-gray row">Provider Rates</div>
                <div v-if="showInputs" class="row mt-3">
                  <div>
                    <input
                      v-model.trim="providerInpatient"
                      disabled
                      solo
                      class="mr-3 rates-provider-inputs-test"
                    />
                    <div class="text-center small-text text-gray">
                      Inpatient
                    </div>
                  </div>
                  <div>
                    <input
                      class="rates-provider-inputs-test"
                      v-model.trim="providerOutpatient"
                      disabled
                    />
                    <div class="text-center small-text text-gray">
                      Outpatient
                    </div>
                  </div>
                </div>
                <div
                  class="row mt-3 text-underline"
                  v-else-if="showRequestToUnmaskRates"
                >
                  <a @click="sendRequest">Request to Unmask Rates </a>
                </div>
                <div class="font blue-text row mt-3" v-else-if="requestStatus">
                  Request Status :
                  <span
                    v-html="
                      requestStatus
                        ? requestStatus.charAt(0).toUpperCase() +
                          requestStatus.slice(1)
                        : '/'
                    "
                  ></span>
                </div>
              </div>
              <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2">
                <div class="text-gray row">Status</div>
                <div class="row mt-3">
                  <div
                    v-html="rateStatus == 'matched' ? 'Matched' : 'Not Matched'"
                    :class="
                      rateStatus == 'matched'
                        ? 'green--text font'
                        : 'red--text font'
                    "
                  ></div>
                </div>
              </div>
            </div>
            <div class="col-md-12 text-end" v-if="showUpdateButton">
              <v-btn class="blue-buttons text-end" @click="updateRates"
                >Update</v-btn
              >
            </div>
          </div>
        </div>
        <div class="col-md-12 d-flex align-end flex-column" v-if="$role != 'user'">
          <div>
            <v-btn  class="blue-buttons mr-6 mt-2" @click="removeProvider">
              Remove Provider
            </v-btn>
            <v-btn
              class="blue-buttons mr-6 ml-6 mt-2"
              @click="$router.push(`/employer/providers`)"
            >
              Cancel
            </v-btn>
            <v-btn class="blue-buttons mt-2" @click="updateProvider">
              Save
            </v-btn>
          </div>
        </div>
      </v-form>
    </div>
    <v-dialog v-model.trim="dialog1" width="600">
       <v-card>
        <v-card-title class="text-h6 text-underline">
          Based on Provider Settings
        </v-card-title>
          
        <v-card-text class="black--text font-weight-black">
          For purposes of demo. <br /> <br />
          The provider can choose one of two options in their settings, related
          to unmasking their rates for employers: <br /><br />
          <v-icon small class="mr-2 dot">fa fa-circle</v-icon> Automatically
          unmask rates upon employer request, and notify provider, or
          <br /><br />
          <v-icon small class="mr-2 dot">fa fa-circle</v-icon> Send notification
          to provider requesting approval to unmask the rates.
        </v-card-text>
        <v-card-actions class="justify-end">
          <div class="text-end justify-end col-md-12">
            <v-btn class="blue-buttons col-md-4 mb-34" @click="dialog1 = false">Proceed</v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <errorModal ref="error_modal" v-on:confirm="closeErrorModal"></errorModal>
  </v-row>
</template>
<script>
import successModal from "../../../../TablesTemplate/SuccessDialog.vue";
import removeDialog from "../../../../TablesTemplate/RemoveDialog.vue";
import errorModal from "../../../../TablesTemplate/ErrorDialog.vue";
import api from "../../../../../services/api";
export default {
  components: {
    successModal,
    removeDialog,
    errorModal,
  },
  data() {
    return {
      rating: 4,
      dialog1: false,
      switch1: true,
      providerName: "",
      providerCity: "",
      participation: "",
      providerType: "",
      providerStatus: "",
      inpatient: "",
      outpatient: "",
      providerInpatient: "",
      providerOutpatient: "",
      distance: "",
      selectAsPreferredProvider: "",
      address1: "",
      address2: "",
      taxId: "",
      taxonomy: "",
      firstName: "",
      lastName: "",
      showInputs: false,
      title: "",
      phone: "",
      email: "",
      providerZip: "",
      rateStatus: "",
      providerState: "",
      requestStatus: "",
      showRates: true,
      showRequestToUnmaskRates: false,
      showUpdateButton: true,
      showInpatient: false,
      valid: false,
      providerTypeItems: [
        {
          text: "Group",
          value: "group",
        },
        {
          text: "Hospital",
          value: "hospital",
        },
        {
          text: "Affiliate",
          value: "affiliate",
        },
      ],
      requiredRules: [(v) => !!v || "This Field is required"],
      taxIdRules: [
        (v) => !!v || "This Field is required",
        (v) =>
          !!/[0-9]{2}\-[0-9]{7}/.test(v) ||
          "Tax ID should contain 9 digits, Please enter your Tax ID in XX-XXXXXXX format",
      ],
      emailRules: [
        (v) => !!v || "This Field is required",
        (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || "E-mail must be valid",
      ],
      phoneRules: [
        (v) =>
          /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(v) ||
          "Phone number must be in a valid format (XXX XXX XXXX)",
      ],
    };
  },
  created() {
    if (this.getSingleProvider) {
      this.providerName = this.getSingleProvider.companyName
        ? this.getSingleProvider.companyName
        : this.getSingleProvider.providerName
        ? this.getSingleProvider.providerName
        : "/";
      this.rateStatus = this.getSingleProvider.rate
        ? this.getSingleProvider.rate
        : "";

      if (this.getSingleProvider.contract) {
        if (this.getSingleProvider.contract.providerRates.inpatient) {
          this.providerInpatient =
            this.getSingleProvider.contract.providerRates.inpatient;
          this.showInpatient = true;
        }
        this.providerOutpatient =
          this.getSingleProvider.contract.providerRates.outpatient;
      }

      //od ovde
      if (
        this.getSingleProvider.ratesVisibility &&
        !this.getSingleProvider.ratesVisibility.requestedAccess
      ) {
        this.showRequestToUnmaskRates = true;
        this.showInputs = false;
        this.showUpdateButton = false;
      } else if (
        this.getSingleProvider.ratesVisibility.requestedAccess &&
        this.getSingleProvider.ratesVisibility.status
      ) {
        this.showRequestToUnmaskRates = false;
        this.showInputs = false;
        this.requestStatus = this.getSingleProvider.ratesVisibility.status;
        this.showUpdateButton = false;

        if (this.getSingleProvider.ratesVisibility.status == "denied") {
          this.showInputs = false;
          this.showRequestToUnmaskRates = true;
          this.requestStatus = false;
          this.showUpdateButton = false;
        } else if (
          this.getSingleProvider.ratesVisibility.status == "accepted"
        ) {
          if (
              this.getSingleProvider.status == "contracted" &&
              this.getSingleProvider.rate == "matched"
            ) {
              this.showRates = false;
            }else{
              this.showInputs = true;
              this.showUpdateButton = true;
            }
     
         
        }
      } else if (
        this.getSingleProvider.settings &&
        this.getSingleProvider.settings.ratesVisibility
      ) {
        this.showRates = true;
        if (
          this.getSingleProvider.settings.ratesVisibility.employersRequireAccess
        ) {
          if (
            !this.getSingleProvider.settings.ratesVisibility.hideFromEmployers
          ) {
            this.showInputs = true;
            this.showRequestToUnmaskRates = false;
            if (
              this.getSingleProvider.status == "contracted" &&
              this.getSingleProvider.rate == "matched"
            ) {
              this.showRates = false;
            }
          } else if (
            this.getSingleProvider.settings.ratesVisibility
              .employersClickShowRates
          ) {
            this.showRequestToUnmaskRates = true;
            this.showUpdateButton = false;
            this.showInputs = false;
          }
        }
      }
      this.providerCity = this.getSingleProvider.city
        ? this.getSingleProvider.city
        : "";
      this.providerStatus = this.getSingleProvider.status
        ? this.getSingleProvider.status
        : "";
      this.address1 = this.getSingleProvider.address1
        ? this.getSingleProvider.address1
        : "";
      this.address2 = this.getSingleProvider.address2
        ? this.getSingleProvider.address2
        : "";
      this.providerZip = this.getSingleProvider.zipCode
        ? this.getSingleProvider.zipCode
        : "";
      this.providerState = this.getSingleProvider.state
        ? this.getSingleProvider.state
        : "";
      this.taxonomy = this.getSingleProvider.taxonomy
        ? this.getSingleProvider.taxonomy
        : this.getSingleProvider.specialty
        ? this.getSingleProvider.specialty
        : "";
      this.phone = this.getSingleProvider.primaryContact.phone
        ? this.getSingleProvider.primaryContact.phone
        : "";
      this.email = this.getSingleProvider.primaryContact.email
        ? this.getSingleProvider.primaryContact.email
        : "";
      this.lastName = this.getSingleProvider.primaryContact.lastName
        ? this.getSingleProvider.primaryContact.lastName
        : "";
      this.firstName = this.getSingleProvider.primaryContact.firstName
        ? this.getSingleProvider.primaryContact.firstName
        : "";
      this.title = this.getSingleProvider.primaryContact.title
        ? this.getSingleProvider.primaryContact.title
        : "";
      this.taxId = this.getSingleProvider.taxId
        ? this.getSingleProvider.taxId
        : "";
      this.providerType = this.getSingleProvider.providerType;
      if (this.getSingleProvider.contract) {
        let user = JSON.parse(localStorage.getItem("user"));
        let employerId = user.groupPortal[0].organization.employer;
        api()
          .get(`/employers/${employerId}/contract`)
          .then((res) => {
            if (res) {
              this.outpatient = res.data.contract.employerRates.outpatient;
              this.showInpatient = false;
              if (
                res.data.contract.employerRates &&
                res.data.contract.employerRates.inpatient
              ) {
                this.showInpatient = true;
                this.inpatient = res.data.contract.employerRates.inpatient;
              }
            }
          });
      }

      if (this.getSingleProvider.rate == "NA") {
        this.showRates = false;
      }
    }
  },
  computed: {
    route() {
      return this.$route.path;
    },
    getSingleProvider() {
      return this.$store.getters.getSingleProviderInEmployer;
    },
    routeParams() {
      return this.$route.params.name;
    },
    redColorInpatient() {
      let redColor = false;
      if (
        this.inpatient != this.providerInpatient &&
        this.rateStatus != "matched"
      ) {
        redColor = true;
      }
      return redColor;
    },
    redColorOutpatient() {
      let redColor = false;
      if (
        this.outpatient != this.providerOutpatient &&
        this.rateStatus != "matched"
      ) {
        redColor = true;
      }
      return redColor;
    },
  },
  methods: {
    changeRates() {
      this.inpatient = this.providerInpatient;
      this.outpatient = this.providerOutpatient;
      if (
        this.inpatient == this.providerInpatient &&
        this.outpatient == this.providerOutpatient
      ) {
        this.rateStatus = "matched";
      } else {
        this.rateStatus = "not matched";
      }
    },
    sendRequest() {
      let user = JSON.parse(localStorage.getItem("user"));
      let employerId = user.groupPortal[0].organization.employer;
      api()
        .post(
          `/employers/${employerId}/providers/${this.getSingleProvider._id}/unmaskRequest`
        )
        .catch((err) => {
          if (err.response.status == "404" || err.response.status == "400") {
            this.showInputs = true;
          }
          this.$refs.error_modal.openPopUp(err.response.data.error);
        })
        .then((res) => {
          if (
            res.data.provider.contract &&
            res.data.provider.contract.providerRates
          ) {
            this.showInputs = true;
            this.showUpdateButton = false;
            this.showRequestToUnmaskRates = false;
          } else if (
            !res.data.provider.settings.ratesVisibility.requestedAccess
          ) {
            this.showUpdateButton = false;
            this.showInputs = false;
            this.showRequestToUnmaskRates = true;
          } else if (
            res.data.provider.settings.ratesVisibility.requestedAccess &&
            res.data.provider.settings.ratesVisibility.status === "pending"
          ) {
            this.showUpdateButton = false;
            this.showRequestToUnmaskRates = false;
            this.requestStatus = res.data.provider.ratesVisibility.status;
          } else if (
            res.data.provider.settings.ratesVisibility.requestedAccess &&
            res.data.provider.settings.ratesVisibility.status === "denied"
          ) {
            this.showUpdateButton = false;
            this.showRequestToUnmaskRates = true;
          }
        });
    },
   showSlash(event){
      if (this.taxId.length == 2 && event.keyCode != 8) {
        this.taxId += "-";
      }
      if (this.taxId.length == 10) {
        api()
          .get("auth/isValidTaxId?taxId=" + this.taxId)
          .catch((res) => {
            if (res) {
              this.taxIdRules = [res.response.data.message];
            }
          });
      } else {
        this.taxIdRules = [
          (v) => !!v || "This Field is required",
          (v) =>
            !!/[0-9]{2}\-[0-9]{7}/.test(v) ||
            "Tax ID should contain 9 digits, Please enter your Tax ID in XX-XXXXXXX format",
        ];
      }
    },
    closeErrorModal() {
      this.$refs.error_modal.closePopUp();
    },
    updateRates() {
      if (this.rateStatus == "matched") {
        let user = JSON.parse(localStorage.getItem("user"));
        let employerId = user.groupPortal[0].organization.employer;
        let data = {
          employerRates: {
            outpatient: Number(this.outpatient),
          },
        };
        if (this.inpatient) {
          data.employerRates.inpatient = Number(this.inpatient);
        }

        api()
          .post(
            `/employers/${employerId}/contract/${this.getSingleProvider._id}/custom`,
            data
          )
          .catch((err) => {
            if (err) {
              this.$refs.error_modal.openPopUp(err.response.data.message);
            }
          })
          .then((res) => {
            if (res) {
              this.$refs.success_modal.openPopUp(
                "Contract created successfully"
              );
            }
          });
      }
    },
    updateModal() {
      this.$refs.success_modal.closePopUp();
      this.$router.push("/employer/providers");
    },
    updateProvider() {
      this.$refs.form.validate();
      if (this.valid) {
        let provider = {};
        if (this.getSingleProvider.type == "1") {
          provider = {
            providerName: this.providerName,
            taxonomy: this.taxonomy,
            city: this.providerCity,
            state: this.providerState,
            zip: this.providerZip,
            preferredParticipation: this.participation,
            providerCategory: this.providerType,
            providerStatus: this.providerStatus,
            address1: this.address1,
            address2: this.address2,
            taxId: this.taxId,
            firstName: this.firstName,
            lastName: this.lastName,
            title: this.title,
            email: this.email,
            phone: this.phone,
          };
        } else if (this.getSingleProvider.type == "2") {
          provider = {
            companyName: this.providerName,
            city: this.providerCity,
            state: this.providerState,
            zip: this.providerZip,
            preferredParticipation: this.participation,
            providerCategory: this.providerType,
            providerStatus: this.providerStatus,
            address1: this.address1,
            address2: this.address2,
            taxId: this.taxId,
            firstName: this.firstName,
            lastName: this.lastName,
            title: this.title,
            email: this.email,
            phone: this.phone,
            taxonomy: this.taxonomy,
          };
        }

        let user = JSON.parse(localStorage.getItem("user"));
        let employerId = user.groupPortal[0].organization.employer;

        api()
          .put(
            `/employers/${employerId}/providers/${this.getSingleProvider._id}`,
            provider
          )
          .then((res) => {
            this.$refs.success_modal.openPopUp("Provider successfully updated");
            this.$store.commit(
              "setSingleProviderInEmployerAdvisor",
              res.data.provider
            );
          });
      }
    },
    removeProvider() {
      this.$refs.dialog_delete.openPopUp(
        "Are you sure that you want to delete this provider?"
      );
    },
    confirmRemove() {
      let user = JSON.parse(localStorage.getItem("user"));
      let employerId = user.groupPortal[0].organization.employer;
      api()
        .delete(
          `/employers/${employerId}/providers/${this.getSingleProvider._id}`
        )
        .then((res) => {
          if (res) {
            this.$router.replace(`/employer/providers`);
          }
        });
    },
    closePopUp() {
      this.dialog = false;
    },
  },
};
</script>
