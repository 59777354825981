<template>
    <v-row class="add-client-form no-padding">
        <successModal  ref="success_modal" v-on:confirm="closeSuccessModal"></successModal>
        <errorModal  ref="error_modal" v-on:confirm="closeErrorModal"></errorModal>
        <div class="col-md-12 form-box">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-12 invite-buttons">
                    <h3 class="font">Invite Members</h3>
                    <!-- <v-btn  class="blue-buttons">Copy and Email Invite Link</v-btn> -->
                </div>
            </div>
            <v-form ref="form" v-model.trim="valid">
                <div v-for="(n,index) in inviteArray" :key="index">
                    <div  class="row">
                        <div class="col-md-4">
                            <label class="font text-gray" for="">Email (required) </label>
                            <v-text-field
                                    required
                                    :rules="n.email != '' ? emailRules : []"
                                    v-model.trim="n.email"
                                    solo
                            ></v-text-field>
                        </div>
                        <div class="col-md-4">
                            <label class="font text-gray" for="">First Name (optional)</label>
                            <v-text-field
                                    required
                                    v-model.trim="n.firstName"
                                    solo
                            ></v-text-field>
                        </div>
                        <div class="col-md-4">
                            <label class="font text-gray" for="">Last Name (optional)</label>
                            <v-text-field
                                    required
                                    v-model.trim="n.lastName"
                                    solo
                            ></v-text-field>
                        </div>
                    </div>
                    <v-divider class=" mb-2"></v-divider>
                </div>
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-12 invite-buttons">
                        <a   @click="addAnotherRow" class="text-decoration-underline">Add Another</a>
                        <div style="display: flex;gap: 1rem">
                            <v-btn class="blue-buttons">Cancel</v-btn>
                            <v-btn @click="showTable" class="blue-buttons">Save & Invite</v-btn>
                        </div>
                    </div>
                </div>
            </v-form>
        </div>
    </v-row>

</template>

<script>
    import successModal from '../../../../TablesTemplate/SuccessDialog.vue'
    import errorModal from '../../../../TablesTemplate/ErrorDialog.vue'

    import api from '../../../../../services/api'

    export default {
        name: 'EmployerInviteMembers',
        data(){
            return{
                itemsLength:3,
                emailRules: [
                    v => !!v || 'This Field is required',
                    v => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || 'E-mail must be valid',
                ],
                valid:false,
                inviteArray:[
                    {
                        'email':"",
                        'lastName':'',
                        'firstName':''
                    },
                    {
                        'email':"",
                        'lastName':'',
                        'firstName':''
                    },
                    {
                        'email':"",
                        'lastName':'',
                        'firstName':''
                    }
                ]
            }
        },
        components:{
            successModal,errorModal
        },
        methods:{
              closeErrorModal(){
                this.$refs.error_modal.closePopUp();
            },
            showTable(){
                this.$refs.form.validate();
                if(this.valid){
                    let user = JSON.parse(localStorage.getItem('user'));
                    let employerId = user.groupPortal[0].organization.employer;

                    let data = {
                        'members':[]
                    }
                    for(let i = 0; i < this.inviteArray.length; i++){
                        if(this.inviteArray[i].email != ''){
                            data.members.push(this.inviteArray[i])
                        }
                    }

                    api().post(`/employers/${employerId}/inviteMembers`,data)
                    .catch((err)=>{
                        if(err){
                            this.$refs.error_modal.openPopUp(err.response.data.message)
                        }
                    }).then((res)=>{
                        if(res){
                            let text = 'Email sucessfully sent'
                            this.$refs.success_modal.openPopUp(text);


                        }
                    });
                }
            },
            addAnotherRow(){
                this.inviteArray.push({
                    'email':"",
                    'lastName':'',
                    'firstName':''
                })

            },
            closeErrorModal(){
               this.$refs.error_modal.closePopUp();
            },
            closeSuccessModal(){
                this.$router.push(`/employer/members`)
                // let data = this.inviteArray

            },
        }
    }
</script>

<style scoped>
    @media (min-width: 500px){
        .invite-buttons{
            display: flex;
            justify-content: space-between
        }
    }
</style>