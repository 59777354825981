<template>
  <v-row class="add-client-form no-padding pa-1">
    <successModal
      ref="success_modal"
      v-on:confirm="closeSuccessModal"
    ></successModal>
    <div class="col-md-12">
      <div class="row blue-underline margin-l-11">
        <div class="col-md-12">
          <h3 class="form-title font ml-2 mb-2 mt-2 font-18">Client Detail</h3>
        </div>
      </div>
 
      <v-form v-model.trim="valid" ref="form" class="row form-row-height">
        <div class="col-md-6">
          <div class="col-md-12 pb-0">
            <label class="font" for="">Company Name</label>
            <v-text-field
              required
              disabled
              :rules="requiredRules"
              v-model.trim="client.companyName"
              solo
            ></v-text-field>
          </div>

          <div class="col-md-12 pb-0 pt-0">
            <label class="font text-gray" for="">Tax ID</label>
            <v-text-field
            v-if="client.taxId"
              required
              v-model.trim="client.taxId"
              solo
              disabled
              placeholder="00-0000000"
              :rules="taxIdRules"
              counter="10"
              maxlength="10"
            ></v-text-field>
            <v-text-field
            v-else
            required
            solo
            disabled
            placeholder="00-0000000"
            counter="10"
            maxlength="10"
          ></v-text-field>
          </div>
          <div class="col-md-12 pb-0 pt-0">
            <label class="font text-gray" for="">Number of Employees</label>
            <v-text-field
              required
              disabled
              v-model.trim="client.noEmployees"
              solo
            ></v-text-field>
          </div>
          <div class="col-md-12 pb-0 pt-0">
            <label class="font text-gray" for="">Number of Plan Members</label>
            <v-text-field
              required
              disabled
              v-model.trim="client.noPlanMembers"
              solo
            ></v-text-field>
          </div>
          <div class="col-md-12 pb-0 pt-0">
            <label class="font text-gray" for="">HQ Address Line 1</label>
            <v-text-field
            v-if="client.address1"
              required
              disabled
              v-model.trim="client.address1"
              :rules="requiredRules"
              solo
            ></v-text-field>
            <v-text-field
            v-else
            required
            disabled
            solo
          ></v-text-field>
          </div>
          <div class="col-md-12 pb-0 pt-0">
            <label class="font text-gray" for="">HQ Address Line 2</label>
            <v-text-field
              required
              disabled
              v-model.trim="client.address2"
              solo
            ></v-text-field>
          </div>
          <v-row class="no-padding">
            <div class="col-md-6 pb-0 pt-0">
              <label class="font text-gray" for="">City</label>
              <v-text-field
              v-if="client.city"
                required
                disabled
                v-model.trim="client.city"
                solo
                :rules="requiredRules"
              ></v-text-field>
              <v-text-field
              v-else
              required
              disabled
              solo
            ></v-text-field>
            </div>
            <div class="col-md-3 pb-0 pt-0">
              <label class="font text-gray" for="">State</label>
              <v-select
              v-if="client.state"
                :items="states"
                item-text="name"
                item-value="name"
                required
                disabled
                v-model.trim="client.state"
                solo
                :rules="requiredRules"
              ></v-select>
              <v-select
              v-else
                required
                disabled
                solo
              ></v-select>
            </div>
            <div class="col-md-3 pb-0 pt-0">
              <label class="font text-gray" for="">Zip</label>
              <v-text-field
              v-if="client.zipCode"
                required
                disabled
                type="number"
                v-model.trim="client.zipCode"
                solo
                :rules="zipRules"
              ></v-text-field>
              <v-text-field
              v-else
              required
              disabled
              type="number"
              solo
            ></v-text-field>
            </div>
            <div class="row no-padding">
              <div class="col-md-8 font text-gray">
                <label for="" class="small-text text-center"
                  >Client Relationship Status</label
                >
                <div class="d-flex mt-2">
                  InActive
                  <v-switch
                    solo
                    
                    class="no-padding ml-2 mr-2 vendors-switch"
                  ></v-switch>
                  Active
                </div>
              </div>
              <div class="col-md-4 mt-5 text-end">
                <a class="text-underline" @click="removeClient"
                  >Remove Client</a
                >
              </div>
            </div>
          </v-row>
        </div>
        <div class="col-md-6">
          <div class="col-md-12 pb-0">
            <label class="font text-gray" for=""
              >Primary Contact First Name
            </label>
            <v-text-field
            v-if="client.primaryContact && client.primaryContact.firstName"
            v-model.trim="client.primaryContact.firstName"
            required
            disabled
            solo
            :rules="requiredRules"
          ></v-text-field>
            <v-text-field
              v-else
              required
              disabled
              solo
            ></v-text-field>
          </div>
          <div class="col-md-12 pb-0 pt-0">
            <label class="font text-gray" for=""
              >Primary Contact Last Name</label
            >
            <v-text-field
            v-if="client.primaryContact && client.primaryContact.lastName"
              v-model.trim="client.primaryContact.lastName"
              required
              disabled
              solo
              :rules="requiredRules"
            ></v-text-field>
            <v-text-field
            v-else
              required
              disabled
              solo
            ></v-text-field>
          </div>
          <div class="col-md-12 pb-0 pt-0">
            <label class="font text-gray" for="">Primary Contact Title</label>
            <v-text-field
            v-if="client.primaryContact && client.primaryContact.title"
              v-model.trim="client.primaryContact.title"
              required
              disabled
              solo
              :rules="requiredRules"
            ></v-text-field>
            <v-text-field
            v-else
              required
              disabled
              solo
            ></v-text-field>
          </div>
          <div class="col-md-12 pb-0 pt-0">
            <label class="font text-gray" for="">Primary Contact Email</label>
            <v-text-field
            v-if="client.primaryContact && client.primaryContact.email"
              v-model.trim="client.primaryContact.email"
              required
              disabled
              :rules="emailRules"
              solo
            ></v-text-field>
            <v-text-field
            v-else
            required
            disabled
            solo
          ></v-text-field>
          </div>
          <div class="col-md-12 pb-0 pt-0">
            <label class="font text-gray" for="">Primary Contact Phone</label>
            <v-text-field
            v-if="client.primaryContact && client.primaryContact.phone"
              v-model.trim="client.primaryContact.phone"
              required
              disabled
              solo
              :rules="phoneRules"
            ></v-text-field>
            <v-text-field
            v-else
            required
            disabled
            solo
          ></v-text-field>
          </div>
          <div class="col-md-12 pb-0 pt-0">
            <label class="font light-blue-text text-underline" @click="generatePdf()" for="">View & Print Agreements</label>
       
          </div>
        </div>
      </v-form>
      <div class="row gray-underline-wizard margin-l-11">
        <div class="col-md-12 row text-end justify-end mb-5 mt-2 pr-10">
          <span
            class="text-underline pointer mr-10"
            style="color:#0572F3;"
            @click="$router.go(-1)"
            >Close</span
          >
          <!-- <span
            class="mr-3 text-underline pointer"
            style="color:#0572F3;"
            @click="update()"
            >Save
          </span> -->
        </div>
      </div>
    </div>

    <errorModal ref="error_modal" v-on:confirm="closeErrorModal"></errorModal>

  </v-row>
</template>
<script>
import { mapGetters } from "vuex";
import api from "../../../../../services/api";
import successModal from "../../../../TablesTemplate/SuccessDialog.vue";
import errorModal from "../../../../TablesTemplate/ErrorDialog.vue";

export default {
  components: { successModal, errorModal  },
  data() {
    return {
      client: {
        companyName: "",
        taxId: "",
        logo: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        zipCode: "",
        displayName: "",
        noEmployees: "",
        noPlanMembers: "",
        status: "",
        primaryContact: {
          phone: "",
          title: "",
          firstName: "",
          lastName: "",
          email: "",
        },
      },

      phoneRules: [
        (v) =>
          /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(v) ||
          "Phone number must be in a valid format (XXX XXX XXXX)",
      ],
      states: [],
      discountRate: "",
      valid: false,
      zipRules: [
        (v) => (v && v.length == 5) || " Zip Code should be five digits long",
      ],
      requiredRules: [(v) => !!v || "This Field is required"],
      emailRules: [
        (v) => !!v || "This Field is required",
        (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || "E-mail must be valid",
      ],
      taxIdRules: [
        (v) => !!v || "This Field is required",
        (v) =>
          !!/[0-9]{2}\-[0-9]{7}/.test(v) ||
          "Tax ID should contain 9 digits, Please enter your Tax ID in XX-XXXXXXX format",
      ],
    };
  },
  computed: {
    ...mapGetters([ "getSingleItem"]),

    singleItem() {
      return this.$store.getters.getSingleItem;
    },
  },
  created() {
    this.getStates();
    // this.getInfo();
    this.getClient();
    // this.client = this.getSingleItem;
  },
  methods: {
    generatePdf() {
      window.open("https://api.apaly.net/terms");
    },
    // showSlash(event) {
    //   if (this.taxId.length == 2 && event.keyCode != 8) {
    //     this.taxId += "-";
    //   }
    // },
    activateFileInput() {
      this.$refs.fileInput.$refs.input.click();
    },
    getStates() {
      api()
        .get("/api/states")
        .catch((err) => {
                  if (err.response.status == 500) {
                    this.$refs.error_modal.openPopUp(
                      "There was an error, system admin is notified. Please try again later."
                    );}
                   else{
                    this.$refs.error_modal.openPopUp(err.response.data)
                   }
                  
                })
        .then((res) => {
          res.data.forEach((el) => {
            if (el.active) {
              this.states.push(el);
            }
          });
        });
    },

    closeSuccessModal() {
      this.$refs.success_modal.closePopUp();
      this.$router.push("/dpc-providers/clients");
    },

    removeClient() {
      // api()
      //   .delete()
      //   .then((res) => {});
    },
    closeErrorModal() {
      this.$refs.error_modal.closePopUp();
    },
    getClient(){
      console.log('test', this.$route.params.id)
      api()
          .get(`/dpcproviders/clients/` + this.$route.params.id, this.client)
          .then((res) => {
          this.client = res.data;
          });
         
    },
    update() {
      this.$refs.form.validate();
      if (this.valid) {
        api()
          .put(`/dpcproviders/clients/` +  this.$route.params.id, this.client)
          .catch((err) => {
                  if (err.response.status == 500) {
                    this.$refs.error_modal.openPopUp(
                      "There was an error, system admin is notified. Please try again later."
                    );}
                   else{
                    this.$refs.error_modal.openPopUp(err.response.data.error.message)
                   }
                })
          .then((res) => {
            this.$refs.success_modal.openPopUp("Account updated successfully");
          });
         
      }
    },
  },
};
</script>