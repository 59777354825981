<template>
  <v-row class="row-height">
    <v-sheet
      v-if="!$route.path.includes('payment')"
      class="overflow-hidden col-md-12"
      style="position: relative; border-radius: 10px"
    >
      <div>
        <v-row
          align="center"
          justify="center"
          class="white-background border-radius-form"
        >
          <div
            :class="
              overlay
                ? 'hideOnMoblie background-opacity col-md-9'
                : 'col-md-12 navigationWidth no-padding '
            "
          >
            <v-row
              class="blue-underline mb-1"
              style="border-top: 1px solid lightgrey"
            >
              <v-col class="col-xl-2 col-md-2 cols-4 pt-2 pb-0" cols="4">
                <div class="col-md-12">
                  <h3 class="form-title font col-md-12 font-18">Invoice Details</h3>
                </div>
              </v-col>
              <v-col class="col-xl-4 col-lg-5 col-md-4 cols-8" cols="8">
                <div
                  class="col-xl-9 col-lg-12 col-md-12 mt-2 d-flex no-padding"
                >
                  <!-- <v-text-field
                    :disabled="overlay"
                    class="input-control mr-5"
                    prepend-inner-icon="mdi-magnify"
                    @input="search"
                    light
                    dense
                    outlined
                    placeholder="Search Members"
                  ></v-text-field>
                  <v-btn
                    :disabled="overlay"
                    class="text-gray filter-btn"
                    @click.stop="drawer = !drawer"
                  >
                    <svg
                      width="17"
                      class="mr-2"
                      height="14"
                      viewBox="0 0 17 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 1H16"
                        stroke="#424242"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M2.59766 5H14.403"
                        stroke="#424242"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M6.3125 13H10.6858"
                        stroke="#424242"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M4.19531 9H12.8046"
                        stroke="#424242"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    Filters
                  </v-btn> -->
                </div>
              </v-col>
              <v-col
                class="col-xl-6 col-lg-5 pr-3 pt-4 col-md-6 text-end"
                cols="12"
                align="end"
              >
                <v-btn 
                @click="generatePdf()"
                class="my-1 mr-4 gray-border-buttons pr-6 pl-6">
                <svg
                    class="mr-2"
                    width="15"
                    height="16"
                    viewBox="0 0 15 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.2361 10.7639C14.0329 10.7639 13.838 10.8434 13.6937 10.985C13.5495 11.1267 13.4676 11.319 13.4659 11.5202V12.6764C13.4659 12.9367 13.3615 13.1862 13.1756 13.3703C12.9897 13.5543 12.7376 13.6577 12.4747 13.6577H2.52525C2.26238 13.6577 2.01027 13.5543 1.8244 13.3703C1.63852 13.1862 1.53409 12.9367 1.53409 12.6764V11.5202C1.51671 11.3307 1.42838 11.1544 1.28645 11.0261C1.14452 10.8978 0.959257 10.8266 0.767046 10.8266C0.574834 10.8266 0.389569 10.8978 0.247642 11.0261C0.105715 11.1544 0.0173841 11.3307 0 11.5202V12.6764C0 13.3395 0.266053 13.9753 0.739629 14.4442C1.21321 14.913 1.85551 15.1764 2.52525 15.1764H12.4747C13.1445 15.1764 13.7868 14.913 14.2604 14.4442C14.7339 13.9753 15 13.3395 15 12.6764V11.5202C15 11.3196 14.9195 11.1272 14.7763 10.9854C14.633 10.8436 14.4387 10.7639 14.2361 10.7639Z"
                      fill="#707070"
                    />
                    <path
                      d="M7.49971 0.176392C7.17488 0.176392 6.86336 0.304137 6.63368 0.531525C6.404 0.758913 6.27496 1.06732 6.27496 1.38889V7.05139C6.27496 7.09283 6.25833 7.13257 6.22873 7.16188C6.19913 7.19118 6.15899 7.20764 6.11713 7.20764H4.43784C4.32437 7.21533 4.2151 7.25322 4.12158 7.31731C4.02807 7.3814 3.95378 7.46931 3.90657 7.57175C3.85935 7.6742 3.84097 7.78736 3.85337 7.89929C3.86576 8.01122 3.90847 8.11776 3.97698 8.20764L7.03885 11.6951C7.0979 11.7576 7.16927 11.8074 7.24855 11.8414C7.32782 11.8754 7.4133 11.893 7.49971 11.893C7.58611 11.893 7.6716 11.8754 7.75087 11.8414C7.83014 11.8074 7.90151 11.7576 7.96057 11.6951L11.0224 8.20764C11.0909 8.11776 11.1337 8.01122 11.146 7.89929C11.1584 7.78736 11.1401 7.6742 11.0928 7.57175C11.0456 7.46931 10.9713 7.3814 10.8778 7.31731C10.7843 7.25322 10.675 7.21533 10.5616 7.20764H8.88228C8.84042 7.20764 8.80028 7.19118 8.77068 7.16188C8.74108 7.13257 8.72445 7.09283 8.72445 7.05139V1.38889C8.72445 1.06732 8.59542 0.758913 8.36573 0.531525C8.13605 0.304137 7.82453 0.176392 7.49971 0.176392V0.176392Z"
                      fill="#707070"
                    />
                  </svg>
                  Export</v-btn
                >
              </v-col>
            </v-row>

            <v-form v-model.trim="valid" ref="form" class="row form-row-height">
        <div class="col-md-6" id="generate-pdf">
          <div class="col-md-12 pb-0">
            <h4 class="form-title col-md-12 pb-8" style="color:#707070">
              Invoice Information 
            </h4>
          </div>
          <div class="col-md-12 d-flex pb-10 pt-0">
            <label class="label-style col-md-5 font text-gray text-end" for="">INVOICE NUMBER: </label>
            <span
            class="span-style col-md-5 col-lg-6 full-border font grey-shade">
            {{invoice.invoiceNo}}
          </span>
          </div>
          <div class="col-md-12 d-flex pb-10 pt-0">
            <label class="label-style col-md-5 font text-gray text-end" for="">MEMBER ID: </label>
            <span
            class="span-style col-md-5 col-lg-6 full-border font grey-shade">
            {{invoice.memberId}}
          </span>
          </div>
          <div class="col-md-12 d-flex pb-10 pt-0">
            <label  class="label-style col-md-5 font text-gray text-end"  for="">CLIENT: </label>
            <span
            class="span-style col-md-5 col-lg-6 full-border font text-underline" style="color:#0573F3">
            {{invoice.client}}
          </span>
          </div>
          <div class="col-md-12 d-flex pb-10 pt-0">
            <label  class="label-style col-md-5 font text-gray text-end"  for="">INVOICE DATE: </label>
            <span
            class="span-style col-lg-6 col-md-5 full-border font grey-shade">
            {{invoice.date | moment("DD/MM/YYYY")}}
          </span>
          </div>
          <div class="col-md-12 d-flex pb-10 pt-0">
            <label  class="label-style col-md-5 font text-gray text-end"  for="">TOTAL CHARGE(USD):</label>
            <span
            class="span-style col-md-5 col-lg-6 full-border font grey-shade">
            ${{invoice.total}}
          </span>
          </div>
        </div>
        <v-divider vertical></v-divider>
        
        <div class="col-md-6">
          <div class="col-md-12 pb-0">
            <h4 class="form-title col-md-12 pb-8"  style="color:#707070">
              Transactions
            </h4>
          </div>
    
          <v-col class="no-padding col-md-1 text-center justify-center">
        
      </v-col>
      <v-divider horizontal></v-divider>
      <v-simple-table fixed-header height="330px">
              <template v-slot:default>
              
                <thead>
                <tr>
                  <th class="text-left">DATE</th>
                  <th class="text-left">ID</th>
                  <th class="text-left">AMOUNT</th>
                  <th class="">BALANCE(USD)</th>
                </tr>
                </thead>
                <tbody v-for="(item,index) in invoice.transactions">
                <tr>
                  <td> {{item.date | moment("DD/MM/YYYY")}}  </td>
                  <td>{{item.id}} </td>
                  <td> {{item.amount}}</td>
                  <td> ${{item.balance}} </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
        </div>
      </v-form>
      <div class="row gray-underline-wizard margin-l-11 pr-10">
        <div class="col-md-12 row text-end justify-end mb-5 mt-2">
          <span
            class="text-underline pointer mr-10"
            style="color:#0572F3;"
            @click="$router.go(-1)"
            >Close</span
          >
    
        </div>
      </div>
          </div>
          
        </v-row>
      </div>
    <!-- 
            <v-simple-table fixed-header height="580px" id="tblData">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">MEMBER NAME</th>

                    <th class="text-left">STATUS</th>
                    <th class="text-left">LAST VISIT</th>
                    <th class="text-left">NEXT VISIT</th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-if="getSingleItem.list.length > 0"
                    v-for="item in getSingleItem.list"
                    :key="item.name"
                    class="text-gray"
                  >
                    <td v-if="item.firstName">
                      <a>{{ item.firstName }} {{ item.lastName }} </a>
                    </td>
                    <td v-else><a>/ </a></td>

                    <td
                      v-html="
                        item.status
                          ? item.status.charAt(0).toUpperCase() +
                            item.status.slice(1)
                          : '/'
                      "
                    ></td>
                    <td v-if="item.lastVisit">
                      {{ item.lastVisit | moment("DD/MM/YYYY") }}
                    </td>
                    <td v-else>/</td>
                    <td v-if="item.nextVisit">
                      {{ item.nextVisit | moment("DD/MM/YYYY") }}
                    </td>
                    <td v-else>/</td>
                  </tr>
                  <tr v-else class="mt-2">
                    <td>
                      <v-text-field
                        solo
                        class="mt-2 mb-2"
                        hide-details
                        v-model="memberName"
                      ></v-text-field>
                    </td>
                    <td>
                      <v-text-field
                        solo
                        class="mt-2 mb-2"
                        hide-details
                        v-model="clientName"
                      ></v-text-field>
                    </td>
                    <td>
                      <v-text-field
                        solo
                        class="mt-2 mb-2"
                        hide-details
                        v-model="status"
                      ></v-text-field>
                    </td>
                    <td>
                      <v-text-field
                        solo
                        class="mt-2 mb-2"
                        hide-details
                        v-model="lastVisit"
                      ></v-text-field>
                    </td>
                    <td>
                      <v-text-field
                        solo
                        class="mt-2 mb-2"
                        hide-details
                        v-model="nextVisit"
                      ></v-text-field>
                    </td>
                    <td>
                      <a>Remove</a>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table> -->

    </v-sheet>
    <router-view></router-view>
  </v-row>
</template>
<script>
import api from "../../../../services/api";
import html2pdf from "html2pdf.js";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      invoice: [],
      items: [],
      valid: false,
      allInvoices: [
        {
          firstName: "",
          lastName: "",
          client: {
            companyName: "",
          },
          lastVisit: "",
          nextVisit: "",
          status: "",
        },
      ],
      overlay: false,
      display: true,
      filterArray: [],
      windowWidth: window.innerWidth,
      noResultMessage: false,
      drawer: null,
      showAging: {
        all: true,
        current: true,
        numDays: true,
        greaterThen: true,
      },
      showStatus: {
        all: true,
        pending: true,
        processing: true,
        paid: true,
      },
      showType: {
        all: true,
        eleven: true,
        boeing: true,
        catepillar: true,
      },
      userStatus: [
        {
          text: "ALL",
          value: "all",
        },

        {
          text: "Join Request",
          value: "join",
        },
        {
          text: "Active",
          value: "active",
        },
        {
          text: "In Active",
          value: "inactive",
        },
        {
          text: "Invited",
          value: "invited",
        },
      ],
      userType: [
        {
          text: "ALL",
          value: "all",
        },
        {
          text: "Admins",
          value: "admins",
        },
        {
          text: "General User",
          value: "users",
        },
      ],
      selectedArray: [],
      selectedTypeArray: [],
      getUsers: [],
      panel: [0, 1],
      filterUse: false,
      type: "",
      status: "",
      statusArray: [],
      typeArray: [],
      filterItems: false,
      memberName: "",
      clientName: "",
      status: "",
      lastVisit: "",
      nextVisit: "",

      filter: {
        status: {
          all: false,
          active: false,
          inactive: false,
          invited: false,
          join: false,
        },
        type: {
          all: false,
          user: false,
          admin: false,
        },
        aging: {
          all: false,
          current: false,
          greaterThen: false,
          numDays: false,
        },
      },
    };
  },
  computed: {
    // ...mapGetters(["getSingleItem"]),
    routeParams() {
      return this.$route.params.name;
    },
    singleEmp() {
      let emp = "";
      if (localStorage.getItem("employer")) {
        emp = JSON.parse(localStorage.getItem("employer"));
      }
      return emp;
    },
    arrayInFilters() {
      return this.allInvoices;
    },
    isChildren() {
      let bool = false;
      if (this.$route.meta && this.$route.meta.isChildren) {
        bool = true;
      }
      return bool;
    },
  },
  created() {
    this.getOne();
    // this.filterArray = this.getSingleItem.list;
  },
  methods: {
    closeErrorModal() {
      this.$refs.error_modal.closePopUp();
    },
    generatePdf() {
      // html2pdf(document.getElementById('generate-pdf'));
      var element = document.getElementById('generate-pdf');
      var opt = {
           margin:       0,
           filename:     'invoiceDetails.pdf',
           image:        { type: 'jpeg', quality: 0.98 },
           html2canvas:  { scale: 1 },
           jsPDF:        { unit: 'px', format: 'a4', orientation: 'landscape' }
        };
 
          // New Promise-based usage:
          html2pdf().from(element).set(opt).save();
 
          // Old monolithic-style usage:
          html2pdf(element, opt);
          
    },
        exportData() {
      var elt = document.getElementById("tblData");
      var downloadLink;
      var dataType = "application/vnd.ms-excel";
      var tableSelect = elt;
      var tableHTML = tableSelect.outerHTML.replace(/ /g, "%20");

      // Specify file name
      let filename = "excel_data.xls";

      // Create download link element
      downloadLink = document.createElement("a");

      document.body.appendChild(downloadLink);

      if (navigator.msSaveOrOpenBlob) {
        var blob = new Blob(["\ufeff", tableHTML], {
          type: dataType,
        });
        navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        // Create a link to the file
        downloadLink.href = "data:" + dataType + ", " + tableHTML;

        // Setting the file name
        downloadLink.download = filename;

        //triggering the function
        downloadLink.click();
      }
    },
    goToPayment() {
      this.$router.push("/dpc-providers/invoices/details/payment");
    },
    pushtoTypeFilter(item, checked) {
      if (checked) {
        if (item.value == "allTypes") {
          this.selectedTypeArray = [];
          this.filter.type = {
            all: true,
            user: true,
            admin: true,
          };
          this.selectedTypeArray.push({ text: "All", value: "allTypes" });
        } else {
          this.selectedTypeArray.push(item);
        }
      } else {
        if (item.value == "allTypes" || this.filter.type.all) {
          this.filter.type = {
            all: false,
            user: false,
            admin: false,
          };
          this.selectedTypeArray = [];
        } else {
          this.selectedTypeArray.forEach((el, index) => {
            if (el.value == item.value) {
              this.selectedTypeArray.splice(index, 1);
            }
          });
        }
      }
    },
    pushFilter(item, checked) {
      if (checked) {
        if (item.value == "all") {
          this.selectedArray = [];
          this.filter.status = {
            all: true,
            active: true,
            inactive: true,
            invited: true,
            join: true,
          };
          this.selectedArray.push({ text: "All", value: "all" });
        } else {
          this.selectedArray.push(item);
        }
      } else {
        if (item.value == "all" || this.filter.status.all) {
          this.filter.status = {
            all: false,
            active: false,
            inactive: false,
            invited: false,
            join: false,
          };
          this.selectedArray = [];
        } else {
          this.selectedArray.forEach((el, index) => {
            if (el.value == item.value) {
              this.selectedArray.splice(index, 1);
            }
          });
        }
      }
    },

    searchStatus() {
      for (let key in this.showStatus) {
        if (key.toLowerCase().includes(this.status.toLowerCase())) {
          this.showStatus[key] = true;
        } else {
          this.showStatus[key] = false;
        }
      }
    },
    searchType() {
      for (let key in this.showType) {
        if (key.toLowerCase().includes(this.type.toLowerCase())) {
          this.showType[key] = true;
        } else {
          this.showType[key] = false;
        }
      }
    },
    filterUsers() {
      if (!this.filter.status && !this.filter.type) {
        this.getData("all");
      } else {
        this.getData(this.filter.status);
      }
      this.filterUse = true;
      this.drawer = false;
    },
    clearAll() {
      this.filter.status = {
        all: false,
        active: false,
        inactive: false,
        invited: false,
        join: false,
      };
      this.filter.type = {
        all: false,
        user: false,
        admin: false,
      };

      this.selectedArray = [];
      this.selectedTypeArray = [];
      this.getData("all");
    },

    removeFilter(item) {
      if (item.value == "allTypes") {
        this.selectedTypeArray = [];
        this.filter.type = {
          all: false,
          user: false,
          admin: false,
        };
      } else if (this.filter.status[item.value]) {
        this.filter.status[item.value] = false;
        this.selectedArray.splice(this.selectedArray.indexOf(item), 1);
      }

      if (item.value == "all") {
        this.selectedArray = [];
        this.filter.status = {
          all: false,
          active: false,
          inactive: false,
          invited: false,
          join: false,
        };
      } else if (this.filter.type[item.value]) {
        this.filter.type[item.value] = false;
        this.selectedTypeArray.splice(this.selectedTypeArray.indexOf(item), 1);
      }
    },

    getData(type) {
      api()
        .get("/dpcproviders/invoices/" + this.$route.params.id)
        .then((res) => {
          if (res) {
            this.allInvoices = res.data.list;
            this.statusArray = this.userStatus;
            this.typeArray = this.userType;
            this.selectedArray = [];
            this.filterArray = this.allInvoices;
          }
        });
    },
    getOne(){
      console.log('id',this.$route.params.id)
      api()
        .get("/dpcproviders/invoice/" +this.$route.params.id)
        .catch((err) => {
            if (err) {
              console.log('err', err.response)
            }
          })
        .then((res) => {
          if (res) {
            this.getSingleItem = res.data
            this.invoice = res.data;
          }
        });
    },
  //  getInvoices(){
  //   api()
  //       .get("/dpcproviders/invoices")
  //       .then((res) => {
  //         if (res) {
  //           this.invoices = res.data;
  //          console.log('thisss invoices', this.invoices)
  //         }
  //       });
  //  },
    search(val) {
      this.getSingleItem.list = this.filterArray.filter((el) => {
        if (el.firstName.toLowerCase().includes(val.toLowerCase())) {
          return el.firstName.toLowerCase().includes(val.toLowerCase());
        } else if (
          el.firstName.toString().toLowerCase().includes(val.toLowerCase())
        ) {
          return el.lastName
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase());
        } else if (el.lastName.toLowerCase().includes(val.toLowerCase())) {
          return el.lastName.toLowerCase().includes(val.toLowerCase());
        }
      });
      if (this.getSingleItem.list.length < 1) {
        this.filterItems = true;
      }
    },
  },
};
</script>
<style>
.border-radius-form {
  border-radius: 5px !important;
}
.padding-1 {
  padding: 0.2rem;
  align-items: center;
}
.full-border
{
  border: 1px solid #9E9E9E;
border-radius: 4px;
}
.span-style{
  height: 32px;
  padding-top: 3px;
}
.label-style{
  margin-top: -8px;
  }
  .grey-shade{
    color: #707070
  }
</style>
