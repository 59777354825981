<template>
 <v-row class="add-client-form no-padding" >
  <div class="col-md-12 form-box">
    <div class="row">
        <div class="col-md-12">
            <h3 class=" font">Add New Employer</h3>
        </div>
    </div>
    
    <form class="row">
        <div class="col-md-4 col-lg-6">
              <label class="font text-gray" for="">Company Name</label>
                <v-text-field
                required
                solo
                tabindex="1"
                ></v-text-field>
        </div>
          <div class="col-md-8 col-lg-6">
            <label class="font text-gray" for="">Current Network Vendor<i class="fas fa-question-circle  yellow-icons"></i></label>
                <v-text-field
                required
                solo
                tabindex="5"
                ></v-text-field>
        </div>
          <div class="col-md-4 col-lg-6">
              <label class="font text-gray" for="">Tax ID <i class="fas fa-question-circle  yellow-icons"></i></label>
                <v-text-field
                required
                solo
                tabindex="2"
                placeholder="00-0000000"
                :rules="taxIdRules"
                counter="10"
                
                ></v-text-field>
        </div>
        <div class="col-md-8 col-lg-6">
            <label class="font text-gray" for="">Current Network Discount Rate (i.e Percent Discount off Billed Charges)<i class="fas fa-question-circle  yellow-icons"></i></label>
                <v-text-field
                tabindex="6"
                required
                solo
                ></v-text-field>
        </div>
      
        <div class="col-md-12 no-padding">
            <div class="col-md-6">
                <label class="font text-gray" for="">Approximate Number of Employees</label>
                <v-text-field
                required
                solo
                tabindex="3"
                ></v-text-field>
            </div>
        </div>
        <div class="col-md-12 no-padding">
            <div class="col-md-6">
                <label class="font text-gray" for="">Approximate Number of Plan Members</label>
                    <v-text-field
                    required
                    solo
                    tabindex="4"
                    ></v-text-field>
            </div>
        </div>
        <div class="col-md-12 d-flex align-end flex-column">
              <div>
                <v-btn class="blue-buttons mr-6" :disabled="loadData" @click="$router.push(`/employer-advisor/employers/${routeParams}/employers`)">
                  Cancel
                </v-btn>
                <v-btn class="blue-buttons" :disabled="loadData" @click="$router.push(`/employer-advisor/employers/${routeParams}/employers`)">
                  Add Employer
                </v-btn>
            </div>
        </div>
      </form>
    </div>
 </v-row>
</template>
<script>
export default{
  data(){
       return{
             taxIdRules: [
                    v => !!v || 'This Field is required',
                    v => !!/[0-9]{2}\-[0-9]{7}/.test(v) || "Tax ID should contain 9 digits, Please enter your Tax ID in XX-XXXXXXX format",
             ],
             loadData:false,
       }
  },
  computed:{
     routeParams(){
       return this.$route.params.name
      }
  }
}
</script>
<style>
.add-client-form{
    padding: 1.5rem;
}

.yellow-icons{
   color: rgb(224, 171, 36);
}
.blue-buttons{
   background:  #4072B7 !important;
   color: white !important;
}

</style>