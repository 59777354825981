<template>
<div>
  <!-- Fullscreen Loader -->
  <FullscreenLoader v-if="loader" />

  <!-- Diaolog Content -->
  <v-dialog v-else-if="!loader && !hideModal" v-model="addSubscriberInfo" persistent max-width="600">
    <v-card class="dialog_container_2">
      <!-- Close Button -->
        <v-card-title class="d-flex justify-end dialog_x">
          <v-icon style="float: right" role="button" @click="closeSubscriberInfoComp()" v-text="'mdi-close'"></v-icon>
        </v-card-title>

        <!-- Dialog Header -->
        <div class="dialog_content">
          <v-card-title class="dialog_title">
            <p>We need additional subscriber information</p>
          </v-card-title>
          <v-card-subtitle class="dialog_sub" >
            As this patient is a dependent, we need additional subscriber information. 
          </v-card-subtitle >
        </div>

        <div class="ce_title">
          Subscriber's Information
          <div class="ce_sub">As shown on their insurance card</div>
        </div>

        <!-- Dialog Contents -->
        <v-form ref="form" class="ce_form" v-model.trim="valid"> 
          <v-card-text>
            <div class="ce_form_content">

              <!-- First Name -->
              <div class="ce_input_container">
                <label>First Name <small>(as in their Insurance Card)</small> </label>
                <v-text-field
                  class="ce_inputfield"
                  placeholder="First Name"
                  :rules="requiredRules"
                  outlined
                  dense
                  hide-details
                  v-model.trim="subscriberInfo.firstName"
                >
                </v-text-field>
              </div>
              
              <!-- Last Name -->
              <div class="ce_input_container">
                <label>Last Name <small>(as in their Insurance Card)</small> </label>
                <v-text-field
                  class="ce_inputfield"
                  placeholder="Last Name"
                  :rules="requiredRules"
                  outlined
                  dense
                  hide-details
                  v-model.trim="subscriberInfo.lastName"
                >
                </v-text-field>
              </div>

              <!-- Date of Birth -->
              <div class="ce_input_container">
                <label>Date of Birth</label>
                <v-menu
                  ref="menu"
                  hide-details
                  v-model.trim="calendarMenu"
                  transition="scale-transition"
                  :close-on-content-click="false"
                  offset-x
                  min-width="auto"
                  location="start"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      class="ce_inputfield"
                      dense
                      v-model="dateFormatted"
                      @blur="subscriberInfo.dob = parseDate(dateFormatted)"
                      outlined
                      v-mask="'##/##/####'"
                      :rules="requiredRules"
                      append-icon="mdi-calendar"
                      persistent-hint
                      hide-details
                      v-bind="attrs"
                      placeholder="MM/DD/YYYY"
                      v-on="on"
                    ></v-text-field>
                  </template>
                </v-menu>
              </div>

              <!-- Gender -->
              <div  class="ce_input_container mt-3">
                <label>Gender</label>
                <v-select
                  outlined
                  dense
                  class="ce_inputfield"
                  placeholder="Select Gender"
                  :items="genders"
                  :rules="requiredRules"
                  hide-details
                  v-model.trim="subscriberInfo.gender"
                ></v-select>
              </div>

              <!-- BUTTONS -->
              <div class="dialog_buttons_3">
                <button class="dialog_double_button_2 dialog_btn_second_6" @click.prevent="closeSubscriberInfoComp()" >
                  Cancel
                </button>
                <button class="dialog_double_button_2 dialog_btn_primary_5" :disabled="!valid" @click.prevent="confirmInfo()" >
                  Confirm information
                </button>
              </div>

            </div>
          </v-card-text>
        </v-form>
    </v-card>
  </v-dialog>

  <!-- Eligibility Successful -->
  <v-dialog v-model="eligibilitySuccess" width="500px" persistent>
    <v-card class="dialog_layout">
      <v-card-title class="d-flex justify-end">
        <v-icon role="button" @click="closeModals()" v-text="'mdi-close'" slot="end"></v-icon>
      </v-card-title>
      <v-card-text class="dialog_content">
        <div class="dialog_title dialog_primary">
          Success! This patient is eligible for this benefit and covered by their employer 
        </div>
        <!-- <div class="dialog_subtext dialog_second">
          Sub text here 
        </div> -->
        <div class="dialog_buttons">
          <button class="dialog_single_button dialog_btn_primary" @click="selectClinicForMember()">
            Register patient
          </button>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>

  <!-- Patient already enrolled to another provider -->
  <v-dialog v-model="alreadyEnrolledToAnother" width="500px" persistent>
    <v-card class="dialog_layout">
      <v-card-title class="d-flex justify-end">
        <v-icon role="button" @click="closeModals()" v-text="'mdi-close'" slot="end"></v-icon>
      </v-card-title>
      <v-card-text class="dialog_content">
        <div class="dialog_title dialog_primary">
          {{ checkEligibilityPatientInfo.firstName }}&nbsp;{{ checkEligibilityPatientInfo.lastName }} is already attributed to another provider.
        </div>
        <!-- <div class="dialog_subtext dialog_second">
          Sub text here 
        </div> -->
        <div class="dialog_buttons">
          <button class="dialog_double_button dialog_btn_second dialog_floatRight" @click="openProfile()">
            View patient profile
          </button>
          <button class="dialog_double_button dialog_btn_primary dialog_floatRight" @click="selectClinicForMember()">
            Select another provider
          </button>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>

  <!-- Generic Error Modal -->
  <v-dialog v-model="genError" width="500px" persistent>
    <v-card class="dialog_layout">
      <v-card-title class="d-flex justify-end">
        <v-icon role="button" @click="closeModals()" v-text="'mdi-close'" slot="end"></v-icon>
      </v-card-title>
      <v-card-text class="dialog_content">
        <div class="dialog_title dialog_error text-center">
          {{ genErrorMess ? genErrorMess:'Oops. Something went wrong' }}
        </div>
        <div class="dialog_buttons">
          <button class="dialog_single_button dialog_btn_primary" @click="genError = false" >
            Okay
          </button>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>

  <!-- Subsriber's Info -->
  <SelectClinic @closeSelectClinic="selectedClinic = false" v-if="selectedClinic" :selectClinic="selectedClinic" />

</div>
</template>

<script>
import SelectClinic from "./selectClinic.vue";
import moment from "moment";
import { mapState } from "vuex";
import FullscreenLoader from "../../../Common/FullscreenLoader.vue";
import api from "../../../../services/api";
// import api from "../../../../services/api";


export default {
  components: {
    SelectClinic,
    FullscreenLoader,
  },
  props: {
  addSubscriberInfo: {
    type: Boolean,
  },
  memberId: {
    type: String,
  },
  },
  data() {
    return {
      patientID: "",
      alreadyEnrolledToAnother: false,
      loader: false,
      hideModal: false,
      genError: false,
      genErrorMess: "",
      selectedClinic: false,
      eligibilitySuccess: false,
      genders: ["Male", "Female"],
      dateFormatted: '',
      calendarMenu: false,
      valid: false,
      validInfo: false,
      subscriberInfo: {
        dob:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      },
      requiredRules: [(v) => !!v || "This Field is required"],
    }
  },
  computed: {
    returnFormatedDate() {
      return moment(this.newMember.dob).format("MM/DD/YYYY");
    },
    ...mapState({
      checkEligibilityPatientInfo: (state) => state.patientInfo,
      closeSubscriberInfoModal: (state) => state.closeSubscriberInfoModal,
    }),
  },
  watch: {
    "closeSubscriberInfoModal" (nVal, oVal) {
      this.hideModal = nVal; // turn hideModal to false
    },
  },
  methods: {
    closeModals() {
      this.eligibilitySuccess = false;
      this.alreadyEnrolledToAnother = false;
      this.genError = false;
      this.loader = false;
      this.hideModal = false;

      // External Modals
      this.selectedClinic = false;

      // Self Modals
      this.closeSubscriberInfoComp();
    },
    openProfile() {
      if(this.checkEligibilityPatientInfo.memberId) {
        let id = this.checkEligibilityPatientInfo.memberId;
        // console.log("Selected Member: ", id);
        this.closeCheckPatientModal();
        if(id) {
          this.$router.push("/dpc-providers/members/member/"+id).then(() => {
            window.location.reload();
          });
        }
        else {
          alert("No member with ID: " + id);
        }
      }
    },
    selectClinicForMember() {

      // Close
      this.hideModal = true;
      this.$store.commit("closeSubscriberInfoModal", true);
      this.loader = false;
      this.eligibilitySuccess = false;

      // Open
      this.selectedClinic = true;
    },
    parseDate (date) {
      if (!date) return null

      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    confirmInfo() {
      this.loader = true;
      this.hideModal = true;
      this.$store.commit("closeSubscriberInfoModal", true);

      if (this.valid) {
        // console.log("this.subscriberInfo: ", this.subscriberInfo);

        let subscribers = {
          firstName: this.subscriberInfo.firstName,
          lastName: this.subscriberInfo.lastName,
          dob: this.subscriberInfo.dob,
          gender: this.subscriberInfo.gender,
        }

        // get from Store (Subscriber's ID if relationship is NOT self)
        if(this.checkEligibilityPatientInfo?.subscriberMemberId) {
          subscribers.memberId = this.checkEligibilityPatientInfo.subscriberMemberId;
        }

        // See if checkEligibilityPatientInfo from store has data then include the subscribers object
        if(this.checkEligibilityPatientInfo) {

          // Set parameter
          let params = {
            ...this.checkEligibilityPatientInfo, // This must be included because this contains the eligibility
            subscriber: subscribers
          }

          // console.log("checkEligibilityPatientInfo: ", this.checkEligibilityPatientInfo);
          // console.log("params: ", params);

          // Submit parameter for request
          api()
          .post("/dpcproviders/check/eligibility", params)
          .catch((err) => {
            this.loader = false;
            if (err) {
              this.closeNewMemberModal();
              this.genError = true,
              this.genErrorMess = err.response.data ? err.response.data : err.response;
              // console.log("Subsriber's Additional Info: Error - ", err.response.data ? err.response.data : err.response);
            }
          })
          .then((res) => {
            this.loader = false;
            // console.log("Subsriber's Additional Info: Sucess - ", res.data);

            // Update eligibility object ID
            params.eligibility = res.data.eligibility

            // Store the updated param
            // Update state, put this in Store so it can be reused
            this.$store.commit("setPatientInfo", params);

            if(res.data) {
              this.eligibilitySuccess = true;
            }
          });
        }
        // else {
        //   // console.log("No patient/member info from store: ", this.checkEligibilityPatientInfo);
        // }
      }
    },
    closeSubscriberInfoComp() {
      // Unhide the hideCheckEligibility
      this.$store.commit("closeTheCheckEligibility", false);

      this.$emit("closeSubscriberInfoComp");
    },
  }
}
</script>

<style>

</style>