<template>
  <div>
    <!-- Loader -->
    <!-- <v-overlay v-if="loader">
      <v-progress-circular indeterminate :size="100" color="#326ab3" class="text-center mt-10 mb-5">
          <img src="/newapalylogoonlyblue.png" height="70" class="img-mobile" />
      </v-progress-circular>
    </v-overlay> -->
    <!-- Fullscreen Loader -->
    <FullscreenLoader v-if="loader" />

    <v-dialog v-else-if="!loader && !hideCheckEligibility" v-model="newMemberDialog" persistent max-width="600">
      <v-card class="dialog_container_2">
        <!-- Close Button -->
        <v-card-title class="d-flex justify-end dialog_x">
          <v-icon style="float: right" role="button" @click="closeNewMemberModal()" v-text="'mdi-close'"></v-icon>
          <div  @click="reopenDialog()"></div>
        </v-card-title>

        <!-- Dialog Header -->
        <div class="dialog_content">
              <v-card-title class="dialog_title mb-5">
                <p>Check Eligibility &amp; Register New Member</p>
              </v-card-title>
              <v-card-subtitle class="dialog_sub" >
                Enter the patient's information to check eligibility and attribute them them to your organization
              </v-card-subtitle >
        </div>

        <div class="ce_title">
          Patient Information 
          <!-- <div class="ce_sub">dfdfvdfvdfv</div> -->
        </div>

        <!-- Dialog Contents -->
        <v-form ref="form" class="ce_form" v-model.trim="valid"> 
          <v-card-text>
            <div class="ce_form_content">

              <!-- First Name -->
              <div class="ce_input_container">
                <label>First Name <small>(as in their Insurance Card)</small> </label>
                <v-text-field
                  class="ce_inputfield"
                  placeholder="First Name" 
                  :rules="requiredRules"
                  outlined
                  dense
                  hide-details
                  v-model.trim="newMember.firstName"
                >
                </v-text-field>
              </div>

              <!-- Last Name -->
              <div class="ce_input_container">
                <label>Last Name</label>
                <v-text-field
                  class="ce_inputfield"
                  placeholder="Last Name"
                  :rules="requiredRules"
                  outlined
                  dense
                  hide-details
                  v-model.trim="newMember.lastName"
                >
                </v-text-field>
              </div>

              <!-- Date of Birth -->
              <div class="ce_input_container">
                <label>Date of Birth</label>
                <v-menu
                  ref="menu"
                  hide-details
                  v-model.trim="calendarMenu"
                  transition="scale-transition"
                  :close-on-content-click="false"
                  offset-x
                  min-width="auto"
                  location="start"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      class="ce_inputfield"
                      dense
                      v-model="dateFormatted"
                      @blur="newMember.dob = parseDate(dateFormatted)"
                      outlined
                      v-mask="'##/##/####'"
                      :rules="requiredRules"
                      append-icon="mdi-calendar"
                      persistent-hint
                      hide-details
                      v-bind="attrs"
                      placeholder="MM/DD/YYYY"
                      v-on="on"
                    ></v-text-field>
                  </template>
                </v-menu>
              </div>

              <!-- Gender -->
              <!-- <div class="col-12 pt-0">
                <label>Gender</label>
                <v-radio-group v-model="newMember.gender" inline hide-details>
                  <v-radio label="Male" value="m"></v-radio>
                  <v-radio label="Female" value="f"></v-radio>
                </v-radio-group>
              </div> -->

              <!-- Gender -->
              <div  class="ce_input_container mt-3">
                <label>Gender</label>
                <v-select
                  outlined
                  dense
                  class="ce_inputfield"
                  placeholder="Select Gender"
                  :items="genders"
                  :rules="requiredRules"
                  hide-details
                  v-model.trim="newMember.gender"
                ></v-select>
              </div>

              <!-- Relationship to Subscriber -->
              <div  class="ce_input_container mt-3">
                <label>Relationship to Subscriber</label>
                <v-select
                  outlined
                  dense
                  class="ce_inputfield"
                  placeholder="Select Relationship"
                  :items="subscriberItems"
                  :rules="requiredRules"
                  hide-details
                  v-model.trim="newMember.subscriberRelation"
                ></v-select>
              </div>

              <div class="ce_title">
                Insurance Information
                <div class="ce_sub">As shown on the insurance card</div>
              </div>

              <!-- Employer -->
              <div class="ce_input_container mt-3">
                <label>Employer</label>

                <!-- If manual input -->
                <div v-if="addItemsManually">
                  <v-text-field
                    
                    class="ce_inputfield"
                    placeholder="Company or Employer Name"
                    :rules="requiredRules"
                    outlined
                    dense
                    hide-details
                    v-model.trim="newMember.employer"
                  >
                  </v-text-field>

                  <button class="ce_select_from_list" @click="selectFromActiveEmployers()">Select from active employers</button>
                </div>

                <!-- If Employer is Active -->
                <v-select
                v-else
                outlined 
                dense
                class="ce_inputfield"
                placeholder="Select Employer"
                :items="employersList"
                item-value="id"
                item-text="companyName"
                return-object
                :rules="requiredRules"
                hide-details
                no-data-text="No employer found"
                v-model="newMember.employer"
                @change="selectedEmployer"
                @click="focusSearchInput()"
                v-click-outside="onClickOutside"
                ref="mySelect"
              >

                <!-- Search Field -->
                <template v-slot:prepend-item>
                  <div class="custom_select_input_container">
                    <input 
                      type="text" 
                      id="focusSearch" class="custom_select_input" 
                      v-model="searchTerm" placeholder="Search employer here" 
                      @input="searchingEmployers"
                    />
                  </div>

                  <!-- Add Manually --> 
                  <!-- Remove for now. Only accept Employer ID -->
                  <!-- <v-list-item @click="addManually()" v-if="!searchTerm || employersList?.length === 0">
                    <v-list-item-content>
                      <div class="ce_add_manually">Add manually</div>
                    </v-list-item-content>
                  </v-list-item> -->
                </template>

                <!-- Show Selected Employer -->
                <template #selection="{ item }">
                  {{ item.companyName }}
                </template>

                <!-- List of Employers -->
                <template #item="{ item }">
                  <div>{{ item.companyName }}</div>
               </template>

                <template #item-disabled="{ item }">
                  <div >{{ item.companyName }}</div>
                </template>
              </v-select>
              </div>
 
              <!-- Member ID -->
              <div class="ce_input_container">
                <label>Insurance Member ID</label>
                <v-text-field
                  outlined
                  dense
                  class="ce_inputfield"
                  placeholder="Enter Insurance Member ID"
                  :rules="requiredRules"
                  hide-details
                  v-model.trim="newMember.memberId"
                >
                </v-text-field>
              </div>

              <!-- Subscriber's Member ID if Relationship is Dependent or Spouse-->
              <div class="ce_input_container" v-if="newMember?.subscriberRelation && newMember?.subscriberRelation != 'Self'">
                <label>Employee's Insurance Member ID?</label>
                <v-text-field
                  outlined
                  dense
                  class="ce_inputfield"
                  placeholder="Enter Employee's Insurance Member ID"
                  :rules="requiredRules"
                  hide-details
                  v-model.trim="newMember.subscriberMemberId"
                >
                </v-text-field>
              </div>

              <!-- Payer ID -->
              <div class="ce_input_container" v-if="payersList?.length >= 2 || inactiveEmployer">
                <!-- Show if there are two or more payer ID -->
                <!-- Hide if there is only one payer ID -->
                <!-- Also hide if Employer is inactive -->
                <label>Payer ID</label>

                <!-- If manual input -->
                <v-text-field
                  v-if="addItemsManually"
                  class="ce_inputfield"
                  placeholder="Company or Employer Name"
                  :rules="requiredRules"
                  outlined
                  dense
                  hide-details
                  v-model.trim="newMember.payerId"
                >
                </v-text-field>

                <!-- If Employer has 2 or more payers -->
                <v-select
                  v-else
                  outlined
                  dense
                  class="ce_inputfield"
                  placeholder="Select Payer ID"
                  :items="payersList"
                  :rules="requiredRules"
                  item-value="id"
                  item-text="payerName"
                  return-object
                  hide-details
                  v-model.trim="newMember.payerId"
                  :disabled="payersList?.length === 1"
                >

                  <!-- Show Payer IDs -->
                  <template #selection="{ item }">
                    <div>{{ item.payerName }} - {{ item.payerId }}</div>
                  </template>

                  <!-- List of Payers -->
                  <template #item="{ item }">
                    <div>{{ item.payerName }} - {{ item.payerId }}</div>
                </template>

                  <template #item-disabled="{ item }">
                    <div>{{ item.payerName }} - {{ item.payerId }}</div>
                  </template>

                </v-select>
              </div>

              <!-- Health Plan Type -->
              <div class="ce_input_container">
                <label>Health Plan Type</label>
                <v-radio-group v-model="newMember.planType" inline hide-details :rules="requiredRadioRules">
                  <v-radio label="HSA" value="HSA"></v-radio>
                  <v-radio label="Non HSA" value="Non-HSA"></v-radio>
                </v-radio-group>
              </div>

            </div>
          </v-card-text>
        </v-form>
      </v-card>

      <!-- BUTTONS -->
      <div class="dialog_buttons_2">
        <button class="dialog_double_button_2 dialog_btn_second_5" @click.prevent="closeNewMemberModal()" >
          Cancel
        </button>
        <button class="dialog_double_button_2 dialog_btn_primary_5" :disabled="!valid" @click.prevent="checkEligibility()" >
          Check Eligibility &amp; Register
        </button>
      </div>
    </v-dialog>

    <!-- Eligibility Successful -->
    <v-dialog v-model="eligibilitySuccess" width="500px" persistent>
      <v-card class="dialog_layout">
        <v-card-title class="d-flex justify-end">
          <v-icon role="button" @click="closeModals()" v-text="'mdi-close'" slot="end"></v-icon>
        </v-card-title>
        <v-card-text class="dialog_content">
          <div class="dialog_title dialog_primary">
              Success! This patient is eligible for this benefit and covered by their employer
          </div>
          <!-- <div class="dialog_subtext dialog_second">
            Sub text here 
          </div> -->
          <div class="dialog_buttons">
            <button class="dialog_single_button dialog_btn_primary" @click="selectClinicForMember()" >
              Register Patient
            </button>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Eligibility Failed -->
    <v-dialog v-model="eligibilityFailed" width="500px" persistent>
      <v-card class="dialog_layout">
        <v-card-title class="d-flex justify-end">
          <v-icon role="button" @click="closeModals()" v-text="'mdi-close'" slot="end"></v-icon>
        </v-card-title>
        <v-card-text class="dialog_content">
          <div class="dialog_title dialog_error">
            This patient is not eligible for this benefit. 
          </div>
          <!-- <div class="dialog_subtext dialog_second">
            Sub text here 
          </div> -->
          <div class="dialog_buttons">
            <button class="dialog_single_button dialog_btn_second" @click="closeModals()">
              Okay
            </button>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Eligibility Failed: Could not verify member -->
    <v-dialog v-model="eligibilityVerifyFailed" width="500px" persistent>
      <v-card class="dialog_layout">
        <v-card-title class="d-flex justify-end">
          <v-icon role="button" @click="closeModals()" v-text="'mdi-close'" slot="end"></v-icon>
        </v-card-title>
        <v-card-text class="dialog_content">
          <div class="dialog_title dialog_error">
           Oops, we could not verify this member's eligibility. 
          </div>
          <div class="dialog_subtext dialog_second">
            Please revise their information and try again
          </div>
          <div class="dialog_buttons">
            <button class="dialog_double_button dialog_btn_second dialog_btn_floatLeft" @click="closeModals()">
              Cancel
            </button>
            <button class="dialog_double_button dialog_btn_primary dialog_btn_floatRight" @click="reviseInfo()">
              Revise information
            </button>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Eligibility Failed: Employer does not offer benefit -->
    <v-dialog v-model="eligibilityNoBenifits" width="500px" persistent>
      <v-card class="dialog_layout">
        <v-card-title class="d-flex justify-end">
          <v-icon role="button" @click="closeModals()" v-text="'mdi-close'" slot="end"></v-icon>
        </v-card-title>
        <v-card-text class="dialog_content">
          <div class="dialog_title dialog_error">
            This employer does not offer this benefit. Your organization may choose to register this patient out of pocket
          </div>
          <!-- <div class="dialog_subtext dialog_second">
            Sub text here 
          </div> -->
          <div class="dialog_buttons">
            <button class="dialog_single_button dialog_btn_second" @click="closeModals()">
              Okay
            </button>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Server Error -->
    <v-dialog v-model="serverError" width="500px" persistent>
      <v-card class="dialog_layout">
        <v-card-title class="d-flex justify-end">
          <v-icon role="button" @click="closeModals()" v-text="'mdi-close'" slot="end"></v-icon>
        </v-card-title>
        <v-card-text class="dialog_content">
          <div class="dialog_title dialog_error">
            Oops, something went wrong. Please try again later
          </div>
          <!-- <div class="dialog_subtext dialog_second">
            Sub text here 
          </div> -->
          <div class="dialog_buttons">
            <button class="dialog_double_button dialog_btn_second dialog_btn_floatLeft" @click="closeModals()">
              Try again later
            </button>
            <button class="dialog_double_button dialog_btn_primary dialog_btn_floatRight" @click="reviseInfo()">
              Try now
            </button>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Eligibility Failed: Member has existing memberhsip elsewhere -->
    <v-dialog v-model="eligibilityExistingMembership" width="500px" persistent>
      <v-card class="dialog_layout">
        <v-card-title class="d-flex justify-end">
          <v-icon role="button" @click="closeModals()" v-text="'mdi-close'" slot="end"></v-icon>
        </v-card-title>
        <v-card-text class="dialog_content">
          <div class="dialog_title dialog_error">
            This patient has an existing membership with another provider. Please ask the patient to use the Apaly Member platform to change their membership or contact support at support@apaly.com
          </div>
          <!-- <div class="dialog_subtext dialog_second">
            Sub text here 
          </div> -->
          <div class="dialog_buttons">
            <button class="dialog_single_button dialog_btn_second" @click="closeModals()">
              Okay
            </button>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Eligibility Failed: Member is already in your Organization  -->
    <v-dialog v-model="eligibilityAlreadyInYourOrg" width="500px" persistent>
      <v-card class="dialog_layout">
        <v-card-title class="d-flex justify-end">
          <v-icon role="button" @click="closeModals()" v-text="'mdi-close'" slot="end"></v-icon>
        </v-card-title>
        <v-card-text class="dialog_content">
          <div class="dialog_title dialog_error">
            {{ this.newMember.firstName }}&nbsp;{{ this.newMember.lastName }} is already attributed to your organization
          </div>
          <!-- <div class="dialog_subtext dialog_second">
            Sub text here 
          </div> -->
          <div class="dialog_buttons">
            <button class="dialog_single_button dialog_btn_second" @click="openProfile()">
              View their profile
            </button>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Generic Error Modal -->
    <v-dialog v-model="genError" width="500px" persistent>
      <v-card class="dialog_layout">
        <v-card-title class="d-flex justify-end">
          <v-icon role="button" @click="genError = false" v-text="'mdi-close'" slot="end"></v-icon>
        </v-card-title>
        <v-card-text class="dialog_content">
          <div class="dialog_title dialog_error text-center">
            {{ genErrorMess ? genErrorMess:'Oops. Something went wrong' }}
          </div>
          <div class="dialog_buttons">
            <button class="dialog_single_button dialog_btn_primary" @click="genError = false" >
              Okay
            </button>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Select Clinic -->
    <SelectClinic @closeSelectClinic="selectedClinic = false" v-if="selectedClinic" :selectClinic="selectedClinic" :patientInfo="newMember"/>

    <!-- Subsriber's Info -->
    <SubscriberInfo @closeSubscriberInfoComp="needSubsriberInfo = false" v-if="needSubsriberInfo" :addSubscriberInfo="needSubsriberInfo" />

  </div>
</template>
<script>
import SelectClinic from "./selectClinic.vue";
import SubscriberInfo from "./subsriberInfoAdditional.vue";
import api from "../../../../services/api";
import moment from "moment";
import { mapGetters, mapState } from "vuex";
import FullscreenLoader from "../../../Common/FullscreenLoader.vue";

export default {
  components: {
    SelectClinic,
    SubscriberInfo,
    FullscreenLoader
  },
  props: {
    newMemberDialog: {
      type: Boolean,
    },
  },
  data() {
    return {
      inactiveEmployer: false,
      genError: false,
      genErrorMess: "",
      hideCheckEligibility: false,
      memberId: "",
      addItemsManually: false,
      searchTerm: "",
      employersCopy: [],
      allEmployers: [],
      employersList: [],
      eligibilityExistingMembership: false,
      eligibilityAlreadyInYourOrg: false,
      selectedClinic: false,
      needSubsriberInfo: false,
      eligibilityNoBenifits: false,
      serverError: false,
      eligibilityVerifyFailed: false,
      eligibilityFailed: false,
      eligibilitySuccess: false,
      newMember: {
        dob:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      },
      dateFormatted: '',
      calendarMenu: false,
      valid: false,
      subscriberItems: ["Self", "Spouse", "Dependent"],
      genders: ["Male", "Female"],
      locations: [],
      // employersList:[],
      payersList: [],
      eligibilitySteps: [
        {
          text: "TPA Verified",
          show: false,
          verify: false,
        },
        {
          text: "Employer & Plan Verified",
          show: false,
          verify: false,
        },
        {
          text: "Platform and Claim System Exchange",
          show: false,
          verify: false,
        },
        {
          text: "Claim System to Platform",
          show: false,
          verify: false,
        },
        {
          text: "Can register member",
          show: false,
          verify: false,
        },
      ],

      dialog: false,
      loader: false,
      errorMessage: "",
      activeMember: false,
      exist: false,
      attributed: false,
      checkedMemberId: '',
      iconVerify: "mdi-close-circle-outline",
      iconCheck: "mdi-check-circle-outline",
      errorModal: false,
      requiredRules: [(v) => !!v || "This Field is required"],
      requiredRadioRules: [v => !!v || 'Please select an option']
    };
  },
  computed: {
    // returnFormatedDate() {
    //   return moment(this.newMember.dob).format("MM/DD/YYYY");
    // },

    ...mapGetters(["getLocationId"]),
    ...mapState({
      // employersList: (state) => state.employersList,
      checkEligibilityPatientInfo: (state) => state.patientInfo,
      closeCheckEligibility: (state) => state.closeCheckEligibility,
    }),
  },
  created() {
    this.loader = true;
    this.getLocations();
    this.getEmployers();
  },
  watch: {
    "closeCheckEligibility" (nVal, oVal) {
      this.hideCheckEligibility = nVal; // turn hideCheckEligibility to false
    },
    "newMember.dob" (val) {
      this.dateFormatted =  moment(this.newMember.dob).format("MM/DD/YYYY");
    },
    "newMember.employer" (nVal, oVal) {
      if(nVal) {
        // this.newMember.employer;
        // console.log("newMember.employer: ", this.newMember.employer);
        this.payersList = this.newMember.employer.payers;
        // console.log("this.payersList: ", this.payersList);

        if(this.payersList?.length === 1) {
          this.newMember.payerId = this.payersList[0]; 
          if (nVal.status == "inactive") { // nVal is Employer List
            this.inactiveEmployer = true;
          }
          else {
            this.inactiveEmployer = false;
          }
          // console.log("Only 1 payer");
        }
        else {
          // console.log("2 or more payer");
        }
        // console.log("this.newMember.payerId: ", this.newMember.payerId);
      }
    }
  },
  methods: {
    closeModals() {
      this.hideCheckEligibility = false;
      this.loader = false;

      this.eligibilitySuccess = false;
      this.eligibilityFailed = false;
      this.eligibilityVerifyFailed = false;
      this.eligibilityNoBenifits = false;
      this.serverError = false;
      this.eligibilityExistingMembership = false;
      this.eligibilityAlreadyInYourOrg = false;

      // External Modals
      this.selectedClinic = false; 
      this.needSubsriberInfo = false;

      // Self Modal
      this.closeNewMemberModal();
    },
    selectClinicForMember() {
      // console.log("Select Clinic for Patient");

      // Close
      this.hideCheckEligibility = true;
      this.$store.commit("closeTheCheckEligibility", true);
      this.loader = false;
      this.eligibilitySuccess = false;

      // Open
      this.selectedClinic = true;
    },
    reviseInfo() {
      // Other Modals
      this.eligibilitySuccess = false;
      this.eligibilityFailed = false;
      this.eligibilityVerifyFailed = false;
      this.eligibilityNoBenifits = false;
      this.serverError = false;
      this.eligibilityExistingMembership = false;
      this.eligibilityAlreadyInYourOrg = false;

      this.hideCheckEligibility = false;
      this.loader = false;
    },
    selectFromActiveEmployers() {
      this.addItemsManually = false;
      // Clear Search
      this.searchTerm = "";
      // Clear fields
      this.newMember.employer = null;
      this.newMember.payerId = null;
    },
    addManually() {
      this.addItemsManually = true;
      // Close select
      this.$refs.mySelect.isMenuActive = false;
      // Clear Search
      this.searchTerm = "";
      // Clear fields
      this.newMember.employer = null;
      this.newMember.payerId = null;
    },
    onClickOutside (event) {
      // console.log('Clicked outside. Event: ', event)
    },
    focusSearchInput() {
      setTimeout(() => {
        document.getElementById("focusSearch").focus();
      }, 100);
    },
    searchingEmployers(e) {
      if (!this.searchTerm) {
        this.employersCopy = this.employersList;
      }

      this.employersList = this.employersCopy.filter((data) => {
        // console.log(data.companyName.toLowerCase().includes(this.searchTerm.toLowerCase()));
        return data.companyName.toLowerCase().includes(this.searchTerm.toLowerCase());
      });

      if(this.searchTerm == '') {
        this.employersList = this.allEmployers;
        this.employersCopy = this.allEmployers;
        // this.getEmployers();
      }
    },
    selectedEmployer() {
      this.getEmployers();
      this.employersList = this.allEmployers;
      this.employersCopy = this.allEmployers;
    },
    getEmployers() {
      api()
        .get(`/dpcproviders/employers/active`)
        .then((res) => {
          if (res?.data) {
            // console.log("getEmployers: ", res.data);
            this.employersList = res.data;
            this.employersCopy = res.data;
            this.allEmployers = res.data;
            
            let count = this.employersList.filter(employer => {
              return employer.payers && employer.payers.length > 1;
            }).length;

            let emp = this.employersList.filter(employer => {
              return employer.payers && employer.payers.length > 1;
            });

            let inactive = this.employersList.filter(employer => {
              return employer.payers && employer.status == "inactive";
            });

            // console.log("Count of employers with 2 or more payers: ", count);
            // console.log("Employers with 2 or more payers: ", emp);
            // console.log("Inactive Employers: ", inactive);
          }
          this.loader = false;
        })
        .catch((err) => {
          this.loader = false;
          // console.log("Error - getEmployers:", err);
        });
      
    },
    parseDate (date) {
      if (!date) return null

      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    openProfile() {
      // console.log("Selected Member: ", this.memberId);
      this.closeNewMemberModal();
      if(this.memberId) {
        this.$router.push("/dpc-providers/members/member/"+this.memberId).then(() => {
          window.location.reload();
        });
      }
      else {
        alert("No member with ID: " + this.memberId);
      }
    },
    checkEligibility() {
      // if (!this.newMember.location || this.newMember.location == null) {
      //   this.errorModal = true;
      // }
      this.$refs.form.validate();
      this.dialog = false;
      this.activeMember = false;

      if (this.valid) {
        this.errorMessage = "";
        // this.eligibilitySteps[0].show = true;
        // this.dialog = true;
        this.loader = true;
        // console.log("newMember: ", this.newMember);

        let params = {
          firstName: this.newMember?.firstName,
          lastName: this.newMember?.lastName,
          gender: this.newMember?.gender == 'Male' ? 'm':'f',
          dob: this.newMember?.dob,
          memberId: this.newMember?.memberId,
          employer: this.newMember?.employer?.id,
          subscriberRelation: this.newMember?.subscriberRelation,
          planType: this.newMember?.planType,
          ...(this.newMember?.payerId ? { payerId: this.newMember?.payerId?.payerId }: {}),
        }

        // If Relationship is NOT Self
        if (this.newMember?.subscriberMemberId) {
          params.subscriberMemberId = this.newMember.subscriberMemberId;
        }

        // console.log("params: ", params);

        // Eligibility Check
        api()
          .post("/dpcproviders/check/eligibility", params)
          .catch((err) => {
            if (err) {
              this.hideCheckEligibility = true;
              this.$store.commit("closeTheCheckEligibility", true);
              this.loader = false;
              this.serverError = true;
              // this.genError = true;
              // this.genErrorMess = err.response.data ? err.response.data : err.response;
              // console.log("Check Eligibility: Failed", err.response.data ? err.response.data : err.response);
            }
          })
          .then((res) => {
            if(res.data) {
              // console.log("Check Eligibility: Success", res.data);
              // console.log("Check Eligibility: Code", res.data.code);

              this.hideCheckEligibility = true;
              this.$store.commit("closeTheCheckEligibility", true);
              this.loader = false;

              // Include eligibility ObjectId
              if(res.data.eligibility) { 
                params.eligibility = res.data.eligibility;
              }

              // Then put this in Store so it can be reused
              this.$store.commit("setPatientInfo", params);

              // Store the available locations for the specific patient/member (locations)
              this.$store.commit("setAvailbaleLocations", res.data.locations);

              if(res.data.code == 200){
                // Sucessful Eligibility Check
                this.eligibilitySuccess = true;
                // console.log("Check Eligibility: 200");
              }
              else if(res.data.code == 201){
                // Sucessful Eligibility Check but need more info
                this.needSubsriberInfo = true;
                // console.log("Check Eligibility: 201");
                this.memberId = res.data?.eligibility;
              }
              else {
                // console.log("Check Eligibility: ELSE");
                // Custom Error Messages
                if(res.data.code == 401) { // Not eligibile
                  this.eligibilityFailed = true;
                  // console.log("Check Eligibility: 401");
                }
                else if(res.data.code == 400) { // Could not verify eligibility
                  this.eligibilityVerifyFailed = true;
                  // console.log("Check Eligibility: 400");
                }
                else if(res.data.code == 403) { // Employer does not offer this benefit
                  this.eligibilityNoBenifits = true;
                  // console.log("Check Eligibility: 403");
                }
                else if(res.data.code == 404) { // Member has an existing membership with another provider
                  this.eligibilityExistingMembership = true;
                  // console.log("Check Eligibility: 404");
                }
                else if(res.data.code == 405) { // Member already attributed to your organization
                  this.eligibilityAlreadyInYourOrg = true;
                  this.memberId = res.data?.member;
                  // console.log("Check Eligibility: 405");
                }
                else if(res.data.code == 500) { // Something went wrong
                  this.serverError = true;
                  // console.log("Check Eligibility: 500");
                }
              }
            }
          });
      } else {
        this.missingFields = true;
      }
    },
    getLocations() {
      api()
        .get("/dpcproviders/locations")
        .then((res) => {
          this.locations = res.data;
        });
    },
    reopenDialog() {
      this.$emit('reopenDialog');
    },
    closeNewMemberModal() {
      this.$emit("closeNewMemberModal");
    },
  },
};
</script>
