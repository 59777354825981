
import api from '../../services/api'

const state = {
    wizzardData:null,
    savedData: [],
}

const getters = {
    getWizzardData(state){
        state.wizzardData.requiredRules = [
            v => !!v || 'This Field is required',
        ];
        state.wizzardData.zipRules= [
            v => ( v && v.length == 5 ) || " Zip Code should be five digits long",
        ];
        state.wizzardData.emailRules= [
            v => !!v || 'This Field is required',
            v => /.+@.+/.test(v) || 'E-mail must be valid',
        ];
        state.wizzardData.taxIdRules= [
            v => !!v || 'This Field is required',
            v => /[0-9]{2}\-[0-9]{7}/.test(v) || "Tax ID should contain 9 digits, Please enter your Tax ID in XX-XXXXXXX format",
        ];
        state.wizzardData.discountRateRules= [
            v =>  /[0-9]/.test(v) || "The value should be from 0 to 100%",

        ];
        state.wizzardData.phoneRules= [
            (v) => /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(v) || "Phone number must be in a valid format (XXX XXX XXXX)",
        ];
     
        return state.wizzardData
    },
    getSavedWizzardData(state){
        state.savedData = JSON.parse(localStorage.getItem('savedWizzardData')) ?? state.savedData
        state.savedData.requiredRules = [
            v => !!v || 'This Field is required',
        ];
        state.savedData.emailRules= [
            v => /.+@.+/.test(v) || 'E-mail must be valid',
        ];
        state.savedData.zipRules= [
            v => ( v && v.length == 5 ) || " Zip Code should be five digits long",
        ];
        state.savedData.taxIdRules= [
            v => !!v || 'This Field is required',
            v => /[0-9]{2}\-[0-9]{7}/.test(v) || "Tax ID should contain 9 digits, Please enter your Tax ID in XX-XXXXXXX format",
        ];
        state.savedData.discountRateRules= [
            v =>  /[0-9]{2}/.test(v) || "The value should be from 0 to 100%",

        ];
        state.savedData.phoneRules= [
            (v) => /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(v) || "Phone number must be in a valid format (XXX XXX XXXX)",
        ];
        // if(this.updateData){
            //    let user = JSON.parse(localStorage.getItem('user'));
            // let advisorId = user.groupPortal[0].organization.employerAdvisor
            // api().delete(`/advisors/${advisorId}/onboard/${this.onboardId}`).then((res)=>{
            //     console.log(res)
            //       this.savedDataLocal = res
            //   });
            // }
                  // if(continueStep){
          //     let user = JSON.parse(localStorage.getItem('user'));
          //     let advisorId = user.groupPortal[0].organization.employerAdvisor
          //   if(this.updateData){
          //     api().put(`/advisors/${advisorId}/onboard/${this.onboardId}`,this.savedDataLocal).then((res)=>{
          //         console.log(res)
          //           this.savedDataLocal = res
          //       });
          //   }else{
          //     api().post(`/advisors/${advisorId}/onboard/${this.onboardId}` , this).then((res)=>{
          //         console.log(res)
          //           this.savedDataLocal = res
          //     });
          //     this.snackbar = true;
          //     this.snackbarText = 'Progress Saved'
          //     this.snackbarColor = 'green'
    
          //   }
           
          // }else{
          //   alert('please fill at least step 1 to save draft')
          // }
        return state.savedData
    },
}

const mutations = {
    setCleanWizzardData(state){
        state.wizzardData = {
            requiredRules:[
                v => !!v || 'This Field is required',
            ],
            emailRules: [
                v => /.+@.+/.test(v) || 'E-mail must be valid',
            ],
            zipRules: [
                v => ( v && v.length == 5 ) || " Zip Code should be five digits long",
            ],
            taxIdRules: [
                v => !!v || 'This Field is required',
                v => /[0-9]{2}\-[0-9]{7}/.test(v) || "Tax ID should contain 9 digits, Please enter your Tax ID in XX-XXXXXXX format",
            ],
            discountRateRules: [
                v =>  /[0-9]{2}/.test(v) || "The value should be from 0 to 100%",
            ],
            phoneRules: [
                (v) => /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(v) || "Phone number must be in a valid format (XXX XXX XXXX)",
            ],
            stepTitle:[
                'Employer Onboarding In Progress',
                'General Information',
                'General Information',
                'Generate Provider List',
                'Rates & Contract',
            ],
            showFinalDialog:false,
            displayAllErrors:'none',
            displayEmailErrors:'none',
            step:0,
            step1data:{
                company_legal_name:null,
                company_display_name:null,
                tax_id:null,
                first_name:null,
                last_name:null,
                title:null,
                email:null
            },
            step2data:{
                provider_network_vendor:null,
                Contracted_network_discount_rate:null,
                health_plan_name:null,
                number_of_plan_members:null,
                number_of_employees:null,
                plan_administrator_company_name:null,
                administrator_contact_first_name:null,
                administrator_contact_last_name:null,
                administrator_contact_email:null,
                administrator_contact_phone:null,
            },

            zip:null,
           
            zipRules:[
                v => ( v && v.length == 5 ) || " Zip Code should be five digits long",
            ],
            zipCodeList:[],
            providersList:[],
            jsondata:[{
                'Zip Code':'',
            }],
            excelError:false,
            results:false,
            arrayLength:false,
            urlData:'',

            step4data:{
                digital_direct_healthcare_contract:false,
                inpatient:null,
                outpatient:null,
                acp_and_dcp_service:false,
                dpc:null,
                apc:null,
            }
        }
    },
    continueWizzardData(state,data){
        state.wizzardData = data;
        // let index = state.savedData.findIndex(c => c.uniqid == data.uniqid);
        // if(index != -1)
        //     state.wizzardData = state.savedData[index];
        // else
        //     state.wizzardData = null
    },
    removeWizzardData(state,uniqid){
        let index = state.savedData.findIndex(c => c.uniqid == uniqid);
        if(index != -1)
            state.savedData.splice(index,1);
        localStorage.setItem('savedWizzardData',JSON.stringify(state.savedData))
    },
    saveWizzardData(state,data){
        let index = state.savedData.findIndex(c => c.uniqid == data.uniqid);
        if(index != -1){
            state.savedData[index] = data;
        }else{
            state.savedData.push(data)
        } 
        let user = JSON.parse(localStorage.getItem('user'));
        let advisorId = user.groupPortal[0].organization.employerAdvisor
       
        // api().post(`/advisors/${advisorId}/onboard`,  data).then((res)=>{
        //     state.savedData = res.data
        // });
         localStorage.setItem('savedWizzardData',JSON.stringify(state.savedData))
    },  
}

const actions = {
}

export default {
    state,
    getters,
    mutations,
    actions
}
