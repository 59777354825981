<template>
  <v-row class="add-client-form no-padding">
    <successModal
      ref="success_modal"
      v-on:confirm="closeSuccessModal"
    ></successModal>
    <div class="col-md-12 form-box">
      <div class="row">
        <div class="col-md-12">
          <h3 class="font ml-3">Add & Invite New Employer</h3>
        </div>
      </div>
      <div class="form-row-height">
        <v-form class="row col-md-6" ref="form" v-model.trim="valid">
          <div class="col-md-12 col-lg-12 pb-0">
            <label class="font text-gray" for="">Company Name </label>
            <v-text-field
              required
              solo
              tabindex="1"
              v-model.trim="companyName"
              :rules="requiredRules"
            ></v-text-field>
          </div>
          <!-- <div class="col-md-8 col-lg-6">
            <label class="font text-gray" for="">Current Network Vendor<i class="fas fa-question-circle  yellow-icons"></i></label>
                <v-text-field
                required
                solo
               tabindex="5"
                v-model.trim="networkVendor"
                :rules="requiredRules"
                ></v-text-field>
        </div> -->
          <div class="col-md-12 col-lg-12 pb-0 pt-0">
            <label class="font text-gray" for="">Tax ID </label>
            <v-text-field
              required
              solo
              @keyup="showSlash($event)"
              tabindex="2"
              v-model.trim="taxId"
              :rules="taxIdRules"
              counter="10"
              placeholder="00-0000000"
              maxlength="10"
            ></v-text-field>
          </div>
          <!-- <div class="col-md-8 col-lg-6">
            <label class="font text-gray" for="">Current Network Discount Rate (i.e Percent Discount off Billed Charges)<i class="fas fa-question-circle  yellow-icons"></i></label>
                <v-text-field
               tabindex="6"
                 type="number"
                required
                :rules="requiredRules"
                 v-model.trim="networkDiscountRate"
                solo
                ></v-text-field>
        </div> -->

          <div class="col-md-12 col-lg-12 pb-0 pt-0">
            <label class="font text-gray" for=""
              >Approximate Number of Employees</label
            >
            <v-text-field
              required
              type="number"
              v-model.trim="noEmployees"
              solo
              tabindex="3"
            ></v-text-field>
          </div>

          <div class="col-md-12 col-lg-12 pb-0 pt-0">
            <label class="font text-gray" for=""
              >Approximate Number of Plan Members</label
            >
            <v-text-field
              required
              v-model.trim="noPlanMembers"
              solo
              type="number"
              tabindex="4"
            ></v-text-field>
          </div>
        </v-form>
      </div>

      <div class="col-md-12 d-flex align-end flex-column">
        <div>
          <v-btn
            class="blue-buttons mr-6"
            :disabled="loadData"
            @click="
              $router.push(
                `/provider-advisor/providers/${routeParams}/employers`
              )
            "
          >
            Cancel
          </v-btn>
          <v-btn class="blue-buttons" @click="submit" :disabled="loadData">
            Add & Invite Employer
          </v-btn>
        </div>
      </div>
    </div>
  </v-row>
</template>
<script>
import api from "../../../../../services/api";
import successModal from "../../../../TablesTemplate/SuccessDialog.vue";
export default {
  components: {
    successModal,
  },
  data() {
    return {
      companyName: "",
      taxId: "",
      noEmployees: Number,
      noPlanMembers: Number,
      networkVendor: "",
      networkDiscountRate: 0,
      requiredRules: [(v) => !!v || "This Field is required"],
      loadData: false,
      valid: false,
      advisorId: 1,
      taxIdRules: [
        (v) => !!v || "This Field is required",
        (v) =>
          !!/[0-9]{2}\-[0-9]{7}/.test(v) ||
          "Tax ID should contain 9 digits, Please enter your Tax ID in XX-XXXXXXX format",
      ],
    };
  },
  computed: {
    routeParams() {
      return this.$route.params.name;
    },
  },
  methods: {
  showSlash(event){
      if (this.taxId.length == 2 && event.keyCode != 8) {
        this.taxId += "-";
      }
      if (this.taxId.length == 10) {
        api()
          .get("auth/isValidTaxId?taxId=" + this.taxId)
          .catch((res) => {
            if (res) {
              this.taxIdRules = [res.response.data.message];
            }
          });
      } else {
        this.taxIdRules = [
          (v) => !!v || "This Field is required",
          (v) =>
            !!/[0-9]{2}\-[0-9]{7}/.test(v) ||
            "Tax ID should contain 9 digits, Please enter your Tax ID in XX-XXXXXXX format",
        ];
      }
    },
    closeSuccessModal() {
      this.$router.push(
        `/provider-advisor/providers/${this.routeParams}/employers`
      );
    },
    submit() {
      this.$refs.form.validate();

      if (this.valid) {
        this.loadData = true;
        let data = {
          companyName: this.companyName,
          taxId: this.taxId,
          noEmployees: Number(this.noEmployees),
          noPlanMembers: Number(this.noPlanMembers),
          // "networkVendor": this.networkVendor,
          // "networkDiscountRate":Number(this.networkDiscountRate)
        };

        let user = JSON.parse(localStorage.getItem("user"));
        let advisorId = user.groupPortal[0].organization.providerAdvisor;
        let provider = JSON.parse(localStorage.getItem("provider"));

        api()
          .post(
            `/advisors/${advisorId}/providers/${provider._id}/employers`,
            data
          )
          .then((res) => {
            if (res) {
              let text = "Employer added successfully";
              this.$refs.success_modal.openPopUp(text);
              this.loadData = false;
            }
          });
      }
    },
  },
};
</script>
<style>
.add-client-form {
  padding: 1.5rem;
}

.yellow-icons {
  color: rgb(224, 171, 36);
}
.blue-buttons {
  background: #4072b7 !important;
  color: white !important;
}
</style>