<template>
  <v-row class="add-client-form no-padding">
    <successModal ref="success_modal" v-on:confirm="createModal"></successModal>
    <div class="col-md-12 form-box">
      <div class="row">
        <div class="col-md-12">
          <h3 class="form-title font">Request New Form of Contract</h3>
        </div>
      </div>
      <div class="form-row-height">
        <v-form v-model.trim="valid" ref="form" class="row">
          <div class="col-md-6 row">
            <div class="col-md-6 pb-0">
              <label class="font text-gray" for="">Your First Name</label>
              <v-text-field
                required
                :rules="requiredRules"
                v-model.trim="firstName"
                solo
              ></v-text-field>
            </div>
            <div class="col-md-6  pb-0">
              <label class="font text-gray" for="">Last Name</label>
              <v-text-field
                required
                :rules="requiredRules"
                solo
                v-model.trim="lastName"
              ></v-text-field>
            </div>
            <div class="col-md-12 pt-0 pb-0">
              <label class="font text-gray" for="">Email</label>
              <v-text-field
                required
                :rules="emailRules"
                solo
                v-model.trim="email"
              ></v-text-field>
            </div>
            <div class="col-md-12 pt-0 pb-0">
              <label class="font text-gray" for="">Title</label>
              <v-text-field
                :rules="requiredRules"
                required
                v-model.trim="title"
                solo
              ></v-text-field>
            </div>

            <div class="col-md-12 pt-0 pb-0">
              <label class="font text-gray" for="">Organization Name</label>
              <v-text-field
                :rules="requiredRules"
                required
                v-model.trim="organization"
                solo
              ></v-text-field>
            </div>
            <div class="col-md-6 pt-0 pb-0">
              <label class="font text-gray" for="">City</label>
              <v-text-field
                :rules="requiredRules"
                required
                v-model.trim="city"
                solo
              ></v-text-field>
            </div>
            <div class="col-md-3 pt-0 pb-0">
              <label class="font text-gray" for="">State</label>
              <v-select
                :rules="requiredRules"
                required
                v-model.trim="state"
                solo
                :items="states"
                item-text="name"
                item-value="name"
              ></v-select>
            </div>
            <div class="col-md-3 pt-0 pb-0">
              <label class="font text-gray" for="">Zip</label>
              <v-text-field
                required
                :rules="requiredRules"
                solo
                type="number"
                v-model.trim="zip"
              ></v-text-field>
            </div>
          </div>

          <div class="col-md-6  pb-0">
            <label for="" class="font text-gray"
              >Tell Us About the Type of Contract You are Looking For</label
            >
            <v-textarea
              rows="10"
              solo
              :rules="requiredRules"
              v-model.trim="description"
              name="input-7-4"
            ></v-textarea>
          </div>
        </v-form>
      </div>
      <div class="col-md-12 d-flex align-end flex-column">
        <div>
          <v-btn
            class="blue-buttons mr-6 mt-2"
            @click="
              $router.push(
                `/employer-advisor/employers/${routeParams}/contracts`
              )
            "
          >
            Cancel
          </v-btn>
          <v-btn class="blue-buttons mt-2" @click="requestContract">
            Submit
          </v-btn>
        </div>
      </div>
    </div>
    <errorModal ref="error_modal" v-on:confirm="closeErrorModal"></errorModal>
  </v-row>
</template>
<script>
import api from "../../../../../services/api";
import successModal from "../../../../TablesTemplate/SuccessDialog.vue";
import errorModal from "../../../../TablesTemplate/ErrorDialog.vue";
export default {
  components: {
    successModal,
    errorModal,
  },
  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      title: "",
      organization: "",
      city: "",
      state: "",
      zip: "",
      description: "",
      states: [],
      valid: false,
      requiredRules: [(v) => !!v || "This Field is required"],
      emailRules: [
        (v) => !!v || "This Field is required",
        (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || "E-mail must be valid",
      ],
      valid: false,
    };
  },
  computed: {
    getSingleAdvisor() {
      return this.$store.getters.getAdvisorGetter;
    },
    routeParams() {
      return this.$route.params.name;
    },
  },
  created() {
    this.getStates();
  },
  methods: {
    createModal() {
      this.$router.push(
        `/employer-advisor/employers/${this.routeParams}/contracts`
      );
    },
    closeErrorModal() {
      this.$refs.error_modal.closePopUp();
    },
    requestContract() {
      this.$refs.form.validate();
      if (this.valid) {
        let user = JSON.parse(localStorage.getItem("user"));
        let advisorId = user.groupPortal[0].organization.employerAdvisor;
        let employer = JSON.parse(localStorage.getItem("employer"));
        let data = {
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          title: this.title,
          organizationName: this.organization,
          city: this.city,
          state: this.state,
          zipCode: this.zip,
          contract: this.description,
        };
        api()
          .post(
            `/advisors/${advisorId}/employers/${employer._id}/contract/request`,
            data
          )
          .catch((err) => {
            if (err) {
              this.$refs.error_modal.openPopUp(err.response.data.message);
            }
          })
          .then((res) => {
            if (res) {
              let text = "Request sent successfully";
              this.$refs.success_modal.openPopUp(text);
            }
          });
      }
    },
    getStates() {
      api()
        .get("/api/states")
        .then((res) => {
          res.data.forEach((el) => {
            if (el.active) {
              this.states.push(el);
            }
          });
        });
    },
  },
};
</script>
